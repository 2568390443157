import { useEffect, useState } from 'react';
import { auth } from '../config/firebaseApp';
import { getRole } from '../services/firebase.service';
import { getUserInfoByUid } from '../services/client.service';
import { UserRolesEnum } from '../utils/rolesEnum';

export const useAuthStatus = () => {
  // assume user to be logged out
  const [loggedInRole, setLoggedInRole] = useState<boolean | string>(false);

  // keep track to display a spinner while auth status is being checked
  const [checkingStatus, setCheckingStatus] = useState(true);
  const [userData, setUserData] = useState<any>();

  useEffect(() => {
    // auth listener to keep track of user signing in and out
    auth.onAuthStateChanged(async (user) => {
      setCheckingStatus(true);
      if (user) {
        const role = await getRole(auth);
        const userInfo = await getUserInfoByUid(
          user.uid,
          !(role === UserRolesEnum.PROSPECTIVE_CLIENT || role === UserRolesEnum.CLIENT)
        );
        setLoggedInRole(role);
        setUserData(userInfo);
      } else {
        setLoggedInRole(false);
      }

      setCheckingStatus(false);
    });
  }, []);

  return { loggedInRole, checkingStatus, userData };
};
