import React, { useState } from 'react';
import { Box, Grid, Button, TextField, Link } from '@mui/material';
import { Formik, type FormikHelpers } from 'formik';
import { useAppDispatch } from '../../../../store/store';
import * as yup from 'yup';
import { COLORS } from './../../../../utils/colors';
import { UserRolesEnum } from '../../../../utils/rolesEnum';
import { useAuthStatus } from '../../../../hooks/useAuthStatus';
import { loginUser } from '../../../../utils/authUtils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../../../store/user/userAction';
import { signOut } from 'firebase/auth';
import { auth } from '../../../../config/firebaseApp';
import { userAccountStatus } from '../../../../utils/userUtils';
import { saveAuditLogOnLogin } from '../../../../services/firebase.service';
import { isIOS } from '../../../../utils/platform';
import { getPasswordResetLink } from '../../../../services/user.service';
import Loading from '../../../../components/loading/loading';

interface LoginFormData {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const styles = {
  gridItem: {
    '&&': {
      padding: '0px 0px 20px 0px',
    },
  },
  errorMessage: {
    color: COLORS.result.error,
    display: 'block',
    marginTop: '16px',
  },
  forgotPassword: {
    mt: 2,
    color: COLORS.palette.gray,
    textDecoration: 'none',
    fontSize: '16px',
    lineHeight: '19px',
  },
};

export default function Login() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const initialValues: LoginFormData = { email: '', password: '' };
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const { loggedInRole, userData } = useAuthStatus();

  const handleLogout = async () => {
    dispatch(logoutUser());
    signOut(auth);
  };

  const handleSubmit = (values: LoginFormData, { setSubmitting }: FormikHelpers<LoginFormData>) => {
    handleLogin(values.email, values.password);
    setSubmitting(false);
  };

  const handleLogin = async (email: string, password: string) => {
    try {
      await loginUser(email, password, dispatch);
    } catch (error: any) {
      if (error?.message && error?.message.includes('auth/user-not-found')) {
        setError('Account not found!');
      } else {
        setError(error?.message);
      }
    }
    if (auth.currentUser) {
      saveAuditLogOnLogin();
    }
  };
  React.useEffect(() => {
    (async () => {
      if (loggedInRole) {
        if (userData.isNewAccount) {
          setIsLoading(true);
          const linkData: any = await getPasswordResetLink(userData.email);
          window.location.href = linkData.data.link;
        } else if (userData?.account === userAccountStatus.DELETED) {
          toast.error('Your Account has been Deleted!');
          handleLogout();
        } else if (loggedInRole === UserRolesEnum.CLIENT) {
          navigate('/client/home');
        } else if (loggedInRole === UserRolesEnum.PROSPECTIVE_CLIENT) {
          navigate('/client/home');
        } else {
          navigate('/admin/dashboard');
        }
      }
    })();
    // eslint-disable-next-line
  }, [userData]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.theme.primaryColor,
      }}>
      <Box height='auto' width='320px'>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <>
              <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center', filter: 'drop-shadow(10px 10px 100px #3F31C1)' }}>
                  <img alt='Logo' src={'/assets/logo.png'} style={isIOS() ? { width: 100 } : { width: 200 }} />
                </Grid>
                <Grid item xs={12} sx={styles.gridItem} mt={5}>
                  <TextField
                    placeholder='Enter email'
                    fullWidth
                    name='email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    variant='standard'
                    sx={{
                      input: {
                        fontSize: 20,
                        lineHeight: 23,
                        color: 'white',
                        borderBottom: '1px solid #ffffff',
                      },
                      '&& :is(div):hover::before': {
                        borderBottom: '1px solid #ffffff',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={styles.gridItem}>
                  <TextField
                    type='password'
                    placeholder='Enter password'
                    fullWidth
                    name='password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    variant='standard'
                    sx={{
                      input: {
                        fontSize: 20,
                        lineHeight: 23,
                        color: 'white',
                        borderBottom: '1px solid #ffffff',
                      },
                      '&& :is(div):hover::before': {
                        borderBottom: '1px solid #ffffff',
                      },
                    }}
                  />
                </Grid>
                {error && <p style={styles.errorMessage}>{error}</p>}
                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '30px' }}>
                  <Button
                    onClick={() => handleSubmit()}
                    fullWidth
                    type='submit'
                    variant='contained'
                    // @ts-ignore
                    color='darkModePrimary'
                    size='large'
                    sx={{
                      borderRadius: '10px',
                      height: '90px',
                      marginBottom: '30px',
                      fontSize: '24px',
                      lineHeight: '28px',
                      color: '#fff',
                    }}>
                    Login
                  </Button>
                  <Link href='/forgotPassword' sx={styles.forgotPassword}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </Box>
    </div>
  );
}
