import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, TextField, IconButton, styled, Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import { Formik, type FormikHelpers } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { addNewProspectiveClient } from '../../../services/client.service';
import { useAppSelector } from '../../../store/store';
import { UserModel } from '../../../store/user/userModel';
import { fetchInfoSessionList } from '../../../services/infoSession.service';
import { InfoSessionEditModel, infoSessionEmpty } from '../../../store/infoSession/infoSessionModel';
import dayjs from 'dayjs';

interface AddProspectiveClientModalProps {
  open: boolean;
  onClose: () => void;
}

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface IProspectiveClient {
  email: string;
}

const AddProspectiveClientModal = (props: AddProspectiveClientModalProps) => {
  const { open } = props;
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [selectedInfoSession, setSelectedInfoSession] = useState<InfoSessionEditModel | null>(infoSessionEmpty);
  const [infoSessionsList, setInfoSessionList] = useState<InfoSessionEditModel[]>([]);
  const initialValues: IProspectiveClient = {
    email: '',
  };

  const schema = yup.object().shape({
    email: yup.string().email('Invalid email address').required('Email is required'),
  });

  const onClose = () => {
    props.onClose();
    setSelectedInfoSession(null);
  };

  const handleSubmit = async (values: IProspectiveClient, { setSubmitting }: FormikHelpers<IProspectiveClient>) => {
    try {
      const result: any = await addNewProspectiveClient(values.email, selectedInfoSession?.id || '', user?.role);
      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message);
    }
    setSubmitting(false);
    onClose();
  };

  const getInfoSessionList = async () => {
    try {
      const unsubscribe = await fetchInfoSessionList((sessionsList: InfoSessionEditModel[]) => {
        if (sessionsList && sessionsList.length) {
          const sessionDateTimeList = sessionsList.map((item: InfoSessionEditModel) => ({
            ...item,
            dateTime: new Date(item.dateTime).toISOString(),
          }));

          const upcomingSorted = sessionDateTimeList
            .filter((session) => new Date(session.dateTime).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0))
            .sort((a, b) => new Date(a.dateTime).setHours(0, 0, 0, 0) - new Date(b.dateTime).setHours(0, 0, 0, 0));

          setInfoSessionList(upcomingSorted);
        }
      });
      return () => {
        unsubscribe();
      };
    } catch (err) {
      // Handle error here
    }
  };

  useEffect(
    () => {
      getInfoSessionList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema} validateOnMount={true}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
          <Box sx={styles.centeredStyle}>
            <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
              <h2 id='simple-modal-title'>Invite Prospective Client</h2>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  size='small'
                  name='email'
                  type='email'
                  label='Enter email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  value={selectedInfoSession}
                  disableClearable
                  size='small'
                  onChange={(event: any, newValue: any) => {
                    setSelectedInfoSession(newValue);
                  }}
                  options={infoSessionsList.map((option: any) => ({ ...option }))}
                  getOptionLabel={(option: any) =>
                    option.id
                      ? `${option.title} | ${dayjs(option.dateTime)
                          .tz('America/New_York')
                          .format('MMMM DD, YYYY, hh:mm A')} | ${option.sessionType} | ${
                          option.location ? option.location : ''
                        }`
                      : ''
                  }
                  renderInput={(params) => <TextField {...params} label='Select Info session' />}
                />
              </Grid>
              <Grid container justifyContent={'center'} pt={4}>
                <Grid item>
                  <LoadingButton
                    label='Send Invite'
                    onClick={() => {
                      handleSubmit();
                    }}
                    loading={isSubmitting}
                    disabled={!isValid || !selectedInfoSession?.id}
                    size='medium'
                    type='submit'
                    variant='contained'
                    styles={{ textTransform: 'none' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default AddProspectiveClientModal;

const styles = {
  centeredStyle: {
    width: '500px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    borderRadius: '14px',
    '@media (max-width: 480px)': {
      width: '90%',
      height: '90%',
      padding: '20px',
      margin: '10px',
    },
  },
};
