import * as React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { addNewClient } from '../../../../services/client.service';
import { UserModel } from '../../../../store/user/userModel';
import { useAppSelector } from '../../../../store/store';
import ClientForm from './clientForm';

const CreateClient = () => {
  const navigate = useNavigate();
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const isSelfCreated = false;

  const onFormSubmit = async (payload: any) => {
    try {
      const result = await addNewClient(payload, isSelfCreated, user);

      if (result.success) {
        toast.success(result.message);
        navigate('/admin/dashboard');
      } else {
        toast.error(result.message);
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <Box mt={2} pt={5}>
      <Typography variant='h5' mb={2}>
        Create Client
      </Typography>

      <ClientForm
        onSubmit={(paylad: any) => {
          onFormSubmit(paylad);
        }}
        isSelf={false}
      />
    </Box>
  );
};

export default CreateClient;
