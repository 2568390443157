import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  Modal,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
} from '@mui/material';
import {
  completeProgramTrackForClients,
  updateClientMapsByClientIds,
} from '../../../services/programTrackClientMap.service';
import { useState } from 'react';
import styled from '@emotion/styled';
import { ClientMapStatus } from '../../../utils/clientMapStatus';
import { CheckOutlined, Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { convertTimeStampToDate } from '../../../utils/dateTime';
import { UserRolesEnum } from '../../../utils/rolesEnum';
import { programTrackTypes } from '../../../utils/programTrackEnums';
import SplitButton from '../../SplitButton/SplitButton';
import { AttendanceButtons, AttendanceStatus } from '../../../utils/attendance';
import { LoadingButton } from '../../LoadingButton/LoadingButton';

interface IAttendanceModal {
  open: boolean;
  onClose: Function;
  onSubmit: Function;
  toggleDropoutReasonModal: Function;
  clientList: any[];
  programTrackId: string;
  milestoneStep: string;
  userRole: string;
}

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const AttendanceModal = (props: IAttendanceModal) => {
  const { open, onClose, onSubmit, clientList, programTrackId, milestoneStep, userRole, toggleDropoutReasonModal } =
    props;
  const [selectedClientList, setSelectedClientList] = useState<any[]>([]);
  const [selectedButtonId, setSelectedButtonId] = useState<number>(0);
  const [openIndex, setOpenIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const shouldDropdownVisible = clientList?.length === 1 && clientList[0]?.status === ClientMapStatus.ACTIVE;
  const shouldOptionVisible = selectedClientList?.length === 0 && clientList?.length > 1;
  const isUserAuthorized =
    userRole === UserRolesEnum.SUPER_ADMIN || userRole === UserRolesEnum.TRAINER || userRole === UserRolesEnum.ADMIN;

  //checkbox change handler
  const handleChange = (client: any) => {
    const clnt = selectedClientList.find((clnt) => clnt.clientId === client.clientId);
    if (clnt) {
      const updatedList = selectedClientList.filter((cl) => cl.clientId !== clnt.clientId);
      setSelectedClientList(updatedList);
    } else {
      setSelectedClientList([...selectedClientList, client]);
    }
  };

  const handleClick = (index: any) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  const handleSubmit = async (status: string, attendance: string) => {
    setLoading(true);
    const clientIds = (clientList?.length === 1 ? clientList : selectedClientList).map((client) => client.clientId);
    await updateClientMapsByClientIds(clientIds, status, milestoneStep, programTrackId, attendance);
    onSubmit();
    setLoading(false);
    handleOnClose();
  };

  const markAsComplete = async () => {
    setLoading(true);
    const clientIds: any[] = clientList.map((i) => {
      return i.clientId;
    });
    await completeProgramTrackForClients(clientIds, String(milestoneStep), programTrackId);
    onSubmit();
    setLoading(false);
    handleOnClose();
  };

  const handleDropout = () => {
    handleOnClose();
    toggleDropoutReasonModal();
  };

  const attendanceHandler = () => {
    switch (selectedButtonId) {
      case 1:
        handleDropout();
        break;
      case 2:
        markAsComplete();
        break;
      case 3:
        handleSubmit(
          parseInt(milestoneStep) === parseInt(clientList[0]?.milestones.length)
            ? ClientMapStatus.PRE_COMPLETE
            : ClientMapStatus.ACTIVE,
          AttendanceButtons[2].value
        );
        break;
      case 4:
        handleSubmit(
          parseInt(milestoneStep) === parseInt(clientList[0]?.milestones.length)
            ? ClientMapStatus.PRE_COMPLETE
            : ClientMapStatus.ACTIVE,
          AttendanceButtons[3].value
        );
        break;
      default:
        break;
    }
  };

  const filterCondition = (item: any) => {
    if (isUserAuthorized && !shouldOptionVisible) {
      return true;
    }
    if (shouldDropdownVisible) {
      return item.value === 'dropout';
    }
    return false;
  };

  let filteredAttendanceButtons: any[] = AttendanceButtons.filter(filterCondition);

  useEffect(() => {
    const val = filteredAttendanceButtons.find((item) => item.value === AttendanceStatus.PRESENT)?.id || 0;
    setSelectedButtonId(val);
    // eslint-disable-next-line
  }, [filteredAttendanceButtons.length > 0]);

  useEffect(() => {
    setOpenIndex(null);
    setSelectedClientList([]);
    // eslint-disable-next-line
  }, [!open]);

  const handleOnClose = () => {
    setSelectedClientList([]);
    setSelectedButtonId(3);
    setOpenIndex(null);
    onClose();
  };

  //render client list
  const renderClientList = (clientList: any[]) => {
    const milestones = clientList[0]?.milestones;
    return clientList.map((client: any, index: any) => (
      <>
        <Box width={'400px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} key={index}>
          <Typography variant='button' textTransform={'none'}>
            {client.firstName + ' ' + client.lastName}
          </Typography>
          <Box justifyContent={'right'}>
            <IconButton onClick={() => handleClick(index)}>
              {openIndex === index ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            {clientList?.length > 1 ? <Checkbox size='small' onChange={() => handleChange(client)} /> : null}
          </Box>
        </Box>

        {/* Milestones list */}
        <Collapse in={openIndex === index} timeout='auto' unmountOnExit>
          <List component={'div'} disablePadding>
            {client.completedMilestones?.map((milestone: any, index: any) => (
              <ListItem key={index} disablePadding>
                <ListItemText
                  primary={milestones[parseInt(milestone.index)].name + ':'}
                  secondary={
                    <>
                      <Typography mr={2}>
                        {convertTimeStampToDate(milestone.completionDate).format('MMMM DD, YYYY')}
                      </Typography>
                      {clientList[0]?.type === programTrackTypes.COHORT.value ? (
                        <Typography>
                          {convertTimeStampToDate(milestones[parseInt(milestone.index)].deadline).format(
                            'MMMM DD, YYYY'
                          )}
                        </Typography>
                      ) : (
                        <Typography>
                          {milestones[parseInt(milestone.index)].deadline.toLocaleDateString('en-US', {
                            month: 'long',
                            day: '2-digit',
                            year: 'numeric',
                          })}
                        </Typography>
                      )}
                    </>
                  }
                  primaryTypographyProps={styles.listItemPrimaryText}
                  secondaryTypographyProps={styles.listItemSecondaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>
            ))}
            {/* Ongoing milestone */}
            <ListItem disablePadding>
              <ListItemText
                primary={milestones[parseInt(client?.completedMilestones?.length || 0)]?.name + ':'}
                secondary={'Ongoing'}
                primaryTypographyProps={styles.listItemPrimaryText}
                sx={styles.listItemContainer}
              />
            </ListItem>
          </List>
        </Collapse>

        {/* Divider */}
        {clientList?.length > 1 ? (
          clientList?.length === index + 1 ? null : (
            <Box sx={{ my: 1 }}>
              <Divider />
            </Box>
          )
        ) : null}
      </>
    ));
  };

  return (
    <ModalWrapper open={open} onClose={handleOnClose}>
      <Box sx={styles.centeredStyle}>
        {/* Modal Header */}
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6'>Attendance Sheet</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        {/* Modal Content */}
        <Grid container py={3}>
          <Grid item>
            {clientList?.length > 0 ? (
              renderClientList(
                clientList.filter((cl) => (clientList.length > 1 ? cl.status === ClientMapStatus.ACTIVE : true))
              )
            ) : (
              <ListItemText primary='No client yet' primaryTypographyProps={{ textAlign: 'center' }} />
            )}
          </Grid>
        </Grid>

        {/* Divider for buttons */}
        <Box py={2}>
          <Divider />
        </Box>

        {/* Attendance buttons */}
        <Grid container spacing={1} display={clientList?.length > 0 ? 'flex' : 'none'} justifyContent={'space-between'}>
          <Grid item>
            <SplitButton
              loading={loading}
              options={filteredAttendanceButtons}
              onMenuItemClick={(index: number) => {
                setSelectedButtonId(index);
              }}
              defaultValue={AttendanceStatus.PRESENT}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              size='small'
              color={'error'}
              variant={'contained'}
              loading={loading}
              startIcon={<CheckOutlined />}
              disabled={selectedButtonId > 0 ? false : true}
              onClick={attendanceHandler}
              typographyComp={
                <Typography variant='body2' textTransform={'none'} noWrap>
                  Confirm
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Box>
    </ModalWrapper>
  );
};

export default AttendanceModal;

const styles = {
  centeredStyle: {
    width: '500px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    borderRadius: '14px',
    '@media (max-width: 480px)': {
      padding: '20px',
      margin: '10px',
    },
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 1,
  },
  listItemSecondaryText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
