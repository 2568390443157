import { setProgramTracksData } from './../store/programTracks/programTracksAction';
import { functions } from '../config/firebaseApp';
import { httpsCallable } from 'firebase/functions';
import { collection, doc, getDocs, getDoc, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { AppDispatch } from '../store/store';
import { db } from '../config/firebaseApp';
import type { Auth } from 'firebase/auth';
import { publicIpv4 } from 'public-ip';
import { toast } from 'react-toastify';

interface INotificationPayload {
  createdBy: string;
  firstName: string;
  clientType: string;
}

export interface INotification {
  createdAt: Date;
  description: string;
  isRead: boolean;
  title: string;
  userId: string;
}

export const fetchProgramTracks = () => async (dispatch: AppDispatch) => {
  const data = collection(db, 'packages');
  const snapshots = await getDocs(data);
  const programTracks: any[] = snapshots.docs.map((doc) => doc.data());

  if (programTracks.length) {
    programTracks.sort((a, b) => a.order - b.order);
    const orderedPrograms = programTracks.map((program) => {
      const programCopy = { ...program };
      delete programCopy['order'];

      return programCopy;
    });
    dispatch(setProgramTracksData(orderedPrograms));
  }
};

export const fetchUserDetails = async (id: string) => {
  const userRef = doc(db, 'staff', id);
  const userDoc = await getDoc(userRef);

  return userDoc.exists() ? userDoc.data() : null;
};

export const triggerNotification = async (payload: INotificationPayload) => {
  const response = await httpsCallable(functions, 'notifyStaff')(payload);

  return response;
};

export const syncListsWithElastic = async () => {
  try {
    const response = await httpsCallable(functions, 'syncListsWithElastic')({});
    console.log(response);
  } catch (error) {
    console.log(error);
  }
};

export const listenForUserNotificationsByUserId = (userId: string, callback: Function) => {
  try {
    if (!userId) {
      throw new Error('userId is null or undefined!');
    }
    const notificationsRef = collection(db, 'notifications');
    const notificationsQuery = query(
      notificationsRef,
      where('staffId', '==', userId),
      where('isRead', '==', false),
      orderBy('createdAt', 'desc')
    );

    return onSnapshot(notificationsQuery, (snapshot) => {
      const notifyList: any = [];
      snapshot.forEach((doc) => {
        notifyList.push({ id: doc.id, ...(doc.data() as INotification) });
      });

      callback(notifyList);
    });
  } catch (error) {
    toast.error('Error Loading Notifications!');
    return () => {};
  }
};

export const markSingleNotification = async (docId: string) => {
  try {
    if (!docId) {
      throw new Error();
    }
    const response: any = await httpsCallable(functions, 'markReadSingleNotification')({ docId: docId });
    if (response.data.success) {
      toast.success('Notification Marked As Read Successfully!');
    }
  } catch (error) {
    toast.error('Error Marking Notification As Read!');
  }
};

export const markAllNotification = async (staffId: string) => {
  try {
    if (!staffId) {
      throw new Error();
    }
    const response: any = await httpsCallable(functions, 'markReadAllNotification')({ staffId: staffId });
    if (response.data.success) {
      toast.success('All Notifications Marked As Read Successfully!');
    }
  } catch (error) {
    toast.error('Error Marking Notification As Read!');
  }
};

export const getRole = async (auth: Auth) => {
  if (auth?.currentUser) {
    const token = await auth.currentUser.getIdTokenResult();

    return token.claims.role;
  }

  return '';
};

export const saveAuditLogOnLogin = async () => {
  try {
    const ip = await publicIpv4();
    httpsCallable(functions, 'onLogin')({ ip: ip });
  } catch (error: any) {
    console.log(error.message);
  }
};

export const saveAuditLogOnLoginFail = (email: string) => {
  httpsCallable(functions, 'onLoginFail')({ email: email });
};

export const saveAuditLogOnLogout = () => {
  httpsCallable(functions, 'onLogout')({});
};
