import { Box, List, ListItem, ListItemText } from '@mui/material';
import { IClientWorkshopAttendance, IDateTime, IWorkshopInfo } from '../../store/employmentModule/workshopModal';
import { WorkshopTypesEnum } from '../../utils/workshopUtils';
import { CreateColumnCell } from '../CreateColumnCell/CreateColumnCell';
import { COLORS } from '../../utils/colors';
import dayjs from 'dayjs';

interface WorkshopBasicInfoProps {
  workshop: IWorkshopInfo;
  clientWorkshopAttendance?: IClientWorkshopAttendance[];
}

const WorkshopBasicInfo = (props: WorkshopBasicInfoProps) => {
  const { workshop, clientWorkshopAttendance } = props;

  const formatDates = (dates: Array<IDateTime>) => {
    const formattedDates = dates?.map((item: IDateTime) => {
      return dayjs(item.timing).tz('America/New_York').format('MM/DD/YYYY, hh:mm A');
    });
    return formattedDates?.join(' | ');
  };

  const renderWorkshopType = (type: string) => {
    if (type === WorkshopTypesEnum.VIRTUAL) {
      return CreateColumnCell(
        type,
        COLORS.palette.white,
        undefined,
        2,
        COLORS.palette.lightGreen,
        COLORS.palette.green
      );
    } else {
      return CreateColumnCell(type, COLORS.palette.white, undefined, 2, COLORS.palette.lightRed, COLORS.palette.red);
    }
  };
  const attendanceData = clientWorkshopAttendance?.find(
    (item: IClientWorkshopAttendance) => item.workshopId === workshop.id
  );

  const workshopInfo = [
    {
      title: 'Workshop Type:',
      value: renderWorkshopType(workshop.type),
    },
    {
      title: 'Workshop Location:',
      value: workshop?.location || '---',
    },
    {
      title: 'Workshop Reapeat:',
      value: workshop.repeatWeekly ? 'Yes' : 'No',
    },
    {
      title: 'No. of Clients',
      value: workshop.numClients || 0,
    },
    {
      title: 'Workshop Date & Time:',
      value: formatDates(workshop.dateTime),
    },
    {
      title: 'Attendance Status:',
      value: workshop.isAttendanceMarked ? 'Attendance Marked' : 'Not Yet Marked',
    },
  ];

  if (attendanceData) {
    workshopInfo.push({
      title: 'Client Attendance:',
      value: attendanceData.attendance,
    });
  }

  return (
    <Box>
      <List>
        <ListItem disablePadding>
          <ListItemText
            primary={workshop.title}
            primaryTypographyProps={styles.workshopTitle}
            sx={styles.listItemContainer}
          />
        </ListItem>

        <ListItem disablePadding>
          <ListItemText
            primary={workshop.description}
            primaryTypographyProps={styles.workshopDescription}
            sx={styles.listItemContainer}
          />
        </ListItem>

        <Box py={2} />

        {workshopInfo.map((item, index) => (
          <ListItem disablePadding key={index}>
            <ListItemText
              primary={item.title}
              secondary={item.value}
              primaryTypographyProps={styles.listItemPrimaryText}
              sx={styles.listItemContainer}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default WorkshopBasicInfo;

const styles = {
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 1,
  },
  workshopTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  workshopDescription: {
    fontSize: 16,
  },
};
