import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getAvailableProgramTracks } from '../../services/programTrack.service';
import {
  assignProgramTrackToClient,
  getPtcmDataInReviewByClientId,
} from '../../services/programTrackClientMap.service';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { COLORS } from '../../utils/colors';
import { getUserInfoById } from '../../services/user.service';
import { ClientStatus } from '../../utils/clientStatus';
import {
  ICancelIndividualEnrollment,
  cancelClientEnrollment,
  cancelClientIndividualEnrollment,
  enrollClient,
} from '../../services/client.service';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { useAppSelector } from '../../store/store';
import { UserModel } from '../../store/user/userModel';
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import GenericDialog from '../Dialog/genericDialog/genericDialog';
import { shouldShowButton } from '../../pages/admin/dashboard/dashboardAccessControl';
import ClientCompletedProgramTracks from '../ClientCompletedProgramTracks/ClientCompletedProgramTracks';

export type ClientProgramTrackEnrollmentProps = {
  programTrack?: any;
  careerPath?: any;
  clientInfo?: any;
  onSubmit: Function;
  onCancel: Function;
};

export const ClientProgramTrackEnrollment = (props: ClientProgramTrackEnrollmentProps) => {
  const { careerPath, clientInfo, onSubmit, onCancel } = props;
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const isEnrollmentReview = ClientStatus.ENROLLMENT_REVIEW === clientInfo.status;
  const [selectedCareerPath, setSelectedCareerPath] = useState<any>();
  const [selectedProgramTrack, setSelectedProgramTrack] = useState<any>();
  const [programTracks, setProgramTracks] = useState([]);
  const [ptinfoList, setPtinfoList] = useState<any[]>([]);
  const [clientProgramTracks, setClientProgramTracks] = useState<any[]>([]);
  const [selectedClientProgramTrack, setSelectedClientProgramTrack] = useState<any>(null);
  const [initEnrollLoading, setInitEnrollLoading] = useState<boolean>(false);
  const [cancelEnrollLoading, setCancelEnrollLoading] = useState<boolean>(false);
  const [cancelIndividualEnrollLoading, setCancelIndividualEnrollLoading] = useState<boolean>(false);
  const [approveEnrollLoading, setApproveEnrollLoading] = useState<boolean>(false);
  const [individualDeleteAlert, setIndividualDeleteAlert] = useState<boolean>(false);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [approvalAlert, setApprovalAlert] = useState<boolean>(false);

  const handleCareerPathChange = async (event: Object, selectedValue: string) => {
    const selectedPath = careerPath.find((item: any) => item.name === selectedValue);
    setSelectedCareerPath(selectedPath);
    setSelectedProgramTrack(null);
  };

  const handleClientProgramTrackChange = async (event: any) => {
    const selectedTrack = clientProgramTracks.find((item: any) => item.id === event.target.value);
    setSelectedClientProgramTrack(selectedTrack);
  };

  const preparePtinfoList = async (data: any) => {
    let trainer = null;
    await getUserInfoById(data?.trainerId).then((res: any) => {
      trainer = res.firstName && res.lastName ? res.firstName + ' ' + res.lastName : '--';
    });

    const list = [
      {
        id: 1,
        label: 'Trainer Name',
        value: trainer,
      },
      {
        id: 2,
        label: 'Location',
        value: [data?.location],
      },
      {
        id: 3,
        label: 'Student Capacity',
        value: [data?.capacity],
      },
      {
        id: 4,
        label: 'Client Requirements',
        value: [data?.requirements],
      },
    ];
    setPtinfoList([...list]);
  };

  const handleProgramTrackChange = async (event: Object, selectedValue: string) => {
    const selectedTrack = programTracks.find((item: any) => `${item.name} (${item.type})` === selectedValue);
    setSelectedProgramTrack(selectedTrack);
    preparePtinfoList(selectedTrack);
  };

  const handleSubmit = async () => {
    if (selectedProgramTrack) {
      setInitEnrollLoading(true);
      await assignProgramTrackToClient(clientInfo.id, selectedProgramTrack?.id, selectedProgramTrack?.type);
      await fetchPtcmDataInReviewByClientId();
      setSelectedProgramTrack(null);
      setSelectedClientProgramTrack(null);
      setSelectedCareerPath(null);
      setInitEnrollLoading(false);
    }
    onSubmit(selectedProgramTrack?.id);
  };

  const confirmEnrollment = async (clientId: string) => {
    setApproveEnrollLoading(true);
    await enrollClient(clientId, selectedClientProgramTrack.programTrackId);
    setApproveEnrollLoading(false);
    setSelectedClientProgramTrack(null);
    onSubmit(selectedProgramTrack?.id);
  };
  const cancelEnrollment = async () => {
    setCancelEnrollLoading(true);
    await cancelClientEnrollment(clientInfo.id);
    setCancelEnrollLoading(false);
    setSelectedClientProgramTrack(null);
    onCancel();
  };

  const cancelIndividualEnrollment = async () => {
    setCancelIndividualEnrollLoading(true);
    const payload: ICancelIndividualEnrollment = {
      id: clientInfo.id,
      programTrackId: selectedClientProgramTrack.programTrackId,
    };
    await cancelClientIndividualEnrollment(payload);
    setCancelIndividualEnrollLoading(false);
    setSelectedClientProgramTrack(null);
    onCancel();
  };

  const fetchAvailableProgramTracks = async () => {
    if (selectedCareerPath) {
      const unsubscribe: any = await getAvailableProgramTracks(selectedCareerPath.id, clientInfo.id, (data: any) => {
        setProgramTracks(data);
      });
      return () => {
        unsubscribe();
      };
    } else {
      setProgramTracks([]);
    }
  };

  const fetchPtcmDataInReviewByClientId = async () => {
    const unsubscribe = await getPtcmDataInReviewByClientId(clientInfo.id, (data: any) => {
      setClientProgramTracks(data);
    });
    return () => {
      unsubscribe();
    };
  };

  useEffect(() => {
    (async () => {
      await fetchAvailableProgramTracks();
      await fetchPtcmDataInReviewByClientId();
    })();
    // eslint-disable-next-line
  }, [selectedCareerPath, selectedProgramTrack, isEnrollmentReview]);
  return (
    <>
      <Grid container spacing={2} columns={isEnrollmentReview ? 18 : 12}>
        {/* First Column */}
        <Grid
          item
          lg={6}
          display={
            shouldShowButton('enrollInProgramTrack', user.role) && user?.role !== UserRolesEnum.TRAINER
              ? 'block'
              : 'none'
          }>
          <Box sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'} py={2} px={2}>
            <Typography variant='h6' gutterBottom mb={4}>
              Enroll client in a Program Track
            </Typography>
            <Autocomplete
              sx={{ mb: 2 }}
              size='small'
              value={selectedCareerPath ? selectedCareerPath.name : ''}
              disableClearable
              options={careerPath?.map((option: any) => option.name)}
              renderInput={(params) => <TextField {...params} label='Select career path' />}
              onChange={handleCareerPathChange}
            />
            <Autocomplete
              sx={{ mb: 2 }}
              size='small'
              value={selectedProgramTrack ? selectedProgramTrack.name : ''}
              disableClearable
              options={programTracks?.map((option: any) => `${option.name} (${option.type})`)}
              renderInput={(params) => <TextField {...params} label='Select program track' />}
              onChange={handleProgramTrackChange}
              disabled={!selectedCareerPath}
            />
            <Box display='flex' justifyContent='center'>
              <LoadingButton
                onClick={handleSubmit}
                label='Initiate Enrollment Review'
                styles={{
                  textTransform: 'none',
                }}
                loading={initEnrollLoading}
                disabled={!selectedCareerPath || !selectedProgramTrack || initEnrollLoading}
                size='medium'
                type='submit'
                variant='contained'
              />
            </Box>
          </Box>
        </Grid>

        {/* Second Column */}
        <Grid
          item
          lg={6}
          display={
            shouldShowButton('enrollInProgramTrack', user.role) && user?.role !== UserRolesEnum.TRAINER
              ? 'block'
              : 'none'
          }>
          <Box sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'} py={2} px={2}>
            <Typography variant='h6' gutterBottom mb={2}>
              Program Track Details
            </Typography>

            <List>
              {
                // @ts-ignore
                selectedProgramTrack ? (
                  ptinfoList?.map((item: any) => (
                    <ListItem disablePadding key={item.id}>
                      <ListItemText
                        primary={item.label + ':'}
                        secondary={item.value || ''}
                        primaryTypographyProps={styles.listItemPrimaryText}
                        sx={styles.listItemContainer}
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant='body2' color='textSecondary'>
                    No program track selected
                  </Typography>
                )
              }
            </List>
          </Box>
        </Grid>

        <Grid item lg={6} display={isEnrollmentReview ? 'block' : 'none'}>
          <Box sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'} py={2} px={2}>
            <Typography variant='h6' gutterBottom mb={2}>
              Approve or Cancel client enrollment
            </Typography>

            <FormControl sx={{ mb: 4 }}>
              <RadioGroup onChange={handleClientProgramTrackChange} value={selectedClientProgramTrack?.id}>
                {clientProgramTracks?.map((option: any) => (
                  <FormControlLabel
                    key={option.id}
                    value={option.id}
                    control={<Radio />}
                    label={option.programTrackName}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <Box
              gap={2}
              sx={{
                justifyContent: { xs: 'center', md: 'space-between' },
                flexDirection: { xs: 'column', md: 'row' },
                display: { xs: 'block', md: 'flex' },
              }}>
              <LoadingButton
                onClick={() => {
                  setApprovalAlert(true);
                }}
                label='Approve'
                styles={{
                  textTransform: 'none',
                }}
                loading={approveEnrollLoading}
                disabled={
                  !selectedClientProgramTrack ||
                  approveEnrollLoading ||
                  cancelEnrollLoading ||
                  cancelIndividualEnrollLoading
                }
                size='medium'
                type='submit'
                variant='contained'
                color='success'
                startIcon={<CheckCircleOutline />}
              />

              <LoadingButton
                onClick={() => setIndividualDeleteAlert(true)}
                label='Cancel'
                styles={{
                  textTransform: 'none',
                  mt: { xs: 2, md: 0 },
                }}
                loading={cancelIndividualEnrollLoading}
                disabled={!selectedClientProgramTrack || cancelIndividualEnrollLoading || approveEnrollLoading}
                size='medium'
                type='submit'
                variant='contained'
                color='error'
                startIcon={<CancelOutlined />}
              />
            </Box>
            <Box pt={4}>
              <Divider>
                <Chip label='or' sx={{ fontWeight: 'bold' }} />
              </Divider>
            </Box>
            <Box mt={4}>
              <LoadingButton
                onClick={() => setDeleteAlert(true)}
                label='Terminate client enrollment'
                styles={{
                  textTransform: 'none',
                  mt: { xs: 2, md: 0 },
                  width: '100%',
                }}
                loading={cancelEnrollLoading}
                disabled={cancelEnrollLoading || cancelIndividualEnrollLoading || approveEnrollLoading}
                size='medium'
                type='submit'
                variant='contained'
                color='warning'
              />
            </Box>
          </Box>
        </Grid>
        <GenericDialog
          onClose={() => setIndividualDeleteAlert(false)}
          dialogTitle='Are you sure?'
          agreeBtnText='Cancel Enrollment'
          disagreeBtnText='Cancel'
          agreeFunc={() => {
            cancelIndividualEnrollment();
          }}
          open={individualDeleteAlert}
        />
        <GenericDialog
          onClose={() => setDeleteAlert(false)}
          dialogTitle='Are you sure?'
          agreeBtnText='Terminate client enrollment'
          disagreeBtnText='Cancel'
          agreeFunc={() => {
            cancelEnrollment();
          }}
          open={deleteAlert}
        />
        <GenericDialog
          onClose={() => setApprovalAlert(false)}
          dialogTitle='Are you sure?'
          agreeBtnText='Approve Enrollment'
          disagreeBtnText='Cancel'
          color='success'
          agreeFunc={() => {
            confirmEnrollment(clientInfo.id);
          }}
          open={approvalAlert}
        />
      </Grid>
      <Box sx={{ mt: 10 }}>
        <ClientCompletedProgramTracks clientId={clientInfo.id} />
      </Box>
    </>
  );
};

const styles = {
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 1,
  },
};
