import { Grid } from '@mui/material';
import React from 'react';
import { DashboardCard } from '../DashboardCard/DashboardCard';
import { ICommonClient, getClientsWithPendingSchoolLetter } from '../../services/client.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { userAccountStatus } from '../../utils/userUtils';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';

interface IProspectiveClientsDashboardCardProps {
  userRole: string;
}

export default function ClientsPending154ELetterDashboardCard(props: IProspectiveClientsDashboardCardProps) {
  const navigate = useNavigate();
  const [client, setClients] = React.useState<Array<ICommonClient>>([]);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  const checkCardPermission = props.userRole === UserRolesEnum.TRAINER;
  const user: UserModel = useAppSelector((state: any) => state.user.user);

  const loadClients = async () => {
    try {
      if (user.id) {
        setShowLoader(true);
        const clientList = await getClientsWithPendingSchoolLetter(user.id);
        const filteredList: any = clientList.filter((obj: any) => obj.account !== userAccountStatus.DELETED);
        setClients(filteredList);
        setShowLoader(false);
      }
    } catch (err: any) {
      toast.error('Unable to load client list');
    }
  };

  React.useEffect(() => {
    if (checkCardPermission) loadClients();
    // eslint-disable-next-line
  }, [props.userRole]);

  return checkCardPermission ? (
    <Grid item lg={6} xs={12}>
      <DashboardCard
        isLoading={showLoader}
        label='New Clients Pending School Letter'
        desc=''
        list={client}
        // eslint-disable-next-line
        onItemClick={(item: any) => {
          navigate('../clientInfo', { state: { clientId: item.id } });
        }}
      />
    </Grid>
  ) : null;
}
