import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import ClientAttendanceSheet from '../../ClientAttendanceSheet/ClientAttendanceSheet';

interface IClientAttendanceSheetModal {
  open: boolean;
  onClose: Function;
  clientInfo: any;
}

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ClientAttendanceSheetModal = (props: IClientAttendanceSheetModal) => {
  const { open, onClose, clientInfo } = props;
  return (
    <ModalWrapper open={open} onClose={() => onClose()}>
      <Box sx={styles.centeredStyle}>
        {/* Modal Header */}
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6'>Client Attendance Sheet</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container py={3}>
          <Grid item>
            <ClientAttendanceSheet clientInfo={clientInfo} />
          </Grid>
        </Grid>
      </Box>
    </ModalWrapper>
  );
};
export default ClientAttendanceSheetModal;

const styles = {
  centeredStyle: {
    width: '700px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    borderRadius: '14px',
    '@media (max-width: 480px)': {
      padding: '20px',
      margin: '10px',
    },
  },
};
