import React, { useEffect, useState } from 'react';
import { IconButton, Box, Grid, Typography } from '@mui/material';
import AttendanceSheet from '../../InfoSessionAttendanceSheet/InfoSessionAttendanceSheet';
import { toast } from 'react-toastify';
import {
  fetchClientsAttendedInfoSession,
  fetchRegisteredClientsForInfoSession,
  markInfoSessionAttendance,
} from '../../../services/infoSession.service';
import {
  fetchClientsAttendedWorkshop,
  fetchRegisteredClientsForWorkshop,
  markWorkshopAttendance,
} from '../../../services/workshop.service';
import { UserModel } from '../../../store/user/userModel';
import { Loader } from '../../Loader/Loader';
import { useAppSelector } from '../../../store/store';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { COLORS } from '../../../utils/colors';
import CloseIcon from '@mui/icons-material/Close';
import AttendanceTable from '../../AttendanceTable/AttendanceTable';
import dayjs from 'dayjs';

interface AttendanceModalProps {
  open: boolean;
  onClose: Function;
  infoSessionId: string;
  workshopId: string;
  isAttendanceMarked?: boolean;
}

const AttendanceModal = (props: AttendanceModalProps) => {
  const { open, onClose, infoSessionId, workshopId, isAttendanceMarked } = props;
  const user: UserModel = useAppSelector((state: any) => state.user.user) || {};
  const [loading, setLoading] = useState<boolean>(false);
  const [clientLoader, setClientLoader] = useState<boolean>(false);
  const [clientList, setClientList] = useState<any[]>([]);

  const tableCols = [
    { id: 'id', name: '#' },
    { id: 'client_name', name: 'Client Name' },
    { id: 'attendance', name: 'Attendance' },
    { id: 'attendance_by', name: 'Attendance By' },
    { id: 'attendance_at', name: 'Attendance At' },
  ];

  const fetchClients = async (loaderSetter: Function, fetchFunction: Function, id: string) => {
    try {
      loaderSetter(true);
      const loadingTimeout = setTimeout(() => {
        loaderSetter(true);
      }, 200);

      const unsubscribe = await fetchFunction(id, (clientData: any) => {
        clearTimeout(loadingTimeout);
        setClientList(clientData);
        loaderSetter(false);
      });

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    } catch (error) {
      console.error(error);
    }
  };

  // Usage for Info Session
  const fetchClientsByInfoSessionId = async () => {
    return fetchClients(
      setClientLoader,
      isAttendanceMarked ? fetchClientsAttendedInfoSession : fetchRegisteredClientsForInfoSession,
      infoSessionId
    );
  };

  // Usage for Workshop
  const fetchClientsByWorkshopId = async () => {
    return fetchClients(
      setClientLoader,
      isAttendanceMarked ? fetchClientsAttendedWorkshop : fetchRegisteredClientsForWorkshop,
      workshopId
    );
  };

  const handleAttendance = async (selectedClients: string[]) => {
    setLoading(true);
    let response: any;
    if (infoSessionId) {
      response = await markInfoSessionAttendance(selectedClients, infoSessionId, user?.id || '');
    } else if (workshopId) {
      response = await markWorkshopAttendance(selectedClients, workshopId, user?.id || '');
    }
    if (response?.success) {
      toast.success(response.message);
      fetchClientList();
      onClose();
    } else {
      toast.error('Something went wrong');
    }
    setLoading(false);
    setClientList([]);
  };

  const fetchClientList = () => {
    if (infoSessionId) {
      fetchClientsByInfoSessionId();
    } else if (workshopId) {
      fetchClientsByWorkshopId();
    }
  };

  useEffect(() => {
    fetchClientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!open]);

  const getTableRows = () => {
    let rows = clientList.map((client, index) => {
      const type = infoSessionId ? 'info' : 'work';
      const sessionData = type === 'info' ? client.infoSessionAttendance : client.workshopAttendance;
      const attendance = sessionData?.find(
        (attendance: any) =>
          attendance.infoSessionId === (infoSessionId ? infoSessionId : undefined) &&
          attendance.workshopId === (workshopId ? workshopId : undefined)
      );

      return {
        id: index + 1,
        client_name: `${client.firstName} ${client.lastName}`,
        attendance: attendance?.attendance,
        attendance_by: attendance?.markedBy,
        attendance_at: attendance ? dayjs.unix(attendance.markedAt.seconds).format('MM/DD/YYYY') : '',
      };
    });
    return rows;
  };

  const renderContent = () => {
    if (clientLoader) {
      return (
        <Box py={4}>
          <Loader loaderBoxStyle={{ height: '0vh' }} />
        </Box>
      );
    } else if (!isAttendanceMarked && clientList?.length > 0) {
      return (
        <Box py={3}>
          <AttendanceSheet loading={loading} clients={clientList} onSubmit={handleAttendance} />
        </Box>
      );
    } else if (isAttendanceMarked && clientList?.length > 0) {
      return (
        <Box py={3}>
          <AttendanceTable tableCols={tableCols} tableRows={getTableRows()} />
        </Box>
      );
    } else {
      return (
        <Box py={4}>
          <Typography variant='body2' textAlign={'center'} py={2}>
            No clients available or attendance already marked.
          </Typography>
        </Box>
      );
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperProps={{ sx: { borderRadius: '14px' } }}>
      <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Box>
            <Typography variant='h6'>Attendance Sheet</Typography>
            {clientList?.length > 0 && (
              <Typography variant='body2'>Clients with unchecked boxes will be marked as absent.</Typography>
            )}
          </Box>
          <IconButton
            onClick={() => {
              onClose();
            }}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box>{renderContent()}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default AttendanceModal;
