import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { UserModel } from '../../../store/user/userModel';
import { useAppSelector } from '../../../store/store';
import { getMultipleInfoSessionByIds } from '../../../services/infoSession.service';
import { ClientInfoSession } from '../../../components/ClientInfoSession/ClientInfoSession';
import { InfoSessionModel } from '../../../store/infoSession/infoSessionModel';
import { Loader } from '../../../components/Loader/Loader';
import { ClientStatus } from '../../../utils/clientStatus';
import {
  getClientMapActiveStatusByClientId,
  getDroppedOutProgramTracksByClientId,
  hasCompletedProgramTrack,
} from '../../../services/programTrackClientMap.service';
import { getProgramTrackById, IProgramTrack } from '../../../services/programTrack.service';
import { programTrackTypes } from '../../../utils/programTrackEnums';
import { COLORS } from '../../../utils/colors';
import { ClientProgramTrack } from '../../../components/ClientProgramTrack/ClientProgramTrack';
import { getCareerPathInfoById } from '../../../services/careerPath.service';
import { getClientInfoById } from '../../../services/client.service';
import { convertTimeStampToDate } from '../../../utils/dateTime';
import WarningIcon from '@mui/icons-material/Warning';
import { getWorkshopById } from '../../../services/workshop.service';
import { emptyWorkshopInfo, IWorkshopInfo } from '../../../store/employmentModule/workshopModal';
import WorkshopBasicInfo from '../../../components/WorkshopBasicInfo/WorkshopBasicInfo';
import WorkshopAttachmentView from '../../../components/WorkshopAttachmentView/WorkshopAttachmentView';
import { emptyJobInfo, IJobInfo } from '../../../store/employmentModule/employmentModal';
import { getMultipleJobsByIds } from '../../../services/employmentModule.service';
import dayjs from 'dayjs';
import { WorkshopTypesEnum } from '../../../utils/workshopUtils';
/* eslint-disable max-len */
import ClientCompletedProgramTracks from '../../../components/ClientCompletedProgramTracks/ClientCompletedProgramTracks';
import { dropoutReasons } from '../../../utils/dropoutAndSuspensionReasons';
import { DashboardSmallCard } from '../../../components/DashboardSmallCard/DashboardSmallCard';
import { AccountCircleOutlined, ListAltOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function ClientHome() {
  const navigate = useNavigate();
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [infoSessionsData, setInfoSessionsData] = useState<InfoSessionModel[]>([]);
  const [ptcmInfo, setPtcmInfo] = useState<any>();
  const [programTrackInfo, setProgramTrackInfo] = useState<IProgramTrack>();
  const [careerPathInfo, setCareerPathInfo] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [clientInfo, setClientInfo] = useState<any>();
  const [dropProgramTrackInfo, setDropProgramTrackInfo] = useState<any[]>([]);
  const [workshopInfo, setWorkshopInfo] = useState<IWorkshopInfo>(emptyWorkshopInfo);
  const [jobDetails, setJobDetails] = useState<IJobInfo[]>([emptyJobInfo]);
  const [isCompletedPt, setIsCompletedPt] = useState<boolean>(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Mobile view
  // const isMediumScreen = useMediaQuery(theme.breakpoints.down('md')); // Tablet view

  let durationInDays = 0;

  if (programTrackInfo?.endTime && programTrackInfo?.startTime) {
    const endTimeInMs = convertTimeStampToDate(programTrackInfo.endTime).valueOf();
    const startTimeInMs = convertTimeStampToDate(programTrackInfo.startTime).valueOf();
    if (!isNaN(endTimeInMs) && !isNaN(startTimeInMs)) {
      const durationInMs = endTimeInMs - startTimeInMs;
      durationInDays = Math.floor(durationInMs / (24 * 60 * 60 * 1000));
    }
  }
  if (programTrackInfo) {
    durationInDays =
      programTrackInfo.type === programTrackTypes.COHORT.value
        ? durationInDays
        : programTrackInfo.milestones[programTrackInfo.milestones.length - 1]?.days || 0;
  }

  const formatDates = (dates: Array<string>) => {
    if (dates.length > 0) {
      const formattedDates = dates.map((item: string) => {
        return dayjs(item).tz('America/New_York').format('MM/DD/YYYY, hh:mm A');
      });
      return formattedDates.join(' | ');
    }
    return '';
  };

  const getWorkshopInfo = async (workshopId: string) => {
    if (workshopId) {
      const unsubscribe = await getWorkshopById(workshopId, (data: any) => {
        setWorkshopInfo(data);
      });
      return () => {
        unsubscribe();
      };
    }
  };

  const getJobDetails = async (jobIds: string[]) => {
    if (jobIds.length > 0) {
      const jobData = await getMultipleJobsByIds(jobIds);
      setJobDetails(jobData);
    }
  };

  const fetchInfoSessionData = async () => {
    setLoading(true);
    if (clientInfo?.infoSessionIds) {
      const info = await getMultipleInfoSessionByIds(clientInfo.infoSessionIds);
      setInfoSessionsData(info);
    }
    setLoading(false);
  };

  const fetchProgramTrackData = async () => {
    setLoading(true);
    if (clientInfo?.id) {
      const info = await getClientMapActiveStatusByClientId(clientInfo?.id || '');
      setPtcmInfo(info);
      if (info && info?.programTrackId) {
        const ptInfo: any = await getProgramTrackById(info?.programTrackId);
        setProgramTrackInfo(ptInfo);
        if (ptInfo && ptInfo?.careerPathId) {
          const cpInfo: any = await getCareerPathInfoById(ptInfo?.careerPathId);
          setCareerPathInfo(cpInfo);
        }
      }
    }
    setLoading(false);
  };

  const fetchClientDropOuts = async () => {
    if (clientInfo?.id && clientInfo?.status === ClientStatus.DROP_OUT) {
      const data = await getDroppedOutProgramTracksByClientId(clientInfo?.id);
      setDropProgramTrackInfo(data);
    }
  };

  const checkIfClientHasCompletedPt = async () => {
    if (
      clientInfo?.status !== ClientStatus.SUSPENDED &&
      clientInfo?.status !== ClientStatus.PROSPECTIVE &&
      clientInfo?.status !== ClientStatus.PENDING_ASSIGNMENT
    ) {
      const result = await hasCompletedProgramTrack(clientInfo?.id);
      setIsCompletedPt(result);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (user?.id) {
      (async () => {
        const info = await getClientInfoById(user?.id || '');
        setClientInfo(info);
      })();
    }
    setLoading(false);
  }, [user]);

  useEffect(
    () => {
      if (clientInfo?.status === ClientStatus.PROSPECTIVE) {
        fetchInfoSessionData();
      } else if (clientInfo?.status === ClientStatus.ENROLLED) {
        fetchProgramTrackData();
      } else if (clientInfo?.status === ClientStatus.DROP_OUT) {
        fetchClientDropOuts();
      }
      if (clientInfo?.workshopId) {
        getWorkshopInfo(clientInfo?.workshopId);
      }
      if (clientInfo?.scheduledJobInterview) {
        const jobIds = clientInfo.scheduledJobInterview.map((item: any) => item.jobId);
        if (jobIds.length > 0) {
          getJobDetails(jobIds);
        }
      }
      checkIfClientHasCompletedPt();
    },
    // eslint-disable-next-line
    [user, clientInfo]
  );

  if (loading) {
    return <Loader />;
  }

  const renderInfoSessions = () => {
    return (
      <Grid container spacing={2} direction='row' justifyContent='center'>
        {infoSessionsData?.map((item, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={12}>
            <ClientInfoSession data={item} />
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderProgramTrackDetails = () =>
    programTrackInfo &&
    ptcmInfo &&
    careerPathInfo && (
      <Grid item xs={12} lg={12} p={2} mx={3} sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'}>
        <Typography mb={1} variant='h6'>
          Program Track Details
        </Typography>
        <Divider sx={{ my: 1 }} />
        <ClientProgramTrack
          milestones={programTrackInfo.milestones || []}
          completedMilestones={ptcmInfo.completedMilestones || []}
          durationInDays={durationInDays}
          careerPathInfo={careerPathInfo}
          clientPTCMStatus={ptcmInfo.status || ''}
          programTrackName={programTrackInfo.name || ''}
          programTrackType={programTrackInfo.type || ''}
          programTrackId={programTrackInfo.id || ''}
          client={clientInfo}
        />
      </Grid>
    );

  const renderSuspendedView = () => {
    return (
      <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '300px', padding: '20px' }}>
        <Box
          bgcolor='error.main'
          color='white'
          p={2}
          borderRadius={2}
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          mb={1}>
          <WarningIcon fontSize='large' style={{ marginRight: '10px' }} />
          <Typography variant='h5' fontWeight='bold'>
            Account Suspended
          </Typography>
        </Box>
        <Typography variant='body1' color='textSecondary' align='center' mb={3}>
          Your account is currently suspended.
        </Typography>
      </Grid>
    );
  };

  const renderWorkshopDetails = () => (
    <Grid item lg={12} xs={12} p={2} m={3} sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'}>
      <Typography variant='h6'>Workshop Details</Typography>
      <Divider sx={{ my: 1 }} />
      <WorkshopBasicInfo workshop={workshopInfo} />
      {workshopInfo?.type === WorkshopTypesEnum.VIRTUAL ? (
        <>
          <Divider>Resources</Divider>
          <WorkshopAttachmentView workshop={workshopInfo} />
        </>
      ) : null}
    </Grid>
  );

  const renderScheduledInterviewDetails = (scheduledJobInterview: any[]) => (
    <Grid item lg={12} xs={12} mx={3} p={2} sx={{ background: COLORS.card.cardBg }} borderRadius='10px'>
      <Typography variant='h6'>Scheduled Interview Details</Typography>
      <Divider sx={{ my: 1 }} />
      {jobDetails.map((job: IJobInfo, index: number) => (
        <Grid key={job?.id}>
          <List>
            <ListItem>
              <ListItemText
                primary='Title:'
                secondary={job.title}
                primaryTypographyProps={styles.listItemPrimaryText}
                sx={styles.listItemContainer}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='Description:'
                secondary={job.description}
                primaryTypographyProps={styles.listItemPrimaryText}
                sx={styles.listItemContainer}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='Offer Status:'
                secondary={
                  scheduledJobInterview.find((item: any) => item.jobId === job.id)?.offerStatus || 'Interview Scheduled'
                }
                primaryTypographyProps={styles.listItemPrimaryText}
                sx={styles.listItemContainer}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='Selected Interview Dates:'
                secondary={formatDates(
                  scheduledJobInterview.find((item: any) => item.jobId === job.id)?.interviewTimings || []
                )}
                primaryTypographyProps={styles.listItemPrimaryText}
                sx={styles.listItemContainer}
              />
            </ListItem>
          </List>
          {index < jobDetails.length - 1 && <Divider sx={{ my: 1 }} />}
        </Grid>
      ))}
    </Grid>
  );

  const renderCompletedProgramTracks = (clientId: string) => {
    return (
      <Grid item lg={12} xs={12} mx={3} p={2} sx={{ background: COLORS.card.cardBg }} borderRadius='10px'>
        <ClientCompletedProgramTracks clientId={clientId} />
      </Grid>
    );
  };

  const renderHiredJobDetails = (employment: any) => {
    const jobData = jobDetails.find((item: IJobInfo) => item.id === employment.jobId);
    return (
      <Grid item lg={12} xs={12} p={2} m={3} sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'}>
        <Typography variant='h6'>Hired Job Details</Typography>
        <Divider sx={{ my: 1 }} />
        <List>
          <ListItem>
            <ListItemText
              primary={'Title:'}
              secondary={jobData?.title}
              primaryTypographyProps={styles.listItemPrimaryText}
              sx={styles.listItemContainer}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Description:'}
              secondary={jobData?.description}
              primaryTypographyProps={styles.listItemPrimaryText}
              sx={styles.listItemContainer}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Started Date:'}
              secondary={dayjs(employment.startAt).tz('America/New_York').format('MM/DD/YYYY, hh:mm A')}
              primaryTypographyProps={styles.listItemPrimaryText}
              sx={styles.listItemContainer}
            />
          </ListItem>
        </List>
      </Grid>
    );
  };

  const renderDropOutProgramTrackDetails = () => (
    <Grid item lg={12} xs={12} mx={3} p={2} sx={{ background: COLORS.card.cardBg }} borderRadius='10px'>
      <Typography variant='h6'>Drop Out Program Track Details</Typography>
      <Divider sx={{ my: 1 }} />
      {dropProgramTrackInfo.map((ptInfo: any, index: number) => (
        <Grid key={ptInfo?.id}>
          <List>
            <ListItem>
              <ListItemText
                primary='Title:'
                secondary={ptInfo.name}
                primaryTypographyProps={styles.listItemPrimaryText}
                sx={styles.listItemContainer}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='Drop Out Reason:'
                secondary={dropoutReasons.find((item: any) => item.id === ptInfo.dropoutReasonId)?.value}
                primaryTypographyProps={styles.listItemPrimaryText}
                sx={styles.listItemContainer}
              />
            </ListItem>
          </List>
          {index < dropProgramTrackInfo.length - 1 && <Divider sx={{ my: 1 }} />}
        </Grid>
      ))}
    </Grid>
  );

  const renderQuickLinkWidgets = (isSmallScreen: boolean) => {
    if (isSmallScreen) {
      // Render compact outlined buttons for small screens
      return (
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            my: 1,
            px: 2,
          }}>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => navigate('/client/account-info')}
            sx={{
              textTransform: 'none',
            }}>
            Account Info
          </Button>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => navigate('/client/documents')}
            sx={{
              textTransform: 'none',
            }}>
            Documents
          </Button>
        </Grid>
      );
    }

    // Render full widgets for larger screens
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 2,
          my: 1,
        }}>
        <Box
          sx={{
            cursor: 'pointer',
            width: 'auto',
          }}
          onClick={() => navigate('/client/account-info')}>
          <DashboardSmallCard
            label='Account Info'
            desc='Navigate to your account info'
            avatar={<AccountCircleOutlined />}
          />
        </Box>
        <Box
          sx={{
            cursor: 'pointer',
            width: 'auto',
          }}
          onClick={() => navigate('/client/documents')}>
          <DashboardSmallCard label='Documents' desc='Navigate to your document info' avatar={<ListAltOutlined />} />
        </Box>
      </Grid>
    );
  };

  const renderClientHomePage = () => {
    if (clientInfo?.status === ClientStatus.PROSPECTIVE) {
      return renderInfoSessions();
    } else if (clientInfo?.status === ClientStatus.ENROLLED) {
      return renderProgramTrackDetails();
    } else if (clientInfo?.status === ClientStatus.PENDING_ASSIGNMENT) {
      return (
        <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '200px' }}>
          <Typography variant='h6' color='textSecondary'>
            A navigator is being assigned to you. Please wait for further updates.
          </Typography>
        </Grid>
      );
    } else if (clientInfo?.status === ClientStatus.PENDING_ENROLLMENT) {
      return (
        <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '200px' }}>
          <Typography variant='h6' color='textSecondary'>
            You’re almost there! Enrollment in a program track is pending. Please stay tuned for updates on your next
            steps.
          </Typography>
        </Grid>
      );
    } else if (clientInfo?.status === ClientStatus.SUSPENDED) {
      return renderSuspendedView();
    } else if (clientInfo?.status === ClientStatus.DROP_OUT) {
      return renderDropOutProgramTrackDetails();
    }
  };

  return (
    <Grid container gap={2}>
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          alignItems: isSmallScreen ? 'flex-start' : 'center',
          justifyContent: 'space-between',
          m: 5,
          width: '100%',
        }}>
        <Typography variant='h4'>Home</Typography>
      </Grid>

      <Grid sx={{ minWidth: '100%', display: 'flex', justifyContent: 'center', mx: 2 }}>{renderClientHomePage()}</Grid>

      {clientInfo?.status !== ClientStatus.SUSPENDED && clientInfo?.status !== ClientStatus.PROSPECTIVE ? (
        <Grid sx={{ minWidth: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mx: 2 }} gap={2}>
          {clientInfo?.id &&
            clientInfo?.status !== ClientStatus.PENDING_ASSIGNMENT &&
            isCompletedPt &&
            renderCompletedProgramTracks(clientInfo?.id)}
          {clientInfo?.workshopId && renderWorkshopDetails()}
          {clientInfo?.scheduledJobInterview && renderScheduledInterviewDetails(clientInfo?.scheduledJobInterview)}
          {clientInfo?.employment && renderHiredJobDetails(clientInfo?.employment)}
        </Grid>
      ) : null}

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'background.paper',
          boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
        }}>
        <Box sx={{ width: '95%', ml: 1 }}>{renderQuickLinkWidgets(isSmallScreen)}</Box>
      </Box>
    </Grid>
  );
}

const styles = {
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 1,
    alignSelf: 'flex-start',
  },
};
