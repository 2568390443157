import { Grid } from '@mui/material';
import React from 'react';
import { DashboardCard } from '../DashboardCard/DashboardCard';
import { ICommonClient, getClientsLive } from '../../services/client.service';
import { toast } from 'react-toastify';
import { ClientStatus } from '../../utils/clientStatus';
import { useNavigate } from 'react-router-dom';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { adminTypeEnum } from '../../utils/adminTypes';

interface IProspectiveClientsDashboardCardProps {
  userRole: string;
  adminType: string;
}

export default function ClientsPendingAssignmentDashboardCard(props: IProspectiveClientsDashboardCardProps) {
  const { adminType, userRole } = props;
  const navigate = useNavigate();
  const [client, setClients] = React.useState<Array<ICommonClient>>([]);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  const checkCardPermission =
    userRole === UserRolesEnum.SUPER_ADMIN ||
    (userRole === UserRolesEnum.ADMIN && !(adminType === adminTypeEnum.EMPLOYMENT));

  const loadClients = async () => {
    try {
      setShowLoader(true);
      const unsubscribe = getClientsLive(ClientStatus.PENDING_ASSIGNMENT, (data: any) => {
        const filteredClients = data.filter((i: any) => i?.account != 'deleted');
        setClients(filteredClients);
        setShowLoader(false);
      });
      return () => {
        unsubscribe();
      };
    } catch (err: any) {
      toast.error('Unable to load client list');
    }
  };

  React.useEffect(() => {
    if (checkCardPermission) loadClients();
    // eslint-disable-next-line
  }, [userRole]);

  return checkCardPermission ? (
    <Grid item lg={6} xs={12}>
      <DashboardCard
        isLoading={showLoader}
        label='New Clients Pending Navigator'
        desc='List of upcoming clients awaiting navigator'
        list={client}
        // eslint-disable-next-line
        onItemClick={(item: any) => {
          navigate('../clientInfo', { state: { clientId: item.id } });
        }}
      />
    </Grid>
  ) : null;
}
