export const Palette = {
  white: '#fff',
  black: '#000',
  white_f5f5ff: '#f5f5ff',
  blue_4285f4: '#4285f4',
  grey_eee: '#eee',
  grey_F8F8FA: '#F8F8FA',
  grey_C4C4C4: '#C4C4C4',
  grey_5657581F: '#5657581F',
  purple_F2E7FC: '#F2E7FC',
  purple_8C18E2: '#8C18E2',
  blue_E1F5FD: '#E1F5FD',
  green_E4F3E5: '#E4F3E5',
  green_4CAF50: '#4CAF50',
  green_E8F5E9: '#E8F5E9',
  yellow_F3F3E4: '#F3F3E4',
  yellow_D8D0A7: '#D8D0A7',
  red_FBE7E7: '#FBE7E7',
  indigo_4A4DE6: '#4A4DE6',
  orange_FF6422: '#FF6422',
  green_31BD1A: '#31BD1A',
  triadic_fbe2f0: '#fbe2f0',
  triadic_ef4fa6: '#ef4fa6',
  red_FFCDD2: '#FFCDD2',
  red_D50000: '#D50000',
  orange_FF671D: '#FF671D',
  blue_0C2C65: '#0C2C65',
  gray_FAFAFA: '#FAFAFA',
  gray_F5F5F5: '#F5F5F5',
  gray_EEEEEE: '#EEEEEE',
};
