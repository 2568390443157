import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';
import { db, functions } from '../config/firebaseApp';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';

interface IWclData {
  clientIds: string[];
}

export const createWcl = async (wclData: IWclData) => {
  try {
    if (!wclData) {
      throw new Error();
    }

    await httpsCallable(functions, 'createWorkingClientList')(wclData);
  } catch (err: any) {
    toast.error('Error Creating Working Client List!');
  }
};

export const updateWcl = async (wclData: IWclData, param: string, userId: string) => {
  try {
    if (!wclData || !param || !userId) {
      throw new Error('No data provided');
    }

    let payload: any[] = [];

    if (param === 'add') {
      const collectionName = 'workingClientList';
      const docRef = doc(db, collectionName, userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const oldData = docSnap.data().clientIds || [];
        payload = [...oldData, ...wclData.clientIds].filter((value, index, self) => self.indexOf(value) === index);
      }
    }

    const response: any = await httpsCallable(functions, 'updateWorkingClientList')({ clientIds: payload });

    if (response.data.success) {
      toast.success('Working Client List Updated Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Updating Working Client List!');
  }
};

export const getExistingWclData = async (docId: string) => {
  const collectionName = 'workingClientList';
  const docRef = doc(db, collectionName, docId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    await createWcl({ clientIds: [] });
    return [];
  }

  const clientIds = docSnap.data().clientIds;
  const clientsCollection = 'clients';
  const ptcmCollection = 'programTrackClientMap';

  const clientDocsPromises = clientIds.map((clientId: string) => getDoc(doc(db, clientsCollection, clientId)));
  const clientDocs = await Promise.all(clientDocsPromises);

  const clientsWithPtcmPromises = clientDocs.map(async (clientSnap) => {
    if (clientSnap.exists()) {
      let clientDocData: any = { ...clientSnap.data(), id: clientSnap.id };

      const ptcmQuery = query(
        collection(db, ptcmCollection),
        where('clientId', '==', clientSnap.id),
        where('status', '==', 'active')
      );
      const ptcmSnapshot = await getDocs(ptcmQuery);

      if (!ptcmSnapshot.empty) {
        const ptcmDoc = ptcmSnapshot.docs[0];
        clientDocData.programTrackId = ptcmDoc.data().programTrackId;
      }

      return clientDocData;
    }
    return null;
  });

  const clientsData = await Promise.all(clientsWithPtcmPromises);

  return clientsData.filter((client) => client !== null);
};
