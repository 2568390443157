export const dropoutReasons = [
  {
    id: 12,
    value: 'Day One No Show',
  },
  {
    id: 1,
    value: 'Participant could not meet the technical requirements for graduation',
  },
  {
    id: 2,
    value: 'Participant withdrew due to family obligations',
  },
  {
    id: 3,
    value: 'Participant withdrew due to physical health reasons',
  },
  {
    id: 4,
    value: ' Participant withdrew due to mental health reasons',
  },
  {
    id: 5,
    value: 'Participant withdrew due to lack of adequate transportation',
  },
  {
    id: 6,
    value: 'Participant  withdrew due to lack of childcare',
  },
  {
    id: 7,
    value: 'Participant withdrew due to financial obligations',
  },
  {
    id: 8,
    value: 'Participant were dismissed due to behavior',
  },
  {
    id: 9,
    value: 'Participant did not meet attendance requirements',
  },
  {
    id: 10,
    value: 'Participant withdrew because they started a new job during training',
  },
  {
    id: 11,
    value: 'Other',
  },
];

export const suspensionReasons = [
  {
    id: 1,
    value: 'Inappropriate behavior towards PINCC staff or providers',
  },
  {
    id: 2,
    value: 'Refusal to provide required documentation',
  },
  {
    id: 3,
    value: 'Explicit request to never be contacted again regarding PINCC',
  },
  {
    id: 4,
    value: 'Never agreed to terms (including profiles created by PINCC staff)',
  },
];

export const DropoutConst = {
  DROPOUT: 'Client Dropout',
  OTHER_OPTION_ID: 11,
};
