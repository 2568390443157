import React from 'react';
import { NavLink } from 'react-router-dom';
import { DashboardSmallCard } from '../DashboardSmallCard/DashboardSmallCard';
import { EventNoteOutlined } from '@mui/icons-material';

const ViewInfoSessionButton = () => {
  return (
    <NavLink to={'/admin/infoSessionList'} style={{ textDecoration: 'none' }}>
      <DashboardSmallCard
        label='Info Session Schedule'
        desc='View Info Session Schedule'
        avatar={<EventNoteOutlined />}
      />
    </NavLink>
  );
};

export default ViewInfoSessionButton;
