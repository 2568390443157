import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import CustomTable from '../../../components/CustomTable';
import AddWorkshop from '../../../components/Modals/AddWorkshop/AddWorkshop';
import GenericDialog from '../../../components/Dialog/genericDialog/genericDialog';
import { useAppSelector } from '../../../store/store';
import { UserModel } from '../../../store/user/userModel';
import { deleteWorkshop, getWorkshopList, searchWorkshop } from '../../../services/workshop.service';
import { IWorkshopInfo, IWorkshopMetaInfo } from '../../../store/employmentModule/workshopModal';
import { shouldShowButton } from '../dashboard/dashboardAccessControl';
import { COLORS } from '../../../utils/colors';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { IPaginationModel } from '../../../store/pagination/paginationModel';
import { CreateColumnCell } from '../../../components/CreateColumnCell/CreateColumnCell';
import { WorkshopTypesEnum } from '../../../utils/workshopUtils';
import { CreateColumnHeader } from '../../../components/CreateColumnHeader/CreateColumnHeader';
import { useNavigate } from 'react-router-dom';

const WorkshopList = (props: any) => {
  const navigate = useNavigate();
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [workshopList, setWorkshopList] = useState<IWorkshopInfo[]>([]);
  const [selectedWorkshop, setSelectedWorkshop] = useState<IWorkshopInfo>();
  const [workshopMetaInfo, setWorkshopMetaInfo] = useState<IWorkshopMetaInfo>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [workshopModal, setWorkshopModal] = useState<boolean>(false);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const [sortModel, setSortModel] = useState([]);
  const PAGE_SIZE = 10;
  const [paginationModel, setPaginationModel] = useState<IPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [searchPaginationModel, setSearchPaginationModel] = useState<IPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const currentTime = moment.tz('America/New_York');

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteWorkshop(selectedWorkshop?.id || '');
      setLoading(false);
      fetchWorkshopList();
    } catch (err: any) {
      console.error(err?.response.data.message || err?.message);
    }
  };

  const handleSearch = async () => {
    if (searchQuery) {
      setLoading(true);
      setIsSearchMode(true);
      const result = await searchWorkshop(searchQuery.toLowerCase().trim(), searchPaginationModel.page + 1);

      if (result?.workshops.length) {
        setWorkshopMetaInfo(result);
        setWorkshopList(result.workshops);
      } else {
        setWorkshopMetaInfo(result);
        setWorkshopList([]);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [searchPaginationModel.page]);

  const fetchWorkshopList = async () => {
    setLoading(true);
    setIsSearchMode(false);
    setSearchQuery('');
    const unsubscribe = getWorkshopList(paginationModel.page + 1, sortModel, (data: IWorkshopMetaInfo) => {
      if (data.workshops.length > 0) {
        setWorkshopMetaInfo(data);
        setWorkshopList(data.workshops);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  };

  useEffect(
    () => {
      fetchWorkshopList();
    },
    // eslint-disable-next-line
    [editMode, props, shouldFetch, paginationModel.page, sortModel]
  );
  useEffect(() => {
    if (!searchQuery) {
      fetchWorkshopList();
    }
    // eslint-disable-next-line
  }, [searchQuery]);

  const handleAddWorkshop = () => {
    setShouldFetch(true);
    fetchWorkshopList();
  };

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      type: 'string',
      sortable: true,
      width: 350,
    },
    {
      field: 'location',
      headerName: 'Location',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) => params.row.location || '--',
    },
    {
      field: 'dateTime',
      headerName: 'Date & Time',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) =>
        dayjs(params.row.dateTime[0].timing).tz('America/New_York').format('MM/DD/YYYY, hh:mm A'),
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'string',
      sortable: true,
      width: 300,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        if (params.row.type === WorkshopTypesEnum.VIRTUAL) {
          return CreateColumnCell(
            params.row.type,
            COLORS.palette.white,
            undefined,
            2,
            COLORS.palette.lightGreen,
            COLORS.palette.green
          );
        } else if (params.row.type === WorkshopTypesEnum.IN_PERSON) {
          return CreateColumnCell(
            params.row.type,
            COLORS.palette.white,
            undefined,
            2,
            COLORS.palette.lightRed,
            COLORS.palette.red
          );
        } else {
          return CreateColumnCell(
            params.row.type,
            COLORS.palette.white,
            undefined,
            2,
            COLORS.palette.lightBlue,
            COLORS.palette.blue
          );
        }
      },
    },
    {
      field: 'numClients',
      headerName: 'No. of Clients',
      type: 'number',
      sortable: true,
      width: 300,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return CreateColumnCell(
          params.row.numClients || 0,
          COLORS.palette.white,
          undefined,
          2,
          COLORS.palette.lightBlue,
          COLORS.palette.blue
        );
      },
    },
    {
      headerName: 'Actions',
      sortable: false,
      width: 200,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => (
        <Box display={'flex'} gap={2}>
          <Tooltip title='Edit' arrow>
            <IconButton
              color='primary'
              onClick={(e) => {
                e.stopPropagation();
                setSelectedWorkshop(params.row);
                setEditMode(true);
                setWorkshopModal(true);
              }}
              disabled={moment(params.row.dateTime).tz('America/New_York').isBefore(currentTime)}>
              <EditOutlined
                style={{
                  color: moment(params.row.dateTime).tz('America/New_York').isBefore(currentTime)
                    ? COLORS.theme.lightPrimaryColor
                    : COLORS.theme.primaryColor,
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title='Delete' arrow>
            <IconButton
              color='error'
              onClick={(e) => {
                e.stopPropagation();
                setSelectedWorkshop(params.row);
                setDeleteAlert(true);
              }}
              disabled={moment(params.row.dateTime).tz('America/New_York').isBefore(currentTime)}>
              <DeleteOutline
                style={{
                  color: moment(params.row.dateTime).tz('America/New_York').isBefore(currentTime)
                    ? COLORS.palette.lightRed
                    : COLORS.palette.red,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleSortModelChange = (newSortModel: any) => {
    setSortModel(newSortModel);
  };
  console.log(sortModel);
  return (
    <Box>
      <CustomTable
        columns={columns}
        hideSearch={false}
        rows={workshopList || []}
        filterList={[]}
        loading={loading}
        onFilterListChange={() => {}}
        onPaginationModelChange={isSearchMode ? setSearchPaginationModel : setPaginationModel}
        paginationModel={isSearchMode ? searchPaginationModel : paginationModel}
        primaryButtonLable='Create Workshop'
        primaryButtonAction={() => {
          setEditMode(false);
          setWorkshopModal(true);
        }}
        disabled={!shouldShowButton('createWorkshop', user?.role)}
        rowCount={workshopMetaInfo?.totalRecords || 0}
        title='Workshops'
        description='Job Readiness Training'
        onChangeSearchQuery={(query: string) => {
          setSearchQuery(query);
        }}
        onRowClick={(params: any) => {
          navigate('../workshop-info', {
            state: { workshopId: params.row.id },
          });
        }}
        handleSearch={() => handleSearch()}
        searchQuery={searchQuery}
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel}
      />

      <AddWorkshop
        open={workshopModal}
        data={editMode ? selectedWorkshop : null}
        onClose={() => setWorkshopModal(false)}
        mode={editMode ? 'edit' : 'create'}
        onAddWorkshop={handleAddWorkshop}
      />

      <GenericDialog
        onClose={() => setDeleteAlert(false)}
        dialogTitle='Are you sure?'
        agreeBtnText='Delete'
        disagreeBtnText='Cancel'
        agreeFunc={() => handleDelete()}
        open={deleteAlert}
      />
    </Box>
  );
};

export default WorkshopList;
