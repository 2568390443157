import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { COLORS } from '../../../utils/colors';
import { WriteModeEnum } from '../../../utils/writeModeEnum';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { LoadingButton } from '../../LoadingButton/LoadingButton';

interface IAddDateTime {
  open: boolean;
  onClose: () => void;
  mode: string;
  handleSubmit: Function;
  setDateTime: Function;
  dateTime: string;
  loading: boolean;
  data?: any;
  isAddInterviewDate?: boolean;
}

const AddDateTime = (props: IAddDateTime) => {
  const { open, mode, onClose, handleSubmit, dateTime, setDateTime, loading, data, isAddInterviewDate } = props;

  return (
    <Dialog fullWidth open={open} onClose={() => onClose()} PaperProps={{ sx: { borderRadius: '14px' } }}>
      <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Typography variant='h6'>
            {isAddInterviewDate
              ? 'Add New Interview Date'
              : mode === WriteModeEnum.CREATE
              ? 'Add Real Time'
              : 'Update Real Time'}
          </Typography>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Grid item md={12} xs={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ okButtonLabel: 'Select' }}>
              <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                <DateTimePicker
                  label='Date and Time'
                  slotProps={{ textField: { size: 'small', error: false, fullWidth: true, sx: { width: '100%' } } }}
                  name='dateTime'
                  defaultValue={mode === WriteModeEnum.CREATE ? dayjs(dateTime) : dayjs(data.realTime)}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  onChange={(date: string | number | Date | dayjs.Dayjs | null | undefined) => {
                    const formattedDate = dayjs(date)?.toISOString() || '';
                    setDateTime(formattedDate);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Box>
        <Grid container justifyContent={'center'}>
          <Grid item>
            <LoadingButton
              onClick={() => handleSubmit()}
              label={isAddInterviewDate ? 'Add' : mode === WriteModeEnum.CREATE ? 'Create' : 'Update'}
              loading={loading}
              disabled={!dateTime}
              size='medium'
              type='submit'
              variant='contained'
              styles={{ textTransform: 'none' }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddDateTime;
