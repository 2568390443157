import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { db, functions } from '../config/firebaseApp';
import { toast } from 'react-toastify';
import { ClientMapStatus } from '../utils/clientMapStatus';
import { getClientsInfoByIds, getMultipleClientByIds } from './client.service';
import { getMultipleProgramTracksById, getProgramTrackById, programTrackInfoById } from './programTrack.service';
import { getMilestonesArray } from '../utils/milestones';
import { convertTimeStampToDate, getTimeDifferenceInHours, subtractDaysFromToday } from '../utils/dateTime';
import { programTrackTypes } from '../utils/programTrackEnums';
import { httpsCallable } from 'firebase/functions';
import { getMultipleUserInfoByUserIds, getUserInfoById } from './user.service';
import { ClientStatus } from '../utils/clientStatus';
import { AttendanceStatus } from '../utils/attendance';
import { getMultipleCareerPathInfoByIds } from './careerPath.service';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = 'America/New_York';
dayjs.tz.setDefault(tz);

export interface ITrainingCompleteMetaInfo {
  totalRecords: number;
  currentPage: number;
  clients: Array<object>;
  totalPages: number;
}

export const assignProgramTrackToClient = async (
  clientId: string,
  programTrackId: string,
  programTrackType: string
) => {
  try {
    const payload = {
      id: clientId,
      programTrackId: programTrackId,
      programTrackType: programTrackType,
    };

    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(
      collectionRef,
      where('clientId', '==', clientId),
      where('programTrackId', '==', programTrackId),
      where('status', '==', ClientMapStatus.COMPLETE)
    );

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      toast.error('Re-Enrollment Not Allowed For Completed Program Tracks!');
      return;
    }
    const response: any = await httpsCallable(functions, 'setProgramTrackToClient')(payload);

    if (response.data.success) {
      toast.success('Program Track Assigned!');
    }
  } catch (e) {
    toast.error('Error Assigning Program Track To Client');
  }
};

export const getClientMapDataByClientId = async (clientId: string) => {
  try {
    if (!clientId) {
      throw new Error('clientId is null or undefined');
    }

    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(
      collectionRef,
      where('clientId', '==', clientId),
      where('status', 'in', ['review', 'active', 'pre_complete'])
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const list: any = [];
      querySnapshot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      return list;
    } else {
      return [];
    }
  } catch (err: any) {
    //handle error
  }
};

export const getCompletedMilestonesByClientId = async (clientId: string, page: number) => {
  try {
    const clientRef = doc(db, 'clients', clientId);
    const docSnapshot: any = await getDoc(clientRef);

    const status = docSnapshot.data().status;

    if (status === ClientStatus.ENROLLED || status === ClientStatus.DROP_OUT || status === ClientStatus.COMPLETED) {
      const ptcmRef = collection(db, 'programTrackClientMap');
      const q = query(ptcmRef, where('clientId', '==', clientId));
      const querySnapshot = await getDocs(q);
      let ptcmDoc;
      if (!querySnapshot.empty) {
        if (querySnapshot.size > 1) {
          let latestDocument: any = null;
          querySnapshot.forEach((doc) => {
            const ptcm = doc.data();
            const currentTimestamp = ptcm.enrollInitiatedAt;
            if (!latestDocument || currentTimestamp > latestDocument.enrollInitiatedAt) {
              latestDocument = { ...ptcm };
            }
          });
          ptcmDoc = latestDocument;
        } else {
          ptcmDoc = querySnapshot.docs[0].data();
        }

        const startDate = ptcmDoc?.startDate;
        const programTrackId = ptcmDoc.programTrackId;
        const ptRef = doc(db, 'programTrack', programTrackId);
        const ptDoc = await getDoc(ptRef);
        const milestones = ptDoc.data()?.milestones || [];
        const ptType = ptDoc.data()?.type;
        const completedMilestones = ptcmDoc?.completedMilestones || [];
        const currentMilestoneIndex = ptcmDoc?.currentMilestoneIndex;
        const pageSize = 10;
        const offset = (page - 1) * pageSize;
        const totalPages = Math.ceil(milestones.length / pageSize);
        const limitedMilestones = milestones.slice(offset, offset + pageSize);
        const result: any = {
          totalRecords: milestones.length,
          currentPage: page,
          completedMilestones: completedMilestones,
          milestones: limitedMilestones,
          totalPages: totalPages,
          programTrackId: programTrackId,
          currentMilestoneIndex: currentMilestoneIndex,
          ptcmStatus: ptcmDoc.status,
        };
        if (ptType === 'rolling') {
          result.startDate = startDate;
        }
        return result;
      } else {
        return {};
      }
    } else {
      return {};
    }
  } catch (err: any) {
    console.log('Error executing getPTCMDataByClientId:', err);
  }
};

export const getLatestPTCMByProgramTrackId = async (clientId: string, programTrackId: string, page: number) => {
  try {
    const ptcmRef = collection(db, 'programTrackClientMap');
    const q = query(
      ptcmRef,
      where('clientId', '==', clientId),
      where('programTrackId', '==', programTrackId),
      where('status', 'in', ['active', 'drop_out', 'pre_complete', 'complete'])
    );
    const querySnapshot = await getDocs(q);
    let ptcmDoc;
    if (!querySnapshot.empty) {
      if (querySnapshot.size > 1) {
        let latestDocument: any = null;
        querySnapshot.forEach((doc) => {
          const ptcm = doc.data();
          const currentTimestamp = ptcm.enrollInitiatedAt;
          if (!latestDocument || currentTimestamp > latestDocument.enrollInitiatedAt) {
            latestDocument = { ...ptcm };
          }
        });
        ptcmDoc = latestDocument;
      } else {
        ptcmDoc = querySnapshot.docs[0].data();
      }

      const startDate = ptcmDoc?.startDate;
      const programTrackId = ptcmDoc.programTrackId;
      const ptRef = doc(db, 'programTrack', programTrackId);
      const ptDoc = await getDoc(ptRef);
      const milestones = ptDoc.data()?.milestones || [];
      const ptType = ptDoc.data()?.type;
      const completedMilestones = ptcmDoc?.completedMilestones || [];
      const currentMilestoneIndex = ptcmDoc?.currentMilestoneIndex;
      const pageSize = 10;
      const offset = (page - 1) * pageSize;
      const totalPages = Math.ceil(milestones.length / pageSize);
      const limitedMilestones = milestones.slice(offset, offset + pageSize);
      const result: any = {
        totalRecords: milestones.length,
        currentPage: page,
        completedMilestones: completedMilestones,
        milestones: limitedMilestones,
        totalPages: totalPages,
        programTrackId: programTrackId,
        currentMilestoneIndex: currentMilestoneIndex,
        ptcmStatus: ptcmDoc.status,
      };
      if (ptType === 'rolling') {
        result.startDate = startDate;
      }
      return result;
    } else {
      return {};
    }
  } catch (err: any) {
    console.log('Error executing getLatestPTCMByProgramTrackId:', err);
  }
};

export const getClientsByProgramTrackId = async (programTrackId: string) => {
  try {
    if (!programTrackId) {
      throw new Error();
    }
    const programTrackData = await programTrackInfoById(programTrackId);

    let milestonesList = programTrackData?.milestones ?? [];
    const type = programTrackData?.type ?? [];

    const statusList = [
      ClientMapStatus.ACTIVE,
      ClientMapStatus.DROP_OUT,
      ClientMapStatus.COMPLETE,
      ClientMapStatus.PRE_COMPLETE,
    ];
    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(collectionRef, where('programTrackId', '==', programTrackId));
    const querySnapshot = await getDocs(q);
    const clientMapDataList: any = [];
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        if (statusList.includes(doc.data().status)) {
          clientMapDataList.push({ id: doc.id, ...doc.data() });
        }
      });
    }
    const listOfClients: any = [];
    const listOfClientIds: any = [];
    if (!querySnapshot.empty) {
      clientMapDataList.forEach((doc: any) => {
        doc?.completedMilestones?.forEach(async (item: any) => {
          const userData = await getUserInfoById(item?.markedBy || '');
          item.markedBy = userData;
        });
        listOfClients.push({ id: doc.id, ...doc });
        listOfClientIds.push(doc.clientId);
      });

      const clientsInfo = await getClientsInfoByIds(listOfClientIds);
      const mergedList = listOfClients.map((client: any) => {
        const matchingClient = clientsInfo.find((c: any) => c.id === client.clientId);
        if (matchingClient) {
          if (type === programTrackTypes.ROLLING.value) {
            const startDate = convertTimeStampToDate(client.startDate).toDate();
            if (!startDate) {
              throw new Error(`startDate is null or undefined for client with id ${client.clientId}`);
            }
            milestonesList = getMilestonesArray(startDate, milestonesList);
          }
          let val = {
            ...client,
            firstName: matchingClient.firstName,
            middleName: matchingClient.middleName,
            lastName: matchingClient.lastName,
            milestones: milestonesList,
            type: type,
          };
          if (!client.currentMilestoneIndex) {
            val.currentMilestoneIndex = '0';
          }
          return val;
        } else {
          return client;
        }
      });
      return mergedList;
    } else {
      return listOfClients;
    }
  } catch (err: any) {
    //handle error
  }
};

export const updateClientMapsByClientIds = async (
  clientIds: string[],
  status: string,
  milestoneIndex: string,
  programTrackId: string,
  attendance: string
) => {
  try {
    if (!clientIds || !programTrackId || !milestoneIndex || !status || !attendance) {
      throw new Error();
    }

    const payload = {
      ids: clientIds,
      status: status,
      milestoneIndex: milestoneIndex,
      programTrackId: programTrackId,
      attendance: attendance,
    };

    const response: any = await httpsCallable(functions, 'updateClientMaps')(payload);

    if (response.data.success) {
      if (status === ClientMapStatus.DROP_OUT) {
        toast.success('Client Dropped Out');
      } else if (status === ClientMapStatus.ACTIVE) {
        toast.success('Client Milestone Updated');
      } else if (status === ClientMapStatus.COMPLETE) {
        toast.success('Client Completed Program Track');
      } else if (status === ClientMapStatus.PRE_COMPLETE) {
        toast.success('Client Pre Completed Program Track');
      }
    }
  } catch (err: any) {
    toast.error('Error Updating Milestone Step!');
  }
};

export const completeProgramTrackForClients = async (
  clientIds: string[],
  milestoneIndex: string,
  programTrackId: string
) => {
  try {
    if (!clientIds || !programTrackId || !milestoneIndex) {
      throw new Error();
    }

    const payload = {
      ids: clientIds,
      milestoneIndex: milestoneIndex,
      programTrackId: programTrackId,
    };
    const response: any = await httpsCallable(functions, 'completeProgramTrackForClients')(payload);

    if (response.data.success) {
      toast.success('Client Completed Program Track');
    }
  } catch (err: any) {
    toast.error("Couldn't Complete Program Track");
  }
};

export const getClientMapDataByMultipleClientId = async (clientIds: string[]) => {
  try {
    if (!clientIds.length) {
      throw new Error();
    }

    const collectionRef = collection(db, 'programTrackClientMap');
    const querySnapshot = await getDocs(query(collectionRef));

    if (!querySnapshot.empty) {
      const list: any = [];
      querySnapshot.forEach((doc) => {
        if (clientIds.includes(doc.data().clientId)) {
          list.push({ id: doc.id, ...doc.data() });
        }
      });
      return list;
    } else {
      return [];
    }
  } catch (err: any) {
    return [];
  }
};

export const getCompletedClientsInLastXDays = async (duration: string, page: number) => {
  try {
    let days: number = 0;

    if (duration === 'month') {
      days = 30;
    } else if (duration === 'quarter') {
      days = 90;
    }

    const startTimestamp = subtractDaysFromToday(days);

    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(collectionRef, where('status', '==', ClientMapStatus.COMPLETE));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const ptcmList: any = [];
      const clientIds: any = [];
      const programTrackIds: any = [];
      const finalList: any = [];
      querySnapshot.forEach((doc) => {
        const milestoneArr = doc.data().completedMilestones;
        const len = milestoneArr.length;
        const lastMilestone = milestoneArr[len - 1];
        if (convertTimeStampToDate(lastMilestone.completionDate).toDate() >= startTimestamp) {
          ptcmList.push({ id: doc.id, lastMilestoneCompletionDate: lastMilestone.completionDate, ...doc.data() });
          clientIds.push(doc.data().clientId);
          programTrackIds.push(doc.data().programTrackId);
        }
      });

      const clientInfoList = await getMultipleClientByIds(clientIds);
      const programTrackInfo: any[] = await getMultipleProgramTracksById(programTrackIds);

      ptcmList.forEach((doc: any) => {
        let payload: any = {};

        const matchingClient = clientInfoList.find((client: any) => client.id === doc.clientId);
        if (matchingClient) {
          payload.clientInfo = matchingClient;
        }

        const matchingPT = programTrackInfo.find((programTrack: any) => programTrack.id === doc.programTrackId);
        let startTime;

        if (matchingPT.type === 'rolling') {
          startTime = doc.startDate;
        } else if (matchingPT.type === 'cohort') {
          startTime = matchingPT.startTime;
        }

        const start = convertTimeStampToDate(startTime);
        const end = convertTimeStampToDate(doc.lastMilestoneCompletionDate);

        payload.startTime = startTime;
        payload.endTime = doc.lastMilestoneCompletionDate;

        const differenceInDays = end.diff(start, 'day');
        payload.timeRange = differenceInDays;

        finalList.push(payload);
      });

      if (finalList.length > 0) {
        const pageSize = 10;
        const offset = (page - 1) * pageSize;
        const totalPages = Math.ceil(finalList.length / pageSize);
        const limitedClients = finalList.slice(offset, offset + pageSize);
        const result: any = {
          totalRecords: finalList.length,
          currentPage: page,
          clients: limitedClients,
          totalPages: totalPages,
        };
        return result;
      } else {
        return {
          totalRecords: 0,
          currentPage: page,
          clients: [],
          totalPages: 0,
        };
      }
    }
  } catch (error: any) {
    toast.error('Error Retrieving Client data!');
  }
};

export const getClientsByProgramTrack = async (programTrackId: string) => {
  try {
    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(collectionRef, where('programTrackId', '==', programTrackId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const ptcmList: any = [];
      const clientIds: any = [];
      querySnapshot.forEach((doc) => {
        ptcmList.push({ id: doc.id, ...doc.data() });
        clientIds.push(doc.data().clientId);
      });

      const clientInfoList = await getMultipleClientByIds(clientIds);
      return clientInfoList;
    } else {
      return [];
    }
  } catch (error: any) {
    //handle error
  }
};

export const getClientsByProgramTrackForReport = async (programTrackId: string) => {
  try {
    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(
      collectionRef,
      where('programTrackId', '==', programTrackId),
      where('status', 'in', ['active', 'drop_out', 'pre_complete', 'complete'])
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const ptcmList: any = [];
      const clientIds: any = [];
      querySnapshot.forEach((doc) => {
        ptcmList.push({ id: doc.id, ...doc.data() });
        clientIds.push(doc.data().clientId);
      });

      const clientInfoList = await getMultipleClientByIds(clientIds);
      return clientInfoList;
    } else {
      return [];
    }
  } catch (error: any) {
    //handle error
  }
};

export const dropoutClient = async (
  clientIds: string[],
  status: string,
  milestoneIndex: string,
  programTrackId: string,
  dropoutReasonId: number,
  notePayload: any
) => {
  try {
    if (!clientIds || !programTrackId || !milestoneIndex || !status || !dropoutReasonId) {
      throw new Error();
    }

    const payload: any = {
      ids: clientIds,
      status: status,
      milestoneIndex: milestoneIndex,
      programTrackId: programTrackId,
      dropoutReasonId: dropoutReasonId,
      attendance: 'present',
    };

    const response: any = await httpsCallable(functions, 'updateClientMaps')(payload);

    const newNote = {
      ids: notePayload.clientIds,
      description: notePayload.description,
    };

    await httpsCallable(functions, 'createDropoutNote')(newNote);

    if (response.data.success) {
      toast.success('Client Dropped Out');
    }
  } catch (err: any) {
    toast.error('Error: Unable To Dropout Client!');
  }
};

// This function should only be used to convert client at "pre_complete" PTCM status to "complete".
// status in parameter should be "complete"
export const updateClientMapStatus = async (clientId: string, status: string) => {
  try {
    const payload = {
      clientId: clientId,
      status: status,
    };
    const response: any = await httpsCallable(functions, 'updateClientMapStatus')(payload);
    if (response.data.success) {
      toast.success('Client Status Updated Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Updating Client Map Status!');
  }
};

export const getClientsByCurrentMilestoneIndex = async (programTrackId: string, currentMilestoneIndex: string) => {
  try {
    const collectionRef = collection(db, 'programTrackClientMap');
    let q;

    if (currentMilestoneIndex === '0') {
      q = query(
        collectionRef,
        where('programTrackId', '==', programTrackId),
        where('status', '==', ClientMapStatus.ACTIVE),
        where('completedMilestones', '==', [])
      );
    } else {
      q = query(
        collectionRef,
        where('programTrackId', '==', programTrackId),
        where('currentMilestoneIndex', '==', currentMilestoneIndex),
        where('status', '==', ClientMapStatus.ACTIVE)
      );
    }
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const ptcmList: any = [];
      const clientIds: any = [];
      querySnapshot.forEach((doc) => {
        ptcmList.push({ id: doc.id, ...doc.data() });
        clientIds.push(doc.data().clientId);
      });

      const clientInfoList = await getMultipleClientByIds(clientIds);
      return clientInfoList;
    } else {
      return [];
    }
  } catch (error: any) {
    toast.error('Error Fetching Clients!');
  }
};

export const getClientsByPTForFullAttendanceReport = async (programTrackId: string, page: number) => {
  try {
    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(
      collectionRef,
      where('programTrackId', '==', programTrackId),
      where('status', '==', ClientMapStatus.COMPLETE)
    );

    const ptInfo = await programTrackInfoById(programTrackId);
    let milestoneHours = 0;
    ptInfo?.milestones.forEach((item: any) => {
      if (item.startTime && item.endTime) {
        milestoneHours = milestoneHours + getTimeDifferenceInHours(item.startTime, item.endTime);
      }
    });

    const clientDetail: any = [];
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const clientIds: any = [];
      querySnapshot.forEach((doc) => {
        clientIds.push(doc.data().clientId);
      });
      const clientInfoList = await getMultipleClientByIds(clientIds);

      querySnapshot.forEach((doc) => {
        const clientInfo = clientInfoList.find((item: any) => doc.data().clientId === item.id);

        let daysPresent = 0;
        let daysAbsent = 0;
        const completedMilestones = doc.data().completedMilestones;
        completedMilestones.forEach((item: any) => {
          if (item.attendance) {
            if (item.attendance === AttendanceStatus.PRESENT) {
              daysPresent++;
            } else if (item.attendance === AttendanceStatus.ABSENT) {
              daysAbsent++;
            }
          }
        });

        const enrollmentDate = doc.data().enrollmentApprovedAt
          ? convertTimeStampToDate(doc.data().enrollmentApprovedAt).format('MM/DD/YYYY')
          : doc.data().enrollInitiatedAt
          ? convertTimeStampToDate(doc.data().enrollInitiatedAt).format('MM/DD/YYYY')
          : '';

        const completionDate = convertTimeStampToDate(
          completedMilestones[completedMilestones.length - 1].completionDate
        ).format('MM/DD/YYYY');

        const payload = {
          id: clientInfo.id,
          firstName: clientInfo.firstName,
          lastName: clientInfo.lastName,
          daysPresent: daysPresent,
          daysAbsent: daysAbsent,
          milestoneHours: milestoneHours,
          enrollmentDate: enrollmentDate,
          completionDate: completionDate,
        };
        clientDetail.push(payload);
      });

      if (clientDetail.length > 0) {
        const pageSize = 10;
        const offset = (page - 1) * pageSize;
        const totalPages = Math.ceil(clientDetail.length / pageSize);
        const limitedClients = clientDetail.slice(offset, offset + pageSize);
        const result: any = {
          totalRecords: clientDetail.length,
          currentPage: page,
          clientDetail: limitedClients,
          totalPages: totalPages,
        };
        return result;
      } else {
        return {
          totalRecords: 0,
          currentPage: page,
          clientDetail: [],
          totalPages: 0,
        };
      }
    } else {
      return {
        totalRecords: 0,
        currentPage: page,
        clientDetail: [],
        totalPages: 0,
      };
    }
  } catch (error: any) {
    console.log(error);
  }
};

export const getPtcmDataInReviewByClientId = async (clientId: string, callback: Function) => {
  try {
    if (!clientId) {
      throw new Error();
    }
    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(collectionRef, where('clientId', '==', clientId), where('status', '==', 'review'));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const list: any = [];
        const programTrackIds: any = [];

        querySnapshot.forEach((doc) => {
          programTrackIds.push(doc.data().programTrackId);
        });
        const programTracksInfo: any[] = await getMultipleProgramTracksById(programTrackIds);
        querySnapshot.forEach((doc) => {
          const ptcmData = doc.data();
          const matchingPT = programTracksInfo.find((programTrack: any) => programTrack.id === ptcmData.programTrackId);
          ptcmData.programTrackName = matchingPT.name;
          list.push({ id: doc.id, ...ptcmData });
        });
        callback(list);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Program Track Client Map Data');
  }
};

export const getClientsAbove80PrcntComplete = async (callback: Function) => {
  try {
    const collectionRef = collection(db, 'programTrackClientMap');
    const qry = query(collectionRef, where('status', '==', ClientMapStatus.ACTIVE));
    const querySnapshot = await getDocs(qry);

    const clientIds: string[] = [];
    const programTrackPromises: Promise<any>[] = [];

    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      const completedMilestones = docData.completedMilestones;

      programTrackPromises.push(
        getProgramTrackById(docData.programTrackId).then((ptInfo: any) => {
          const milestones = ptInfo?.milestones || [];
          if (milestones.length > 0) {
            const percentageCompletion = (completedMilestones.length / milestones.length) * 100;
            if (percentageCompletion >= 80) {
              clientIds.push(docData.clientId);
            }
          }
        })
      );
    });

    await Promise.all(programTrackPromises);

    const clientList = await getMultipleClientByIds(clientIds);
    callback(clientList);
  } catch (err: any) {
    console.error(err);
    return () => {};
  }
};

export const getClientsCompletedProgramTracks = async (clientId: string) => {
  try {
    const collectionRef = collection(db, 'programTrackClientMap');
    const qry = query(
      collectionRef,
      where('status', '==', ClientMapStatus.COMPLETE),
      where('clientId', '==', clientId)
    );
    const querySnapshot = await getDocs(qry);
    if (!querySnapshot.empty) {
      const programTrackIds: string[] = [];
      const reqPTCMData: any[] = [];
      querySnapshot.forEach((doc) => {
        const obj: any = {
          programTrackId: doc.data().programTrackId,
        };
        if (doc.data().startDate) {
          obj.startTime = doc.data().startDate;
          const completedMilestones = doc.data().completedMilestones;
          obj.endTime = completedMilestones[completedMilestones.length - 1].completionDate;
        }
        reqPTCMData.push(obj);
      });
      querySnapshot.forEach((doc) => {
        programTrackIds.push(doc.data().programTrackId);
      });
      const ptList: any[] = await getMultipleProgramTracksById(programTrackIds);
      const trainerIds: string[] = [];
      const careerPathIds: string[] = [];
      ptList.forEach((item: any) => {
        trainerIds.push(item.trainerId);
        careerPathIds.push(item.careerPathId);
      });
      const trainerList: any[] = await getMultipleUserInfoByUserIds(trainerIds);
      const careerPathList: any[] = await getMultipleCareerPathInfoByIds(careerPathIds);

      for (const programTrack of ptList) {
        programTrack.careerPathName =
          careerPathList.find((item: any) => item.id === programTrack.careerPathId)?.name || '';
        programTrack.trainerName =
          trainerList.find((item: any) => item.id === programTrack.trainerId)?.organization || '';

        if (programTrack.type === programTrackTypes.ROLLING.value) {
          const reqTimeData = reqPTCMData.find((item: any) => item.programTrackId === programTrack.id);
          programTrack.startTime = reqTimeData.startTime;
          programTrack.endTime = reqTimeData.endTime;
        }
      }
      return ptList;
    }
    return [];
  } catch (error) {
    //catch error
  }
};

export const getClientMapActiveStatusByClientId = async (clientId: string) => {
  try {
    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(
      collectionRef,
      where('clientId', '==', clientId),
      where('status', 'in', [ClientMapStatus.ACTIVE, ClientMapStatus.PRE_COMPLETE])
    );
    const querySnapshot: any = await getDocs(q);

    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      return data;
    } else {
      return '';
    }
  } catch (error: any) {
    // handle error
    console.error('Error fetching client map active status:', error);
    throw new Error('Error fetching client map active status');
  }
};

export const getClientMapByClientIdAndPTid = async (clientId: string, programTrackId: string) => {
  try {
    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(
      collectionRef,
      where('clientId', '==', clientId),
      where('programTrackId', '==', programTrackId),
      where('status', 'in', [ClientMapStatus.ACTIVE, ClientMapStatus.PRE_COMPLETE])
    );
    const querySnapshot: any = await getDocs(q);

    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      return data;
    } else {
      return '';
    }
  } catch (error: any) {
    // handle error
    console.error('Error fetching client map active status:', error);
    throw new Error('Error fetching client map active status');
  }
};

export const getClientsWithCompletedPT = async (startDate: Dayjs | null, endDate: Dayjs | null, page: number) => {
  const result: ITrainingCompleteMetaInfo = {
    totalRecords: 0,
    currentPage: page,
    clients: [],
    totalPages: 0,
  };

  try {
    const programTrackClientMapRef = collection(db, 'programTrackClientMap');
    const q = query(programTrackClientMapRef, where('status', '==', ClientMapStatus.COMPLETE));
    const querySnapshot = await getDocs(q);

    const completedDocuments: any[] = [];
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    const clientIds: any = [];
    const staffIds: any = [];

    for (const docSnapshot of querySnapshot.docs) {
      const data = docSnapshot.data();

      if (Array.isArray(data?.completedMilestones) && data.completedMilestones.length > 0) {
        const lastMilestone: any = data.completedMilestones[data.completedMilestones.length - 1];
        const completionDate = convertTimeStampToDate(lastMilestone.completionDate);

        if (
          (completionDate.isSame(start, 'day') || completionDate.isAfter(start, 'day')) &&
          (completionDate.isSame(end, 'day') || completionDate.isBefore(end, 'day'))
        ) {
          clientIds.push(data.clientId);
          completedDocuments.push({
            id: docSnapshot.id,
            completionDate: lastMilestone.completionDate,
            ...data,
          });
        }
      }
    }

    const clientsData = await Promise.all(
      [...clientIds].map(async (clientId) => {
        const clientSnapshot = await getDoc(doc(db, 'clients', clientId));
        if (clientSnapshot.exists() && clientSnapshot.data()?.assignedTo) {
          staffIds.push(clientSnapshot.data()?.assignedTo);
        }
        return clientSnapshot.exists() ? { id: clientSnapshot.id, data: clientSnapshot.data() } : null;
      })
    );

    const staffData = await Promise.all(
      [...staffIds].map(async (staffId) => {
        const staffSnapshot = await getDoc(doc(db, 'staff', staffId));
        return staffSnapshot.exists() ? { id: staffSnapshot.id, data: staffSnapshot.data() } : null;
      })
    );

    const clientsMap = Object.fromEntries(
      clientsData
        .filter((client): client is { id: string; data: any } => client !== null)
        .map((client) => [client.id, client.data])
    );

    const staffMap = Object.fromEntries(
      staffData
        .filter((staff): staff is { id: string; data: any } => staff !== null)
        .map((staff) => [staff.id, staff.data])
    );

    const programTrackIds = completedDocuments.map((doc) => doc.programTrackId);
    // eslint-disable-next-line max-len
    const programTrackData = await Promise.all(
      programTrackIds.map((trackId) => getDoc(doc(db, 'programTrack', trackId)))
    );
    const programTracksMap = Object.fromEntries(programTrackData.map((snapshot) => [snapshot.id, snapshot.data()]));

    const completedClients = completedDocuments
      .map((doc) => {
        const clientData = clientsMap[doc.clientId];

        if (!clientData) {
          return null;
        }

        const programTrack = programTracksMap[doc.programTrackId]?.name || '';
        const navigator = clientData?.assignedTo
          ? `${staffMap[clientData.assignedTo]?.firstName || ''} ${staffMap[clientData.assignedTo]?.lastName || ''}`
          : '';
        const clientName = `${clientData?.firstName} ${clientData?.lastName}`;

        return {
          id: doc.id,
          completionDate: doc.completionDate,
          clientName,
          programTrack,
          navigator,
          ...doc,
        };
      })
      .filter((client) => client !== null);

    const pageSize = 10;
    const offset = (page - 1) * pageSize;
    const totalPages = Math.ceil(completedClients.length / pageSize);
    const limitedClients = completedClients.slice(offset, offset + pageSize);

    result.totalRecords = completedClients.length;
    result.clients = limitedClients;
    result.totalPages = totalPages;

    return result;
  } catch (error) {
    console.error('Error getting completed documents: ', error);
    throw error;
  }
};

export const getDroppedOutProgramTracksByClientId = async (clientId: string): Promise<any[]> => {
  if (!clientId) return [];

  try {
    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(collectionRef, where('clientId', '==', clientId), where('status', '==', ClientMapStatus.DROP_OUT));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return [];
    }

    const dropOutData: any[] = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const programTrackIds = dropOutData.map((doc: any) => doc.programTrackId);

    const ptInfo = await getMultipleProgramTracksById(programTrackIds);

    return ptInfo.map((item: any) => ({
      ...item,
      dropoutReasonId: dropOutData.find((i: any) => i.programTrackId === item.id)?.dropoutReasonId || null,
    }));
  } catch (error) {
    console.error('Error fetching dropped-out program tracks:', error);
    return [];
  }
};

export const hasCompletedProgramTrack = async (clientId: string): Promise<boolean> => {
  if (!clientId) return false;

  try {
    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(collectionRef, where('clientId', '==', clientId), where('status', '==', ClientMapStatus.COMPLETE));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty;
  } catch (error) {
    console.error('Error checking completed program track status:', error);
    return false;
  }
};
