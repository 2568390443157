import React, { useState, useEffect } from 'react';
import { Box, Grid, Modal, Typography, IconButton, TextField, Autocomplete } from '@mui/material';
import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';
import { getProgramTracksByTrainerId } from '../../services/programTrack.service';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { addAssociateEmail } from '../../services/programTrackTrainerMap.service';
import { Formik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  selectedProgramTrack: Yup.object().required('Program Track is required'),
});
const AddAssociateEmail = (props: any) => {
  const { open, onClose, onAddAssociateTrainer } = props;
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [programTrack, setProgramTrack] = useState([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(
    () => {
      (async () => {
        const programTrack = await getProgramTracksByTrainerId(user?.id || '');
        setProgramTrack(programTrack);
      })();
    },
    // eslint-disable-next-line
    []
  );

  const localStyle = {
    centeredStyle: {
      width: '400px',
      height: '280px',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: '14px',
      '@media (max-width: 480px)': {
        padding: '20px',
        margin: '10px',
        width: '400px',
      },
    },
    loadingButtonGrid: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 1,
    },
  };
  const ModalWrapper = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    if (user?.id) {
      const payload = {
        associateEmail: values.email,
        programTrackId: values.selectedProgramTrack?.id,
        trainerId: user?.id,
      };
      try {
        setIsLoading(true);
        await addAssociateEmail(payload);
        setIsLoading(false);
        onAddAssociateTrainer();
        onClose();
      } catch (error) {
        console.error('Error creating new Associate Trainer!', error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  const initialValues = {
    email: '',
    selectedProgramTrack: {
      id: '',
      label: '',
    },
  };

  return (
    <ModalWrapper open={open} onClose={handleClose}>
      <Box sx={localStyle.centeredStyle}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting, values, handleSubmit, handleBlur, handleChange, touched, errors, setFieldValue }) => {
            return (
              <>
                <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
                  <Grid item>
                    <Typography variant='h6'>Add Associate Email</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      size='small'
                      name='email'
                      type='text'
                      label='Enter Email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      size='small'
                      value={values.selectedProgramTrack}
                      disableClearable
                      options={programTrack.map((item: any) => ({
                        label: item.name,
                        id: item.id,
                      }))}
                      getOptionLabel={(option: any) => {
                        return option.label;
                      }}
                      onChange={(event, object) => {
                        setFieldValue('selectedProgramTrack', object);
                      }}
                      renderInput={(params) => <TextField {...params} label='Select Program Track' />}
                      isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12} sx={localStyle.loadingButtonGrid}>
                    <LoadingButton
                      type='submit'
                      label='Submit'
                      onClick={() => handleSubmit()}
                      size='large'
                      variant='contained'
                      loading={isLoading}
                      disabled={isSubmitting || !values.email || !values.selectedProgramTrack.id}
                    />
                  </Grid>
                </Grid>
              </>
            );
          }}
        </Formik>
      </Box>
    </ModalWrapper>
  );
};

export default AddAssociateEmail;
