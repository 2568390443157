import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DashboardCard } from '../DashboardCard/DashboardCard';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchRegisteredClientsForInfoSession } from '../../services/infoSession.service';

interface IClientsExpectingToAttendInfoSessionProps {
  infoSessionId: string;
}
export const ClientsExpectingToAttendInfoSession = (props: IClientsExpectingToAttendInfoSessionProps) => {
  const { infoSessionId } = props;
  const navigate = useNavigate();
  const [clientList, setClientList] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const loadClients = async () => {
    try {
      setShowLoader(true);
      const unsubscribe = await fetchRegisteredClientsForInfoSession(infoSessionId, (clientList: any) => {
        setClientList(clientList);
        setShowLoader(false);
      });
      return () => {
        unsubscribe();
      };
    } catch (err: any) {
      toast.error('Unable to load client list');
    }
  };

  useEffect(() => {
    loadClients();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid sx={{ width: '100%' }}>
      <DashboardCard
        isLoading={showLoader}
        label={'Clients Expecting to Attend Info-Session'}
        desc={'List of current clients expecting to attend the info-session'}
        list={clientList}
        // eslint-disable-next-line
        onItemClick={(item: any) => {
          navigate('../clientInfo', { state: { clientId: item.id } });
        }}
      />
    </Grid>
  );
};
