import React from 'react';
import * as yup from 'yup';
import { Box, Grid, TextField, Link } from '@mui/material';
import { Formik, type FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { COLORS } from './../../../../utils/colors';
import { forgotPassword } from '../../../../services/user.service';
import { LoadingButton } from '../../../../components/LoadingButton/LoadingButton';

interface IForgotPassword {
  email: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const styles = {
  gridItem: {
    '&&': {
      padding: '0px 0px 20px 0px',
    },
  },
  errorMessage: {
    color: COLORS.result.error,
    display: 'block',
    marginTop: '16px',
  },
  image: {
    width: '292px',
    marginBottom: 100,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  cancelLink: {
    color: COLORS.palette.gray,
    textDecoration: 'none',
    fontSize: '16px',
    lineHeight: '19px',
  },
};

export default function ForgotPassword() {
  const [loading, setLoading] = React.useState(false);
  const initialValues: IForgotPassword = { email: '' };

  const handleSubmit = async (values: IForgotPassword, { setSubmitting }: FormikHelpers<IForgotPassword>) => {
    try {
      setLoading(true);
      const result = await forgotPassword(values.email);

      setLoading(false);
      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (err: any) {
      toast.error(err.message);
      setLoading(false);
    }

    setSubmitting(false);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.theme.primaryColor,
      }}>
      <Box height='auto' width='320px'>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid xs={12} sx={{ textAlign: 'center', filter: 'drop-shadow(10px 10px 100px #3F31C1)' }}>
                  <img src={'/assets/logo.png'} alt='logo' style={styles.image} />
                </Grid>
                <Grid item xs={12} sx={styles.gridItem}>
                  <TextField
                    placeholder='Enter email'
                    fullWidth
                    name='email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    variant='standard'
                    sx={{
                      input: {
                        fontSize: 20,
                        lineHeight: 23,
                        color: 'white',
                        borderBottom: '1px solid #ffffff',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '30px' }}>
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    // @ts-ignore
                    color='darkModePrimary'
                    loading={loading}
                    size='large'
                    label='Send Link'
                    styles={{
                      backgroundColor: COLORS.palette.skyBlue,
                      width: '100%',
                      marginBottom: '20px',
                      borderRadius: '10px',
                      height: '40px',
                      fontSize: '16px',
                      lineHeight: '28px',
                      color: '#fff',
                    }}
                  />
                  <Link href='/login' style={styles.cancelLink}>
                    Cancel
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
