import * as React from 'react';
import { LoadingButton as MUILoadingButton } from '@mui/lab';
import { ButtonSize, ButtonType, ButtonVariant, Color } from '../../utils/common';

export type LoadingButtonProps = {
  onClick?: Function;
  loading?: boolean;
  disabled?: boolean;
  size?: ButtonSize;
  type?: ButtonType;
  styles?: object;
  label?: string;
  variant?: ButtonVariant | undefined;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  color?: Color;
  typographyComp?: React.ReactNode;
};

export const LoadingButton = (props: LoadingButtonProps) => {
  const { typographyComp, onClick, loading, disabled, size, type, styles, label, variant, endIcon, startIcon, color } =
    props;

  return (
    <MUILoadingButton
      color={color}
      onClick={() => (onClick ? onClick() : null)}
      loading={loading}
      disabled={disabled}
      size={size}
      type={type || 'button'}
      variant={variant}
      sx={styles}
      startIcon={startIcon}
      endIcon={endIcon}>
      {label ? label : typographyComp}
    </MUILoadingButton>
  );
};
