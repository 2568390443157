import React from 'react';
import { Link, Typography } from '@mui/material';

interface ICustomLinkProps {
  text: string;
  fullName: string;
  urlName: string;
  isDisabled?: boolean;
}

const CustomNavigationLink: React.FC<ICustomLinkProps> = (props) => {
  const { text, fullName, urlName, isDisabled = false } = props;

  const handleClick = () => {
    let url = '';
    if (process.env.REACT_APP_PROJECT_ID === 'nyatlas-test') {
      url = `https://pincctest.com/${urlName}`;
    } else if (process.env.REACT_APP_PROJECT_ID === 'nyatlas-portal') {
      url = `https://pincc.nyc/${urlName}`;
    }

    const applicantName = encodeURIComponent(fullName);
    const finalUrl = `${url}?applicantName=${applicantName}`;

    window.open(finalUrl, '_blank', 'noopener,noreferrer');
  };

  if (isDisabled) {
    return (
      <Typography
        sx={{
          textDecoration: 'none',
        }}>
        {text}
      </Typography>
    );
  }

  return (
    <Link onClick={handleClick} target='_blank' sx={{ cursor: 'pointer' }} rel='noopener noreferrer'>
      {text}
    </Link>
  );
};

export default CustomNavigationLink;
