import { collection, doc, getDoc, getDocs, onSnapshot } from 'firebase/firestore';
import { db, functions } from '../config/firebaseApp';
import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';
import { UserModel } from '../store/user/userModel';
import { UserRolesEnum } from '../utils/rolesEnum';
import { twilioNumbers } from '../utils/twilioPhoneNumbers';
import { getMultipleClientByIds } from './client.service';
import { convertTimeStampToDate } from '../utils/dateTime';
import { adminTypeEnum } from '../utils/adminTypes';

export const sendTextMessage = async (text: string, recipientId: string, senderId: string) => {
  let message_payload: any = {
    senderId: senderId,
    recipientId: recipientId,
    text: text,
  };
  await httpsCallable(functions, 'sendTextMessage')(message_payload);
};

export const getMessageList = async (clientData: any, user: UserModel, callback: Function) => {
  try {
    if (clientData) {
      let chatId = '';
      if (user?.role === UserRolesEnum.ADMIN) {
        if (clientData.chatId) {
          chatId = clientData.chatId;
        } else {
          chatId = twilioNumbers.admin + '_' + clientData.id;
        }
      } else if (user?.role === UserRolesEnum.SUPER_ADMIN) {
        if (clientData.chatId) {
          chatId = clientData.chatId;
        } else {
          chatId = twilioNumbers.superAdmin + '_' + clientData.id;
        }
      } else if (user?.role === UserRolesEnum.NAVIGATOR) {
        const lisisonId = clientData?.employmentLiaisonId || '';
        const colDocRef = doc(db, 'smsConversations', `${lisisonId}_${clientData.id}`);
        const resultDoc = await getDoc(colDocRef);
        if (resultDoc.exists()) {
          chatId = lisisonId + '_' + clientData.id;
        } else {
          chatId = user?.id + '_' + clientData.id;
        }
      } else if (user?.role === UserRolesEnum.EMPLOYMENT_LIAISON) {
        const navigatorId = clientData?.assignedTo || '';
        const colDocRef = doc(db, 'smsConversations', `${navigatorId}_${clientData.id}`);
        const resultDoc = await getDoc(colDocRef);
        if (resultDoc.exists()) {
          chatId = navigatorId + '_' + clientData.id;
        } else {
          chatId = user?.id + '_' + clientData.id;
        }
      }
      const colRef = doc(db, 'smsConversations', chatId);

      const unsubscribe = onSnapshot(colRef, (snapshot) => {
        if (snapshot.exists()) {
          const messageListData = { id: snapshot.id, ...snapshot.data() };
          callback(messageListData);
        } else {
          callback({});
        }
      });

      return unsubscribe;
    }

    callback({});
    return () => {};
  } catch (error: any) {
    toast.error('Error Subscribing Sms Conversation data!');
    return () => {};
  }
};

export const fetchChatChannelsByUserId = async (user: UserModel) => {
  try {
    const querySnapshot = await getDocs(collection(db, 'smsConversations'));
    if (!querySnapshot.empty) {
      const chatList: any = [];
      let clientIds: string[] = [];
      let reqChatMessage: any = [];
      querySnapshot.forEach((doc) => {
        chatList.push({ id: doc.id, ...doc.data() });
      });
      if (user.role === UserRolesEnum.SUPER_ADMIN) {
        // reqChatMessage = chatList.filter((item: any) => item.id.includes(twilioNumbers.superAdmin));
        reqChatMessage = chatList;
        clientIds = chatList.map((item: any) => item.id.split('_')[1]);
      } else if (user.role === UserRolesEnum.ADMIN) {
        if (user.adminType === adminTypeEnum.NAVIGATOR) {
          reqChatMessage = chatList.filter(
            (item: any) =>
              item.id.includes(twilioNumbers.admin) ||
              item.messages.some(
                (message: any) =>
                  message?.senderRole === UserRolesEnum.NAVIGATOR || message?.recipientRole === UserRolesEnum.NAVIGATOR
              )
          );
          clientIds = reqChatMessage.map((item: any) => item.id.split('_')[1]);
        } else if (user.adminType === adminTypeEnum.EMPLOYMENT) {
          reqChatMessage = chatList.filter(
            (item: any) =>
              item.id.includes(twilioNumbers.admin) ||
              item.messages.some(
                (message: any) =>
                  message?.senderRole === UserRolesEnum.EMPLOYMENT_LIAISON ||
                  message?.recipientRole === UserRolesEnum.EMPLOYMENT_LIAISON
              )
          );
          clientIds = reqChatMessage.map((item: any) => item.id.split('_')[1]);
        } else {
          reqChatMessage = chatList.filter((item: any) => item.id.includes(twilioNumbers.admin));
          clientIds = reqChatMessage.map((item: any) => item.id.split('_')[1]);
        }
      } else {
        reqChatMessage = chatList.filter((item: any) =>
          item.messages.some((message: any) => message.senderId === user.id || message.recipientId === user.id)
        );
        clientIds = reqChatMessage.map((item: any) => item.id.split('_')[1]);
      }
      const clientList = await getMultipleClientByIds(clientIds);
      const updatedList = [];
      for (const message of reqChatMessage) {
        const clientId = message.id.split('_')[1];
        const latest = message.messages.sort((a: any, b: any) => {
          return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        })[0];

        const client = clientList.find((client: any) => client.id === clientId);

        if (client) {
          client.lastMessage = latest.text;
          client.lastMessageAt = convertTimeStampToDate(latest.timeStamp).format('DD/MM hh:mm');
          client.chatId = message.id;
        }
        updatedList.push(client);
      }
      return updatedList;
    } else {
      return [];
    }
  } catch (err: any) {
    // console err
  }
  return [];
};

//
