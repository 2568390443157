export type RolePermissionType = {
  [key: string]: number[];
};

export const DashboardButton = {
  createClient: 1,
  createInfoSession: 2,
  viewInfoSession: 3,
  startNewProgramTrack: 4,
  inviteProspectiveClient: 5,
  createProgramTrack: 6,
  createNewCurriculum: 7,
  createCareerPath: 8,
  resendInvitationMailToPC: 9,
  createWorkshop: 10,
  viewWorkshops: 11,
  createJob: 12,
  enrollInProgramTrack: 13,
  assignEmploymentLiaison: 14,
  suspendClient: 15,
  assignNavigator: 16,
  accessNotes: 17,
  viewJobs: 18,
  convertPcToClient: 19,
  toggleIntakeStatus: 20,
  toggleActiveStatus: 21,
  markClientReadyForEmployment: 21,
  backFillProgramTrackData: 21,
  backFillEmploymentData: 21,
  updateEmploymentInfo: 22,
  EnrollInWorkshop: 23,
  createEditActivityLogs: 24,
  showProgramTrackcardOnDashboard: 25,
};

export const RolePermissions: RolePermissionType = {
  caseManager: [
    DashboardButton.viewInfoSession,
    DashboardButton.resendInvitationMailToPC,
    DashboardButton.enrollInProgramTrack,
    DashboardButton.accessNotes,
  ],
  navigator: [
    DashboardButton.inviteProspectiveClient,
    DashboardButton.createClient,
    DashboardButton.viewInfoSession,
    DashboardButton.resendInvitationMailToPC,
    DashboardButton.enrollInProgramTrack,
    DashboardButton.suspendClient,
    DashboardButton.convertPcToClient,
    DashboardButton.accessNotes,
    DashboardButton.toggleIntakeStatus,
    DashboardButton.toggleActiveStatus,
    DashboardButton.backFillProgramTrackData,
    DashboardButton.createEditActivityLogs,
    DashboardButton.showProgramTrackcardOnDashboard,
  ],
  trainer: [
    DashboardButton.accessNotes,
    DashboardButton.enrollInProgramTrack,
    DashboardButton.showProgramTrackcardOnDashboard,
  ],
  admin: [
    DashboardButton.createClient,
    DashboardButton.createInfoSession,
    DashboardButton.viewInfoSession,
    DashboardButton.startNewProgramTrack,
    DashboardButton.inviteProspectiveClient,
    DashboardButton.createNewCurriculum,
    DashboardButton.createProgramTrack,
    DashboardButton.createCareerPath,
    DashboardButton.resendInvitationMailToPC,
    DashboardButton.createWorkshop,
    DashboardButton.viewWorkshops,
    DashboardButton.createJob,
    DashboardButton.enrollInProgramTrack,
    DashboardButton.assignEmploymentLiaison,
    DashboardButton.suspendClient,
    DashboardButton.convertPcToClient,
    DashboardButton.assignNavigator,
    DashboardButton.accessNotes,
    DashboardButton.viewJobs,
    DashboardButton.toggleIntakeStatus,
    DashboardButton.toggleActiveStatus,
    DashboardButton.markClientReadyForEmployment,
    DashboardButton.backFillProgramTrackData,
    DashboardButton.backFillEmploymentData,
    DashboardButton.updateEmploymentInfo,
    DashboardButton.EnrollInWorkshop,
    DashboardButton.createEditActivityLogs,
    DashboardButton.showProgramTrackcardOnDashboard,
  ],
  superAdmin: [
    DashboardButton.createClient,
    DashboardButton.createInfoSession,
    DashboardButton.viewInfoSession,
    DashboardButton.startNewProgramTrack,
    DashboardButton.inviteProspectiveClient,
    DashboardButton.createNewCurriculum,
    DashboardButton.createProgramTrack,
    DashboardButton.createCareerPath,
    DashboardButton.resendInvitationMailToPC,
    DashboardButton.createWorkshop,
    DashboardButton.viewWorkshops,
    DashboardButton.createJob,
    DashboardButton.enrollInProgramTrack,
    DashboardButton.assignEmploymentLiaison,
    DashboardButton.suspendClient,
    DashboardButton.convertPcToClient,
    DashboardButton.assignNavigator,
    DashboardButton.accessNotes,
    DashboardButton.viewJobs,
    DashboardButton.toggleIntakeStatus,
    DashboardButton.toggleActiveStatus,
    DashboardButton.markClientReadyForEmployment,
    DashboardButton.backFillProgramTrackData,
    DashboardButton.backFillEmploymentData,
    DashboardButton.updateEmploymentInfo,
    DashboardButton.EnrollInWorkshop,
    DashboardButton.createEditActivityLogs,
    DashboardButton.showProgramTrackcardOnDashboard,
  ],
  employmentLiaison: [
    DashboardButton.createWorkshop,
    DashboardButton.viewWorkshops,
    DashboardButton.createJob,
    DashboardButton.viewJobs,
    DashboardButton.accessNotes,
    DashboardButton.toggleIntakeStatus,
    DashboardButton.backFillEmploymentData,
    DashboardButton.updateEmploymentInfo,
    DashboardButton.EnrollInWorkshop,
    DashboardButton.createEditActivityLogs,
  ],
};

export const shouldShowButton = (button: keyof typeof DashboardButton, userRole: any): boolean => {
  const allowedButtons = RolePermissions[userRole];
  return allowedButtons?.includes(DashboardButton[button]);
};
