import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../config/firebaseApp';
import { toast } from 'react-toastify';

export const fetchPTAnalytics = async () => {
  try {
    const ptAnalyticsRef = collection(db, 'programTrackAnalytics');
    const snapshots = await getDocs(ptAnalyticsRef);
    const ptAnalyticsList: any[] = snapshots.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });

    return ptAnalyticsList;
  } catch (error) {
    toast.error('Error Retrieving Program Track Analytics!');
    return [];
  }
};

export const getPTAnalyticsById = async (id: string) => {
  try {
    if (!id) {
      throw new Error('id is null or undefined');
    }

    const ptAnalyticsDocRef = doc(db, 'programTrackAnalytics', id);
    const ptAnalyticsDoc = await getDoc(ptAnalyticsDocRef);

    if (ptAnalyticsDoc.exists()) {
      return { id: ptAnalyticsDoc.id, ...ptAnalyticsDoc.data() };
    } else {
      return {};
    }
  } catch (err: any) {
    toast.error('Error Retrieving Program Track Analytics!');
    return {};
  }
};
