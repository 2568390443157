import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { assignNavigatorToClient } from '../../../services/client.service';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import styled from '@emotion/styled';

export type NavigatorSelectionProps = {
  clientId: string;
  navigators: any;
  open: boolean;
  onClose: Function;
  onSubmit: Function;
};

type SelectedNavigator = {
  id: string;
  label: string;
};

const localStyle = {
  centeredStyle: {
    width: '200x',
    height: '220px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '14px',
    '@media (max-width: 480px)': {
      padding: '20px',
      margin: '10px',
      width: '400px',
    },
  },

  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  loadingBtn: {
    width: '450px',
    mt: 2,
  },
};

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const NavigatorSelection = (props: NavigatorSelectionProps) => {
  const [selectedNavigator, setSelectedNavigator] = React.useState<SelectedNavigator>({ id: '', label: '' });
  const [loading, setLoading] = React.useState(false);

  //assign navigator to client button handler
  const onSubmit = async () => {
    setLoading(true);
    await assignNavigatorToClient(props.clientId, selectedNavigator?.id);
    setLoading(false);
    props.onSubmit(selectedNavigator?.id);
    props.onClose();
  };
  return (
    <ModalWrapper open={props.open} onClose={() => props.onClose()}>
      <Box sx={localStyle.centeredStyle}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Grid item>
            <Typography variant='h6'>Assign Navigator to Client</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => props.onClose()}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Autocomplete
          size='small'
          freeSolo
          options={props.navigators.map((item: any) => ({
            label: item.firstName + ' ' + item.lastName,
            id: item.id,
          }))}
          getOptionLabel={(option: any) => {
            return option.label;
          }}
          onChange={(event, object) => {
            setSelectedNavigator(object);
          }}
          renderInput={(params) => <TextField {...params} label='Select navigator' />}
        />
        <Grid container>
          <Grid item xs={12} sx={localStyle.gridContainer}>
            <LoadingButton
              onClick={() => {
                onSubmit();
              }}
              label={'Submit'}
              styles={localStyle.loadingBtn}
              loading={loading}
              disabled={loading}
              size='medium'
              type='submit'
              variant='outlined'
            />
          </Grid>
        </Grid>
      </Box>
    </ModalWrapper>
  );
};
