import React, { useEffect, useState } from 'react';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationList,
  Sidebar,
  ConversationHeader,
  Conversation,
  Avatar,
} from '@chatscope/chat-ui-kit-react';
import { convertTimeStampToDate } from '../../utils/dateTime';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { fetchChatChannelsByUserId, getMessageList, sendTextMessage } from '../../services/textMessaging.service';
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { AddUserListOnMessagingPage } from '../Modals/AddUserList/AddUserList';
import { twilioNumbers } from '../../utils/twilioPhoneNumbers';

const SmsChatWithClients = (props: any) => {
  const { clientInfo, hideSidebar, isGeneralMessagingPage } = props;
  const avatarIcon = 'https://cdn-icons-png.flaticon.com/128/3899/3899618.png';
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  const [channelsList, setChannelsList] = useState<any[]>([]);
  const [loadingChannels, setLoadingChannels] = useState<boolean>(false);
  const [isSMSmessaging, setIsSMSmessaging] = useState<boolean>(false);
  const [showUserListModal, setShowUserListModal] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarStyle, setSidebarStyle] = useState({});
  const [chatContainerStyle, setChatContainerStyle] = useState({});
  const [conversationContentStyle, setConversationContentStyle] = useState({});
  const [messageValue, setMessageValue] = useState('');
  const [messagesList, setMessagesList] = useState([]);
  const [clientData, setClientData] = useState<any>(null);
  const handleBackClick = () => setSidebarVisible(!sidebarVisible);
  const clientId = isGeneralMessagingPage ? clientData?.id : clientInfo.id;
  useEffect(
    () => {
      (async () => {
        try {
          setLoadingMessages(true);
          const unsubscribe = await getMessageList(clientData, user, (data: any) => {
            setMessagesList(data.messages);
            setLoadingMessages(false);
          });
          return () => {
            unsubscribe();
          };
        } catch (err) {
          setLoadingMessages(false);
          // catch error
        }
      })();
    },
    // eslint-disable-next-line
    [clientData]
  );

  useEffect(
    () => {
      if (!isGeneralMessagingPage) {
        setClientData(clientInfo);
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      (async () => {
        setLoadingChannels(true);
        if (isGeneralMessagingPage) {
          const channels = await fetchChatChannelsByUserId(user);
          setChannelsList(channels);
          setLoadingChannels(false);
        }
      })();
    },
    // eslint-disable-next-line
    []
  );
  const submitMessage = async () => {
    const message: string = messageValue;
    setMessageValue('');
    let senderId = '';
    if (user?.role === UserRolesEnum.ADMIN || user?.role === UserRolesEnum.SUPER_ADMIN) {
      senderId = user?.role;
    } else {
      senderId = user?.id || '';
    }
    await sendTextMessage(message, clientId, senderId);
  };

  useEffect(() => {
    if (sidebarVisible) {
      setSidebarStyle({
        display: 'flex',
        flexBasis: 'auto',
        width: '100%',
        maxWidth: '100%',
      });
      setConversationContentStyle({
        display: 'flex',
      });

      setChatContainerStyle({
        display: 'none',
      });
    } else {
      setSidebarStyle({});
      setConversationContentStyle({});
      setChatContainerStyle({});
    }
    // eslint-disable-next-line
  }, [sidebarVisible, setSidebarVisible, setConversationContentStyle, setSidebarStyle, setChatContainerStyle]);

  return (
    <div
      style={{
        height: '600px',
        position: 'relative',
      }}>
      <MainContainer responsive>
        {hideSidebar ? null : (
          <Sidebar position='left' scrollable={false} style={sidebarStyle} loading={loadingChannels}>
            <ConversationList>
              {channelsList.length > 0 ? (
                channelsList.map((item: any, index: any) => (
                  <Conversation
                    key={index}
                    active={item?.chatId === clientData?.chatId}
                    lastActivityTime={
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}>
                        {item?.lastMessageAt ? (
                          <>
                            <span style={{ color: 'teal' }}>{item?.lastMessageAt.split(' ')[0]}</span>
                            <span style={{ color: 'teal' }}>{item?.lastMessageAt.split(' ')[1]}</span>
                          </>
                        ) : null}
                      </span>
                    }
                    onClick={() => {
                      setClientData(item);
                    }}>
                    <Avatar src={avatarIcon} />
                    <Conversation.Content
                      name={item?.firstName + ' ' + item?.lastName}
                      style={{ ...conversationContentStyle, width: '50%' }}
                      info={item?.lastMessage}
                    />
                  </Conversation>
                ))
              ) : loadingChannels ? null : (
                <Typography sx={{ mt: 2, display: 'flex', justifyContent: 'center' }} variant='body1'>
                  {'No active conversations!'}
                </Typography>
              )}
            </ConversationList>

            {/* Start chat menu */}
            <Divider />
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <List disablePadding>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setShowUserListModal(true);
                      setIsSMSmessaging(true);
                    }}>
                    <ListItemIcon>
                      <Add />
                    </ListItemIcon>
                    <ListItemText primary='Start chat with a client' />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Sidebar>
        )}
        <ChatContainer style={chatContainerStyle}>
          {clientData && (
            <ConversationHeader>
              <ConversationHeader.Back onClick={handleBackClick} />
              <ConversationHeader.Content userName={clientData?.firstName || '' + ' ' + clientData?.lastName} />
            </ConversationHeader>
          )}
          <MessageList style={{ marginTop: 5 }} loading={loadingMessages}>
            {messagesList?.map((item: any, index: any) => (
              <Message
                key={index}
                model={{
                  message: item.text,
                  direction:
                    item?.senderRole === 'staff' ||
                    item?.senderRole === UserRolesEnum.ADMIN ||
                    item?.senderRole === UserRolesEnum.SUPER_ADMIN ||
                    item?.senderRole === UserRolesEnum.NAVIGATOR ||
                    item?.senderRole === UserRolesEnum.EMPLOYMENT_LIAISON
                      ? 'outgoing'
                      : 'incoming',
                  position: 'single',
                }}>
                <Message.Footer sentTime={convertTimeStampToDate(item.timeStamp).format('MMMM DD, hh:mm A')} />
              </Message>
            ))}
          </MessageList>
          <MessageInput
            placeholder='Type message here'
            attachButton={false}
            disabled={
              !clientData
                ? true
                : isGeneralMessagingPage && user?.role === UserRolesEnum.SUPER_ADMIN && clientData?.chatId
                ? !clientData?.chatId?.includes(twilioNumbers.superAdmin)
                : isGeneralMessagingPage && user?.role === UserRolesEnum.ADMIN && clientData?.chatId
                ? !clientData?.chatId?.includes(twilioNumbers.admin)
                : false
            }
            onSend={() => {
              submitMessage();
            }}
            onChange={(val) => setMessageValue(val)}
            value={messageValue}
            onPaste={(evt) => {
              evt.preventDefault();
              setMessageValue(evt.clipboardData.getData('text'));
            }}
          />
        </ChatContainer>
      </MainContainer>
      <AddUserListOnMessagingPage
        open={showUserListModal}
        onClose={() => setShowUserListModal(false)}
        isSMSmessaging={isSMSmessaging}
        isClientList={true}
        data={(client: any) => {
          setClientData(client);
          setShowUserListModal(false);
          const clientExists = channelsList.some((existingClient) => existingClient.chatId === client.chatId);
          if (!clientExists) {
            channelsList.push(client);
          }
        }}
      />
    </div>
  );
};

export default SmsChatWithClients;
