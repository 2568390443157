import {
  ArticleOutlined,
  AutoGraphOutlined,
  BadgeOutlined,
  BuildCircleOutlined,
  ChatOutlined,
  ClearAllOutlined,
  EventNoteOutlined,
  ListAltOutlined,
  OtherHousesOutlined,
  PersonOutlineOutlined,
  WorkOutlineOutlined,
} from '@mui/icons-material';

export const AdminSidebarMenu = [
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    icon: <OtherHousesOutlined />,
  },
  {
    path: '/admin/client',
    name: 'Clients',
    icon: <PersonOutlineOutlined />,
  },
  {
    path: '/admin/infoSessionList',
    name: 'Info-Sessions',
    icon: <EventNoteOutlined />,
  },
  {
    path: '/admin/client-employment',
    name: 'Employment',
    icon: <BadgeOutlined />,
  },
  {
    path: '/admin/message',
    name: 'Messaging',
    icon: <ChatOutlined />,
  },
  {
    path: '/admin/program-track-list',
    name: 'Program Tracks List',
    icon: <ListAltOutlined />,
  },
  {
    path: '/admin/careerPathList',
    name: 'Career Path List',
    icon: <ListAltOutlined />,
  },
  {
    path: '/admin/curriculum-list',
    name: 'Curriculum List',
    icon: <ListAltOutlined />,
  },
  {
    path: '/admin/associate-trainer-list',
    name: 'Associate Trainers List',
    icon: <ListAltOutlined />,
  },
  {
    path: '/admin/resource',
    name: 'Resources',
    icon: <ClearAllOutlined />,
  },
  {
    path: '/admin/analytics',
    name: 'Analytics',
    icon: <AutoGraphOutlined />,
  },
  {
    path: '/admin/report',
    name: 'Reports',
    icon: <ArticleOutlined />,
  },
  {
    path: '/admin/workshops',
    name: 'Workshops',
    icon: <BuildCircleOutlined />,
  },
  {
    path: '/admin/job',
    name: 'Job Opportunities',
    icon: <WorkOutlineOutlined />,
  },
];
