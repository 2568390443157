import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InfoSessionModel, InfoSessionReduxModel } from './infoSessionModel';

const initialInfoSessionState: InfoSessionReduxModel = {
  infoSessionList: [],
};

const infoSessionSlice = createSlice({
  name: 'infoSession',
  initialState: initialInfoSessionState,
  reducers: {
    setInfoSessionList(state, action: PayloadAction<InfoSessionModel[]>) {
      state.infoSessionList = action.payload;
    },
    resetInfoSessionList() {
      return structuredClone(initialInfoSessionState);
    },
  },
});

export default infoSessionSlice;
