import infoSessionSlice from './infoSessionSlice';
import type { AnyAction } from '@reduxjs/toolkit';
import type { ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { InfoSessionModel } from './infoSessionModel';

export const InfoSessionActions = infoSessionSlice.actions;

export const setInfoSessionList = (
  infoSessionList: InfoSessionModel[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch(InfoSessionActions.setInfoSessionList(infoSessionList));
  };
};

export const resetInfoSessionList = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch(InfoSessionActions.resetInfoSessionList(undefined));
  };
};
