import { Typography, Container, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
// import { useNavigate } from 'react-router-dom';

const InfoSessionExpiredPage = () => {
  // const navigate = useNavigate();

  // const handleBackClick = () => {
  //   navigate('/infosessions');
  // };

  return (
    <Container maxWidth='xl' sx={{ height: '100%' }}>
      <Box sx={{ padding: 4, borderRadius: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
          <InfoIcon color='error' sx={{ fontSize: 40, mb: 2 }} />
          <Typography variant='h6' color='error' gutterBottom>
            Info Session Unavailable
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            Sorry, no info sessions are available at this time. Please check back later for upcoming sessions.
          </Typography>
        </Box>
      </Box>
      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
        <Button variant='text' color='primary' onClick={handleBackClick} sx={{ textTransform: 'capitalize' }}>
          Back to Info Sessions
        </Button>
      </Box> */}
    </Container>
  );
};

export default InfoSessionExpiredPage;
