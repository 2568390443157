import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Grid,
  InputLabel,
  Box,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
} from '@mui/material';
import { Field, Formik, type FormikHelpers } from 'formik';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import { COLORS } from '../../../../utils/colors';
import {
  educations,
  employeeStatus,
  emptyOtherDetails,
  ethnics,
  genders,
  highestDL,
  interestedInOptions,
  IOtherDetails,
  publicAssistance,
  referralSource,
  ReferralSourceConst,
} from '../utils';

const schema = yup.object().shape({
  gender: yup.string(),
  otherGender: yup.string(),
  ethnic: yup.string(),
  employmentStatus: yup.string(),
  educationalBackground: yup.string().required('Educational Background is required'),
  caseNumber: yup.string(),
  publicAssistance: yup.array(),
  publicAssistanceOther: yup.string(),
  ethnicOther: yup.string(),
  referralSource: yup.string().required('Referral source is required.'),
  refCommunityOrg: yup.string().when('referralSource', (value) => {
    if (value[0] == 'Other, Specify') {
      return yup.string().required('Community organization is required.');
    } else {
      return yup.string().notRequired();
    }
  }),
  highestDL: yup.string().required('DL Info required'),
});

const OtherInfo = (props: any) => {
  const [selectedEthnic, setSelectedEthnic] = useState<string>('');
  const [selectedReferralSource, setSelectedReferralSource] = useState<string>('');
  const [selectedPublicAssistance, setSelectedPublicAssistance] = useState<string[]>(
    props.data?.publicAssistance || []
  );
  const [selectedInterestedInFields, setSelectedInterestedInFields] = React.useState<string[]>(
    props.data?.interestedIn || []
  );

  const [isHSISelected, setIsHSISelected] = useState(false);

  const getValuesOnBack = () => {
    return {
      gender: props.data.gender,
      otherGender: props.data.otherGender,
      ethnic: props.data.ethnic,
      employmentStatus: props.data.employmentStatus,
      educationalBackground: props.data.educationalBackground,
      caseNumber: props.data.caseNumber,
      publicAssistance: props.data.publicAssistance,
      publicAssistanceOther: props.data?.publicAssistanceOther || '',
      ethnicOther: props.data?.ethnicOther || '',
      referralSource: props.data.referralSource || '',
      refCommunityOrg: props.data.refCommunityOrg || '',
      interestedIn: props.data.interestedIn || [],
      highestDL: props.data.highestDL || '',
    };
  };
  const saveInfo = (values: IOtherDetails, { setSubmitting }: FormikHelpers<IOtherDetails>) => {
    const payload = {
      ...values,
      publicAssistance: selectedPublicAssistance,
      interestedIn: selectedInterestedInFields,
    };
    props.handleNext(payload);
    setSelectedPublicAssistance([]);
    setSubmitting(false);
  };

  const handleSelect = (item: string) => {
    if (selectedPublicAssistance?.includes(item)) {
      setSelectedPublicAssistance(selectedPublicAssistance?.filter((id: string) => id !== item));
    } else {
      setSelectedPublicAssistance([...selectedPublicAssistance, item]);
    }
  };

  const handleSelectForInterestedIn = (item: string) => {
    if (selectedInterestedInFields?.includes(item)) {
      setSelectedInterestedInFields(selectedInterestedInFields?.filter((id: string) => id !== item));
    } else {
      setSelectedInterestedInFields([...selectedInterestedInFields, item]);
    }
  };

  useEffect(() => {
    if (selectedPublicAssistance.length > 0) {
      setIsHSISelected(true);
    } else {
      setIsHSISelected(false);
    }
  }, [selectedPublicAssistance]);

  return (
    <Box sx={localStyle.mainBoxStyle}>
      <Formik
        initialValues={props.isBackMode ? getValuesOnBack() : emptyOtherDetails}
        onSubmit={saveInfo}
        validationSchema={schema}
        validateOnMount={true}>
        {({ values, handleSubmit, handleChange, handleBlur, touched, errors, isValid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel style={{ color: COLORS.palette.black }} id='gender-label'>
                      Gender
                    </InputLabel>
                    <Field
                      as={Select}
                      labelId='gender-label'
                      label='Gender'
                      name='gender'
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.gender && Boolean(errors.gender)}
                      helperText={touched.gender && errors.gender}>
                      {genders.map((option) => (
                        <MenuItem key={option.id} value={option.title}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                {values.gender === 'Other' ? (
                  <Grid item xs={12} lg={4}>
                    <TextField
                      label='Please specify'
                      fullWidth
                      placeholder='Please specify other gender'
                      name='otherGender'
                      value={values.otherGender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.otherGender && Boolean(errors.otherGender)}
                      helperText={touched.otherGender && errors.otherGender}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel style={{ color: COLORS.palette.black }} id='ethnic-label'>
                      Race/Ethnic Identity
                    </InputLabel>
                    <Field
                      as={Select}
                      labelId='ethnic-label'
                      name='ethnic'
                      label='Race/Ethnic Identity'
                      value={values.ethnic}
                      onChange={(event: any) => {
                        handleChange(event);
                        setSelectedEthnic(event.target.value);
                      }}
                      onBlur={handleBlur}
                      error={touched.ethnic && Boolean(errors.ethnic)}
                      helperText={touched.ethnic && errors.ethnic}>
                      {ethnics.map((option) => (
                        <MenuItem key={option.id} value={option.title}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>

                {selectedEthnic === ethnics.find((i) => i.id === 8)?.title ? (
                  <Grid item xs={12} lg={4}>
                    <TextField
                      label='Please specify'
                      fullWidth
                      placeholder='Please specify other Ethnic Identity'
                      name='ethnicOther'
                      value={values.ethnicOther}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ethnicOther && Boolean(errors.ethnicOther)}
                      helperText={touched.ethnicOther && errors.ethnicOther}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel style={{ color: COLORS.palette.black }} id='employmentStatus-label'>
                      Employment Status
                    </InputLabel>
                    <Field
                      as={Select}
                      labelId='employmentStatus-label'
                      name='employmentStatus'
                      label='Employment Status'
                      value={values.employmentStatus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.employmentStatus && Boolean(errors.employmentStatus)}
                      helperText={touched.employmentStatus && errors.employmentStatus}>
                      {employeeStatus.map((option) => (
                        <MenuItem key={option.id} value={option.title}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel sx={localStyle.formLabel} required>
                      Highest Driver’s License Obtained
                    </InputLabel>
                    <Field
                      as={Select}
                      name='highestDL'
                      label='Highest Driver’s license obtained'
                      value={values.highestDL}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.highestDL && Boolean(errors.highestDL)}
                      helperText={touched.highestDL && errors.highestDL}>
                      {highestDL.map((option) => (
                        <MenuItem key={option.id} value={option.title}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Field>
                    {touched.highestDL && errors.highestDL ? (
                      <Typography color='error' variant='caption'>
                        {errors.highestDL}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel sx={localStyle.formLabel} id='education-label' required>
                      Educational Background
                    </InputLabel>
                    <Field
                      as={Select}
                      labelId='education-label'
                      name='educationalBackground'
                      label='Educational Background'
                      value={values.educationalBackground}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.educationalBackground && Boolean(errors.educationalBackground)}
                      helperText={touched.educationalBackground && errors.educationalBackground}>
                      {educations.map((option) => (
                        <MenuItem key={option.id} value={option.title}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Field>
                    {touched.educationalBackground && errors.educationalBackground ? (
                      <Typography color='error' variant='caption'>
                        {errors.educationalBackground}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel sx={localStyle.formLabel} id='referral-source' required>
                      Referral Source
                    </InputLabel>
                    <Field
                      as={Select}
                      labelId='referral-source'
                      name='referralSource'
                      label='Referral Source'
                      value={values.referralSource}
                      onChange={(event: any) => {
                        handleChange(event);
                        setSelectedReferralSource(event.target.value);
                      }}
                      onBlur={handleBlur}
                      error={touched.referralSource && Boolean(errors.referralSource)}
                      helperText={touched.referralSource && errors.referralSource}>
                      {referralSource.map((option) => (
                        <MenuItem key={option.id} value={option.title}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Field>
                    {touched.referralSource && errors.referralSource ? (
                      <Typography color='error' variant='caption'>
                        {errors.referralSource}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Grid>

                {selectedReferralSource ===
                referralSource.find((i) => i.id === ReferralSourceConst.COMMUNITY_BASED_ORG_ID)?.title ? (
                  <Grid item xs={12} lg={4}>
                    <TextField
                      label='Please specify'
                      fullWidth
                      placeholder='Please specify other Referral Source'
                      name='refCommunityOrg'
                      value={values.refCommunityOrg}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.refCommunityOrg && Boolean(errors.refCommunityOrg)}
                      helperText={touched.refCommunityOrg && errors.refCommunityOrg}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12} lg={4}>
                  <FormControl>
                    <FormLabel sx={{ fontSize: 17, fontWeight: '600' }} id='demo-row-radio-buttons-group-label'>
                      Interested In
                    </FormLabel>
                    <List>
                      {interestedInOptions.map((item: any) => (
                        <ListItem key={item.id} disablePadding>
                          <Checkbox
                            size='small'
                            onChange={() => {
                              handleSelectForInterestedIn(item.title);
                            }}
                            checked={selectedInterestedInFields.includes(item.title)}
                            sx={{ alignSelf: 'flex-start' }}
                          />
                          <ListItemText primary={item.title} />
                        </ListItem>
                      ))}
                    </List>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <FormControl>
                    <FormLabel
                      sx={{
                        fontSize: 17,
                        fontWeight: '600',
                        marginBottom: '-10px',
                        marginTop: '-10px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      id='demo-row-radio-buttons-group-label'>
                      Household Supports and Income
                      <Typography component='span' sx={{ color: 'red', marginLeft: 0.5, fontSize: '2em' }}>
                        *
                      </Typography>
                    </FormLabel>
                    <Typography variant='caption' sx={{ fontSize: 14, color: 'text.secondary', display: 'block' }}>
                      PINCC services are targeted to New Yorkers who fall within the below categories. Check all that
                      apply:
                    </Typography>
                    <List>
                      {publicAssistance.map((item: any) => (
                        <ListItem key={item.id} disablePadding>
                          <Checkbox
                            size='small'
                            onChange={() => {
                              handleSelect(item.title);
                            }}
                            checked={selectedPublicAssistance.includes(item.title)}
                            sx={{ alignSelf: 'flex-start' }}
                          />
                          <ListItemText primary={item.title} />
                        </ListItem>
                      ))}
                    </List>
                  </FormControl>
                </Grid>

                {selectedPublicAssistance?.length > 0 && (
                  <Grid item xs={12} lg={4}>
                    <TextField
                      label='Case Number'
                      fullWidth
                      placeholder='Case Number'
                      name='caseNumber'
                      value={values.caseNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.caseNumber && Boolean(errors.caseNumber)}
                      helperText={touched.caseNumber && errors.caseNumber}
                    />
                  </Grid>
                )}
              </Grid>

              <Box sx={localStyle.buttonBoxStyle}>
                <Typography>{isValid}</Typography>

                <Button
                  color='inherit'
                  disabled={false}
                  onClick={props.handleBack}
                  sx={{ mr: 1 }}
                  startIcon={<ChevronLeft />}
                  size={'large'}>
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  type='submit'
                  variant='contained'
                  // @ts-ignore
                  color='darkModePrimary'
                  size='large'
                  disabled={isHSISelected && isValid ? false : true}
                  endIcon={<ChevronRight />}>
                  {'Next'}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

const localStyle = {
  mainBoxStyle: {
    width: '100%',
    mt: 5,
  },

  buttonBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    mt: 5,
  },

  formLabel: {
    color: COLORS.palette.black,
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputLabel-asterisk': {
      color: 'red',
      fontSize: '2em',
      marginLeft: '-3px',
    },
  },
};

export default OtherInfo;
