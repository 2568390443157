import { Box, Grid, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from '../../../utils/colors';
import PCListForAttendanceRoster from '../../PCListForAttendanceRoster/PCListForAttendanceRoster';

interface AddPCToAttendanceRosterModalProps {
  open: boolean;
  onClose: Function;
  infoSessionId: string;
}

const AddPCToAttendanceRosterModal = (props: AddPCToAttendanceRosterModalProps) => {
  const { open, onClose, infoSessionId } = props;

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperProps={{ sx: { borderRadius: '14px' } }}>
      <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Box>
            <Typography variant='h6'>Add Prospective Clients to Attendance Roster</Typography>
            <Typography variant='body2'>
              Select prospective clients in order to add them in info-session attendance roster
            </Typography>
          </Box>
          <IconButton
            onClick={() => {
              onClose();
            }}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box>
          <PCListForAttendanceRoster
            infoSessionId={infoSessionId}
            onSubmit={() => {
              onClose();
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddPCToAttendanceRosterModal;
