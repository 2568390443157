import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';
import { functions } from '../config/firebaseApp';
import { IBackFillPTData } from '../components/Modals/BackFillProgramTrackData/BackFillProgramTrackData';
import { IBackFillEmplData } from '../components/Modals/BackFillEmploymentData/BackFillEmploymentData';

export const backFillProgramTrackData = async (payload: IBackFillPTData) => {
  try {
    const response: any = await httpsCallable(functions, 'BackFillProgramTrackData')(payload);
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Backfilling Client Program Track Data!');
    }
  } catch (error) {
    toast.error('Error While Backfilling Client Program Track Data!');
  }
};

export const backEmploymentData = async (payload: IBackFillEmplData) => {
  try {
    const response: any = await httpsCallable(functions, 'BackFillEmploymentData')(payload);
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Backfilling Client Employment Data!');
    }
  } catch (error) {
    toast.error('Error While Backfilling Client Employment Data!');
  }
};
