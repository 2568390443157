import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../config/firebaseApp';
import { toast } from 'react-toastify';
import { IWorkshopInfo, IWorkshopMetaInfo } from '../store/employmentModule/workshopModal';
import { collection, doc, getDocs, query, onSnapshot, where } from 'firebase/firestore';
import { userAccountStatus } from '../utils/userUtils';
import { getMultipleClientByIds } from './client.service';
import { getUserInfoById } from './user.service';
import { ISortModel } from '../utils/common';
import dayjs from 'dayjs';

export const addNewWorkshop = async (workshopInfo: IWorkshopInfo) => {
  try {
    if (!workshopInfo) {
      throw new Error();
    }
    const response: any = await httpsCallable(functions, 'createWorkshop')(workshopInfo);

    if (response.data.success) {
      toast.success('New Workshop Created Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Creating a Workshop!');
  }
};

export const editWorkshop = async (workshopInfo: IWorkshopInfo) => {
  try {
    if (!workshopInfo) {
      throw new Error();
    }
    const response: any = await httpsCallable(functions, 'editWorkshop')(workshopInfo);

    if (response.data.success) {
      toast.success('Workshop Updated Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Updating a Workshop!');
  }
};

export const deleteWorkshop = async (workshopId: string) => {
  try {
    const response: any = await httpsCallable(functions, 'deleteWorkshop')({ id: workshopId });
    if (response.data.success) {
      toast.success('Workshop Deleted Successfully!');
    }
  } catch (error: any) {
    toast.error('Error Deleting Workshop!');
  }
};

export const getWorkshopList = (page: number, sortModel: ISortModel[], callback: Function) => {
  const pageSize = 10;
  const offset = (page - 1) * pageSize;

  try {
    const workshopsCollectionRef = collection(db, 'workshops');

    // Subscribe to changes using onSnapshot
    const unsubscribe = onSnapshot(workshopsCollectionRef, (querySnapshot) => {
      const workshops: any = [];
      querySnapshot.forEach((doc) => {
        workshops.push({ id: doc.id, ...doc.data() });
      });

      if (sortModel.length > 0) {
        const field = sortModel[0].field;
        const sort = sortModel[0].sort;

        if (field === 'title') {
          workshops.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.title.localeCompare(b.title);
            } else if (sort === 'desc') {
              return b.title.localeCompare(a.title);
            }
          });
        } else if (field === 'location') {
          workshops.sort((a: any, b: any) => {
            if (!a.location && !b.location) {
              return 0;
            } else if (!a.location) {
              return 1;
            } else if (!b.location) {
              return -1;
            } else {
              if (sort === 'asc') {
                return a.location.localeCompare(b.location);
              } else if (sort === 'desc') {
                return b.location.localeCompare(a.location);
              }
            }
          });
        } else if (field === 'dateTime') {
          workshops.sort((a: any, b: any) => {
            const dateA = dayjs(a.dateTime[0].timing).tz('America/New_York');
            const dateB = dayjs(b.dateTime[0].timing).tz('America/New_York');
            if (sort === 'asc') {
              return dateA.valueOf() - dateB.valueOf();
            } else if (sort === 'desc') {
              return dateB.valueOf() - dateA.valueOf();
            }
            return 0;
          });
        } else if (field === 'type') {
          workshops.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.type.localeCompare(b.type);
            } else if (sort === 'desc') {
              return b.type.localeCompare(a.type);
            }
          });
        }
      } else {
        workshops.sort((a: any, b: any) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB.getTime() - dateA.getTime();
        });
      }

      const totalPages = Math.ceil(workshops.length / pageSize);
      const limitedWorkshops = workshops.slice(offset, offset + pageSize);

      const result = {
        totalRecords: workshops.length,
        currentPage: page,
        workshops: limitedWorkshops,
        totalPages: totalPages,
      };

      callback(result);
    });

    return unsubscribe; // Return the unsubscribe function directly
  } catch (err: any) {
    toast.error('Error Retrieving Workshop Data!');
    return () => {}; // Return a dummy function if an error occurs
  }
};

export const getMultipleWorkshopsByIds = async (workshopIds: string[]) => {
  try {
    if (workshopIds.length) {
      const workshopRef = collection(db, 'workshops');
      const querySnapshot = await getDocs(query(workshopRef));
      const documents = querySnapshot.docs.filter((doc) => workshopIds.includes(doc.id));
      const docList: any = [];

      documents.forEach((doc) => {
        docList.push({ id: doc.id, ...doc.data() });
      });

      return docList;
    }
    return [];
  } catch (err: any) {
    toast.error('Error Retrieving Workshops!');
    return [];
  }
};

export const searchWorkshop = async (searchQuery: string, page: number) => {
  try {
    if (!searchQuery) {
      throw new Error();
    }

    const response: any = await httpsCallable(functions, 'searchWorkShopByTitle')({ query: searchQuery });

    const workshopData = response.data.data;
    const workshopIds: any = [];
    let workshopList = [];

    if (workshopData.length > 0) {
      for (const data of workshopData) {
        workshopIds.push(data.id);
      }
      workshopList = await getMultipleWorkshopsByIds(workshopIds);
      workshopList.sort((a: any, b: any) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB.getTime() - dateA.getTime();
      });
      const pageSize = 10;
      const offset = (page - 1) * pageSize;
      const totalPages = Math.ceil(workshopList.length / pageSize);
      const limitedWorkshops = workshopList.slice(offset, offset + pageSize);
      const result: IWorkshopMetaInfo = {
        totalRecords: workshopList.length,
        currentPage: page,
        workshops: limitedWorkshops,
        totalPages: totalPages,
      };
      return result;
    } else {
      return {
        totalRecords: 0,
        currentPage: page,
        workshops: [],
        totalPages: 0,
      };
    }
  } catch (error) {
    toast.error('Error Searching Workshop!');
  }
};

export const getWorkshopById = async (id: string, callback: Function) => {
  try {
    if (id) {
      const colRef = doc(db, 'workshops', id);

      const unsubscribe = onSnapshot(colRef, (snapshot) => {
        if (snapshot.exists()) {
          const workshopData = { id: snapshot.id, ...snapshot.data() };
          callback(workshopData);
        } else {
          callback({});
        }
      });

      return unsubscribe;
    }

    callback({});
    return () => {};
  } catch (error: any) {
    toast.error('Error Subscribing to Workshop Data!');
    return () => {};
  }
};

export const fetchAllWorkshops = async (callback: Function) => {
  const workshops: any = [];
  try {
    const colRef = collection(db, 'workshops');
    const unsubscribe = onSnapshot(colRef, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const workshops: any[] = [];

        querySnapshot.forEach((doc) => {
          if (!doc.data().isAttendanceMarked) {
            workshops.push({ id: doc.id, ...doc.data() });
          }
        });

        callback(workshops);
      } else {
        callback([]);
      }
    });

    return unsubscribe;
  } catch (err: any) {
    console.log(err.message);
  }
  return workshops;
};

export const fetchRegisteredClientsForWorkshop = async (workshopId: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const q = query(collectionRef, where('workshopId', '==', workshopId));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientIds: string[] = [];
        querySnapshot.docs.forEach((doc: any) => {
          if (doc.data().account !== userAccountStatus.DELETED && !doc.data().workshopAttendance) {
            clientIds.push(doc.id);
          }
        });
        const clientList = await getMultipleClientByIds(clientIds);
        callback(clientList);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Client Data Registered in a Workshop');
  }
};

export const fetchClientIdsByWorkshopId = async (workshopId: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const q = query(collectionRef, where('workshopId', '==', workshopId));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientIds: string[] = [];
        querySnapshot.docs.forEach((doc: any) => {
          if (doc.data().account !== userAccountStatus.DELETED && !doc.data().workshopAttendance) {
            clientIds.push(doc.id);
          }
        });
        callback(clientIds);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Client Data Registered in a Workshop');
  }
};

export const markWorkshopAttendance = async (clientIds: string[], workshopId: string, markedBy: string) => {
  try {
    const payload = {
      ids: clientIds,
      workshopId: workshopId,
      markedBy: markedBy,
    };
    const response = await httpsCallable(functions, 'markClientsWorkshopAttendance')(payload);
    return response.data;
  } catch (error) {
    toast.error('Error While Marking Workshop Attendance!');
  }
};

export const fetchClientsAttendedWorkshop = async (workshopId: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'clients');
    const q = query(collectionRef, where('workshopId', '==', workshopId));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const clientIds: string[] = [];
        querySnapshot.docs.forEach((doc: any) => {
          if (doc.data().account !== userAccountStatus.DELETED && doc.data().workshopAttendance) {
            clientIds.push(doc.id);
          }
        });
        const clientList = await getMultipleClientByIds(clientIds);
        for (const client of clientList) {
          for (const item of client.workshopAttendance) {
            if (item.markedBy) {
              const userData: any = await getUserInfoById(item.markedBy);
              item.markedBy = userData?.firstName + ' ' + userData?.lastName;
            }
          }
        }
        callback(clientList);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    throw new Error('Error While Fetching Client Data Attended a Workshop');
  }
};
