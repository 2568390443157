import { UserReduxModel } from './userModel';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ICommonClient } from '../../services/client.service';

const initialUserState: UserReduxModel = {
  user: undefined,
  staffList: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser(state, action: PayloadAction<ICommonClient>) {
      state.user = action.payload;
    },
    resetUser() {
      return structuredClone(initialUserState);
    },
    setStaffList(state, action: PayloadAction<ICommonClient[]>) {
      state.staffList = action.payload;
    },
  },
});

export default userSlice;
