import { collection, getDocs, query, where } from 'firebase/firestore';
import { subtractDaysFromToday } from '../utils/dateTime';
import { db } from '../config/firebaseApp';
import { toast } from 'react-toastify';

export const getPCBoardedInLastXDays = async (days: number) => {
  try {
    const startTimestamp = subtractDaysFromToday(days);
    const collectionRef = collection(db, 'prospectiveClientAnalytics');
    const q = query(collectionRef, where('createdAt', '>=', startTimestamp));

    const querySnapshot = await getDocs(q);

    return querySnapshot.size;
  } catch (error) {
    toast.error('Error Retrieving data!');
  }
};

export const getPCConvertedInLastXDays = async (days: number) => {
  try {
    const startTimestamp = subtractDaysFromToday(days);
    const collectionRef = collection(db, 'prospectiveClientAnalytics');
    const q = query(collectionRef, where('convertedAt', '>=', startTimestamp));

    const querySnapshot = await getDocs(q);

    return querySnapshot.size;
  } catch (error) {
    toast.error('Error Retrieving data!');
  }
};
