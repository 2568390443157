import { Box } from '@mui/material';
import React, { useState } from 'react';
import { DashboardSmallCard } from '../DashboardSmallCard/DashboardSmallCard';
import AddInfoSessionModal from '../Modals/AddInfoSession/AddInfoSession';
import { MoreTimeOutlined } from '@mui/icons-material';
import { WriteModeEnum } from '../../utils/writeModeEnum';

const AddInfoSessionWidget = () => {
  const [infoSessionModal, setInfoSessionModal] = useState(false);
  return (
    <>
      <Box style={{ cursor: 'pointer' }} onClick={() => setInfoSessionModal(true)}>
        <DashboardSmallCard label='Create Info Session' desc='Schedule an Info Session' avatar={<MoreTimeOutlined />} />
      </Box>
      <AddInfoSessionModal
        open={infoSessionModal}
        onClose={() => setInfoSessionModal(false)}
        mode={WriteModeEnum.CREATE}
        data={null}
        onAddInfoSession={() => {}}
      />
    </>
  );
};

export default AddInfoSessionWidget;
