import { InputLabel, TextField as MuiTextField } from '@mui/material';
import { COLORS } from '../utils/colors';

const TextField = (props: any) => {
  const { label, ...rest } = props;
  return (
    <>
      {label && (
        <InputLabel required={rest.required} sx={styles.formLabel}>
          {label}
        </InputLabel>
      )}
      <MuiTextField size='small' {...rest} />
    </>
  );
};

export default TextField;

const styles = {
  formLabel: {
    color: COLORS.palette.black,
    fontSize: '12px',
    marginBottom: '5px',
    '& .MuiInputLabel-asterisk': {
      color: 'red',
    },
  },
};
