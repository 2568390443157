import React, { ReactElement } from 'react';
import { Tooltip, Typography, Box } from '@mui/material';

interface CustomTooltipProps {
  title: string | ReactElement;
  children: ReactElement;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children, ...props }) => {
  return (
    <Tooltip
      title={<Typography sx={{ textAlign: 'justify', fontSize: 10 }}>{title}</Typography>}
      arrow
      placement='top'
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#edf5f4',
            color: '#000',
            borderRadius: '8px',
            border: '2px solid black',
            padding: '10px',
          },
        },
        arrow: {
          sx: {
            color: '#edf5f4',
            '&::before': {
              border: '2px solid black',
            },
          },
        },
      }}
      {...props}>
      <Box>{children}</Box>
    </Tooltip>
  );
};

export default CustomTooltip;
