import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { useAppDispatch } from '../../store/store';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebaseApp';
import { logoutUser } from '../../store/user/userAction';

export type ClientNavbarProps = {
  title?: string;
  toggelSidebar?: () => void;
};

export default function ClientNavbar(props: ClientNavbarProps) {
  const { title } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    signOut(auth);
    handleMenuClose();
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position='static' elevation={0}>
          <Toolbar>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 2 }}
              onClick={props.toggelSidebar}>
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1, justifyContent: 'center', display: 'flex' }}>
              {title}
            </Typography>
            <IconButton sx={{ p: 0 }} onClick={handleMenuOpen}>
              <Avatar />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>

      {/* Drop down menu */}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuItem component={Box} onClick={handleLogout}>
          Log out
        </MenuItem>
      </Menu>
    </>
  );
}
