import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment';
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = 'America/New_York';
dayjs.tz.setDefault(tz);

export const convertTimeStampToDate = (timeStamp: any) => {
  const newYorkTime = dayjs.tz(timeStamp.toMillis(), 'America/New_York');
  return newYorkTime;
};

export const addDaysToDate = (startDate: Date, days: number) => {
  const date = startDate;
  const newDate = dayjs(date).add(days, 'day');
  return newDate.toDate();
};

export const getDateInFormat = (date: string, format = 'MM/DD/YYYY') => {
  const parsedDate = dayjs(date, 'YYYY-MM-DD hh:mm A');
  const formattedDate = parsedDate.format(format);
  return formattedDate;
};

export const getTimeInFormat = (date: string) => {
  const parsedDate = dayjs(date, 'YYYY-MM-DD hh:mm A');
  const formattedTime = parsedDate.format('hh:mm A');
  return formattedTime;
};

export const subtractDaysFromToday = (days: number) => {
  const now = dayjs();
  const startTimestamp = now.subtract(days, 'day').startOf('day').toDate();
  return startTimestamp;
};

// creating time solts based on openTime, CloseTime and intervalTime
export const createTimeSlots = (fromTime: string, toTime: string, interval: number, use24HourFormat: boolean) => {
  if (!moment(fromTime, 'HH:mm').isValid() || !moment(toTime, 'HH:mm').isValid() || interval <= 0) {
    throw new Error('Invalid input');
  }
  const startTime = moment(fromTime, 'HH:mm');
  const endTime = moment(toTime, 'HH:mm');
  const timeFormat = use24HourFormat ? 'HH:mm' : 'h:mm';
  let arr: any[] = [];
  while (startTime <= endTime || startTime.format('HH:mm') === endTime.format('HH:mm')) {
    arr.push(startTime.format(timeFormat));
    startTime.add(interval, 'minute');
  }
  return arr;
};

export const HourFormat = [
  {
    id: 1,
    value: 'pm',
    title: 'PM',
  },
  {
    id: 2,
    value: 'am',
    title: 'AM',
  },
];

export const getTimeDifferenceInHours = (startTime: string, endTime: string) => {
  const startDate: any = new Date(`2000-01-01 ${startTime}`);
  const endDate: any = new Date(`2000-01-01 ${endTime}`);

  const timeDifferenceInMilliseconds = endDate - startDate;

  const timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60);

  return timeDifferenceInHours;
};
