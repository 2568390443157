import userSlice from './userSlice';
import type { AnyAction } from '@reduxjs/toolkit';
import type { ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ICommonClient } from '../../services/client.service';
import { resetInfoSessionList } from '../infoSession/infoSessionAction';
import { resetProgramTracks } from '../programTracks/programTracksAction';
import { saveAuditLogOnLogout } from '../../services/firebase.service';

export const UserActions = userSlice.actions;

export const updateUser = (payload: ICommonClient): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch(UserActions.setUser(payload));
  };
};

export const logoutUser = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    saveAuditLogOnLogout();
    dispatch(UserActions.resetUser(undefined));
    resetInfoSessionList();
    resetProgramTracks();
  };
};

export const setStaffList = (staffList: ICommonClient[]): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch(UserActions.setStaffList(staffList));
  };
};
