import {
  Box,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { useEffect, useState } from 'react';
import { fetchPCForAttendanceRoster } from '../../services/client.service';
import { toast } from 'react-toastify';
import { addPCsToAttendanceRoster } from '../../services/infoSession.service';
import { Loader } from '../Loader/Loader';
import { convertTimeStampToDate } from '../../utils/dateTime';

interface PCListForAttendanceRosterProps {
  infoSessionId: string;
  onSubmit: Function;
}

const localStyle = {
  headCellStyle: {
    width: '33%',
    fontWeight: 'bold',
  },
};

const PCListForAttendanceRoster = (props: PCListForAttendanceRosterProps) => {
  const { infoSessionId, onSubmit } = props;
  const [clientList, setClientList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedClients, setSelectedClients] = useState<any[]>([]);
  const [clientLoader, setClientLoader] = useState<boolean>(false);

  const handleClientSelect = (clientId: string) => {
    if (selectedClients.includes(clientId)) {
      setSelectedClients(selectedClients.filter((id: string) => id !== clientId));
    } else {
      setSelectedClients([...selectedClients, clientId]);
    }
  };

  const fetchEligibleClients = async () => {
    try {
      setClientLoader(true);
      const loadingTimeout = setTimeout(() => {
        setClientLoader(true);
      }, 200);

      const unsubscribe = await fetchPCForAttendanceRoster(infoSessionId, (clientData: any) => {
        clearTimeout(loadingTimeout);
        setClientList(clientData);
        setClientLoader(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddToAttendanceRoster = async () => {
    setLoading(true);
    let response: any;
    response = await addPCsToAttendanceRoster(selectedClients, infoSessionId);
    if (response?.success) {
      toast.success(response.message);
      onSubmit();
    } else {
      toast.error('Something went wrong');
    }
    setLoading(false);
    setClientList([]);
  };

  useEffect(() => {
    fetchEligibleClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box py={3}>
      {clientLoader ? (
        <Loader />
      ) : (
        <Box>
          {clientList.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='center' sx={localStyle.headCellStyle}>
                      Client Name
                    </TableCell>
                    <TableCell align='center' sx={localStyle.headCellStyle}>
                      Created At
                    </TableCell>
                    <TableCell align='center' sx={localStyle.headCellStyle}>
                      Select
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientList.map((client: any) => (
                    <TableRow key={client.id}>
                      <TableCell align='center' size='small'>
                        {client.firstName} {client.lastName}
                      </TableCell>
                      <TableCell align='center' size='small'>
                        {convertTimeStampToDate(client?.createdAt).format('MM/DD/YYYY')}
                      </TableCell>
                      <TableCell align='center' size='small'>
                        <Checkbox
                          checked={selectedClients.includes(client.id)}
                          onChange={() => handleClientSelect(client.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
          <Grid container direction='column' alignItems='center' justifyContent='center' pt={2}>
            <Grid item xs={12}>
              <LoadingButton
                size='small'
                color={'primary'}
                variant={'contained'}
                loading={loading}
                disabled={selectedClients.length > 0 ? false : true}
                onClick={handleAddToAttendanceRoster}
                typographyComp={
                  <Typography variant='body2' textTransform={'capitalize'} noWrap>
                    Submit
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          {/* </Box> */}
          <Box display={clientList?.length > 0 ? 'none' : 'block'}>
            <Typography variant='body2' textAlign={'center'} py={2}>
              No Clients Eligible Yet.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PCListForAttendanceRoster;
