import { Grid, Link, Typography } from '@mui/material';
import { COLORS } from '../../../utils/colors';
import { isIOS } from '../../../utils/platform';
import ClientForm from '../../admin/client/createClient/clientForm';
import { addNewClient } from '../../../services/client.service';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAnInfoSessionByCustomUrlName } from '../../../services/infoSession.service';
import dayjs from 'dayjs';
import InfoSessionExpiredPage from '../../admin/client/createClient/InfoSessionExpiredPage';
import Loading from '../../../components/loading/loading';

const InfoSession = () => {
  const { customUrlName } = useParams();
  const isSelfCreated = true;
  const [isSignupComplete, setIsSignupComplete] = useState<boolean>(false);
  const [isSessionOutDated, setIsSessionOutdated] = React.useState<boolean>(false);
  const [isSessionExist, setIsSessionExist] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onFormSubmit = async (payload: any) => {
    try {
      const data = { isSelfSignup: true, ...payload };
      const result = await addNewClient(data, isSelfCreated);

      if (result.success) {
        setIsSignupComplete(true);
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    (async () => {
      if (customUrlName) {
        setLoading(true);
        const sessionData: any = await getAnInfoSessionByCustomUrlName(customUrlName);
        if (sessionData) {
          const dateTime = dayjs(sessionData.dateTime).startOf('day');
          const currentTime = dayjs().startOf('day');
          if (dateTime.isBefore(currentTime)) {
            setIsSessionOutdated(true);
          }
        } else {
          setIsSessionExist(false);
        }
        setLoading(false);
      }
    })();
  }, [customUrlName]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center' sx={styles.root}>
      <Grid item xs={12}>
        <img alt='Logo' src={'/assets/logo.png'} style={isIOS() ? { width: 50 } : { width: 100 }} />
      </Grid>
      {customUrlName && (isSessionOutDated || !isSessionExist) ? (
        <Grid item xs={12} sx={styles.centeredStyle} my={4}>
          <InfoSessionExpiredPage />
        </Grid>
      ) : (
        <Grid item xs={12} sx={styles.centeredStyle} my={4}>
          <Grid container direction='row' mb={4}>
            <Typography variant='h5'>Signup for Info Session</Typography>
          </Grid>
          <ClientForm
            isSelf={true}
            onSubmit={(paylad: any) => {
              onFormSubmit(paylad);
            }}
            isSignupComplete={isSignupComplete}
            customUrlName={customUrlName}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Link href='/login' sx={styles.loginBtn}>
          Back to login
        </Link>
      </Grid>
    </Grid>
  );
};

export default InfoSession;

const styles = {
  root: {
    minHeight: '100vh',
    backgroundColor: COLORS.theme.primaryColor,
  },
  loginBtn: {
    color: COLORS.palette.gray,
    textDecoration: 'none',
    fontSize: '16px',
    lineHeight: '19px',
  },
  centeredStyle: {
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    borderRadius: '14px',
    '@media (max-width: 480px)': {
      width: '90%',
      height: '90%',
      padding: '20px',
      margin: '10px',
    },
  },
};
