export const ClientStatus = {
  PROSPECTIVE: 'prospective',
  PENDING_ASSIGNMENT: 'pending_assignment',
  PENDING_ENROLLMENT: 'pending_enrollment',
  ENROLLMENT_REVIEW: 'enrollment_review',
  ENROLLED: 'enrolled',
  COMPLETED: 'completed',
  DROP_OUT: 'drop_out',
  SUSPENDED: 'suspended',
};
