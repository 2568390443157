export const caseManagerTypeOptions = [
  { id: 1, value: 'Jobs-Plus', label: 'Job Plus' },
  { id: 2, value: 'Career Service', label: 'Career Service' },
  { id: 3, value: 'PINCC Partner', label: 'PINCC Partner' },
];

export const caseManagerTypes = {
  JOB_PLUS: 'Jobs-Plus',
  CAREER_SERVICE: 'Career Service',
  PINCC_PARTNER: 'PINCC Partner',
};
