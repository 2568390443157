import { Box } from '@mui/material';
import React, { useState } from 'react';
import { DashboardSmallCard } from '../DashboardSmallCard/DashboardSmallCard';
import AddNewCurriculum from '../Modals/AddNewCurriculum/AddNewCurriculum';
import { ViewTimelineOutlined } from '@mui/icons-material';
import { pseudoAddCurriculum } from '../../services/curriculum.service';

const CreateNewCurriculumWidget = () => {
  const [createCurriculumModal, setCreateCurriculumModal] = useState(false);

  const dummyCallAddCurriculum = async () => {
    await pseudoAddCurriculum();
  };

  return (
    <>
      <Box
        style={{ cursor: 'pointer' }}
        onClick={() => {
          dummyCallAddCurriculum();
          setCreateCurriculumModal(true);
        }}>
        <DashboardSmallCard
          label='Create new Curriculum'
          desc='Create a New Curriculum'
          avatar={<ViewTimelineOutlined />}
        />
      </Box>
      <AddNewCurriculum open={createCurriculumModal} onClose={() => setCreateCurriculumModal(false)} mode='create' />
    </>
  );
};

export default CreateNewCurriculumWidget;
