import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { DashboardCard } from '../DashboardCard/DashboardCard';
import { ICommonClient, fetchClientCompletedWorkshop, getClientsLive } from '../../services/client.service';
import { toast } from 'react-toastify';
import { ClientStatus } from '../../utils/clientStatus';
import { useNavigate } from 'react-router-dom';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { getClientsAbove80PrcntComplete } from '../../services/programTrackClientMap.service';
import { adminTypeEnum } from '../../utils/adminTypes';

interface ClientsOnLiaisonDashBoardCardProps {
  userRole: string;
  adminType: string;
  isCompletedClients?: boolean;
  isJRTCompleted?: boolean;
}

export default function ClientsOnLiaisonDashBoardCard(props: ClientsOnLiaisonDashBoardCardProps) {
  const { userRole, isCompletedClients, isJRTCompleted, adminType } = props;
  const navigate = useNavigate();
  const [client, setClients] = useState<Array<ICommonClient>>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const checkCardPermission =
    userRole === UserRolesEnum.EMPLOYMENT_LIAISON || (UserRolesEnum.ADMIN && adminType === adminTypeEnum.EMPLOYMENT);

  const loadClients = async () => {
    try {
      setShowLoader(true);
      if (isCompletedClients) {
        const unsubscribe = getClientsLive(ClientStatus.COMPLETED, (data: any) => {
          const filteredClients = data.filter((i: any) => i?.account !== 'deleted');
          setClients(filteredClients);
          setShowLoader(false);
        });
        return () => {
          unsubscribe();
        };
      } else if (isJRTCompleted) {
        const unsubscribe = await fetchClientCompletedWorkshop((data: any) => {
          const filteredClients = data.filter((i: any) => i?.account !== 'deleted');
          setClients(filteredClients);
          setShowLoader(false);
        });
        return () => {
          unsubscribe();
        };
      } else {
        await getClientsAbove80PrcntComplete((data: any) => {
          const filteredClients = data.filter((i: any) => i?.account !== 'deleted');
          setClients(filteredClients);
          setShowLoader(false);
        });
      }
    } catch (err: any) {
      toast.error('Unable to load client list');
    }
  };

  React.useEffect(() => {
    if (checkCardPermission) loadClients();
    // eslint-disable-next-line
  }, [props.userRole]);

  return checkCardPermission ? (
    <Grid item lg={6} xs={12}>
      <DashboardCard
        isLoading={showLoader}
        label={
          isCompletedClients
            ? UserRolesEnum.ADMIN && adminType === adminTypeEnum.EMPLOYMENT
              ? 'Clients Ready for Employment Assignment'
              : 'Clients Ready for Intake'
            : isJRTCompleted
            ? 'Clients completed Job Readiness Training'
            : 'Clients about 80% completed'
        }
        desc={
          isCompletedClients
            ? 'List of clients who completed a Program Track'
            : isJRTCompleted
            ? 'List of Clients completed Job Readiness Training'
            : 'List of clients who completed 80% of a Program Track'
        }
        list={client}
        // eslint-disable-next-line
        onItemClick={(item: any) => {
          navigate('../clientInfo', { state: { clientId: item.id } });
        }}
      />
    </Grid>
  ) : null;
}
