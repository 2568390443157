import React, { useEffect, useState } from 'react';
import { FieldArray, Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Box,
  Grid,
  IconButton,
  Typography,
  MenuItem,
  Divider,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Checkbox,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import { WriteMode } from '../../../utils/common';
import { WriteModeEnum } from '../../../utils/writeModeEnum';
import { IJobInfo, IPreHireReq } from '../../../store/employmentModule/employmentModal';
import { JobTypes } from '../../../utils/jobTypes';
import { COLORS } from '../../../utils/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddCircleRounded } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { addNewJobOpportunity, editJobOpportunity } from '../../../services/employmentModule.service';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { getEmployerList } from '../../../services/user.service';
import { UserModel } from '../../../store/user/userModel';
import { preHireRequirementsOptions, TBD } from '../../ClientEmployment/EmploymentEnum';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface AddJobProps {
  open: boolean;
  mode: WriteMode;
  data: IJobInfo | null | undefined;
  onClose: () => void;
  onWriteCurriculum: () => void;
}

interface ISelectedEmployer {
  id: string;
  label: string;
}

const AddJob = (props: AddJobProps) => {
  const { open, mode, data, onClose, onWriteCurriculum } = props;
  const [employers, setEmployers] = useState<UserModel[]>([]);
  const [selectedEmployer, setSelectedEmployer] = useState<ISelectedEmployer>({ id: '', label: '' });
  const [selectedPreHireReqs, setSelectedPreHireReqs] = useState<IPreHireReq[]>([]);
  const [otherPreHireReq, setOtherPreHireReq] = useState<string>('');
  const [toBeDetermined, setToBeDetermined] = useState<boolean>(false);

  const handlePreHireReqToggle = (option: IPreHireReq) => {
    const currentIndex = selectedPreHireReqs.findIndex((selectedPreHireReqs) => selectedPreHireReqs.id === option.id);
    const newSelectedOptions = [...selectedPreHireReqs];

    if (currentIndex === -1) {
      newSelectedOptions.push(option);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }

    setSelectedPreHireReqs(newSelectedOptions);
  };

  const validationSchema = Yup.object({
    id: Yup.string().optional(),
    employer: Yup.string().required('Employer is required'),
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    notes: Yup.string().required('Notes is required'),
    salary: Yup.string().required('Salary is required'),
    type: Yup.string().required('Type is required'),
    availableTimings: Yup.array()
      .of(
        Yup.object().shape({
          timing: Yup.string().required('Timing is required'),
        })
      )
      .min(1, 'At least one timing is required'),
  });

  const initialValues: IJobInfo = {
    id: data?.id || '',
    employer: data?.employer || '',
    title: data?.title || '',
    description: data?.description || '',
    notes: data?.notes || '',
    salary: data?.salary || '',
    type: data?.type || '',
    numApplicants: data?.numApplicants || 0,
    availableTimings: data?.availableTimings || [{ timing: dayjs().toISOString() }],
    createdAt: data?.createdAt || dayjs().toISOString(),
    updatedAt: dayjs().toISOString(),
  };

  const handleSubmit = async (values: IJobInfo, { setSubmitting }: FormikHelpers<IJobInfo>) => {
    try {
      const payload: any = { ...values };
      if (selectedPreHireReqs.some((option: IPreHireReq) => option.id === '9')) {
        const updatedSelectedPreHireReqs = selectedPreHireReqs.map((option) =>
          option.id === '9' ? { ...option, value: otherPreHireReq } : option
        );
        payload.preHireRequirements = updatedSelectedPreHireReqs;
      } else {
        payload.preHireRequirements = selectedPreHireReqs;
      }
      // if (toBeDetermined) {
      // }
      if (mode === WriteModeEnum.CREATE) {
        delete payload.id;
        await addNewJobOpportunity(payload);
      } else {
        await editJobOpportunity(payload);
      }
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
    onWriteCurriculum();
    onClosing();
  };

  useEffect(() => {
    if (data?.preHireRequirements) {
      setSelectedPreHireReqs(data.preHireRequirements);
    }
  }, [data?.preHireRequirements]);

  useEffect(() => {
    const fetchEmployers = async () => {
      const employersList = await getEmployerList();
      setEmployers(employersList);
    };
    fetchEmployers();
  }, []);

  useEffect(() => {
    setSelectedEmployer({ id: '', label: data?.employer || '' });
  }, [data?.employer]);

  useEffect(() => {
    const isTbdPresent = data?.availableTimings.some((item) => item.timing === TBD) || false;
    setToBeDetermined(isTbdPresent);
  }, [data?.availableTimings]);

  const onClosing = () => {
    onClose();
    setSelectedEmployer({ id: '', label: '' });
    setOtherPreHireReq('');
    setSelectedPreHireReqs([]);
  };
  return (
    <Dialog open={open} onClose={onClosing} PaperProps={{ sx: { borderRadius: '14px' } }}>
      <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Typography variant='h6'>{mode === WriteModeEnum.CREATE ? 'Create New Job' : 'Edit Job'}</Typography>
          <IconButton onClick={onClosing}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          isInitialValid={false}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <Box py={2}>
              <Grid container spacing={2} pb={2}>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    fullWidth
                    size='small'
                    disableClearable
                    value={selectedEmployer}
                    options={employers.map((item: any) => ({
                      label: item.organization,
                      id: item.id,
                    }))}
                    getOptionLabel={(option: any) => option.label}
                    onChange={(event, object: any) => {
                      setSelectedEmployer(object);
                      setFieldValue('employer', object.id);
                    }}
                    renderInput={(params) => <TextField {...params} label='Select Employer' />}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    name='title'
                    label='Title'
                    size='small'
                    type='text'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title ? errors.title : ''}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    name='salary'
                    label='Salary'
                    size='small'
                    type='text'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.salary}
                    error={Boolean(touched.salary && errors.salary)}
                    helperText={touched.salary && errors.salary ? errors.salary : ''}
                  />
                </Grid>

                <Grid item lg={12} xs={12}>
                  <TextField
                    fullWidth
                    select
                    size='small'
                    label='Type'
                    name='type'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.type}
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}>
                    {JobTypes.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Divider>Fill Interview Timings</Divider>
                </Grid>
                <Grid item md={12} xs={12} spacing={1}>
                  <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title={TBD} arrow>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Checkbox
                          aria-label='TBD'
                          size='small'
                          checked={toBeDetermined}
                          onChange={() => {
                            setToBeDetermined(!toBeDetermined);
                            values.availableTimings = [{ timing: `${TBD}` }];
                          }}
                        />
                        <Typography variant='body1'>TBD</Typography>
                      </Box>
                    </Tooltip>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FieldArray
                      name='availableTimings'
                      render={({ push, remove }) => (
                        <Grid container spacing={2} alignItems={'center'}>
                          {values.availableTimings.map((timings, index) => {
                            let timing = '';
                            return (
                              <React.Fragment key={timings.timing.toString()}>
                                <Grid item md={10} xs={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    localeText={{ okButtonLabel: 'Select' }}>
                                    <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                      <DateTimePicker
                                        disablePast
                                        disabled={toBeDetermined}
                                        label={'Timing'}
                                        slotProps={{ textField: { size: 'small', error: false, fullWidth: true } }}
                                        name={`availableTimings.${index}.timing`}
                                        defaultValue={
                                          dayjs(values?.availableTimings[index]?.timing).isValid()
                                            ? dayjs(values?.availableTimings[index]?.timing)
                                            : null
                                        }
                                        viewRenderers={{
                                          hours: renderTimeViewClock,
                                          minutes: renderTimeViewClock,
                                          seconds: renderTimeViewClock,
                                        }}
                                        onChange={(date: dayjs.Dayjs | null) => {
                                          timing = date?.toISOString() || '';
                                        }}
                                        onViewChange={(view) => {
                                          if (view === 'meridiem') {
                                            const updatedTimings = [...values.availableTimings];
                                            updatedTimings[index].timing = timing || '';
                                            setFieldValue('availableTimings', updatedTimings);
                                          }
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Grid>

                                <Grid item md={1} xs={6}>
                                  <IconButton
                                    color='error'
                                    disabled={values.availableTimings.length === 1 || toBeDetermined}
                                    onClick={() => {
                                      remove(index);
                                    }}>
                                    <DeleteIcon
                                      style={{
                                        color:
                                          values.availableTimings.length === 1
                                            ? COLORS.palette.lightRed
                                            : COLORS.palette.red,
                                      }}
                                    />
                                  </IconButton>
                                </Grid>
                              </React.Fragment>
                            );
                          })}

                          <Grid item md={1} xs={6}>
                            <IconButton
                              color='primary'
                              disabled={toBeDetermined}
                              onClick={() =>
                                push({
                                  timing: '',
                                })
                              }>
                              <AddCircleRounded
                                style={{
                                  color: COLORS.theme.primaryColor,
                                }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}></FieldArray>
                  </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Divider>Fill Notes & Description</Divider>
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    name='description'
                    label='Description'
                    size='small'
                    type='text'
                    rows={5}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description ? errors.description : ''}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    name='notes'
                    label='Notes'
                    size='small'
                    type='text'
                    rows={5}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.notes}
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes ? errors.notes : ''}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Accordion sx={{ mb: 1, mt: 1 }} variant='outlined'>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls={`panel-content`}
                      id={`panel-header`}>
                      <Typography>Pre Hire Requirements</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        maxHeight: 200,
                        overflowY: 'auto',
                        paddingRight: 2,
                      }}>
                      <List dense>
                        {Array.isArray(preHireRequirementsOptions) &&
                          preHireRequirementsOptions.map((option: IPreHireReq) => {
                            const labelId = `checkbox-list-secondary-label-${option.id}`;
                            const isChecked = selectedPreHireReqs.some(
                              (selectedPreHireReqs) => selectedPreHireReqs.id === option.id
                            );

                            return (
                              <ListItem
                                key={option.id}
                                secondaryAction={
                                  <Checkbox
                                    size='small'
                                    edge='end'
                                    onChange={() => handlePreHireReqToggle(option)}
                                    checked={isChecked}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                }
                                disablePadding>
                                <ListItemButton>
                                  <ListItemText id={labelId} primary={option.value} />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {selectedPreHireReqs.some((option: IPreHireReq) => option.id === '9') ? (
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      name='otherPreHireReq'
                      label='Other Pre Hire Requirement'
                      size='small'
                      type='text'
                      onChange={(event: any) => {
                        setOtherPreHireReq(event.target.value);
                      }}
                      onBlur={handleBlur}
                      value={otherPreHireReq}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid container justifyContent={'center'}>
                <Grid item>
                  <LoadingButton
                    onClick={() => handleSubmit()}
                    label={mode === WriteModeEnum.CREATE ? 'Submit' : 'Update'}
                    loading={isSubmitting}
                    disabled={selectedPreHireReqs.length < 1}
                    size='medium'
                    type='submit'
                    variant='contained'
                    styles={{ textTransform: 'none' }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddJob;
