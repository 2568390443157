import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db, functions } from '../config/firebaseApp';
import { httpsCallable } from 'firebase/functions';
import { getUserInfoById } from './user.service';
import { getClientInfoById } from './client.service';

export const saveChatChannel = async (clientId: string, staffId: string) => {
  let channel_payload: any = {
    clientId: clientId,
    staffId: staffId,
  };
  const response = await httpsCallable(functions, 'createUserChannel')(channel_payload);
  return response.data;
};

export const getChatChannelsById = (id: string, callback: Function) => {
  try {
    if (!id) {
      throw new Error('Chat channels ID is not valid');
    }

    const channelsRef = collection(db, 'userChannels', id, 'channels');
    const channelsQuery = query(channelsRef, orderBy('lastMessageAt', 'desc'));
    const unsubscribe = onSnapshot(channelsQuery, async (querySnapshot) => {
      const list: any[] = [];

      for (const doc of querySnapshot.docs) {
        const docData = doc.data();
        const updatedDocData = { id: doc.id, ...docData };
        let info: any = null;
        try {
          info = await getUserInfoById(docData?.userId);
        } catch (error) {
          info = await getClientInfoById(docData?.userId);
        }
        const data = { channelInfo: updatedDocData, senderInfo: info };
        list.push(data);
      }
      callback(list);
    });

    return unsubscribe;
  } catch (err: any) {
    return () => {};
  }
};

export const getChannelIdByUserIds = async (clientId: string, staffId: string) => {
  try {
    const collectionRef = collection(db, 'userChannels', clientId, 'channels');
    const q = query(collectionRef, where('userId', '==', staffId));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      for (const channelDoc of querySnapshot.docs) {
        const channelId = channelDoc.id;
        return channelId;
      }
    }
    return '';
  } catch (err: any) {
    throw new Error('Error Retrieving Channel');
  }
};
