import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from '../../../utils/colors';
import { UserModel } from '../../../store/user/userModel';
import { getNavigatorList, getUserInfoById } from '../../../services/user.service';
import { Formik, FieldArray, type FormikHelpers } from 'formik';
import * as yup from 'yup';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { IProgramTrack, getProgramTrackById, getProgramTracks } from '../../../services/programTrack.service';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import { backFillProgramTrackData } from '../../../services/backfillClientsData.service';
import { convertTimeStampToDate } from '../../../utils/dateTime';
import { useAppSelector } from '../../../store/store';
import { UserRolesEnum } from '../../../utils/rolesEnum';
import { ClientStatus } from '../../../utils/clientStatus';
import {
  getClientMapActiveStatusByClientId,
  getClientMapByClientIdAndPTid,
} from '../../../services/programTrackClientMap.service';

interface IBackFillPTDataProps {
  open: boolean;
  onClose: () => void;
  clientInfo: any;
  refreshData: Function;
}

interface IMilestoneValue {
  index: number;
  name: string;
  attendance: string;
  completionDate: string;
}

export interface IBackFillPTData {
  id?: string;
  navigatorId?: string;
  navigatorAssignedAt?: string;
  programTrackId: string;
  enrollmentInitiatedAt: string;
  enrollmentApprovedAt: string;
  milestones: IMilestoneValue[];
  isClientCompleted?: boolean;
}

const BackFillProgramTrackData = (props: IBackFillPTDataProps) => {
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const { open, onClose, clientInfo, refreshData } = props;
  const [navigatorList, setNavigatorList] = useState<UserModel[]>([]);
  const [programTrackList, setProgramTrackList] = useState<IProgramTrack[]>([]);
  const [programTrackData, setProgramTrackData] = useState<IProgramTrack | null>(null);
  const [clientMapData, setClientMapData] = useState<any>(null);
  const [shouldFetch, setShouldFetch] = useState<boolean>(true);
  const [selectedProgramTrack, setSelectedProgramTrack] = useState<{ id: string; label: string }>({
    id: '',
    label: '',
  });
  const [selectedNavigator, setSelectedNavigator] = useState<{ id: string; label: string }>({
    id: '',
    label: '',
  });
  const [isPTCompleted, setIsPTCompleted] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const validationSchema = yup.object().shape({
    navigatorId: yup.string().when(() => {
      if (!clientInfo.assignedTo) {
        return yup.string().required('Navigator is Required');
      } else {
        return yup.string().notRequired();
      }
    }),
    navigatorAssignedAt: yup.string().when(() => {
      if (!clientInfo.assignedTo) {
        return yup.string().required('Navigator Assignment Date is Required');
      } else {
        return yup.string().notRequired();
      }
    }),
    programTrackId: yup.string().required('Program Track is required'),
    enrollmentInitiatedAt: yup.string().required('Program Track Enrollment Initiation Date is required'),
    enrollmentApprovedAt: yup.string().required('Program Track Enrollment Approval Date is required'),
  });

  const initialValues: IBackFillPTData = {
    navigatorId: '',
    navigatorAssignedAt: '',
    programTrackId: '',
    enrollmentInitiatedAt: '',
    enrollmentApprovedAt: '',
    milestones: [],
  };

  useEffect(() => {
    (async () => {
      if (clientInfo.assignedTo) {
        const userInfo: any = await getUserInfoById(clientInfo.assignedTo);
        setSelectedNavigator({ id: clientInfo.assignedTo, label: `${userInfo?.firstName} ${userInfo?.lastName}` });
      }
      if (clientInfo.status === ClientStatus.ENROLLED) {
        const clientMapInfo = await getClientMapActiveStatusByClientId(clientInfo.id);
        setClientMapData(clientMapInfo);
        if (clientMapInfo) {
          const ptData: any = await getProgramTrackById(clientMapInfo.programTrackId);
          setSelectedProgramTrack({ id: clientMapInfo.programTrackId, label: ptData.name });
        }
      }
    })();
  }, [clientInfo.status, clientInfo.id, clientInfo.assignedTo]);

  useEffect(() => {
    (async () => {
      const navData = await getNavigatorList();
      setNavigatorList(navData);
      const ptList = await getProgramTracks();
      setProgramTrackList(ptList);
    })();
  }, []);

  useEffect(() => {
    if (selectedProgramTrack.id) {
      (async () => {
        const ptData: any = await getProgramTrackById(selectedProgramTrack.id);
        if (clientInfo.status === ClientStatus.ENROLLED && shouldFetch) {
          const ptcmData = await getClientMapByClientIdAndPTid(clientInfo.id, selectedProgramTrack.id);
          for (let i = 0; i < ptcmData.completedMilestones.length; i++) {
            ptData.milestones[i].attendance = ptcmData.completedMilestones[i].attendance;
          }
          setShouldFetch(false);
        }
        setProgramTrackData(ptData);
      })();
    }
    // eslint-disable-next-line
  }, [selectedProgramTrack.id]);

  const handleClose = () => {
    onClose();
    setSelectedProgramTrack({ id: '', label: '' });
    setSelectedNavigator({ id: '', label: '' });
    setProgramTrackData(null);
    setClientMapData(null);
    setIsPTCompleted(false);
    setShowWarning(false);
  };

  const handleSubmit = async (values: IBackFillPTData, { setSubmitting }: FormikHelpers<IBackFillPTData>) => {
    try {
      const payload = { ...values, isClientCompleted: isPTCompleted, id: clientInfo.id };
      await backFillProgramTrackData(payload);
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
    refreshData();
    handleClose();
  };

  const checkIfAllMilestonesFilled = (milestones: IMilestoneValue[]) => {
    let isAllMilestonesFilled: boolean = true;
    for (const milestone of milestones) {
      if (!milestone.attendance || !milestone.completionDate) {
        isAllMilestonesFilled = false;
        break;
      } else {
        continue;
      }
    }
    return isAllMilestonesFilled;
  };

  const handleCheckBox = (values: IBackFillPTData) => {
    const isAllMilestonesFilled = checkIfAllMilestonesFilled(values.milestones);
    if (isAllMilestonesFilled) {
      setIsPTCompleted(!isPTCompleted);
      setShowWarning(false);
    } else {
      setShowWarning(!showWarning);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: '14px', width: '60%', maxWidth: 'none' } }}>
        <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
            <Typography variant='h6'>{`Backfill Client's Program Track Data`}</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            isInitialValid={false}>
            {({ values, handleSubmit, isSubmitting, setFieldValue, isValid }) => {
              // eslint-disable-next-line
              useEffect(() => {
                if (programTrackData) {
                  setFieldValue(
                    'milestones',
                    programTrackData.milestones.map((milestone: any, index: number) => ({
                      index,
                      attendance: milestone.attendance ? milestone.attendance : '',
                      name: milestone.name,
                      completionDate: milestone.deadline
                        ? dayjs(convertTimeStampToDate(milestone.deadline)).toISOString()
                        : '',
                    }))
                  );
                }
                if (clientMapData) {
                  setFieldValue(
                    'enrollmentInitiatedAt',
                    clientMapData ? dayjs(convertTimeStampToDate(clientMapData.enrollInitiatedAt)).toISOString() : null
                  );
                  setFieldValue(
                    'enrollmentApprovedAt',
                    clientMapData
                      ? dayjs(convertTimeStampToDate(clientMapData.enrollmentApprovedAt)).toISOString()
                      : null
                  );
                }
                if (clientInfo.assignedTo) {
                  setFieldValue('navigatorId', clientInfo.assignedTo);
                }
                if (clientMapData && clientInfo.status === ClientStatus.ENROLLED) {
                  setFieldValue('programTrackId', clientMapData.programTrackId);
                }
                // eslint-disable-next-line
              }, [programTrackData, setFieldValue]);
              return (
                <form>
                  <Grid container>
                    {user.role !== UserRolesEnum.NAVIGATOR ? (
                      <>
                        <Typography fontWeight={'bold'} sx={{ mt: 1 }}>
                          Assign Navigator :
                        </Typography>
                        <Grid container display={'flex'} justifyContent={'space-between'}>
                          <Grid lg={6} xs={12} sx={{ pr: 1, mt: 1 }}>
                            <Autocomplete
                              fullWidth
                              size='small'
                              disableClearable
                              value={selectedNavigator}
                              options={navigatorList.map((item: any) => ({
                                label: item.firstName + ' ' + item.lastName,
                                id: item.id,
                              }))}
                              getOptionLabel={(option: any) => option.label}
                              onChange={(event, object: any) => {
                                setFieldValue('navigatorId', object.id);
                                setSelectedNavigator(object);
                              }}
                              renderInput={(params) => <TextField {...params} label='Select Navigator' />}
                              isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                            />
                          </Grid>
                          <Grid lg={6} md={12} xs={12} pr={1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ okButtonLabel: 'Select' }}>
                              <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                <DateTimePicker
                                  label='Assignment Date'
                                  slotProps={{
                                    textField: { size: 'small', error: false, fullWidth: true },
                                  }}
                                  name='navigatorAssignedAt'
                                  value={values.navigatorAssignedAt ? dayjs(values.navigatorAssignedAt) : null}
                                  viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                  }}
                                  onChange={(date: string | number | Date | dayjs.Dayjs | null | undefined) => {
                                    const formattedDate = dayjs(date)?.toISOString() || '';
                                    setFieldValue('navigatorAssignedAt', formattedDate);
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                    <Typography fontWeight={'bold'} sx={{ mt: 2 }}>
                      Enroll in a Program Track :
                    </Typography>
                    <Grid container display={'flex'} justifyContent={'space-between'}>
                      <Grid lg={4} xs={12} sx={{ pr: 1, mt: 1 }}>
                        <Autocomplete
                          fullWidth
                          size='small'
                          disableClearable
                          value={selectedProgramTrack}
                          options={programTrackList.map((item: any) => ({
                            label: item.name,
                            id: item.id,
                          }))}
                          getOptionLabel={(option: any) => option.label}
                          onChange={(event, object: any) => {
                            setFieldValue('programTrackId', object.id);
                            setSelectedProgramTrack(object);
                          }}
                          renderInput={(params) => <TextField {...params} label='Select Program Track' />}
                          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                        />
                      </Grid>
                      <Grid lg={4} xs={12} pr={1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ okButtonLabel: 'Select' }}>
                          <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                            <DateTimePicker
                              label='Enrollment Initiated At'
                              slotProps={{
                                textField: { size: 'small', error: false, fullWidth: true },
                              }}
                              name='enrollmentInitiatedAt'
                              value={values.enrollmentInitiatedAt ? dayjs(values.enrollmentInitiatedAt) : null}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              onChange={(date: string | number | Date | dayjs.Dayjs | null | undefined) => {
                                const formattedDate = dayjs(date)?.toISOString() || '';
                                setFieldValue('enrollmentInitiatedAt', formattedDate);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      <Grid lg={4} xs={12} pr={1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ okButtonLabel: 'Select' }}>
                          <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                            <DateTimePicker
                              label='Enrollment Approved At'
                              slotProps={{
                                textField: { size: 'small', error: false, fullWidth: true },
                              }}
                              name='dateTime'
                              value={values.enrollmentApprovedAt ? dayjs(values.enrollmentApprovedAt) : null}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              onChange={(date: string | number | Date | dayjs.Dayjs | null | undefined) => {
                                const formattedDate = dayjs(date)?.toISOString() || '';
                                setFieldValue('enrollmentApprovedAt', formattedDate);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        mt: 2,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <Typography fontWeight={'bold'}>Milestones :</Typography>
                      {selectedProgramTrack.id ? (
                        <Box sx={{ display: 'inline', alignContent: 'flex-end' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Typography>Mark Client Completed</Typography>
                            <Checkbox
                              aria-label='Mark Client Completed'
                              checked={isPTCompleted}
                              onChange={() => {
                                handleCheckBox(values);
                              }}
                            />
                          </Box>
                          {showWarning && (
                            <Typography variant='body2' color='error'>
                              {'(All milestones must be filled to mark the client as completed)'}
                            </Typography>
                          )}
                        </Box>
                      ) : null}
                    </Box>
                    <Grid container display={'flex'} justifyContent={'space-between'}>
                      <FieldArray
                        name='milestones'
                        render={() => (
                          <>
                            {programTrackData?.milestones.length > 0 ? (
                              values.milestones.map((milestone, index) => (
                                <Grid container key={index} spacing={2} alignItems='center' sx={{ mt: 1 }}>
                                  <Grid item lg={4} xs={12} pr={1}>
                                    <TextField
                                      size='small'
                                      fullWidth
                                      id={`milestones[${index}].name`}
                                      label='Name'
                                      value={milestone.name}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item lg={4} xs={12} pr={1}>
                                    <TextField
                                      size='small'
                                      fullWidth
                                      select
                                      id={`milestones[${index}].attendance`}
                                      value={milestone.attendance ? milestone.attendance : null}
                                      label='Attendance'
                                      onChange={(event) => {
                                        setFieldValue(`milestones[${index}].attendance`, event.target.value);
                                      }}>
                                      <MenuItem value={'present'}>Present</MenuItem>
                                      <MenuItem value={'absent'}>Absent</MenuItem>
                                    </TextField>
                                  </Grid>
                                  <Grid item lg={4} xs={12} pr={1}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                      localeText={{ okButtonLabel: 'Select' }}>
                                      <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                        <DateTimePicker
                                          label='Completion Date'
                                          slotProps={{
                                            textField: { size: 'small', error: false, fullWidth: true },
                                          }}
                                          name={`milestones[${index}].completionDate`}
                                          value={milestone.completionDate ? dayjs(milestone.completionDate) : null}
                                          viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                          }}
                                          onChange={(date: string | number | Date | dayjs.Dayjs | null | undefined) => {
                                            const formattedDate = dayjs(date)?.toISOString() || '';
                                            setFieldValue(`milestones[${index}].completionDate`, formattedDate);
                                          }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </Grid>
                                </Grid>
                              ))
                            ) : (
                              <Typography mt={1} variant='caption'>
                                Please select a Program Track!
                              </Typography>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 2 }} justifyContent={'center'}>
                    <Grid item>
                      <LoadingButton
                        label={'Submit'}
                        onClick={() => handleSubmit()}
                        loading={isSubmitting}
                        disabled={!isValid}
                        size='medium'
                        type='submit'
                        variant='contained'
                        styles={{ textTransform: 'none' }}
                      />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BackFillProgramTrackData;
