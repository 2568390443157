import { Timestamp, collection, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { db, functions } from '../config/firebaseApp';
import { toast } from 'react-toastify';
import { convertTimeStampToDate } from '../utils/dateTime';
import moment from 'moment-timezone';
import { UserModel } from '../store/user/userModel';
import { shouldShowButton } from '../pages/admin/dashboard/dashboardAccessControl';
import { UserRolesEnum } from '../utils/rolesEnum';
import { programTrackTypes } from '../utils/programTrackEnums';
import { httpsCallable } from 'firebase/functions';
import { ISortModel } from '../utils/common';
import { getMultipleCareerPathInfoByIds } from './careerPath.service';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = 'America/New_York';
dayjs.tz.setDefault(tz);

export interface IProgramTrack {
  id?: string;
  careerPathId: string;
  createdBy: string;
  curriculumId: string;
  curriculumName: string;
  description: string;
  endTime?: Timestamp;
  location: string;
  name: string;
  type: string;
  startTime?: Timestamp;
  time: string;
  trainerId?: string;
  navigatorId?: string;
  navigatorInfo?: any;
  trainerInfo?: any;
  careerPathInfo?: any;
  milestones: any;
  capacity: number | null;
  enrolled?: number | null;
  requirements: string | null;
}

export interface IProgramTrackMetaInfo {
  totalRecords: number;
  currentPage: number;
  programTracks: Array<IProgramTrack>;
  totalPages: number;
}

export interface IChildProgramTrack {
  parentId: string;
  milestones: Array<Object>;
  startTime: Timestamp;
  endTime: Timestamp;
}

export interface IPTReport {
  totalRecords: number;
  currentPage: number;
  programTracks: Array<object>;
  totalPages: number;
}

export const getAvailableProgramTracks = async (careerPathId: string, clientId: string, callback: Function) => {
  try {
    const collectionRef = collection(db, 'programTrackClientMap');
    const q = query(collectionRef, where('clientId', '==', clientId), where('status', '==', 'review'));
    const querySnapshot = await getDocs(q);

    const programTrackIds: any = [];
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        programTrackIds.push(doc.data().programTrackId);
      });
    }
    const getDate = moment.tz('America/New_York');
    const currentDate = getDate.format('YYYY-MM-DD');

    const programTrackCollectionRef = collection(db, 'programTrack');
    const ptQuery = query(programTrackCollectionRef, where('careerPathId', '==', careerPathId));
    const unsubscribe = onSnapshot(ptQuery, async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const availableProgramTracks: any[] = [];
        querySnapshot.docs.forEach((doc: any) => {
          if (doc.data().type === programTrackTypes.COHORT.value) {
            const startDate = convertTimeStampToDate(doc.data().startTime).format('YYYY-MM-DD');
            if (startDate > currentDate && !programTrackIds.includes(doc.id)) {
              availableProgramTracks.push({ id: doc.id, ...doc.data() });
            }
          } else {
            if (!programTrackIds.includes(doc.id)) {
              availableProgramTracks.push({ id: doc.id, ...doc.data() });
            }
          }
        });
        callback(availableProgramTracks);
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (err: any) {
    //nothing
  }
};

export const getActiveAndFutureProgramTracks = async () => {
  try {
    const programTrackCollectionRef = collection(db, 'programTrack');
    const cohortQuery = query(
      programTrackCollectionRef,
      where('endTime', '>', Timestamp.fromDate(new Date())),
      where('type', '==', 'cohort')
    );
    const rollingQuery = query(programTrackCollectionRef, where('type', '==', 'rolling'));

    const cohortQuerySnapshot = await getDocs(cohortQuery);
    const rollingQuerySnapshot = await getDocs(rollingQuery);

    let programTracks: any = [];

    if (!cohortQuerySnapshot.empty) {
      cohortQuerySnapshot.forEach((doc) => {
        programTracks.push({ id: doc.id, ...doc.data() });
      });
    }

    if (!rollingQuerySnapshot.empty) {
      rollingQuerySnapshot.forEach((doc) => {
        programTracks.push({ id: doc.id, ...doc.data() });
      });
    }

    if (programTracks.length > 0) {
      return programTracks;
    } else {
      return [];
    }
  } catch (err: any) {
    return [];
  }
};

export const getFilteredProgramTracks = (programTrackList: any[], activeIds: number[]) => {
  const getDate = moment.tz('America/New_York');
  const currentDate = getDate.format('YYYY-MM-DD');

  const filteredPT: any[] = [];

  programTrackList.forEach((data: any) => {
    if (data.type === programTrackTypes.COHORT.value) {
      const startDate = convertTimeStampToDate(data.startTime).format('YYYY-MM-DD');
      const endDate = convertTimeStampToDate(data.endTime).format('YYYY-MM-DD');
      const archive = activeIds.includes(1);
      const active = activeIds.includes(2);
      const future = activeIds.includes(3);
      if (archive && active && future) {
        if (currentDate > endDate || startDate > currentDate || (startDate <= currentDate && currentDate <= endDate)) {
          filteredPT.push(data);
        }
      } else if (archive && active) {
        if (currentDate > endDate || (startDate <= currentDate && currentDate <= endDate)) {
          filteredPT.push(data);
        }
      } else if (active && future) {
        if (startDate > currentDate || (startDate <= currentDate && currentDate <= endDate)) {
          filteredPT.push(data);
        }
      } else if (archive && future) {
        if (currentDate > endDate || startDate > currentDate) {
          filteredPT.push(data);
        }
      } else if (archive) {
        if (currentDate > endDate) {
          filteredPT.push(data);
        }
      } else if (active) {
        if (startDate <= currentDate && currentDate <= endDate) {
          filteredPT.push(data);
        }
      } else if (future) {
        if (startDate > currentDate) {
          filteredPT.push(data);
        }
      } else {
        filteredPT.push(data);
      }
    } else {
      filteredPT.push(data);
    }
  });
  return filteredPT;
};

export const getProgramTracksByQuery = async (
  activeIds: number[],
  page: number,
  user: UserModel,
  sortModel: ISortModel[]
) => {
  try {
    const programTrackCollectionRef = collection(db, 'programTrack');
    let querySnapshot;

    if (user && user.role === UserRolesEnum.TRAINER) {
      const trainerQuery = query(programTrackCollectionRef, where('trainerId', '==', user.id));
      querySnapshot = await getDocs(trainerQuery);
    } else {
      querySnapshot = await getDocs(programTrackCollectionRef);
    }

    const pageSize = 10;
    const offset = (page - 1) * pageSize;

    if (!querySnapshot.empty) {
      const programTracks: IProgramTrack[] = [];
      const careerPathIds: any = [];
      querySnapshot.forEach((doc: any) => {
        const data = { id: doc.id, ...doc.data() } as IProgramTrack;
        programTracks.push(data);
        careerPathIds.push(data.careerPathId);
      });
      const careerPathInfoList = await getMultipleCareerPathInfoByIds(careerPathIds);
      for (const programTrack of programTracks) {
        programTrack.careerPathInfo =
          careerPathInfoList.find((data: any) => data.id === programTrack.careerPathId) || '';
      }

      const filteredProgramTracks = getFilteredProgramTracks(programTracks, activeIds);

      if (sortModel.length > 0) {
        const field = sortModel[0].field;
        const sort = sortModel[0].sort;

        if (field === 'name') {
          filteredProgramTracks.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.name.localeCompare(b.name);
            } else if (sort === 'desc') {
              return b.name.localeCompare(a.name);
            }
          });
        } else if (field === 'type') {
          filteredProgramTracks.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.type.localeCompare(b.type);
            } else if (sort === 'desc') {
              return b.type.localeCompare(a.type);
            }
          });
        } else if (field === 'careerPathName') {
          filteredProgramTracks.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.careerPathInfo.name.localeCompare(b.careerPathInfo.name);
            } else if (sort === 'desc') {
              return b.careerPathInfo.name.localeCompare(a.careerPathInfo.name);
            }
          });
        } else if (field === 'curriculumName') {
          filteredProgramTracks.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.curriculumName.localeCompare(b.curriculumName);
            } else if (sort === 'desc') {
              return b.curriculumName.localeCompare(a.careerPathInfo.name);
            }
          });
        } else if (field === 'startTime') {
          filteredProgramTracks.sort((a, b) => {
            const dateA = a.startTime ? convertTimeStampToDate(a.startTime).toDate() : null;
            const dateB = b.startTime ? convertTimeStampToDate(b.startTime).toDate() : null;

            if (dateA && dateB) {
              if (sort === 'asc') {
                return dateA.getTime() - dateB.getTime();
              } else if (sort === 'desc') {
                return dateB.getTime() - dateA.getTime();
              }
            } else if (dateB) {
              return 1;
            } else if (dateA) {
              return -1;
            }
            return 0;
          });
        } else if (field === 'endTime') {
          filteredProgramTracks.sort((a, b) => {
            const dateA = a.endTime ? convertTimeStampToDate(a.endTime).toDate() : null;
            const dateB = b.endTime ? convertTimeStampToDate(b.endTime).toDate() : null;
            if (dateA && dateB) {
              if (sort === 'asc') {
                return dateA.getTime() - dateB.getTime();
              } else if (sort === 'desc') {
                return dateB.getTime() - dateA.getTime();
              }
            } else if (dateB) {
              return 1;
            } else if (dateA) {
              return -1;
            }
            return 0;
          });
        } else if (field === 'enrollmentCount') {
          filteredProgramTracks.sort((a: any, b: any) => {
            const enrollmentA = Number(a.enrolled);
            const enrollmentB = Number(b.enrolled);

            if (sort === 'asc') {
              return enrollmentA - enrollmentB;
            } else if (sort === 'desc') {
              return enrollmentB - enrollmentA;
            }

            return 0;
          });
        }
      } else {
        filteredProgramTracks.sort((a, b) => {
          const dateA = a.createdAt ? convertTimeStampToDate(a.createdAt).toDate() : null;
          const dateB = b.createdAt ? convertTimeStampToDate(b.createdAt).toDate() : null;

          if (dateA && dateB) {
            return dateB.getTime() - dateA.getTime();
          } else if (dateB) {
            return 1; // Place documents without 'createdAt' field after those with 'createdAt'
          } else if (dateA) {
            return -1; // Place documents without 'createdAt' field before those with 'createdAt'
          } else {
            return 0; // Both documents don't have 'createdAt' field, no preference in order
          }
        });
      }

      const totalPages = Math.ceil(filteredProgramTracks.length / pageSize);
      const limitedProgramTracks = filteredProgramTracks.slice(offset, offset + pageSize);
      const result: IProgramTrackMetaInfo = {
        totalRecords: filteredProgramTracks.length,
        currentPage: page,
        programTracks: limitedProgramTracks,
        totalPages: totalPages,
      };
      return result;
    } else {
      return {
        totalRecords: 0,
        currentPage: page,
        programTracks: [],
        totalPages: 0,
      };
    }
  } catch (err: any) {
    //nothing
  }
};

export const programTrackInfoById = async (programTrackId: string) => {
  try {
    if (!programTrackId) {
      return;
    }

    const programTrackDocRef = doc(db, 'programTrack', programTrackId);

    const programTrackDoc = await getDoc(programTrackDocRef);

    if (programTrackDoc.exists()) {
      return {
        id: programTrackDoc.id,
        milestones: programTrackDoc.data().milestones,
        type: programTrackDoc.data().type,
        ...programTrackDoc.data(),
      };
    } else {
      return {
        id: '',
        milestones: [],
        type: '',
      };
    }
  } catch (err: any) {
    console.log(err);
  }
};

export const deleteProgramTrack = async (programTrackId: string) => {
  try {
    const response: any = await httpsCallable(functions, 'deleteProgramTrack')({ id: programTrackId });
    if (response.data.success) {
      toast.success('Program Track Deleted Successfully!');
    }
  } catch (error: any) {
    toast.error('Error Deleting Program Track!');
  }
};

export const addProgramTrack = async (programTrack: IProgramTrack, userRole?: string) => {
  try {
    if (!programTrack) {
      throw new Error();
    }
    const hasPermission = shouldShowButton('createProgramTrack', userRole);
    if (!hasPermission) {
      throw new Error('You do not have permission to create a Program Track');
    }
    const response: any = await httpsCallable(functions, 'createProgramTrack')(programTrack);

    if (response.data.success) {
      toast.success('New Program Track Created Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Creating Program Track!');
  }
};

export const pseudoAddProgramTrack = async () => {
  try {
    const programTrack = null;
    await httpsCallable(functions, 'createProgramTrack')(programTrack);
  } catch (err: any) {
    // err
  }
};

export const updateProgramTrackInfoById = async (programTrackId: string, payload: any) => {
  try {
    if (!programTrackId) {
      throw new Error();
    }

    const programTrackDocRef = doc(db, 'programTrack', programTrackId);

    const programTrackDoc = await getDoc(programTrackDocRef);

    if (programTrackDoc.exists()) {
      const data = { ...payload, id: programTrackId };
      const response: any = await httpsCallable(functions, 'editProgramTrack')(data);

      if (response.data.success) {
        toast.success('Program Track Updated Successfully!');
      }
    } else {
      toast.error('Error Updating Program Track!');
    }
  } catch (err: any) {
    toast.error('Error Updating Program Track!');
  }
};

export const getMultipleProgramTracksById = async (programTrackIds: string[]) => {
  try {
    if (!programTrackIds || programTrackIds.length === 0) {
      return [];
    }

    const ptRef = collection(db, 'programTrack');
    const querySnapshot = await getDocs(query(ptRef));
    const documents = querySnapshot.docs.filter((doc) => programTrackIds.includes(doc.id));

    const ptList: any = [];

    documents.forEach((doc) => {
      ptList.push({ id: doc.id, ...doc.data() });
    });

    return ptList;
  } catch (error: any) {
    console.error('Error fetching program tracks:', error);
    return [];
  }
};

export const searchProgramTracks = async (searchQuery: string, page: number, activeIds: number[], user: UserModel) => {
  try {
    if (!searchQuery) {
      throw new Error();
    }

    const response: any = await httpsCallable(functions, 'searchProgramTrackByName')({ query: searchQuery });

    const programTrackData = response.data.data;
    const programTrackIds: any = [];
    let programTrackList = [];

    if (programTrackData.length > 0) {
      for (const data of programTrackData) {
        programTrackIds.push(data.id);
      }

      const programTrackCollectionRef = collection(db, 'programTrack');

      if (user.role === UserRolesEnum.TRAINER) {
        const trainerQuery = query(programTrackCollectionRef, where('trainerId', '==', user.id));
        const querySnapshot = await getDocs(trainerQuery);
        const programTrackIdsByTrainer = querySnapshot.docs.map((doc) => doc.id);
        const commonIds = programTrackIdsByTrainer.filter((id) => programTrackIds.includes(id));
        programTrackList = await getMultipleProgramTracksById(commonIds);
      } else {
        programTrackList = await getMultipleProgramTracksById(programTrackIds);
      }

      if (programTrackList.length > 0) {
        const filteredPT = getFilteredProgramTracks(programTrackList, activeIds);
        const pageSize = 10;
        const offset = (page - 1) * pageSize;
        const totalPages = Math.ceil(filteredPT.length / pageSize);
        const paginatedProgramTracks = filteredPT.slice(offset, offset + pageSize);

        const result = {
          totalRecords: filteredPT.length,
          currentPage: page,
          programTracks: paginatedProgramTracks,
          totalPages: totalPages,
        };
        return result;
      } else {
        return {
          totalRecords: 0,
          currentPage: 1,
          programTracks: [],
          totalPages: 0,
        };
      }
    } else {
      return {
        totalRecords: 0,
        currentPage: 1,
        programTracks: [],
        totalPages: 0,
      };
    }
  } catch (error) {
    toast.error('Error Searching Program Tracks!');
  }
};

export const getProgramTracks = async () => {
  try {
    const programTrackCollectionRef = collection(db, 'programTrack');
    const snapshots = await getDocs(programTrackCollectionRef);
    const ptList: any[] = snapshots.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });

    return ptList;
  } catch (error) {
    toast.error('Error Fetching Program Tracks!');
    return [];
  }
};

export const getProgramTracksByTrainerId = async (trainerId: string) => {
  try {
    const ptRef = collection(db, 'programTrack');
    const q = query(ptRef, where('trainerId', '==', trainerId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const ptList: any = [];
      querySnapshot.forEach((doc) => {
        ptList.push({ id: doc.id, ...doc.data() });
      });
      return ptList;
    } else {
      return [];
    }
  } catch (error: any) {
    //nothing
  }
};

export const getPTsForFullAttendaceReport = async () => {
  try {
    const getDate = moment.tz('America/New_York');
    const currentDate = getDate.format('YYYY-MM-DD');
    const programTrackCollectionRef = collection(db, 'programTrack');
    const snapshots = await getDocs(programTrackCollectionRef);
    const ptList: any = [];
    snapshots.docs.forEach((doc) => {
      if (doc.data().type === programTrackTypes.ROLLING.value) {
        ptList.push({
          ...doc.data(),
          id: doc.id,
        });
      } else if (doc.data().type === programTrackTypes.COHORT.value) {
        const endDate = convertTimeStampToDate(doc.data().endTime).format('YYYY-MM-DD');
        if (endDate > currentDate) {
          ptList.push({
            ...doc.data(),
            id: doc.id,
          });
        } else {
          return;
        }
      }
    });
    return ptList;
  } catch (error) {
    throw new Error('Error Fetching Program Tracks!');
  }
};

export const getProgramTrackById = async (programTrackId: any) => {
  try {
    if (programTrackId) {
      const ptDocRef = doc(db, 'programTrack', programTrackId);
      const ptDoc = await getDoc(ptDocRef);

      if (ptDoc.exists()) {
        return { id: ptDoc.id, ...ptDoc.data() };
      }
    }
    return null;
  } catch (error: any) {
    return null;
  }
};

export const getCohortPTWithinRange = async (startDate: Dayjs, endDate: Dayjs, page: number) => {
  try {
    const ptRef = collection(db, 'programTrack');

    const q = query(ptRef, where('type', '==', 'cohort'), where('startTime', '>=', startDate.toDate()));

    const querySnapshot = await getDocs(q);

    const cohortPrograms = querySnapshot.docs
      .map((doc) => {
        const data = doc.data();
        const endTime = data.endTime;

        if (
          convertTimeStampToDate(endTime).tz('America/New_York').isBefore(endDate) ||
          convertTimeStampToDate(endTime).tz('America/New_York').isSame(endDate)
        ) {
          return {
            id: doc.id,
            dateRange: `${convertTimeStampToDate(data.startTime)
              .tz('America/New_York')
              .format('MM-DD-YYYY')} / ${convertTimeStampToDate(data.endTime)
              .tz('America/New_York')
              .format('MM-DD-YYYY')}`,
            ...doc.data(),
          };
        }
        return null;
      })
      .filter((doc): doc is { id: string; dateRange: string } => doc !== null);

    const pageSize = 10;
    const offset = (page - 1) * pageSize;
    const totalPages = Math.ceil(cohortPrograms.length / pageSize);
    const limitedProgramTracks = cohortPrograms.slice(offset, offset + pageSize);
    const result: IPTReport = {
      totalRecords: cohortPrograms.length,
      currentPage: page,
      programTracks: limitedProgramTracks,
      totalPages: totalPages,
    };

    return result;
  } catch (error) {
    console.error('Error Fetching Cohort Program Tracks:', error);
    throw new Error('Unable To Fetch Cohort Program Tracks');
  }
};

export const getRollingProgramTracks = async (page: number) => {
  try {
    const ptRef = collection(db, 'programTrack');

    const q = query(ptRef, where('type', '==', 'rolling'));

    const querySnapshot = await getDocs(q);

    const rollintPT = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const pageSize = 10;
    const offset = (page - 1) * pageSize;
    const totalPages = Math.ceil(rollintPT.length / pageSize);
    const limitedProgramTracks = rollintPT.slice(offset, offset + pageSize);
    const result: IPTReport = {
      totalRecords: rollintPT.length,
      currentPage: page,
      programTracks: limitedProgramTracks,
      totalPages: totalPages,
    };

    return result;
  } catch (error) {
    console.error('Error Fetching Rolling Program Tracks:', error);
    throw new Error('Unable To Fetch Rolling Program Tracks');
  }
};
