import { Box } from '@mui/material';
import React, { useState } from 'react';
import { DashboardSmallCard } from '../DashboardSmallCard/DashboardSmallCard';
import AddCareerPath from '../Modals/AddCareerPath/AddCareerPath';
import { SchoolOutlined } from '@mui/icons-material';

const CreateCareerPathWidget = () => {
  const [careerPathModal, setCareerPathModal] = useState(false);
  return (
    <>
      <Box style={{ cursor: 'pointer' }} onClick={() => setCareerPathModal(true)}>
        <DashboardSmallCard label='Create Career Path' desc='Add new Career Path' avatar={<SchoolOutlined />} />
      </Box>
      <AddCareerPath
        open={careerPathModal}
        mode={'CREATE'}
        onClose={() => setCareerPathModal(false)}
        onAddCareerPath={() => {}}
      />
    </>
  );
};

export default CreateCareerPathWidget;
