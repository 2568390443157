import React, { CSSProperties } from 'react';
// eslint-disable-next-line
import { Box, Typography, CircularProgress, CircularProgressProps } from '@mui/material';

export type LoaderProps = {
  loaderStyle?: CSSProperties;
  loaderBoxStyle?: CSSProperties;
  loaderColor?: CircularProgressProps['color'];
  text?: string;
  textColor?: string;
  textStyle?: CSSProperties;
};

export const Loader = (props: LoaderProps) => {
  const { loaderStyle, loaderBoxStyle, loaderColor, text, textColor, textStyle } = props;
  return (
    <Box sx={{ ...style.loader, ...loaderBoxStyle }}>
      <CircularProgress color={loaderColor ? loaderColor : 'primary'} sx={loaderStyle} />
      <Typography variant='button' color={textColor ? textColor : 'black'} textTransform={'none'} mt={2} sx={textStyle}>
        {text ? text : 'Loading...'}
      </Typography>
    </Box>
  );
};

const style = {
  loader: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    py: 5,
    my: 5,
  },
};
