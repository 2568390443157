import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { COLORS } from '../../utils/colors';

interface AttendanceTableProps {
  tableCols: any[];
  tableRows: any[];
}

const AttendanceTable = (props: AttendanceTableProps) => {
  const { tableCols, tableRows } = props;
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none', border: `1px solid ${COLORS.palette.borderColor}` }}>
      <Table sx={{ minWidth: 600 }} size='small'>
        <TableHead>
          <TableRow>
            {tableCols.map((col: any, index: any) => (
              <TableCell sx={{ fontWeight: 'bold' }} key={index}>
                {col.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row: any, index: any) => (
            <TableRow key={index}>
              {tableCols.map((col: any, index: any) => (
                <TableCell key={index}>{row[col.id]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AttendanceTable;
