import { Box, Typography } from '@mui/material';
// import { ClientMessaging } from '../../../components/ClientMessaging/ClientMessaging';
import SmsChatWithClients from '../../../components/SmsChatWithClients/SmsChatWithClients';

const Message = () => {
  return (
    <Box mt={2} pt={5}>
      <Box mb={5}>
        <Typography variant='h5' mb={2}>
          Text Messaging
        </Typography>
        <SmsChatWithClients isGeneralMessagingPage={true} />
      </Box>
      {/* <Typography variant='h5' mb={2}>
        In-App Messaging
      </Typography>
      <ClientMessaging /> */}
    </Box>
  );
};

export default Message;
