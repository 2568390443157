import { Box } from '@mui/material';
import React, { useState } from 'react';
import { DashboardSmallCard } from '../DashboardSmallCard/DashboardSmallCard';
import AddProspectiveClientModal from '../Modals/AddProspectiveClient/AddProspectiveClient';
import { ContactMailOutlined } from '@mui/icons-material';

const AddInviteProspectiveClientWidget = () => {
  const [prospectiveClientModal, setProspectiveClientModal] = useState(false);
  return (
    <>
      <Box style={{ cursor: 'pointer' }} onClick={() => setProspectiveClientModal(true)}>
        <DashboardSmallCard
          label='Invite Prospective Client'
          desc='Send email invite for Info Session.'
          avatar={<ContactMailOutlined />}
        />
      </Box>
      <AddProspectiveClientModal open={prospectiveClientModal} onClose={() => setProspectiveClientModal(false)} />
    </>
  );
};

export default AddInviteProspectiveClientWidget;
