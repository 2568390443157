import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Formik, Field } from 'formik';
import styled from '@emotion/styled';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../../../config/firebaseApp';
import { Box, Grid, TextField } from '@mui/material';
import { COLORS } from '../../../../utils/colors';
import { TERMS_TEXT } from './utils';
import { loginUser } from '../../../../utils/authUtils';
import { useAppDispatch } from '../../../../store/store';
import { useAuthStatus } from '../../../../hooks/useAuthStatus';
import { UserRolesEnum } from '../../../../utils/rolesEnum';
import { actionTypes } from '../../../../utils/actionEnum';
import { passwordStrength } from 'check-password-strength';
import { updateUsersNewAccountStatus } from '../../../../services/user.service';
import { LoadingButton } from '../../../../components/LoadingButton/LoadingButton';

const buttonStyle = {
  backgroundColor: COLORS.palette.sky,
  color: COLORS.palette.white,
  borderRadius: '10px',
  height: '50px',
  '&:hover': {
    backgroundColor: COLORS.palette.sky,
  },
  marginBottom: '20px',
  marginTop: '10px',
};

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.theme.primaryColor};
`;

const LoginContainer = styled.div`
  position: relative;
  padding: 0px 30px;
  width: 400px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0px 12px;

  @media (max-width: 480px) {
    margin-top: 50px;
  }
`;

const HeaderTitle = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-size: 26px;
  color: white;

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Logo = styled.img`
  height: 100px;
  width: 100px;
  @media (max-width: 480px) {
    height: 100px;
    width: 100px;
  }
`;

const TermsText = styled.a`
  display: block;
  margin-top: 24px;
  color: #2c80ff;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  text-align: center;
`;

const AgreementContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
`;

const TransparentBG = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background: #d8d8d8;
  opacity: 0.2;
  border-radius: 10px;
`;

const AgreementText = styled.p`
  color: #ffffff;
  padding: 10px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const CheckAgreement = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-family: 'Roboto', sans-serif;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const Label = styled.label`
  margin-bottom: 15px;
  color: white;
  font-weight: normal;
  margin-top: 16px;
  font-family: 'Roboto', sans-serif;
`;

const CreatePassword = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: white;
  margin-top: 30px;
`;

const ErrorText = styled.p`
  color: #d32f2f;
  display: block;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
`;

const styles = {
  gridItem: {
    '&&': {
      padding: '0px 0px 20px 0px',
    },
  },
  input: {
    padding: '10px',
    color: COLORS.palette.white,
    borderBottom: `1px solid ${COLORS.palette.white}`,
  },
};

interface LoginFormData {
  password: string;
  confirmPassword: string;
  acceptTerms: boolean | null;
}

function CreateAccount() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { loggedInRole, checkingStatus, userData } = useAuthStatus();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      if (checkingStatus) return;
      if (loggedInRole) {
        if (loggedInRole === UserRolesEnum.PROSPECTIVE_CLIENT) {
          navigate('/client/home');
        } else if (loggedInRole === UserRolesEnum.CLIENT) {
          navigate('/client/coming-soon');
        } else {
          navigate('/admin/dashboard');
        }
        await updateUsersNewAccountStatus(userData.id, loggedInRole);
      }
    })();
    // eslint-disable-next-line
  }, [isSubmitted]);
  const queryParams = new URLSearchParams(location.search);
  const initialValues: LoginFormData = { password: '', confirmPassword: '', acceptTerms: null };

  const isStaffMode = () => queryParams.get('action') === actionTypes.STAFF;
  const isClientMode = () => queryParams.get('action') === actionTypes.CLIENT;
  const isForgotPassMode = () => queryParams.get('action') === actionTypes.FORGOT;
  const getOobCode = () => queryParams.get('oobCode');

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .test(
        'password-strength',
        'Password should contain at least one number, one special character, and one uppercase letter',
        function (password) {
          const passwordStrengthResult = passwordStrength(password);
          if (passwordStrengthResult.id !== 3) {
            // eslint-disable-next-line
            const errMsg =
              'Password must be 10 character long and ' +
              'must contain at least one number, one special character, and one uppercase letter';
            const err = {
              message: errMsg,
              path: 'password',
            };
            return this.createError(err);
          }
          return true;
        }
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Password and Confirm password does not match')
      .required('Confirm password is required'),
    acceptTerms: isClientMode()
      ? yup.boolean().oneOf([true], 'You must agree to the terms and conditions')
      : yup.boolean().nullable().oneOf([true], 'You must agree to the terms and conditions'),
  });

  const handleResetPassword = async (auth: any, oobCode: any, newPassword: string) => {
    try {
      const email = await verifyPasswordResetCode(auth, oobCode);
      await confirmPasswordReset(auth, oobCode, newPassword);
      await loginUser(email, newPassword, dispatch);
    } catch (err: any) {
      toast.error(err?.message);
    }
  };

  const handleSave = async (values: LoginFormData) => {
    try {
      setIsLoading(true);
      const oobCode = getOobCode();
      await handleResetPassword(auth, oobCode, values.password);
      setIsSubmitted(true);
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <MainContainer>
      <LoginContainer>
        <Header>
          <Logo src='/assets/logo.png' />
          <Box>
            <HeaderTitle>
              <span style={{ fontWeight: '400' }}>{isForgotPassMode() ? 'Hi,' : 'Welcome'}</span>{' '}
            </HeaderTitle>
          </Box>
        </Header>
        {isClientMode() ? <TermsText href='/privacy-policy.html'>Terms of Service and Privacy Policy</TermsText> : null}
        {isClientMode() ? (
          <AgreementContainer>
            <TransparentBG />
            <AgreementText>{TERMS_TEXT}</AgreementText>
          </AgreementContainer>
        ) : null}
        <Formik initialValues={initialValues} onSubmit={handleSave} validationSchema={schema}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <>
              {isClientMode() ? (
                <CheckAgreement>
                  <Field
                    type='checkbox'
                    name='acceptTerms'
                    style={{ marginRight: '5px' }}
                    className={
                      'checkbox form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')
                    }
                  />
                  <Label htmlFor='acceptTerms' className='form-check-label'>
                    I accept the agreement.
                  </Label>
                </CheckAgreement>
              ) : null}
              {errors.acceptTerms && <ErrorText>{errors.acceptTerms}</ErrorText>}
              <CreatePassword>Create a password</CreatePassword>
              <Grid container sx={{ marginTop: '16px' }}>
                <Grid item xs={12} sx={styles.gridItem}>
                  <TextField
                    type='password'
                    id='component-simple'
                    placeholder='Enter password'
                    fullWidth
                    name='password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    variant='standard'
                    inputProps={{ style: styles.input }}
                  />
                </Grid>
                <Grid item xs={12} sx={styles.gridItem}>
                  <TextField
                    type='password'
                    id='component-simple'
                    placeholder='Confirm password'
                    fullWidth
                    name='confirmPassword'
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    variant='standard'
                    inputProps={{ style: styles.input }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <LoadingButton
                    onClick={() => {
                      handleSubmit();
                    }}
                    loading={isLoading}
                    label={isForgotPassMode() ? 'Change Password' : isStaffMode() ? 'Submit' : 'Create Account'}
                    type='submit'
                    styles={buttonStyle}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </LoginContainer>
    </MainContainer>
  );
}

export default CreateAccount;
