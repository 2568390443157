import React, { useEffect, useState } from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationList,
  Sidebar,
  ConversationHeader,
  Conversation,
  Avatar,
} from '@chatscope/chat-ui-kit-react';
import { getChannelIdByUserIds, getChatChannelsById, saveChatChannel } from '../../services/chatChanels.service';
import { useAppSelector } from '../../store/store';
import { UserModel } from '../../store/user/userModel';
import { convertTimeStampToDate } from '../../utils/dateTime';
import { getChatMessagesByChannelId, saveUserMessage, updateMessage } from '../../services/chatMessages.service';
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { AddUserListOnMessagingPage } from '../Modals/AddUserList/AddUserList';
import { getClientInfoById } from '../../services/client.service';
import { Add } from '@mui/icons-material';

export interface Channel {
  channelInfo: Object;
  senderInfo: Object;
}
export interface ClientMessagingProps {
  activeChannelDetails?: Channel;
  currentUserId?: string;
  clientId?: string;
  hideSidebar?: boolean;
}

export const ClientMessaging = (props: ClientMessagingProps) => {
  const { activeChannelDetails, currentUserId, clientId, hideSidebar } = props;
  const avatarIcon = 'https://cdn-icons-png.flaticon.com/128/3899/3899618.png';
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [loadingChannels, setLoadingChannels] = useState<boolean>(false);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  const [messageValue, setMessageValue] = useState('');
  const [channelsList, setChannelsList] = useState<any[]>([]);
  const [messagesList, setMessagesList] = useState<any[]>([]);
  const [unreadMessages, setUnreadMessages] = useState<any[]>([]);
  const [activeChannel, setActiveChannel] = useState<any>(activeChannelDetails || { channelInfo: {}, senderInfo: {} });
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarStyle, setSidebarStyle] = useState({});
  const [chatContainerStyle, setChatContainerStyle] = useState({});
  const [conversationContentStyle, setConversationContentStyle] = useState({});
  const [showUserListModal, setShowUserListModal] = useState(false);
  const [isClientList, setIsClientList] = useState(false);
  const [userData, setUserData] = useState<any>();

  const handleBackClick = () => setSidebarVisible(!sidebarVisible);

  const submitMessage = async () => {
    const message: string = messageValue;
    setMessageValue('');

    if (userData && message) {
      const newUserData = userData;
      const channelData: any = await saveChatChannel(user.id || '', userData.id);
      setUserData('');
      await saveUserMessage(user.id || '', channelData.data.id, message, activeChannel.channelInfo.userId || '');

      const activateChannel = {
        channelInfo: { id: channelData.data.id, userId: activeChannel.channelInfo.userId },
        senderInfo: newUserData,
      };
      setActiveChannel(activateChannel);
    } else {
      await saveUserMessage(
        user.id || '',
        activeChannel.channelInfo.id,
        message,
        activeChannel.channelInfo.userId || ''
      );
    }
  };

  const fetchChatMessages = () => {
    let list: any[] = [...unreadMessages];
    const unsubscribeFunctions = channelsList.map((item) => {
      return getChatMessagesByChannelId(item.channelInfo?.id, (data: any) => {
        const msgData = data
          ?.filter((i: any) => i.senderId !== user.id)
          .map((dt: any) => {
            return { channelInfo: item.channelInfo, messageInfo: dt, senderInfo: item.senderInfo };
          });

        if (msgData) {
          msgData.forEach((item: any) => {
            const msgIndex = list.findIndex((i: any) => i.messageInfo.id === item.messageInfo.id);

            if (msgIndex === -1 && item.messageInfo.isRead === false) {
              list.push(item);
            } else {
              if (msgIndex !== -1 && item.messageInfo.isRead === true) {
                list = list.filter((v: any) => v.messageInfo.id !== item.messageInfo.id);
              }
            }
          });
        }
        setUnreadMessages([...list]);
      });
    });

    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  };

  const getUnreadCount = (channelId: any) => {
    const msgs = unreadMessages.filter((i: any) => i.channelInfo.id === channelId);
    return msgs.length;
  };

  useEffect(() => {
    (async () => {
      try {
        const channelId = await getChannelIdByUserIds(clientId || '', user.id || '');
        const clientInfo = await getClientInfoById(clientId);
        const activateChannel = { channelInfo: { id: channelId }, senderInfo: clientInfo };
        setActiveChannel(activateChannel);
      } catch (error: any) {
        //console log here
      }
    })();
    // eslint-disable-next-line
  }, [clientId]);

  useEffect(() => {
    const unsubscribe = fetchChatMessages();

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [channelsList.length > 0]);

  useEffect(() => {
    if (hideSidebar) {
      return;
    }

    setLoadingChannels(true);
    const unsubscribe = getChatChannelsById(user.id || '', (data: any) => {
      try {
        const channels = data;
        if (clientId || activeChannel.channelInfo?.userId) {
          const userId = clientId || activeChannel.channelInfo?.userId;
          const filtered = data.filter((i: any) => i.channelInfo.userId === userId);
          setChannelsList(filtered);
        } else {
          setChannelsList(channels);
        }

        setLoadingChannels(false);
      } catch (error: any) {
        //console log here
      }
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoadingMessages(true);
    const unsubscribe = getChatMessagesByChannelId(activeChannel.channelInfo?.id, (data: any) => {
      setMessagesList(data);
      updateMessage(user?.id || '', activeChannel.channelInfo?.id);
      setLoadingMessages(false);
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [activeChannel]);

  useEffect(() => {
    if (sidebarVisible) {
      setSidebarStyle({
        display: 'flex',
        flexBasis: 'auto',
        width: '100%',
        maxWidth: '100%',
      });
      setConversationContentStyle({
        display: 'flex',
      });

      setChatContainerStyle({
        display: 'none',
      });
    } else {
      setSidebarStyle({});
      setConversationContentStyle({});
      setChatContainerStyle({});
    }
    // eslint-disable-next-line
  }, [sidebarVisible, setSidebarVisible, setConversationContentStyle, setSidebarStyle, setChatContainerStyle]);

  return (
    <div
      style={{
        height: '600px',
        position: 'relative',
      }}>
      <MainContainer responsive>
        {hideSidebar ? null : (
          <Sidebar position='left' scrollable={false} style={sidebarStyle} loading={loadingChannels}>
            <ConversationList>
              {channelsList.length > 0 ? (
                channelsList.map((item: any, index: any) => (
                  <Conversation
                    key={index}
                    active={item.channelInfo?.id === activeChannel.channelInfo?.id}
                    unreadCnt={getUnreadCount(item.channelInfo?.id)}
                    lastActivityTime={
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}>
                        {item.channelInfo?.lastMessageAt ? (
                          <>
                            <span style={{ color: 'teal' }}>
                              {convertTimeStampToDate(item.channelInfo?.lastMessageAt).format('MM/DD')}
                            </span>
                            <span style={{ color: 'teal' }}>
                              {convertTimeStampToDate(item.channelInfo?.lastMessageAt).format('hh:mm')}
                            </span>
                          </>
                        ) : null}
                      </span>
                    }
                    onClick={() => {
                      setActiveChannel(item);
                    }}>
                    <Avatar src={avatarIcon} />
                    <Conversation.Content
                      name={item?.senderInfo?.firstName + ' ' + item?.senderInfo?.lastName}
                      style={{ ...conversationContentStyle, width: '50%' }}
                      info={item.channelInfo?.lastMessage}
                    />
                  </Conversation>
                ))
              ) : loadingChannels ? null : (
                <Typography sx={{ mt: 2, display: 'flex', justifyContent: 'center' }} variant='body1'>
                  {'No active conversations!'}
                </Typography>
              )}
            </ConversationList>

            {/* Start chat menu */}
            <Divider />
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <List disablePadding>
                {currentUserId ? null : (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setShowUserListModal(true);
                        setIsClientList(true);
                      }}>
                      <ListItemIcon>
                        <Add />
                      </ListItemIcon>
                      <ListItemText primary='Start chat with a client' />
                    </ListItemButton>
                  </ListItem>
                )}
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setShowUserListModal(true);
                      setIsClientList(false);
                    }}>
                    <ListItemIcon>
                      <Add />
                    </ListItemIcon>
                    <ListItemText primary='Start chat with a staff' />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Sidebar>
        )}

        {activeChannel.channelInfo.id ? (
          <ChatContainer style={chatContainerStyle}>
            <ConversationHeader>
              <ConversationHeader.Back onClick={handleBackClick} />
              <ConversationHeader.Content
                userName={activeChannel?.senderInfo.firstName + ' ' + activeChannel?.senderInfo.lastName}
              />
            </ConversationHeader>
            <MessageList loading={loadingMessages}>
              {messagesList?.map((item: any, index: any) => (
                <Message
                  key={index}
                  model={{
                    message: item.text,
                    direction: item.senderId === user.id ? 'outgoing' : 'incoming',
                    position: 'single',
                  }}>
                  <Message.Footer sentTime={convertTimeStampToDate(item.timestamp).format('MMMM DD, hh:mm')} />
                </Message>
              ))}
            </MessageList>
            <MessageInput
              placeholder='Type message here'
              attachButton={false}
              onSend={() => {
                submitMessage();
              }}
              onChange={(val) => setMessageValue(val)}
              value={messageValue}
              onPaste={(evt) => {
                evt.preventDefault();
                setMessageValue(evt.clipboardData.getData('text'));
              }}
            />
          </ChatContainer>
        ) : (
          <ChatContainer style={chatContainerStyle}>
            <MessageList>
              <MessageList.Content
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                  textAlign: 'center',
                  fontSize: '1.2em',
                }}>
                No chat selected yet.
              </MessageList.Content>
            </MessageList>
          </ChatContainer>
        )}
      </MainContainer>
      <AddUserListOnMessagingPage
        open={showUserListModal}
        onClose={() => setShowUserListModal(false)}
        isClientList={isClientList}
        clientId={currentUserId}
        data={(data: any) => {
          const filteredChannel = channelsList.find((i: any) => i.senderInfo.id === data.id);

          if (filteredChannel) {
            setActiveChannel(filteredChannel);
          } else {
            //delete old value
            if (userData) {
              const index = channelsList.findIndex((i: any) => i.senderInfo.id === userData.id);
              if (index !== -1) {
                channelsList.splice(index, 1);
              }
            }
            //insert new value
            setUserData(data);
            const newChannel = { channelInfo: { id: 'xyz', userId: data.id }, senderInfo: data };
            setActiveChannel(newChannel);
            channelsList.push(newChannel);
          }
          setShowUserListModal(false);
        }}
      />
    </div>
  );
};
