import { Grid, Typography, Chip } from '@mui/material';

export const CreateColumnCell = (
  value: string,
  background?: string,
  color?: string,
  borderWidth?: number,
  borderColor?: string,
  textColor?: string
) => {
  return (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      <Chip
        size='small'
        sx={{
          background: background,
          color: color,
          borderStyle: 'solid',
          borderWidth: borderWidth || 0,
          borderColor: borderColor,
        }}
        label={
          <Typography
            variant='button'
            sx={{
              fontSize: '10px',
              textTransform: 'uppercase',
              lineHeight: 1.5,
              color: textColor,
            }}>
            {value}
          </Typography>
        }
      />
    </Grid>
  );
};
