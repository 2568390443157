import React, { useState } from 'react';
import { ClientStatus } from '../../../../utils/clientStatus';
import ProspectiveClientsInfo from '../prospectiveClientsInfo/ProspectiveClientsInfo';
import ClientInfo from '../clientInfo/clientInfo';
import { Box, IconButton, Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useLocation } from 'react-router-dom';

const localStyle = {
  IconStyles: {
    backgroundColor: 'primary.main',
    color: 'white',
    boxShadow: 3,
    '&:hover': {
      backgroundColor: 'primary.dark',
    },
    '&:disabled': {
      color: 'grey.500',
      backgroundColor: 'grey.200',
    },
    fontSize: 30,
  },
};

const WorkingClientList = () => {
  const { currentClientIndex, wcList } = useLocation().state;
  const [currentIndex, setCurrentIndex] = useState(currentClientIndex || 0);
  const [currentClientId, setCurrentClientId] = useState(wcList[currentIndex].id);

  const nextSlide = () => {
    setCurrentIndex((prevIndex: number) => {
      const newIndex = prevIndex + 1;
      setCurrentClientId(wcList[newIndex].id);
      return newIndex;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex: number) => {
      const newIndex = prevIndex - 1;
      setCurrentClientId(wcList[newIndex].id);
      return newIndex;
    });
  };

  const currentClient = wcList[currentIndex];

  return (
    <Box sx={{ mx: 'auto', textAlign: 'center' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center' mt={2}>
          <IconButton
            onClick={prevSlide}
            disabled={currentIndex === 0}
            sx={{
              ...localStyle.IconStyles,
              backgroundColor: currentIndex === 0 ? 'grey.300' : localStyle.IconStyles.backgroundColor,
            }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant='h5' fontWeight={500}>
            {'Working Clients'}
          </Typography>
          <IconButton
            onClick={nextSlide}
            disabled={currentIndex === wcList.length - 1}
            sx={{
              ...localStyle.IconStyles,
              backgroundColor: currentIndex === wcList.length - 1 ? 'grey.300' : localStyle.IconStyles.backgroundColor,
            }}>
            <ArrowForwardIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {currentClient.status === ClientStatus.PROSPECTIVE ? (
            <ProspectiveClientsInfo clientId={currentClientId} />
          ) : (
            <ClientInfo clientId={currentClientId} programTrackId={currentClient?.programTrackId || null} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default WorkingClientList;
