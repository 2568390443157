interface IReportOptions {
  id: number;
  title: string;
  value?: string;
}

export const ReportUtils: IReportOptions[] = [
  {
    id: 1,
    title: 'Case Load Report',
  },
  {
    id: 2,
    title: 'Full Client Profile Report',
  },
  {
    id: 3,
    title: 'Employment Pipeline Report',
  },
  {
    id: 4,
    title: 'Program Track Client Progress Report',
  },
  {
    id: 5,
    title: 'Full attendance report',
  },
  {
    id: 6,
    title: 'Training Complete',
  },
  {
    id: 7,
    title: 'New Job Orders/Employer Commitments',
  },
  {
    id: 8,
    title: 'Training Slots(Cohort)',
  },
  {
    id: 9,
    title: 'Training Slots(Rolling)',
  },
  {
    id: 10,
    title: 'Referred To Job',
  },
  {
    id: 11,
    title: 'Total Job Placements',
  },
  {
    id: 12,
    title: 'Job Starts (Job Type)',
  },
  {
    id: 13,
    title: 'Job Placement Offers',
  },
  {
    id: 14,
    title: 'New Employers',
  },
];

export const EmpPipeline: IReportOptions[] = [
  {
    id: 1,
    title: 'Monthly',
    value: 'month',
  },
  {
    id: 2,
    title: 'Quarter',
    value: 'quarter',
  },
];

export const caseLoadRoles: IReportOptions[] = [
  {
    id: 1,
    title: 'Navigators',
  },
  {
    id: 2,
    title: 'Employment Liaisons',
  },
];

export const reportBasedCount: { [key: number]: string } = {
  6: 'Total Clients',
  7: 'Total Job Opportunities',
  8: 'Total Program Track',
  9: 'Total Program Track',
  10: 'Total Clients',
  11: 'Total Clients',
  12: 'Total Clients',
  14: 'Total Employers',
};
