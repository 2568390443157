export const adminTypes = [
  { id: 1, value: 'HRA', label: 'HRA' },
  { id: 2, value: 'navigator', label: 'Navigator' },
  { id: 3, value: 'employment', label: 'Employment' },
];

export const adminTypeEnum = {
  HRA: 'HRA',
  NAVIGATOR: 'navigator',
  EMPLOYMENT: 'employment',
};
