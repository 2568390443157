import { ProgramTracksModel, ProgramTracksReduxModel } from './programTracksModel';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: ProgramTracksReduxModel = {
  programTracks: [],
  loading: true,
};

const programTracksSlice = createSlice({
  name: 'programTracks',
  initialState: initialState,
  reducers: {
    setProgramTracks(state, action: PayloadAction<ProgramTracksModel[]>) {
      state.programTracks = action.payload;
      state.loading = false;
    },
    resetProgramTracks() {
      return structuredClone(initialState);
    },
  },
});

export default programTracksSlice;
