import styled from '@emotion/styled';
import { CheckBoxOutlined, Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  completeProgramTrackForClients,
  getClientsByCurrentMilestoneIndex,
  updateClientMapsByClientIds,
} from '../../../services/programTrackClientMap.service';
import SplitButton from '../../SplitButton/SplitButton';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import { AttendanceButtons } from '../../../utils/attendance';
import { ClientMapStatus } from '../../../utils/clientMapStatus';
import DropoutReasonModal from '../DropoutReasonModal/DropoutReasonModal';

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const localStyle = {
  centeredStyle: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    borderRadius: '14px',
    width: '600px',
    height: '80%',
    '@media (max-width: 480px)': {
      padding: '20px',
      margin: '10px',
    },
  },
  titleGrid: {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  fieldArray: {
    position: 'relative',
    top: '20px',
    left: '17px',
    width: '515px',
  },
  loadingButtonGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
};

interface IBulkAttendanceModal {
  open: boolean;
  onClose: Function;
  programTrackInfo: any;
  refresh: Function;
}

const BulkAttendanceModal = (props: IBulkAttendanceModal) => {
  const { open, onClose, programTrackInfo, refresh } = props;
  const milestones = programTrackInfo?.milestones;

  const [selectedMilestone, setSelectedMilestone] = useState<any>();
  const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState<number>(-1);
  const [clientList, setClientList] = useState([]);
  const [selectedButtonId, setSelectedButtonId] = useState(0);
  const [showDropoutReasonModal, setShowDropoutReasonModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientLoader, setClientLoader] = useState(false);

  const handleMilestoneChange = (event: any, newValue: any) => {
    setSelectedMilestone(newValue);
    const index = milestones.indexOf(newValue);
    setSelectedMilestoneIndex(index);
  };

  const getClientsByMilestone = async () => {
    if (selectedMilestoneIndex >= 0) {
      setClientLoader(true);
      const list = await getClientsByCurrentMilestoneIndex(programTrackInfo.id, selectedMilestoneIndex.toString());
      setClientList(list);
      setClientLoader(false);
    }
  };

  const [selectedClients, setSelectedClients] = useState<any>([]);

  const handleClientSelect = (clientId: string) => {
    if (selectedClients.includes(clientId)) {
      setSelectedClients(selectedClients.filter((id: string) => id !== clientId));
    } else {
      setSelectedClients([...selectedClients, clientId]);
    }
  };

  const markAsComplete = async () => {
    setLoading(true);
    await completeProgramTrackForClients(selectedClients, String(selectedMilestoneIndex + 1), programTrackInfo.id);
    setLoading(false);
    handleClose();
    refresh();
  };

  const markAsPresent = async (status: string) => {
    setLoading(true);
    await updateClientMapsByClientIds(
      selectedClients,
      status,
      String(selectedMilestoneIndex + 1),
      programTrackInfo.id,
      'present'
    );
    setLoading(false);
    handleClose();
    refresh();
  };

  const markAsAbsent = async (status: string) => {
    setLoading(true);
    await updateClientMapsByClientIds(
      selectedClients,
      status,
      String(selectedMilestoneIndex + 1),
      programTrackInfo.id,
      'absent'
    );
    setLoading(false);
    handleClose();
    refresh();
  };

  const handleAttendance = () => {
    if (selectedButtonId === 1) {
      setShowDropoutReasonModal(true);
    } else if (selectedButtonId === 2) {
      markAsComplete();
    } else if (selectedButtonId === 3) {
      let status;
      if (milestones.length - 1 === selectedMilestoneIndex) {
        status = ClientMapStatus.PRE_COMPLETE;
      } else {
        status = ClientMapStatus.ACTIVE;
      }

      markAsPresent(status);
    } else if (selectedButtonId === 4) {
      let status;
      if (milestones.length - 1 === selectedMilestoneIndex) {
        status = ClientMapStatus.PRE_COMPLETE;
      } else {
        status = ClientMapStatus.ACTIVE;
      }
      markAsAbsent(status);
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedMilestoneIndex(-1);
    setSelectedButtonId(0);
    setSelectedMilestone(null);
    setClientList([]);
  };

  useEffect(() => {
    setClientList([]);
    setSelectedClients([]);
    getClientsByMilestone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMilestoneIndex]);

  return (
    <>
      <ModalWrapper open={open} onClose={() => handleClose()}>
        <Box sx={localStyle.centeredStyle}>
          <Grid container sx={localStyle.titleGrid}>
            <Grid item>
              <Typography variant='h6'>Attendance Sheet</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleClose()}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={localStyle.fieldArray}>
            <Grid item xs={12}>
              <Autocomplete
                size='small'
                disableClearable
                value={selectedMilestone}
                onChange={handleMilestoneChange}
                options={milestones}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label='Select Milestone' />}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ mt: 4 }}>
                <Divider>
                  <Chip label='Client List' sx={{ fontWeight: 'bold' }} />
                </Divider>
              </Box>
            </Grid>
            {clientLoader ? (
              <Grid item xs={12} ml={28}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item xs={12}>
                {clientList.length === 0 ? (
                  <Typography variant='body2' sx={{ textAlign: 'center' }}>
                    No Client Available
                  </Typography>
                ) : (
                  <List>
                    {clientList.map((client: any) => (
                      <ListItem key={client.id} disablePadding>
                        <ListItemText primary={client.firstName + ' ' + client.lastName} />
                        <Checkbox
                          checked={selectedClients.includes(client.id)}
                          onChange={() => handleClientSelect(client.id)}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={1}
            display={clientList?.length > 0 ? 'flex' : 'none'}
            justifyContent={'space-between'}>
            <Grid item xs={12}>
              <Box sx={{ mt: 14 }}>
                <Divider>
                  <Chip label='Actions' sx={{ fontWeight: 'bold' }} />
                </Divider>
              </Box>
            </Grid>
            <Grid item>
              <SplitButton
                loading={loading}
                options={AttendanceButtons}
                onMenuItemClick={(index: number) => {
                  setSelectedButtonId(index);
                }}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                size='small'
                color={'error'}
                variant={'contained'}
                loading={loading}
                startIcon={<CheckBoxOutlined />}
                disabled={selectedButtonId > 0 && selectedClients.length > 0 ? false : true}
                onClick={handleAttendance}
                typographyComp={
                  <Typography variant='body2' textTransform={'none'} noWrap>
                    Confirm
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Box>
      </ModalWrapper>
      <DropoutReasonModal
        open={showDropoutReasonModal}
        onClose={() => setShowDropoutReasonModal(!showDropoutReasonModal)}
        programTrackId={programTrackInfo?.id}
        milestoneStep={String(selectedMilestoneIndex + 1)}
        clientList={selectedClients}
        onSubmit={() => {
          handleClose();
          refresh();
        }}
      />
    </>
  );
};

export default BulkAttendanceModal;
