import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { getCareerPathList } from '../../../services/careerPath.service';
import { ICurriculumData, getCurriculumsByCareerPathId } from '../../../services/curriculum.service';
import { Field, Formik, type FormikHelpers } from 'formik';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { COLORS } from '../../../utils/colors';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import * as yup from 'yup';
import { useAppSelector } from '../../../store/store';
import { UserModel } from '../../../store/user/userModel';
import { IProgramTrack, addProgramTrack, updateProgramTrackInfoById } from '../../../services/programTrack.service';
import { getUserInfoById } from '../../../services/user.service';
import { programTrackTimes, programTrackTypes } from '../../../utils/programTrackEnums';
import styled from '@emotion/styled';
import { getMilestonesArray, convertMilestonesDeadlineToDays } from '../../../utils/milestones';
import { toast } from 'react-toastify';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Loader } from '../../Loader/Loader';
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = 'America/New_York';
dayjs.tz.setDefault(tz);

const localStyle = {
  centeredStyle: {
    width: '900px',
    height: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    borderRadius: '14px',
    '@media (max-width: 480px)': {
      padding: '20px',
      margin: '10px',
    },
  },
  formLabel: {
    color: COLORS.palette.black,
    marginBottom: '5px',
    '& .MuiInputLabel-asterisk': {
      color: 'red',
    },
  },
};

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const schema = yup.object().shape({
  name: yup.string().required('Title is required'),
  location: yup.string().required('Location is required'),
  description: yup.string().required('Description is required'),
  requirements: yup.string().required('Client Requirements is required'),
  time: yup.string().required('Time is required'),
  type: yup.string().required('Type is required'),
  capacity: yup.number().required('Student Capacity is required').integer('Student Capacity must be an integer'),
  startTime: yup.date().when('type', {
    is: programTrackTypes.COHORT.value,
    then: () => yup.date().required('Start time is required'),
    otherwise: () => yup.date().nullable(),
  }),
});

interface IselectedData {
  id: any;
  label?: any;
}
interface IFormValues {
  name: string;
  location: string;
  description: string;
  trainer?: string;
  time: string;
  type: string;
  startTime?: Date | null;
  endTime?: Date | null;
  careerPathName?: string;
  curriculumName?: string;
  trainerName?: string;
  trainerId?: string;
  curriculumId?: string;
  careerPathId?: string;
  selectedCareerPath?: IselectedData;
  selectedTrainer?: IselectedData;
  selectedCurriculum?: IselectedData;
  id?: string;
  milestones?: any;
  capacity: number | null;
  requirements: string | null;
}

const AddProgramTrack = (props: any) => {
  const { open, onClose } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [careerPaths, setCareerPaths] = useState([]);
  const [curriculums, setCurriculums] = useState([]);
  const [selectedCareerPath, setSelectedCareerPath] = useState({ id: '', label: '' });
  const [selectedCurriculum, setSelectedCurriculum] = useState<ICurriculumData | null>(null);
  const [trainerName, setTrainerName] = useState<string>('');
  const [selectedMilestones, setSelectedMilestones] = useState([]);
  const [maxMilestoneDays, setMaxMilestoneDays] = useState(0);
  const [endDate, setEndDate] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [editLoading, setEditLoading] = useState(false);
  const initialValues: IFormValues = {
    name: '',
    careerPathName: '',
    curriculumName: '',
    trainerName: '',
    description: '',
    requirements: '',
    time: '',
    type: '',
    startTime: null,
    endTime: null,
    location: '',
    milestones: [],
    capacity: null,
  };

  //initial loader render function
  const showLoader = () => {
    return <Loader />;
  };

  const getInitialValuesOnEdit: any = () => {
    return {
      name: props.data?.name || '',
      description: props.data?.description || '',
      requirements: props.data?.requirements || '',
      time: props.data?.time || '',
      type: props.data?.type || '',
      startTime: props.data?.startTime,
      endTime: props.data?.endTime,
      location: props.data?.location || '',
      capacity: props.data?.capacity || null,
      selectedCareerPath: {
        id: props.data?.careerPathId || '',
        label: props.data?.careerPathName || '',
      },
      selectedCurriculum: {
        id: props.data?.curriculumId || '',
        name: props.data?.curriculumName || '',
      },
      selectedTrainer: {
        id: props.data?.trainerId || '',
        label: props.data?.trainerName || '',
      },
      selectedMilestones: props.data?.milestones || [],
    };
  };
  const user: UserModel = useAppSelector((state: any) => state.user.user);

  useEffect(() => {
    setLoading(true);
    const selectedCPath = { id: props.data?.careerPathId || '', label: props.data?.careerPathName || '' };
    setSelectedCareerPath(selectedCPath);
    const selectedCurriculum = {
      id: props.data?.curriculumId || '',
      name: props.data?.curriculumName || '',
      careerPathId: '',
      trainerId: props.data?.trainerId || '',
      createdBy: '',
      description: '',
      milestones: [],
    };
    setSelectedCurriculum(selectedCurriculum);
    const selectedTrainer = props.data?.trainerName || '';
    setTrainerName(selectedTrainer);
    const selectedMilestones =
      props.data?.type === programTrackTypes.ROLLING.value
        ? props.data?.milestones || []
        : convertMilestonesDeadlineToDays(props.data?.startTime, props.data?.milestones || []);
    setSelectedMilestones(selectedMilestones);
    const startDate = dayjs(props.data?.startTime || null).toDate();
    setStartDate(startDate);
    setLoading(false);
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    const fetchCareerPaths = async () => {
      const careerPathsList = await getCareerPathList();
      setCareerPaths(careerPathsList);
    };

    fetchCareerPaths();
  }, []);

  useEffect(() => {
    const fetchCurriculums = async () => {
      if (selectedCareerPath.id) {
        const curriculumList = await getCurriculumsByCareerPathId(selectedCareerPath.id);
        setCurriculums(curriculumList);
      }
    };
    fetchCurriculums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCareerPath]);

  useEffect(
    () => {
      let maxDays = 0;
      selectedMilestones.forEach((milestone: any) => {
        if (parseInt(milestone.days) > maxDays) {
          maxDays = milestone.days;
        }
      });
      setMaxMilestoneDays(maxDays);
      const endDate = dayjs(startDate).add(maxMilestoneDays, 'days').toDate();
      setEndDate(endDate);
    },
    // eslint-disable-next-line
    [selectedMilestones, startDate, maxMilestoneDays]
  );

  const handleSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
    try {
      const params = {
        careerPathId: selectedCareerPath.id,
        curriculumId: selectedCurriculum?.id,
        curriculumName: selectedCurriculum?.name,
        time: values.time,
        type: values.type,
        name: values.name,
        location: values.location,
        description: values.description,
        capacity: values.capacity,
        createdBy: user.id,
        trainerId: selectedCurriculum?.trainerId,
        enrolled: 0,
        requirements: values.requirements,
      };

      let payload = {};

      if (values.type === programTrackTypes.COHORT.value) {
        const milestones = selectedMilestones.sort((a: any, b: any) => a.days - b.days) || [];
        payload = {
          startTime: dayjs(startDate).toDate(),
          endTime: dayjs(endDate).toDate(),
          milestones: getMilestonesArray(new Date(startDate), milestones),
          ...params,
        };
      } else if (values.type === programTrackTypes.ROLLING.value) {
        payload = {
          milestones: selectedMilestones.sort((a: any, b: any) => a.days - b.days),
          ...params,
        };
      }

      await addProgramTrack(payload as IProgramTrack, user?.role);
      setSubmitting(false);
      handleClose();
      props.onAddProgramTrack();
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const handleEdit = async (values: IFormValues) => {
    const params = {
      careerPathId: selectedCareerPath.id,
      curriculumId: selectedCurriculum?.id,
      curriculumName: selectedCurriculum?.name,
      time: values.time,
      type: values.type,
      name: values.name,
      location: values.location,
      description: values.description,
      requirements: values.requirements,
      capacity: values.capacity,
      trainerId: selectedCurriculum?.trainerId,
      enrolled: props.data?.enrolled,
    };
    let payload = {};
    if (values.type === programTrackTypes.COHORT.value) {
      const milestones = selectedMilestones.sort((a: any, b: any) => a.days - b.days) || [];
      payload = {
        startTime: dayjs(startDate).toDate(),
        endTime: dayjs(endDate).toDate(),
        milestones: getMilestonesArray(new Date(startDate), milestones),
        ...params,
      };
    } else if (values.type === programTrackTypes.ROLLING.value) {
      payload = {
        milestones: selectedMilestones.sort((a: any, b: any) => a.days - b.days),
        ...params,
      };
    }

    try {
      setEditLoading(true);
      await updateProgramTrackInfoById(props.data?.id || '', payload);
      setEditLoading(false);
      handleClose();
      props.onAddProgramTrack();
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message);
    }
    props.onClose();
  };

  const handleClose = () => {
    setSelectedCareerPath({ id: '', label: '' });
    setSelectedCurriculum({} as ICurriculumData);
    setTrainerName('');
    setSelectedMilestones([]);
    setMaxMilestoneDays(0);
    setEndDate(null);
    setStartDate(null);
    onClose();
  };

  const disableSubmit = () => {
    if (!selectedCareerPath.id || !selectedCurriculum?.id) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalWrapper open={open} onClose={handleClose}>
      <Box sx={localStyle.centeredStyle}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Typography variant='h5' sx={{ fontWeight: 'bold' }} id='modal-title'>
            {props.mode === 'CREATE' ? 'Create Program Track' : 'Edit Program Track'}
          </Typography>
          <IconButton onClick={handleClose}>
            <GridCloseIcon />
          </IconButton>
        </Grid>
        {loading ? (
          showLoader()
        ) : (
          <Formik
            initialValues={props.data ? getInitialValuesOnEdit() : initialValues}
            onSubmit={props.mode === 'CREATE' ? handleSubmit : handleEdit}
            validationSchema={schema}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => (
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    disableClearable
                    value={selectedCareerPath}
                    options={careerPaths.map((item: any) => ({
                      label: item.name,
                      id: item.id,
                    }))}
                    getOptionLabel={(option: any) => option.label}
                    onChange={(event, object: any) => {
                      setSelectedCareerPath(object);
                      setSelectedCurriculum(null);
                      setTrainerName('');
                      setSelectedMilestones([]);
                    }}
                    renderInput={(params) => <TextField {...params} label='Select Career Path' />}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    disableClearable
                    value={selectedCurriculum || undefined}
                    disabled={selectedCareerPath.id ? false : true}
                    onChange={async (event, object: any) => {
                      setSelectedCurriculum(object);
                      setSelectedMilestones(object ? object.milestones : []);
                      if (object) {
                        const trainerInfo: any = await getUserInfoById(object.trainerId);
                        const trainerName = trainerInfo ? trainerInfo.firstName + ' ' + trainerInfo.lastName : '';
                        setTrainerName(trainerName);
                      } else {
                        setTrainerName('');
                      }
                    }}
                    options={curriculums}
                    getOptionLabel={(option: any) => {
                      return option.name;
                    }}
                    renderInput={(params) => <TextField {...params} label='Select Curriculum' />}
                    isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name='trainer'
                    type='text'
                    disabled
                    label='Trainer Name'
                    value={trainerName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.trainer && Boolean(errors.trainer)}
                    helperText={touched.trainer && errors.trainer}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name='name'
                    type='text'
                    label='Program Track Name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name='location'
                    type='text'
                    label='Location'
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.location && Boolean(errors.location)}
                    helperText={touched.location && errors.location}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name='capacity'
                    type='number'
                    label='Student Capacity'
                    value={values.capacity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.capacity && Boolean(errors.capacity)}
                    helperText={touched.capacity && errors.capacity}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      name='type'
                      value={values.type}
                      label='Type'
                      select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={props.mode === 'CREATE' ? false : true}
                      error={touched.type && Boolean(errors.type)}>
                      <MenuItem value={'cohort'}>{programTrackTypes.COHORT.label}</MenuItem>
                      <MenuItem value={'rolling'}>{programTrackTypes.ROLLING.label}</MenuItem>
                    </TextField>
                  </FormControl>
                  {touched.type && Boolean(errors.type) ? (
                    <p style={{ color: COLORS.result.error }}>{errors.type}</p>
                  ) : null}
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      name='time'
                      value={values.time}
                      label='Time'
                      select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.time && Boolean(errors.time)}>
                      <MenuItem value={'full_time'}>{programTrackTimes.FULL_TIME}</MenuItem>
                      <MenuItem value={'part_time'}>{programTrackTimes.PART_TIME}</MenuItem>
                    </TextField>
                  </FormControl>
                  {touched.time && Boolean(errors.time) ? (
                    <p style={{ color: COLORS.result.error }}>{errors.time}</p>
                  ) : null}
                </Grid>
                {values.type === programTrackTypes.COHORT.value ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <Field
                        name='startTime'
                        label='Start Date'
                        as={TextField}
                        component={DatePicker}
                        disabled={values.type === programTrackTypes.ROLLING.value || !values.type ? true : false}
                        minDate={dayjs()}
                        inputFormat='MM/DD/YYYY'
                        value={dayjs(values.startTime)}
                        onChange={(date: string | number | Date | dayjs.Dayjs | null | undefined) => {
                          const formattedDate = dayjs(date, 'MM/DD/YYYY').toDate();
                          const endDate = dayjs(formattedDate).add(maxMilestoneDays, 'days').toDate();
                          setStartDate(formattedDate);
                          setEndDate(endDate);
                          setFieldValue('startTime', formattedDate);
                        }}
                        error={touched.startTime && Boolean(errors.startTime)}
                        sx={{ width: '100%' }}
                      />
                      {touched.startTime && Boolean(errors.startTime) ? (
                        <p style={{ color: COLORS.result.error }}>{errors.startTime}</p>
                      ) : null}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name='endTime'
                        component={DatePicker}
                        label='End Date'
                        as={TextField}
                        disabled
                        inputFormat='MM/DD/YYYY'
                        value={dayjs(endDate)}
                        onChange={() => {
                          setFieldValue('endTime', endDate);
                        }}
                        error={touched.endTime && Boolean(errors.endTime)}
                        sx={{ width: '100%' }}
                      />
                      {touched.endTime && Boolean(errors.endTime) ? (
                        <p style={{ color: COLORS.result.error }}>{errors.endTime}</p>
                      ) : null}
                    </Grid>
                  </>
                ) : null}
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name='description'
                    type='text'
                    label='Description'
                    value={values.description}
                    onChange={handleChange}
                    rows={4}
                    multiline
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name='requirements'
                    type='text'
                    label='Client Requirements'
                    value={values.requirements}
                    onChange={handleChange}
                    rows={4}
                    multiline
                    onBlur={handleBlur}
                    error={touched.requirements && Boolean(errors.requirements)}
                    helperText={touched.requirements && errors.requirements}
                  />
                </Grid>
                {selectedMilestones.length > 0 ? (
                  <Grid item md={6} xs={12}>
                    <InputLabel sx={localStyle.formLabel}>Milestones</InputLabel>
                    {selectedMilestones.map((milestone: any, index) => (
                      <Grid container spacing={1} key={index} sx={{ marginTop: index > 0 ? '10px' : 0 }}>
                        <Grid item xs={12}>
                          <TextField
                            name={`milestones[${index}].name`}
                            fullWidth
                            disabled
                            label='Milestone Name'
                            value={milestone.name}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            name={`milestones[${index}].type`}
                            label='Milestone Type'
                            value={milestone.type}
                            onChange={handleChange}
                            disabled
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            name={`milestones[${index}].days`}
                            label='Day'
                            type='number'
                            fullWidth
                            value={milestone.days}
                            onChange={(event) => {
                              const newValue = event.target.value;
                              const updatedMilestones: any = selectedMilestones.map((milestoneObj: any, idx) => {
                                if (idx === index) {
                                  return { ...milestoneObj, days: newValue };
                                }
                                return milestoneObj;
                              });
                              setSelectedMilestones(updatedMilestones);
                              setFieldValue(`milestones[${index}].days`, newValue);
                            }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : null}
                <Grid
                  item
                  xs={12}
                  lg={12}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}>
                  <LoadingButton
                    onClick={() => {
                      if (props.mode === 'CREATE') {
                        handleSubmit();
                      } else {
                        handleEdit(values);
                      }
                    }}
                    label={props.mode === 'CREATE' ? 'Create' : 'Update'}
                    styles={{ width: '400px' }}
                    loading={isSubmitting || editLoading}
                    disabled={!isValid || isSubmitting || disableSubmit() || editLoading}
                    size='large'
                    type='submit'
                    variant='contained'
                  />
                </Grid>
              </Grid>
            )}
          </Formik>
        )}
      </Box>
    </ModalWrapper>
  );
};

export default AddProgramTrack;
