import React from 'react';
import { NavLink } from 'react-router-dom';
import { DashboardSmallCard } from '../DashboardSmallCard/DashboardSmallCard';
import { EventNoteOutlined } from '@mui/icons-material';

const ViewWorkshopsButton = () => {
  return (
    <NavLink to={'/admin/workshops'} style={{ textDecoration: 'none' }}>
      <DashboardSmallCard
        label='Workshops Schedule'
        desc='View All Scheduled Workshops'
        avatar={<EventNoteOutlined />}
      />
    </NavLink>
  );
};

export default ViewWorkshopsButton;
