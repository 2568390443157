export const UserRolesEnum = {
  ADMIN: 'admin',
  NAVIGATOR: 'navigator',
  TRAINER: 'trainer',
  CASE_MANAGER: 'caseManager',
  SUPER_ADMIN: 'superAdmin',
  EMPLOYER: 'employer',
  EMPLOYMENT_LIAISON: 'employmentLiaison',
  PROSPECTIVE_CLIENT: 'prospectiveClient',
  CLIENT: 'client',
};
