import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { convertTimeStampToDate } from '../../utils/dateTime';
import { CancelOutlined, Check } from '@mui/icons-material';
import { programTrackTypes } from '../../utils/programTrackEnums';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import DropoutReasonModal from '../Modals/DropoutReasonModal/DropoutReasonModal';
import { findMaxMilestoneFromClient } from '../../utils/milestones';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';
import { ClientStatus } from '../../utils/clientStatus';
import { ClientMapStatus } from '../../utils/clientMapStatus';
import ClientCompletedProgramTracks from '../ClientCompletedProgramTracks/ClientCompletedProgramTracks';

export type ClientProgramTrackProps = {
  client?: any;
  milestones: any[];
  completedMilestones: any[];
  clientPTCMStatus?: string;
  durationInDays?: number;
  careerPathInfo: any;
  programTrackName: string;
  programTrackType: string;
  programTrackId?: string;
  refreshData?: Function;
};

export const ClientProgramTrack = (props: ClientProgramTrackProps) => {
  const {
    client,
    milestones,
    completedMilestones,
    durationInDays,
    careerPathInfo,
    programTrackName,
    programTrackType,
    programTrackId,
    refreshData,
    clientPTCMStatus,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [showDropoutReasonModal, setShowDropoutReasonModal] = useState<boolean>(false);
  const user: UserModel = useAppSelector((state: any) => state.user.user);

  const getMilestoneCompletionDate = (index: number) => {
    const milestone = completedMilestones?.find((cm: any) => parseInt(cm.index) === index);
    if (milestone) {
      return convertTimeStampToDate(milestone.completionDate).format('MM/DD/YYYY');
    }
    return null;
  };

  const percentageCompleted =
    completedMilestones?.length > 0 && milestones?.length > 0
      ? ((completedMilestones?.length / milestones?.length) * 100).toFixed(2)
      : 0;

  return (
    <Box>
      <Box sx={{ width: '100%' }}>
        <Box mb={1} sx={styles.programTrackBox}>
          <Box>
            <Typography variant='h6'>
              [{careerPathInfo?.code}] {careerPathInfo?.name}
            </Typography>
            <Typography variant='body1'>{programTrackName}</Typography>
          </Box>
          {/* Program Track Progress Bar */}
          <Box sx={styles.progressBox}>
            <Typography variant='button' textTransform={'none'}>
              {percentageCompleted}% Complete
            </Typography>
            {programTrackType === programTrackTypes.COHORT.value ? <></> : null}
            <Typography variant='caption'>{durationInDays} Days Total</Typography>
            {user?.role &&
            client?.status === ClientStatus.ENROLLED &&
            clientPTCMStatus !== ClientMapStatus.PRE_COMPLETE ? (
              <Box pt={2}>
                <LoadingButton
                  type='button'
                  size='small'
                  variant='contained'
                  color='error'
                  loading={loading}
                  onClick={async () => {
                    setShowDropoutReasonModal(!showDropoutReasonModal);
                  }}
                  startIcon={<CancelOutlined />}
                  typographyComp={
                    <Typography variant='body2' textTransform={'none'} noWrap>
                      Dropout
                    </Typography>
                  }
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>

      {/* Program Track Timeline */}
      {milestones?.length > 0 ? (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}>
          {/* Completed Milestones */}
          {milestones?.map((milestone: any, index: any) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot
                  color={completedMilestones?.find((cm: any) => parseInt(cm.index) === index) ? 'primary' : 'grey'}>
                  <Check sx={{ fontSize: '16px' }} />
                </TimelineDot>
                {index !== milestones?.length - 1 ? <TimelineConnector /> : null}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant='button'>{milestone?.name}</Typography>
                <Typography variant='body2'>{getMilestoneCompletionDate(index)}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      ) : (
        <Typography variant='body2'>No milestones completed</Typography>
      )}

      {/* Dropout Reason Modal */}
      {showDropoutReasonModal ? (
        <DropoutReasonModal
          open={showDropoutReasonModal}
          onClose={() => setShowDropoutReasonModal(!showDropoutReasonModal)}
          programTrackId={programTrackId || ''}
          milestoneStep={
            client.currentMilestoneIndex ? String(parseInt(findMaxMilestoneFromClient([client])) + 1) : '1'
          }
          clientList={[client.id]}
          onSubmit={() => {
            setLoading(false);
            setTimeout(() => {
              refreshData ? refreshData() : null;
            }, 2000);
          }}
        />
      ) : null}
      {user?.role && (
        <Box sx={{ mt: 10 }}>
          <ClientCompletedProgramTracks clientId={client.id} />
        </Box>
      )}
    </Box>
  );
};

const styles = {
  progressBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: { lg: 'flex-end' },
  },
  programTrackBox: {
    display: { lg: 'flex' },
    direction: { lg: 'row' },
    justifyContent: { lg: 'space-between' },
    alignItems: { lg: 'center' },
  },
  styleTimeline: {
    justifyContent: 'left',
    alignItems: 'left',
    display: 'flex',
  },
};
