import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { convertTimeStampToDate } from '../../utils/dateTime';
import { COLORS } from '../../utils/colors';

interface ClientAttendanceSheetProps {
  clientInfo: any;
}

const ClientAttendanceSheet = (props: ClientAttendanceSheetProps) => {
  const { clientInfo } = props;
  const milestones: any[] = clientInfo?.milestones;

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none', border: `1px solid ${COLORS.palette.borderColor}` }}>
      <Table sx={{ minWidth: 600 }} size='small'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Milestone Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>End At</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Completed At</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientInfo?.completedMilestones.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>No information.</TableCell>
            </TableRow>
          ) : (
            clientInfo.completedMilestones.map((row: any, index: any) => (
              <TableRow key={index}>
                <TableCell>{milestones[parseInt(row.index)]?.name}</TableCell>
                <TableCell>
                  {milestones[parseInt(row.index)]?.deadline
                    ? convertTimeStampToDate(milestones[parseInt(row.index)]?.deadline).format('MMMM DD, YYYY')
                    : '--'}
                </TableCell>
                <TableCell>
                  {row?.completionDate ? convertTimeStampToDate(row?.completionDate).format('MMMM DD, YYYY') : '--'}
                </TableCell>
                <TableCell>
                  {String(row.attendance).charAt(0).toUpperCase() + String(row.attendance).slice(1)}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientAttendanceSheet;
