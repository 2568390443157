import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../utils/colors';
import { MonetizationOnOutlined, PlayArrow } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import MilestonesList from '../../../components/MilestonesList/MilestonesList';
import { UserModel } from '../../../store/user/userModel';
import { useAppSelector } from '../../../store/store';
import { getClientInfoById, updateClientInterest } from '../../../services/client.service';
import { LoadingButton } from '../../../components/LoadingButton/LoadingButton';

export const ProspectiveClientCareerPathInfo = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [clientInfo, setClientInfo] = React.useState<any>();
  const [disableButton, setDisableButton] = React.useState(false);
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const { careerPathInfo } = useLocation().state;
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate('/client/career-paths');
  };

  React.useEffect(() => {
    (async () => {
      const client: any = await getClientInfoById(user.id);
      setClientInfo(client.interestedIn);
    })();
    // eslint-disable-next-line
  }, [disableButton]);

  const handleClick = async () => {
    setLoading(true);
    const careerPathId = careerPathInfo?.id || '';
    await updateClientInterest(user?.id || '', careerPathId);
    setDisableButton(!disableButton);
    setLoading(false);
  };
  const handleDisable = () => {
    return clientInfo === careerPathInfo.id ? true : false;
  };

  return (
    <>
      <Box sx={styles.centerStyle}>
        <Typography variant='h6' color='white' textAlign={'center'} mb={2}>
          Career Path Info
        </Typography>
        <Grid container justifyContent={'center'}>
          <Box sx={{ width: 500 }}>
            <Box sx={styles.videoBox}>
              <PlayArrow sx={styles.playArrow} />
            </Box>
            <Box sx={{ p: 1 }}>
              <Typography variant='h6' color='white' textAlign={'center'}>
                {careerPathInfo?.name}
              </Typography>
              <Typography variant='body2' color='white' textAlign={'center'}>
                {careerPathInfo?.name}
              </Typography>
            </Box>
            <Grid sx={styles.cardGridItem}>
              <MonetizationOnOutlined sx={styles.monetizationIcon} />
              <Typography variant='h6' color={COLORS.pc_pts.heading} fontWeight={500} style={styles.textStyle}>
                Salaries:
              </Typography>
              <Typography fontSize={20} color='white'>
                {careerPathInfo?.salaries}
              </Typography>
            </Grid>
            <Box sx={styles.textBox}>
              <Typography variant='h6' color={COLORS.pc_pts.heading} fontWeight={500}>
                On the Job:
              </Typography>
              <Typography sx={styles.description}>{careerPathInfo.description}</Typography>
              <Box sx={styles.listStyle}>
                <MilestonesList milestones={careerPathInfo.milestones} />
              </Box>
            </Box>
            <Grid sx={styles.buttonGridItem}>
              <Box>
                <Button size='small' variant='contained' onClick={handleGoBack} sx={styles.goBackBtn}>
                  <Typography variant='button' fontWeight={550} color='primary'>
                    Go back
                  </Typography>
                </Button>
              </Box>
              <Box sx={styles.btnBox}>
                <LoadingButton
                  size='small'
                  variant='contained'
                  type='submit'
                  color={'success'}
                  label={"I'M INTERESTED"}
                  loading={loading}
                  styles={styles.button}
                  onClick={() => {
                    handleClick();
                  }}
                  disabled={handleDisable() || loading}
                />
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default ProspectiveClientCareerPathInfo;

const styles = {
  centerStyle: {
    background: COLORS.theme.primaryColor,
    height: '100vh',
    minHeight: '100vh',
    pt: 2,
  },
  playArrow: {
    color: 'white',
  },
  btnBox: {
    justifyContent: 'center',
    display: 'flex',
    ml: 12,
  },
  cardGridItem: {
    px: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '10px',
  },
  videoBox: {
    width: '100%',
    height: '25%',
    py: 5,
    background: COLORS.palette.black,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    border: '1px solid white',
  },
  monetizationIcon: {
    color: COLORS.pc_pts.heading,
  },
  textStyle: {
    marginRight: '10px',
    marginLeft: '5px',
  },
  textBox: {
    p: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    color: 'white',
  },
  listStyle: {
    background: COLORS.theme.primaryColor,
    marginTop: '25px',
  },
  button: {
    mt: 4,
  },
  goBackBtn: {
    marginTop: 4,
    bgcolor: COLORS.palette.white,
    '&:hover': {
      bgcolor: 'rgb(173, 216, 230)',
    },
  },
  buttonGridItem: {
    px: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};
