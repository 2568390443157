import programTracksSlice from './programTracksSlice';
import type { AnyAction } from '@reduxjs/toolkit';
import type { ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ProgramTracksModel } from './programTracksModel';

export const ProgramTracksActions = programTracksSlice.actions;

export const setProgramTracksData = (
  payload: ProgramTracksModel[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch(ProgramTracksActions.setProgramTracks(payload));
  };
};

export const resetProgramTracks = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch(ProgramTracksActions.resetProgramTracks(undefined));
  };
};
