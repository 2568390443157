import { Box, Button, Grid, IconButton, Modal, TextField } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import React from 'react';
import { COLORS } from '../../../utils/colors';
import { FieldArray, Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { addNewCareerPath, editCareerPathById } from '../../../services/careerPath.service';
import { useAppSelector } from '../../../store/store';
import { UserModel } from '../../../store/user/userModel';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import styled from '@emotion/styled';

interface IAddCareerPathProps {
  open: boolean;
  onClose: () => void;
  data?: IFormValues | null;
  mode: string;
  onAddCareerPath: Function;
}

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const localStyle = {
  centeredStyle: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    borderRadius: '14px',
    width: '600px',
    height: '600px',
    '@media (max-width: 480px)': {
      padding: '20px',
      margin: '10px',
    },
  },
  formLabel: {
    color: COLORS.palette.black,
    marginBottom: '5px',
    '& .MuiInputLabel-asterisk': {
      color: 'red',
    },
  },
  titleGrid: {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  fieldArray: {
    position: 'relative',
    top: '20px',
    left: '17px',
    width: '515px',
  },
  loadingButtonGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  removeButtonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const validationSchema = Yup.object({
  code: Yup.string().required('Code is required'),
  name: Yup.string().required('Name is required'),
  minSalary: Yup.string().required('Min Salary is required').matches(/^\d+$/, 'Min Salary must be a number'),
  maxSalary: Yup.string()
    .required('Max Salary is required')
    .matches(/^\d+$/, 'Max Salary must be a number')
    .test('maxSalary', 'Max Salary must be greater than Min Salary', function (value) {
      const minSalary = this.resolve(Yup.ref('minSalary'));
      if (!minSalary || !value) {
        return true;
      }
      return Number(value) > Number(minSalary);
    }),
  description: Yup.string().required('Description is required'),
  info: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Info Name is required'),
      description: Yup.string().required('Info Description is required'),
    })
  ),
});

interface InfoItem {
  name: string;
  description: string;
}

interface IFormValues {
  code: string;
  name: string;
  minSalary: string;
  maxSalary: string;
  description: string;
  info: InfoItem[];
  id?: string;
}

const initialValues: IFormValues = {
  code: '',
  name: '',
  maxSalary: '',
  minSalary: '',
  description: '',
  info: [],
};

const AddCareerPath = (props: IAddCareerPathProps) => {
  const { open, onClose, onAddCareerPath } = props;
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
    if (user.id) {
      const careerPath = {
        createdBy: user?.id,
        code: values.code,
        name: values.name,
        salaries: `$${values.minSalary}-$${values.maxSalary}`,
        description: values.description,
        info: values.info,
      };
      try {
        await addNewCareerPath(careerPath, user?.role);
        setSubmitting(false);
        onAddCareerPath();
        onClose();
      } catch (error) {
        console.error('Error creating new Career Path!', error);
      }
    }
  };

  const handleEdit = async (values: IFormValues) => {
    if (props.data && props.data.id) {
      const payload = {
        code: values.code,
        name: values.name,
        salaries: `$${values.minSalary}-$${values.maxSalary}`,
        description: values.description,
        info: values.info,
      };
      setLoading(true);
      await editCareerPathById(props.data.id, payload);
      setLoading(false);
      onAddCareerPath();
      onClose();
    }
  };

  const getInitialValuesOnEdit = () => {
    if (props.data) {
      return {
        code: props.data.code,
        name: props.data.name,
        maxSalary: props.data.maxSalary,
        minSalary: props.data.minSalary,
        description: props.data.description,
        info: props.data.info,
      };
    } else return initialValues;
  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <Box sx={localStyle.centeredStyle}>
        <Grid container sx={localStyle.titleGrid}>
          <h2 id='simple-modal-title'>{props.mode === 'CREATE' ? 'Create Career Path' : 'Edit Career Path'}</h2>
          <IconButton onClick={onClose}>
            <GridCloseIcon />
          </IconButton>
        </Grid>
        <Formik
          initialValues={props.data ? getInitialValuesOnEdit() : initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
          }) => (
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  name='code'
                  type='text'
                  label='Code'
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.code && Boolean(errors.code)}
                  helperText={touched.code && errors.code}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  name='name'
                  type='text'
                  label='Name'
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name='minSalary'
                  type='text'
                  label='Min Salary'
                  value={values.minSalary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.minSalary && Boolean(errors.minSalary)}
                  helperText={touched.minSalary && errors.minSalary}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name='maxSalary'
                  type='text'
                  label='Max Salary'
                  value={values.maxSalary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.maxSalary && Boolean(errors.maxSalary)}
                  helperText={touched.maxSalary && errors.maxSalary}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  name='description'
                  type='text'
                  label='Description'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline
                  rows={3}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>
              <Grid container sx={localStyle.fieldArray}>
                <FieldArray name='info'>
                  {({ remove, push }) => (
                    <Grid container spacing={2}>
                      {values.info &&
                        values.info.map((obj, index) => (
                          <React.Fragment key={index}>
                            <Grid item md={6} xs={12}>
                              <TextField
                                name={`info.${index}.name`}
                                value={obj.name}
                                label='Name'
                                fullWidth
                                onChange={(e) => setFieldValue(`info.${index}.name`, e.target.value)}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                name={`info.${index}.description`}
                                label='Description'
                                value={obj.description}
                                fullWidth
                                multiline
                                rows={2}
                                onChange={(e) => setFieldValue(`info.${index}.description`, e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sx={localStyle.removeButtonGrid}>
                              <Button variant='contained' color='secondary' onClick={() => remove(index)}>
                                Remove
                              </Button>
                            </Grid>
                          </React.Fragment>
                        ))}
                      <Grid item xs={12}>
                        <Button variant='contained' color='primary' onClick={() => push({ name: '', description: '' })}>
                          Add Info
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={12} lg={12} sx={localStyle.loadingButtonGrid}>
                <LoadingButton
                  onClick={() => {
                    if (props.mode === 'CREATE') {
                      handleSubmit();
                    } else {
                      handleEdit(values);
                    }
                  }}
                  label={props.mode === 'CREATE' ? 'Create' : 'Update'}
                  styles={{ width: '400px' }}
                  loading={isSubmitting || loading}
                  disabled={!isValid || isSubmitting || loading}
                  size='large'
                  type='submit'
                  variant='contained'
                />
              </Grid>
            </Grid>
          )}
        </Formik>
      </Box>
    </ModalWrapper>
  );
};

export default AddCareerPath;
