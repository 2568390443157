import React, { useEffect, useState } from 'react';
import { VisibilityOutlined, DeleteOutline, CloudUploadOutlined, FileUploadOutlined } from '@mui/icons-material';
import { Box, Grid, Paper, Typography, IconButton, Tooltip, Divider, Chip } from '@mui/material';
import { IDocumentData, getClientDocuments } from '../../services/client.service';
import { toast } from 'react-toastify';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';
import { COLORS } from '../../utils/colors';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { addDocument } from '../../services/client.service';
import { deleteDocument } from '../../services/client.service';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { getEmploymentIntakeInfoById } from '../../services/employmentModule.service';
import { getFileNameFromUrl } from '../../utils/fileHandler';

interface IClientDocumentProps {
  clientInfo: any;
  isClientView?: boolean;
}

const ClientDocuments = (props: IClientDocumentProps) => {
  const { clientInfo, isClientView } = props;
  const nofile = 'Browse file to upload';
  const user: UserModel = useAppSelector((state: any) => state.user.user) || {};
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [file, setFile] = React.useState({ name: null });
  const [documents, setDocuments] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const { id: clientId } = clientInfo;
  const isValidUser =
    user.role === UserRolesEnum.ADMIN ||
    user.role === UserRolesEnum.NAVIGATOR ||
    user.role === UserRolesEnum.SUPER_ADMIN;

  const getDocuments = async () => {
    try {
      const response: any = await getClientDocuments(clientId);
      setDocuments(response);
    } catch (e) {
      toast.error('Error fetching documents');
    }
  };

  useEffect(() => {
    (async () => {
      await getDocuments();
    })();
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const { files } = event.target;
    if (files[0]?.size > 25 * 1024 * 1024) {
      setFile({ name: null });
      toast.error('File Size is too large');
    } else {
      setFile(files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (file?.name) {
      try {
        let payload: IDocumentData = {
          name: file?.name,
          file: file,
          role: isClientView ? 'client' : user.role,
          uploadedBy: user.id || '',
        };
        setLoading(true);
        await addDocument(clientId, payload, user?.role, isClientView);
        setLoading(false);
        await getDocuments();
        setFile({ name: null });
      } catch (e) {
        toast.error('Something went wrong.');
      }
    }
  };

  const handleDocumentDelete = async (documentId: string) => {
    try {
      await deleteDocument(clientId, documentId, user.role);
      await getDocuments();
    } catch (e) {
      toast.error('Something went wrong.');
    }
  };

  const getEmploymentIntakeData = async () => {
    const unsubscribe = getEmploymentIntakeInfoById(clientId, (data: any) => {
      setUploadedFiles(data?.urls || []);
    });
    return () => {
      unsubscribe();
    };
  };

  useEffect(() => {
    (async () => {
      await getEmploymentIntakeData();
    })();
    // eslint-disable-next-line
  }, []);

  const uploadedFileViewHandler = (url: any, index: number) => {
    return (
      <Grid key={index.toString()} item xs={12} lg={12} mb={1}>
        <Box
          sx={{
            height: 'auto',
            borderRadius: 10,
            backgroundColor: COLORS.palette.borderColor,
            border: `1px dashed ${COLORS.theme.primaryColor}`,
          }}>
          <Grid container alignItems={'center'} spacing={2} px={1}>
            <Grid item xs={8} lg={8}>
              <Typography variant='caption' align='center' sx={{ fontWeight: 'bold' }}>
                {getFileNameFromUrl(url, clientInfo)}
              </Typography>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Box display='flex' justifyContent='flex-end' alignItems='center' gap='16px'>
                <Tooltip title='View' arrow>
                  <IconButton
                    type='submit'
                    color='success'
                    onClick={() => {
                      window.open(url);
                    }}>
                    <VisibilityOutlined />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };

  //letter view render function
  const documentView = (document: any) => {
    return (
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          height: 'auto',
          border: `1px dashed ${COLORS.theme.primaryColor}`,
          borderRadius: 10,
          backgroundColor: COLORS.palette.borderColor,
          m: 1,
        }}>
        <Grid container alignItems={'center'} spacing={2} px={1}>
          <Grid item xs={8} lg={8}>
            {/* Document title */}
            <Typography variant='caption' align='center' sx={{ fontWeight: 'bold' }}>
              {document.name}
            </Typography>
          </Grid>
          <Grid item xs={4} lg={4}>
            <Box display='flex' justifyContent='flex-end' alignItems='center' gap='16px'>
              {/* View document */}
              <Tooltip title='View' arrow>
                <IconButton
                  type='submit'
                  color='success'
                  onClick={() => {
                    window.open(document.url);
                  }}>
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
              {/* Delete document */}
              {isValidUser ? (
                <Tooltip title='Delete' arrow>
                  <IconButton type='submit' color='error' onClick={() => handleDocumentDelete(document.documentId)}>
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  //letter upload render function
  const uploadDocument = () => {
    return (
      <Paper elevation={0} sx={{ width: '100%' }}>
        <input type='file' name='file' ref={fileInputRef} onChange={handleFileChange} hidden />
        <Box width='100%' p={4} sx={{ backgroundColor: '#f6f6f6', borderRadius: '10px' }}>
          <Box mt={2} border='2px dashed' borderColor={COLORS.theme.primaryColor} onClick={handleClick} height='250px'>
            <Box display='flex' flexDirection='column' height='100%' justifyContent='center' alignItems='center'>
              <CloudUploadOutlined fontSize='large' color='primary' />
              <Typography variant='body1' textTransform={'none'} fontWeight={'bold'} color={COLORS.theme.primaryColor}>
                {file?.name ? file?.name : nofile}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' justifyContent='center' mt={2}>
            <LoadingButton
              onClick={() => {
                handleFileUpload();
              }}
              label={'Upload'}
              styles={{ width: '100%', borderRadius: '100px' }}
              loading={loading}
              disabled={!file?.name || loading}
              size='large'
              type='submit'
              variant='contained'
              endIcon={<FileUploadOutlined />}
            />
          </Box>
        </Box>
      </Paper>
    );
  };

  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item lg={6} xs={12}>
          {/* document upload */}
          {isClientView || isValidUser ? uploadDocument() : null}

          {/* document view and delete */}
          {documents.length ? documents.map((document: any) => documentView(document)) : null}

          {/* view employment intake document */}
          {uploadedFiles?.length > 0 && (
            <Box py={3}>
              <Divider>
                <Chip label='Employment Intake' sx={{ fontWeight: 'bold' }} />
              </Divider>
            </Box>
          )}
          {uploadedFiles?.length
            ? uploadedFiles.map((url: any, index: number) => uploadedFileViewHandler(url, index))
            : null}
        </Grid>
      </Grid>
    </>
  );
};

export default ClientDocuments;
