import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DashboardCard } from '../DashboardCard/DashboardCard';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchRegisteredClientsForWorkshop } from '../../services/workshop.service';

interface IClientsExpectingToAttendWorkshopProps {
  workshopId: string;
}
export const ClientsExpectingToAttendWorkshop = (props: IClientsExpectingToAttendWorkshopProps) => {
  const { workshopId } = props;
  const navigate = useNavigate();
  const [clientList, setClientList] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const loadClients = async () => {
    try {
      setShowLoader(true);
      const unsubscribe = await fetchRegisteredClientsForWorkshop(workshopId, (clientList: any) => {
        setClientList(clientList);
        setShowLoader(false);
      });
      return () => {
        unsubscribe();
      };
    } catch (err: any) {
      toast.error('Unable to load client list');
    }
  };

  useEffect(() => {
    loadClients();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid sx={{ width: '100%' }}>
      <DashboardCard
        isLoading={showLoader}
        label={'Clients Expecting to Attend Workshop'}
        desc={'List of current clients expecting to attend the workshop'}
        list={clientList}
        // eslint-disable-next-line
        onItemClick={(item: any) => {
          navigate('../clientInfo', { state: { clientId: item.id } });
        }}
      />
    </Grid>
  );
};
