import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, functions } from '../config/firebaseApp';
import { toast } from 'react-toastify';
import { httpsCallable } from 'firebase/functions';

export interface ITrainerMapMetaInfo {
  totalRecords: number;
  currentPage: number;
  programTrackTrainerMaps: Array<Object>;
  totalPages: number;
}
export interface ItrainerMapData {
  associateEmail: string;
  programTrackId: string;
  trainerId: string;
}

export const getAllAssociateTrainers = async (trainerId: string, page: number) => {
  const pageSize = 10;
  const offset = (page - 1) * pageSize;
  try {
    const pttmRef = collection(db, 'programTrackTrainerMap');
    const q = query(pttmRef, where('trainerId', '==', trainerId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const pttmList: any = [];
      querySnapshot.forEach((doc) => {
        pttmList.push({ id: doc.id, ...doc.data() });
      });
      const totalPages = Math.ceil(pttmList.length / pageSize);
      const limitedPTTrainerMaps = pttmList.slice(offset, offset + pageSize);
      const result: ITrainerMapMetaInfo = {
        totalRecords: pttmList.length,
        currentPage: page,
        programTrackTrainerMaps: limitedPTTrainerMaps,
        totalPages: totalPages,
      };
      return result;
    } else {
      return {
        totalRecords: 0,
        currentPage: page,
        programTrackTrainerMaps: [],
        totalPages: 0,
      };
    }
  } catch (error: any) {
    toast.error('Error Retrieving Associate Trainers!');
  }
};

export const addAssociateEmail = async (trainerMapData: ItrainerMapData) => {
  try {
    if (!trainerMapData) {
      throw new Error('Career Path data is either null or undefined');
    }

    const response: any = await httpsCallable(functions, 'addAssociateEmail')(trainerMapData);

    if (response.data.success) {
      toast.success('New Associate Trainer created successfully!');
    }
  } catch (err: any) {
    toast.error('Error Creating New Associate Trainer!');
  }
};

export const searchAssociateTrainer = async (searchQuery: string, page: number, trainerId: string) => {
  try {
    if (!searchQuery) {
      throw new Error('Required arguments are either null or undefined');
    }

    const response: any = await httpsCallable(functions, 'searchAssociateTrainerByPTName')({ query: searchQuery });

    const searchData = response.data.data;
    const pttmDocIds: any = [];
    let pttmDocList = [];

    if (searchData.length > 0) {
      for (const data of searchData) {
        pttmDocIds.push(data.id);
      }

      pttmDocList = await getMultiplePTTMByIds(pttmDocIds, trainerId);
      const pageSize = 10;
      const offset = (page - 1) * pageSize;
      const totalPages = Math.ceil(pttmDocList.length / pageSize);
      const limitedPTTMDocs = pttmDocList.slice(offset, offset + pageSize);
      const result: ITrainerMapMetaInfo = {
        totalRecords: pttmDocList.length,
        currentPage: page,
        programTrackTrainerMaps: limitedPTTMDocs,
        totalPages: totalPages,
      };
      return result;
    } else {
      return {
        totalRecords: 0,
        currentPage: page,
        programTrackTrainerMaps: [],
        totalPages: 0,
      };
    }
  } catch (error) {
    toast.error('Error Seacrching Associate Trainers!');
  }
};

export const getMultiplePTTMByIds = async (docIds: string[], trainerId: string) => {
  try {
    if (!docIds.length || !trainerId) {
      throw new Error('docIds or trainer Id are null or undefined');
    }

    const pttmRef = collection(db, 'programTrackTrainerMap');
    const q = query(pttmRef, where('trainerId', '==', trainerId));
    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs.filter((doc) => docIds.includes(doc.id));
    const pttmDocList: any = [];

    documents.forEach((doc) => {
      pttmDocList.push({ id: doc.id, ...doc.data() });
    });

    return pttmDocList;
  } catch (err: any) {
    toast.error('Error Retrieving Program Track Trainer Data!');
    return [];
  }
};
