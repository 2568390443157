import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = 'America/New_York';
dayjs.tz.setDefault(tz);
import { toast } from 'react-toastify';
import {
  IInfoSessionsMetaInfo,
  getInfoSessionsByQuery,
  searchInfoSession,
} from '../../../services/infoSession.service';
import { InfoSessionModel } from '../../../store/infoSession/infoSessionModel';
import CustomTable from '../../../components/CustomTable';
//eslint-disable-next-line
import { useNavigate } from 'react-router-dom';

const UpcomingInfoSession = (props: any) => {
  const navigate = useNavigate();
  const [infoSessionsList, setInfoSessionsList] = React.useState<Array<InfoSessionModel>>([]);
  const [sortModel, setSortModel] = useState([]);

  const PAGE_SIZE = 10;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [infoSessionMetaInfo, setInfoSessionMetaInfo] = React.useState<IInfoSessionsMetaInfo>();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [searchPaginationModel, setSearchPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [loading, setLoading] = useState(false);
  const [isSearchMode, setIsSearchMode] = React.useState(false);

  const getInfoSessionList = async () => {
    try {
      setLoading(true);
      setIsSearchMode(false);
      setSearchQuery('');

      const unsubscribe = getInfoSessionsByQuery(paginationModel.page + 1, sortModel, (result: any) => {
        if (result?.infoSessions.length) {
          setInfoSessionMetaInfo(result);
          const sessionDateTimeList = result?.infoSessions?.map((item: any) => {
            return {
              ...item,
              dateTime: new Date(item.dateTime).toISOString(),
            };
          });
          setInfoSessionsList([...sessionDateTimeList]);
        } else {
          setInfoSessionMetaInfo(result);
          setInfoSessionsList([]);
        }
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isSearchMode) {
      getInfoSessionList();
    }
    // eslint-disable-next-line
  }, [props, paginationModel.page]);

  const handleSearch = async () => {
    if (searchQuery) {
      setLoading(true);
      setIsSearchMode(true);
      const result = await searchInfoSession(
        searchQuery.toLowerCase().trim(),
        searchPaginationModel.page + 1,
        sortModel
      );

      if (result?.infoSessions.length) {
        setInfoSessionMetaInfo(result);
        const sessionDateTimeList = result?.infoSessions.map((item: any) => {
          return {
            ...item,
            dateTime: new Date(item.dateTime).toISOString(),
          };
        });

        setInfoSessionsList([...sessionDateTimeList]);
      } else {
        setInfoSessionMetaInfo(result);
        setInfoSessionsList([]);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSearchMode) {
      handleSearch();
    } else {
      getInfoSessionList();
    }
    // eslint-disable-next-line
  }, [searchPaginationModel.page, sortModel]);

  useEffect(() => {
    if (!searchQuery) getInfoSessionList();
    // eslint-disable-next-line
  }, [searchQuery]);

  const columns = [
    {
      field: 'hra',
      headerName: 'HRA Liaison Site',
      type: 'string',
      sortable: false,
      width: 250,
    },
    {
      field: 'location',
      headerName: 'Location',
      type: 'string',
      sortable: false,
      width: 250,
    },
    {
      field: 'title',
      headerName: 'Program',
      type: 'string',
      sortable: false,
      width: 250,
    },
    {
      field: 'dateTime',
      headerName: 'Date & Time',
      type: 'string',
      sortable: false,
      width: 250,
      renderCell: (params: any) => dayjs(params.row.dateTime).tz('America/New_York').format('MMMM DD, YYYY, hh:mm A'),
    },
    {
      field: 'sessionType',
      headerName: 'Type of Session',
      type: 'string',
      sortable: false,
      width: 350,
    },
  ];

  const handleSortModelChange = (newSortModel: any) => {
    setSortModel(newSortModel);
  };

  return (
    // <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center' sx={styles.root}>
    //   <Grid item xs={12} sx={{ my: 2 }}>
    //     <img alt='Logo' src={'/assets/logo.png'} style={isIOS() ? { width: 50 } : { width: 100 }} />
    //   </Grid>
    //  </Grid>
    // <Grid item xs={12} sx={styles.centeredStyle} my={4}>
    // </Grid>
    <CustomTable
      columns={columns}
      hideSearch={false}
      rows={infoSessionsList.length ? infoSessionsList : []}
      filterList={[]}
      loading={loading}
      onFilterListChange={() => {}}
      onPaginationModelChange={isSearchMode ? setSearchPaginationModel : setPaginationModel}
      paginationModel={isSearchMode ? searchPaginationModel : paginationModel}
      primaryButtonLable=''
      primaryButtonAction={() => {}}
      hidePrimaryBtn={true}
      rowCount={infoSessionMetaInfo?.totalRecords || 0}
      onRowClick={(params: any) => {
        navigate(`/infosessions/${params.row.customUrlName}`);
      }}
      title='Upcoming Info Sessions'
      onChangeSearchQuery={(query: string) => {
        setSearchQuery(query);
      }}
      handleSearch={() => handleSearch()}
      searchQuery={searchQuery}
      onSortModelChange={handleSortModelChange}
      sortModel={sortModel}
    />
  );
};

export default UpcomingInfoSession;

// const styles = {
//   root: {
//     minHeight: '100vh',
//     backgroundColor: COLORS.theme.primaryColor,
//   },
//   loginBtn: {
//     color: COLORS.palette.gray,
//     textDecoration: 'none',
//     fontSize: '16px',
//     lineHeight: '19px',
//   },
//   centeredStyle: {
//     width: '80%',
//     bgcolor: 'background.paper',
//     boxShadow: 24,
//     p: 4,
//     overflow: 'auto',
//     borderRadius: '14px',
//     '@media (max-width: 480px)': {
//       width: '90%',
//       height: '90%',
//       padding: '20px',
//       margin: '10px',
//     },
//   },
// };
