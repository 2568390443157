import { Grid, Typography } from '@mui/material';

export const CreateColumnHeader = (headerName: string) => {
  return (
    <Grid container sx={{ justifyContent: 'center', alignItems: 'center', width: 300 }} justifyContent={'center'}>
      <Typography variant='button' sx={{ textTransform: 'capitalize' }}>
        {headerName}
      </Typography>
    </Grid>
  );
};
