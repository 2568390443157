import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';

interface GenericDialogProps {
  dialogTitle?: string;
  dialogContent?: string;
  agreeBtnText: string;
  disagreeBtnText: string;
  agreeFunc: Function;
  onClose: Function;
  open: boolean;
  color?: string;
}

const GenericDialog = (props: GenericDialogProps) => {
  const { open, onClose, dialogTitle, dialogContent, agreeBtnText, agreeFunc, disagreeBtnText } = props;
  const defaultContent: string = 'Confirming this action will result in irreversible and permanent changes';

  return (
    <Dialog
      fullWidth
      // maxWidth={'xs'}
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperProps={{ sx: { borderRadius: '10px' } }}>
      <DialogTitle>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Box>
            <Typography variant='h6'>{dialogTitle || null}</Typography>
          </Box>
          <IconButton
            onClick={() => {
              onClose();
            }}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant='body1'>{dialogContent || defaultContent}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color='error'
          onClick={() => {
            onClose();
          }}>
          <Typography variant='button' sx={{ textTransform: 'none' }}>
            {disagreeBtnText}
          </Typography>
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onClose();
            agreeFunc();
          }}>
          <Typography variant='button' sx={{ textTransform: 'none' }}>
            {agreeBtnText}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenericDialog;
