import { styled } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

export const CustomDataGrid = styled(DataGrid)(() => ({
  border: 0,
  borderColor: 'transparent',
  [`& .${gridClasses.panelContent}`]: {
    borderRadius: '8px',
  },
  [`& .${gridClasses.cell}`]: {
    border: 0,
    borderColor: 'transparent',
    borderBottom: 'none',
    '&:focus': {
      outline: 'none !important',
    },
  },
  [`& .${gridClasses['columnSeparator--sideRight']}`]: {
    display: 'none',
    outline: 'none !important',
    border: 'none',
  },

  [`& .${gridClasses.columnHeaderTitleContainer}`]: {
    border: 'none',
    '&:focus': {
      border: 'none',
      '@media (focus: none)': {
        border: 'none',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    border: 'none',
    outline: 'none !important',
    '&:focus': {
      border: 'none',
      outline: 'none !important',
      '@media (focus: none)': {
        border: 'none',
        outline: 'none !important',
      },
    },
  },

  [`& .${gridClasses.columnHeaders}`]: {
    border: '1px solid #E6E7E9',
    borderRadius: '8px',
  },
  [`& .${gridClasses.footerContainer}`]: {
    border: 'none',
  },
  [`& .${gridClasses.virtualScroller}`]: {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: '8px',
    },
  },
  [`& .${gridClasses.row}`]: {
    borderRadius: '8px',
    marginTop: '10px',
    border: '1px solid #E6E7E9',

    '&:hover, &.Mui-hovered': {
      backgroundColor: '#E6E7E9',
      cursor: 'pointer',
      '@media (hover: none)': {
        backgroundColor: '#E6E7E9',
        cursor: 'pointer',
      },
    },
  },
}));
