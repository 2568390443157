import { Palette } from './palette';

export const COLORS = {
  theme: {
    primaryColor: '#13236E',
    lightPrimaryColor: '#7484bf',
    linkColor: Palette.blue_4285f4,
  },
  result: {
    error: '#d32f2f',
  },
  palette: {
    white: '#fff',
    sky: '#59ceff',
    gray: '#8D85AC',
    lightYellow: '#FEF3C6',
    lightGreen: '#76ce82',
    lightSky: '#DBE9FE',
    black: '#000',
    blue: '#2c80ff',
    lightBlue: '#5fb1ff',
    green: '#24A841',
    lightBlack: '#181819',
    lightGray: '#181819',
    borderColor: '#E6E7E9',
    transparent: '#00000000',
    skyBlue: '#59ceff',
    red: '#ff200c',
    lightRed: '#ff795b',
    purple: '#512DA8',
    lightPurple: '#7E57C2',
    orange: '#E64A19',
    lightOrange: '#FF7043',
  },
  card: {
    cardBg: '#f5f5f5',
  },
  client: {
    allClientBg: Palette.white_f5f5ff,
    allClientColor: Palette.black,
    prospectiveClientBg: Palette.purple_F2E7FC,
    prospectiveClientColor: Palette.purple_8C18E2,
    pendingEnrollmentBg: Palette.triadic_fbe2f0,
    pendingEnrollmentColor: Palette.triadic_ef4fa6,
    enrolledBg: Palette.yellow_F3F3E4,
    enrolledColor: Palette.yellow_D8D0A7,
    completedBg: Palette.green_E4F3E5,
    completedColor: Palette.green_4CAF50,
    dropoutBg: Palette.red_FFCDD2,
    dropoutColor: Palette.red_D50000,
    pendingAssignmentBg: Palette.blue_E1F5FD,
    pendingAssignmentColor: Palette.indigo_4A4DE6,
    searchBarBg: Palette.grey_F8F8FA,
    suspendedClientBg: Palette.grey_C4C4C4,
    suspendedClientColor: Palette.black,
    activeColor: Palette.green_31BD1A,
    enrollmentReviewBg: Palette.red_FBE7E7,
    enrollmentReviewColor: Palette.orange_FF6422,
  },
  pc_pts: {
    cardBorder: Palette.orange_FF671D,
    cardBg: Palette.blue_0C2C65,
    cardBtn: Palette.orange_FF671D,
    heading: Palette.orange_FF671D,
  },
  ptinfo: {
    cardBg: Palette.gray_EEEEEE,
  },
  notesCategory: {
    generalNoteBg: Palette.green_E4F3E5,
    generalNoteColor: Palette.green_4CAF50,
    declinedServicesBg: Palette.purple_F2E7FC,
    declinedServicesColor: Palette.purple_8C18E2,
    awaitingDocBg: Palette.grey_C4C4C4,
    awaitingDocColor: Palette.black,
    folloUpBg: Palette.blue_E1F5FD,
    folloUpColor: Palette.indigo_4A4DE6,
    attemptedFollowUpBg: Palette.yellow_F3F3E4,
    attemptedFollowUpColor: Palette.yellow_D8D0A7,
    programTrackBg: Palette.red_FBE7E7,
    programTrackColor: Palette.orange_FF6422,
    progressNoteBg: Palette.triadic_fbe2f0,
    progressNoteColor: Palette.triadic_ef4fa6,
  },
};
