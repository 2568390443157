export const JobTypes = [
  { id: 1, value: 'construction', title: 'Construction' },
  { id: 2, value: 'industrial/transportation', title: 'Industrial/Transportation' },
  { id: 3, value: 'security', title: 'Security' },
  { id: 4, value: 'general maintenance', title: 'General Maintenance' },
];

export const employmentTypes = [
  { id: 1, title: 'Full-time' },
  { id: 2, title: 'Part-time' },
  { id: 3, title: 'Seasonal employment' },
  { id: 4, title: 'Earn and Learn' },
  { id: 5, title: 'Other (please specify)' },
];

export const employmentStatusOptions = [
  { id: 1, title: 'Employed in-field by an employer who partners with your training program' },
  { id: 2, title: 'Employed in-field by an employer who doesn’t partner with your training program' },
  { id: 3, title: 'Still seeking employment in-field' },
  { id: 4, title: 'Not seeking employment in-field' },
  { id: 5, title: 'Could not contact' },
];
