import { addDaysToDate } from './dateTime';

export const getMilestonesArray = (startTime: Date, milestones: Array<Object>) => {
  const newArray = milestones?.map((item: any) => {
    const obj = {
      deadline: addDaysToDate(startTime, item.days),
      name: item.name,
      type: item.type,
      deliver: item.deliver,
      endTime: item.endTime,
      hours: item.hours,
      mode: item.mode,
      startTime: item.startTime,
    };
    return obj;
  });
  return newArray;
};

export const convertMilestonesDeadlineToDays = (startTime: string, milestones: Array<Object>) => {
  const startDateTime: any = new Date(startTime);

  const resultArray = milestones.map((item: any) => {
    const deadlineDateTime: any = item.deadline.toDate();
    const differenceInMillis = deadlineDateTime - startDateTime;
    const differenceInDays = differenceInMillis > 0 ? Math.floor(differenceInMillis / (24 * 60 * 60 * 1000)) : 0;

    const obj = {
      name: item.name,
      type: item.type,
      days: differenceInDays,
    };

    return obj;
  });

  return resultArray;
};

export const findMaxMilestoneFromClient = (data: any[]) => {
  let max: any = null;
  data.forEach((item) => {
    if (item['currentMilestoneIndex'] !== undefined) {
      const value = item['currentMilestoneIndex'];
      if (max === null || value > max) {
        max = value;
      }
    }
  });
  return max;
};
