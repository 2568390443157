import React from 'react';
import { Box } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import Loading from '../loading/loading';
import { AdminSidebarMenu } from '../../utils/adminSidebar';
import { UserRolesEnum } from '../../utils/rolesEnum';

const Layout = () => {
  const { loggedInRole, checkingStatus } = useAuthStatus();

  if (checkingStatus) return <Loading />;
  if (!loggedInRole) return <Navigate to='/login' />;

  const filteredSidebarMenu = AdminSidebarMenu.filter((item) => {
    if (item.path === '/admin/associate-trainer-list' && loggedInRole !== UserRolesEnum.TRAINER) {
      return false;
    }
    if (
      (item.path === '/admin/resource' ||
        item.path === '/admin/analytics' ||
        item.path === '/admin/report' ||
        item.path === '/admin/job' ||
        item.path === '/admin/workshops' ||
        item.path === '/admin/infoSessionList' ||
        item.path === '/admin/client-employment' ||
        item.path === '/admin/message' ||
        item.path === '/admin/careerPathList' ||
        item.path === '/admin/curriculum-list' ||
        item.path === '/admin/curriculum-list') &&
      loggedInRole === UserRolesEnum.TRAINER
    ) {
      return false;
    }
    return true;
  });

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%', position: 'relative' }}>
      <div style={{ position: 'relative', width: '15%' }}>
        <Sidebar sidebarMenuItems={filteredSidebarMenu} />
      </div>
      <div style={{ background: 'white', width: '85%', position: 'relative' }}>
        <Navbar />
        <Box sx={{ my: 10, px: 5 }}>
          <Outlet />
        </Box>
      </div>
    </div>
  );
};

export default Layout;
