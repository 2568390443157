import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { db } from '../config/firebaseApp';
import { toast } from 'react-toastify';

export const getcurriculumAnalyticsById = async (id: string) => {
  try {
    if (!id) {
      throw new Error();
    }

    const curriculumAnalyticsRef = doc(db, 'curriculumAnalytics', id);
    const curriculumAnalyticsDoc = await getDoc(curriculumAnalyticsRef);

    if (curriculumAnalyticsDoc.exists()) {
      return { id: curriculumAnalyticsDoc.id, ...curriculumAnalyticsDoc.data() };
    } else {
      return {};
    }
  } catch (err: any) {
    toast.error('Error Retrieving Curriculum Analytics');
    return {};
  }
};

export const getMultipleCurriculumAnalyticsByIds = async (ids: string[]) => {
  try {
    if (!ids.length) {
      throw new Error();
    }

    const curriculumAnalyticsRef = collection(db, 'curriculumAnalytics');
    const querySnapshot = await getDocs(query(curriculumAnalyticsRef));
    const documents = querySnapshot.docs.filter((doc) => ids.includes(doc.id));

    const curriculumAnalyticsList: any = [];

    documents.forEach((doc) => {
      curriculumAnalyticsList.push({ id: doc.id, ...doc.data() });
    });

    return curriculumAnalyticsList;
  } catch (err: any) {
    toast.error('Error Retrieving Curriculum Analytics');
    return [];
  }
};
