import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CreateAccount from './pages/client/auth/createAccount/createAccount';
import Packages from './pages/client/auth/packages/packages';
import Login from './pages/admin/auth/login/login';
import ClientsList from './pages/admin/client/client';
import Dashboard from './pages/admin/dashboard/dashboard';
import MessagePage from './pages/admin/message/message';
import Resource from './pages/admin/resource/resource';
import Setting from './pages/admin/setting/setting';
import Job from './pages/admin/job/job';
import Analytics from './pages/admin/analytics/analytics';
import UserManagement from './pages/admin/userManagement/userManagement';
import Layout from './components/Layout/Layout';
import CreateClient from './pages/admin/client/createClient/createClient';
import InfoSessionList from './pages/admin/infoSessionList/infoSessionList';
import ClientInfo from './pages/admin/client/clientInfo/clientInfo';
import ForgotPassword from './pages/admin/auth/forgotPassword/forgotPassword';
import ProgramTrackList from './pages/admin/programTrackList/programTrackList';
import './App.css';
import CareerPathList from './pages/admin/careerPathList/careerPathList';
import { ProgramTrackInfo } from './pages/admin/programTrackInfo/programTrackInfo';
import CurriculumList from './pages/admin/curriculumList/curriculumList';
import ClientLayout from './components/ClientLayout/ClientLayout';
import ClientHome from './pages/client/home';
import ClientDocumentsPage from './pages/client/documents';
import ClientMessagingPage from './pages/client/messaging';
import ProspectiveClientCareerPaths from './pages/client/prospectiveClientCareerPath/prospectiveClientCareerPath';
import ProspectiveClientCareerPathInfo from './pages/client/prospectiveClientCPInfo/prospectiveClientCareerPathInfo';
import Report from './pages/admin/report/report';
import AssociateTrainersList from './components/AssociateTrainersList/AssociateTrainersList';
import { UserModel } from './store/user/userModel';
import { useAppSelector } from './store/store';
import { UserRolesEnum } from './utils/rolesEnum';
import InfoSession from './pages/client/infosession/infosession';
import WorkshopList from './pages/admin/workshopList/WorkshopList';
import WorkshopInfo from './pages/workshopInfo/workshopInfo';
import JobInfo from './pages/JobInfo/JobInfo';
import InfoSessionInfo from './pages/InfoSessionInfo/InfoSessionInfo';
import ProspectiveClientsInfo from './pages/admin/client/prospectiveClientsInfo/ProspectiveClientsInfo';
import ClientEmploymentPage from './pages/admin/client/clientEmploymentPage';
import ClientAgreement from './pages/client/ClientAgreement/ClientAgreement';
import UpcomingInfoSession from './pages/client/UpcomingInfoSession/UpcomingInfoSession';
import TwilioConfirmation from './components/TwilioConfirmation/TwilioConfirmation';
import WorkingClientList from './pages/admin/client/workingClientList/workingClientList';
import ClientAccountInfoPage from './pages/client/AccountInfo';

function App() {
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const userRole: string = user ? user.role : '';
  const isJobOrWorkshopAllowed: boolean =
    userRole === UserRolesEnum.SUPER_ADMIN ||
    userRole === UserRolesEnum.ADMIN ||
    userRole === UserRolesEnum.EMPLOYMENT_LIAISON;

  const location = useLocation();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Routes>
        <Route path='/forgotPassword' element={<ForgotPassword />} />
        <Route path='/admin/*' element={<Layout />}>
          <Route index element={<Navigate to='dashboard' />} />
          <Route path='client' element={<ClientsList />} />
          <Route path='client-employment' element={<ClientEmploymentPage />} />
          <Route path='clientInfo' element={<ClientInfo />} />
          <Route path='prospective-client-info' element={<ProspectiveClientsInfo />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='working-client-list' element={<WorkingClientList key={location.key} />} />
          <Route path='message' element={<MessagePage />} />
          <Route path='program-track-list' element={<ProgramTrackList />} />
          {isJobOrWorkshopAllowed ? <Route path='workshops' element={<WorkshopList />} /> : null}
          {isJobOrWorkshopAllowed ? <Route path='workshop-info' element={<WorkshopInfo />} /> : null}
          {isJobOrWorkshopAllowed ? <Route path='job-info' element={<JobInfo />} /> : null}
          <Route path='program-track-info' element={<ProgramTrackInfo />} />
          <Route path='resource' element={<Resource />} />
          {isJobOrWorkshopAllowed ? <Route path='job' element={<Job />} /> : null}
          {userRole === UserRolesEnum.SUPER_ADMIN ? <Route path='setting' element={<Setting />} /> : null}
          <Route path='user' element={<UserManagement />} />
          <Route path='analytics' element={<Analytics />} />
          <Route path='report' element={<Report />} />
          <Route path='infoSessionList' element={<InfoSessionList />} />
          <Route path='curriculum-List' element={<CurriculumList />} />
          <Route path='createClient' element={<CreateClient />} />
          <Route path='careerPathList' element={<CareerPathList />} />
          <Route path='info-session-info' element={<InfoSessionInfo />} />
          <Route path='infosessions' element={<UpcomingInfoSession />} />
          {userRole === UserRolesEnum.TRAINER ? (
            <Route path='associate-trainer-list' element={<AssociateTrainersList />} />
          ) : null}
          <Route path='*' element={<Navigate to='dashboard' />} />
        </Route>
        <Route path='/client/account' element={<CreateAccount />} />
        <Route path='/client/*' element={<ClientLayout />}>
          <Route index element={<Navigate to='home' />} />
          <Route path='home' element={<ClientHome />} />
          <Route path='packages' element={<Packages />} />
          <Route path='job-info' element={<JobInfo />} />
          <Route path='career-paths' element={<ProspectiveClientCareerPaths />} />
          <Route path='career-path-info' element={<ProspectiveClientCareerPathInfo />} />
          <Route path='documents' element={<ClientDocumentsPage />} />
          <Route path='messaging' element={<ClientMessagingPage />} />
          <Route path='account-info' element={<ClientAccountInfoPage />} />
          <Route path='*' element={<Navigate to='home' />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/infosession' element={<InfoSession />} />
        <Route path='/twilio-confirmation' element={<TwilioConfirmation />} />
        <Route path='/infosessions/:customUrlName' element={<InfoSession />} />
        <Route path='/clientAgreement' element={<ClientAgreement />} />
        <Route path='/' element={<Login />} />
        <Route path='/*' element={<Navigate to='login' />} />
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
