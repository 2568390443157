import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Typography, Grid } from '@mui/material';

interface Milestone {
  name: string;
  description: string;
}

interface MilestonesListProps {
  milestones: Milestone[];
}

const localStyle = {
  styleTimeline: {
    position: 'relative',
    right: '230px',
  },
  textStyle: {
    color: 'white',
  },
};

const MilestonesList: React.FC<MilestonesListProps> = ({ milestones }) => {
  return (
    <Grid container>
      <Grid item xs={6} md={12}>
        <Timeline sx={localStyle.styleTimeline}>
          {milestones?.map((milestone, index) => (
            <TimelineItem key={milestone.name}>
              <TimelineSeparator>
                <TimelineDot />
                {index !== milestones.length - 1 ? <TimelineConnector /> : null}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant='subtitle1' sx={localStyle.textStyle}>
                  {milestone.name}
                </Typography>
                <Typography variant='body2' sx={localStyle.textStyle}>
                  {milestone.description}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Grid>
    </Grid>
  );
};

export default MilestonesList;
