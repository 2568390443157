import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { enrollClient } from '../../services/client.service';
import { getClientsByProgramTrack } from '../../services/programTrackClientMap.service';
import { ClientStatus } from '../../utils/clientStatus';
import GenericDialog from '../Dialog/genericDialog/genericDialog';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../utils/colors';

const ClientEnrollmentReview = (props: any) => {
  const navigate = useNavigate();
  const { programTrackId } = props;
  const [clientInfo, setClientInfo] = useState([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedClientId, setSelectedClientId] = useState<string>('');
  const user: UserModel = useAppSelector((state: any) => state.user.user);

  const clientEnrollment = async () => {
    props.toggleReviewLoader(true);
    await enrollClient(selectedClientId, programTrackId);
    props.fetchClientsOnReview();
    props.toggleReviewLoader(false);
  };

  const loadClients = async () => {
    const clients = await getClientsByProgramTrack(programTrackId);
    const underReview = clients?.filter((item: any) => item.status === ClientStatus.ENROLLMENT_REVIEW);
    setClientInfo(underReview);
  };

  useEffect(() => {
    const fetchDataOnMount = async () => {
      await loadClients();
    };
    // eslint-disable-next-line
    fetchDataOnMount();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {clientInfo.length > 0 ? (
          clientInfo?.map((client: any) => {
            return (
              <ListItem key={client.id}>
                <ListItemText
                  onClick={() => {
                    navigate('../clientInfo', {
                      state: { clientId: client.id, programTrackId: programTrackId },
                    });
                  }}
                  sx={styles.listItem}
                  primary={`${client.firstName} ${client.lastName}`}
                />
                <LoadingButton
                  onClick={() => {
                    setSelectedClientId(client.id);
                    setOpenDialog(true);
                  }}
                  color='primary'
                  label={'Enroll'}
                  disabled={user.role === UserRolesEnum.TRAINER}
                  styles={{ textTransform: 'none' }}
                  size='small'
                  type='submit'
                  variant='contained'
                />
              </ListItem>
            );
          })
        ) : (
          <ListItem>
            <ListItemText primary={'No client yet.'} />
          </ListItem>
        )}
      </List>
      <GenericDialog
        dialogTitle='Confirmation'
        dialogContent='Are you sure to review this client for enrollment?'
        agreeBtnText='Confirm'
        disagreeBtnText='Cancel'
        agreeFunc={() => {
          clientEnrollment();
        }}
        onClose={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
      />
    </>
  );
};

export default ClientEnrollmentReview;

const styles = {
  listItem: {
    p: 1,
    borderRadius: 2,
    ':hover': { cursor: 'pointer', backgroundColor: COLORS.card.cardBg },
  },
};
