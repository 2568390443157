import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';
import { Field } from 'formik';
import { HourFormat, createTimeSlots } from '../../utils/dateTime';

export const TimeDropdown = ({ name }: any) => {
  const temp = createTimeSlots('01:00', '12:45', 15, false);
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
  };
  return (
    <>
      <Grid item xs={6}>
        <Field
          fullWidth
          name={`${name}.time`}
          as={TextField}
          label='HH:MM'
          size='small'
          select
          SelectProps={{
            IconComponent: null,
            MenuProps: menuProps,
          }}>
          {temp.map((hour) => (
            <MenuItem key={hour} value={hour}>
              {hour}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          name={`${name}.meridiem`}
          as={TextField}
          label='AM/PM'
          size='small'
          select
          SelectProps={{
            IconComponent: null,
            MenuProps: menuProps,
          }}>
          {HourFormat.map((meridiem, index) => (
            <MenuItem key={index} value={meridiem.value}>
              {meridiem.title}
            </MenuItem>
          ))}
        </Field>
      </Grid>
    </>
  );
};
