import React from 'react';
import { FileUploadOutlined, VisibilityOutlined, CheckCircleOutlined, CloudUploadOutlined } from '@mui/icons-material';
import { Box, Button, Paper, Typography, Grid } from '@mui/material';
import { approve154ELetter, upload154ELetter } from '../../services/client.service';
import { toast } from 'react-toastify';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { COLORS } from '../../utils/colors';
import { LoadingButton } from '../LoadingButton/LoadingButton';

interface IClientLetterProps {
  clientInfo: any;
}

const localStyle = {
  uploadBtn: {
    width: '100%',
    borderRadius: '100px',
  },
  boxStyle: {
    backgroundColor: '#f6f6f6',
    borderRadius: '10px',
  },
  verifyBtn: {
    color: COLORS.palette.green,
    width: '350px',
  },
};

const ClientLetter = (props: IClientLetterProps) => {
  const { clientInfo } = props;
  const nofile = 'Browse file to upload';
  const user: UserModel = useAppSelector((state: any) => state.user.user) || {};
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [file, setFile] = React.useState({ name: null });
  const [loading, setLoading] = React.useState(false);
  const [schoolLetterUrl, setSchoolLetterUrl] = React.useState(clientInfo?.letter_154E?.url);

  const [isLetterUploaded, setIsLetterUploaded] = React.useState(clientInfo?.letter_154E?.url ? true : false);
  const [isLetterVerified, setIsLetterVerified] = React.useState(clientInfo?.letter_154E?.isVerified ? true : false);

  const hasUserViewPermit =
    user.role === UserRolesEnum.ADMIN ||
    user.role === UserRolesEnum.SUPER_ADMIN ||
    user.role === UserRolesEnum.CASE_MANAGER ||
    user.role === UserRolesEnum.NAVIGATOR;
  const hasUserUploadPermit =
    user.role === UserRolesEnum.TRAINER || user.role === UserRolesEnum.ADMIN || user.role === UserRolesEnum.SUPER_ADMIN;

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const { files } = event.target;
    if (files[0]?.size > 25 * 1024 * 1024) {
      setFile({ name: null });
      toast.error('File Size is too large');
    } else {
      setFile(files[0]);
    }
  };

  const handleLetterUpload = async () => {
    setLoading(true);
    const url = await upload154ELetter(file, clientInfo?.id);
    setLoading(false);
    setIsLetterUploaded(true);
    setSchoolLetterUrl(url);
  };

  const handleLetterApprove = async () => {
    setLoading(true);
    await approve154ELetter(clientInfo?.id);
    setLoading(false);
    setIsLetterVerified(true);
  };

  //letter view render function
  const renderLetterView = (letterUrl: string) => {
    if (letterUrl || isLetterVerified) {
      return (
        <Box display={'flex'} alignItems={'center'}>
          <Button
            sx={{ width: '350px' }}
            type='submit'
            variant='outlined'
            startIcon={<VisibilityOutlined />}
            onClick={() => {
              window.open(schoolLetterUrl);
            }}>
            {'View'}
          </Button>
          <Box px={1} />

          {/* Letter verification button */}
          <LoadingButton
            onClick={() => {
              handleLetterApprove();
            }}
            label={clientInfo.letter_154E?.isVerified || isLetterVerified ? 'Verified' : 'Verify'}
            styles={localStyle.verifyBtn}
            loading={loading}
            disabled={clientInfo.letter_154E?.isVerified || isLetterVerified || loading ? true : false}
            size='medium'
            type='submit'
            variant='outlined'
            startIcon={<CheckCircleOutlined />}
          />
        </Box>
      );
    }
    if (!letterUrl) {
      return (
        <Typography p={2} variant='body1' textAlign={'center'}>
          School letter not uploaded yet.
        </Typography>
      );
    }
  };

  //letter upload render function
  const renderLetterUpload = () => {
    return (
      <Paper elevation={0} sx={{ width: '100%' }}>
        <input type='file' name='file' ref={fileInputRef} onChange={handleFileChange} hidden />
        <Box width='100%' p={4} sx={localStyle.boxStyle}>
          <Box mt={2} border='2px dashed' borderColor={COLORS.theme.primaryColor} onClick={handleClick} height='250px'>
            <Box display='flex' flexDirection='column' height='100%' justifyContent='center' alignItems='center'>
              <CloudUploadOutlined fontSize='large' color='primary' />
              <Typography variant='body1' textTransform={'none'} fontWeight={'bold'} color={COLORS.theme.primaryColor}>
                {file?.name ? file?.name : nofile}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' justifyContent='center' mt={2}>
            <LoadingButton
              onClick={() => {
                handleLetterUpload();
              }}
              label={'Upload'}
              styles={localStyle.uploadBtn}
              loading={loading}
              disabled={!file?.name || loading}
              size='large'
              type='submit'
              variant='contained'
              endIcon={<FileUploadOutlined />}
            />
          </Box>
        </Box>
      </Paper>
    );
  };

  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item lg={6} xs={12}>
          {/* letter upload */}
          {hasUserUploadPermit && !isLetterUploaded ? renderLetterUpload() : null}

          {/* letter view */}
          {hasUserViewPermit && isLetterUploaded ? renderLetterView(schoolLetterUrl) : null}

          {/* no permission message */}
          {!hasUserUploadPermit && !hasUserViewPermit ? (
            <Typography p={2} variant='body1' textAlign={'center'}>
              No permission.
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default ClientLetter;
