import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Box, Stack } from '@mui/material';
import styled from '@emotion/styled';
import '../../../../config/firebaseApp';
import { fetchProgramTracks } from '../../../../services/firebase.service';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { Loader } from '../../../../components/Loader/Loader';

const HeaderText = styled.div`
  color: #ffffff;
  font-weight: 100;
  font-size: 16px;
  font-family: 'Archivo', sans-serif;
  font-style: italic;
`;

const UserAvatar = styled.div`
  height: 31px;
  width: 31px;
  background: #d8d8d8;
  border-radius: 100%;
`;

const CardContainer = styled(Card)`
  width: 250px;
  height: 400px;
  position: relative;
  background: #2f369b;
  padding: 0px 15px;
  border-radius: 20px;
  border: '2px solid #ede889';
  margin: 0px 10px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-style: italic;
`;

const BGImg = styled.img`
  position: absolute;
  top: 85px;
  left: 70px;
`;

const PackageLable = styled.h3`
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  color: #fff;
  margin-top: 45px;
`;

const SubTitleContainer = styled.ul`
  padding-left: 20px;
  margin-top: 80px;
`;

const SubTitleText = styled.li`
  margin: 16px 0px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
`;

const GridStyled = styled(Grid)`
  overflow-x: auto;
  margin-top: 20px;

  @media (min-width: 840px) {
    justify-content: center;
  }
`;

function Packages() {
  const dispatch = useAppDispatch();

  const programTracks = useAppSelector((state: any) => state.programTracks.programTracks);
  const fetching = useAppSelector((state: any) => state.programTracks.loading);

  useEffect(() => {
    const getProgramTracks = () => dispatch(fetchProgramTracks());
    getProgramTracks();
  }, [dispatch]);

  return (
    <>
      <Stack
        direction={{ xs: 'row', sm: 'row' }}
        justifyContent='space-between'
        alignItems='center'
        spacing={{ xs: 1, sm: 2, md: 4 }}
        sx={{ px: 2, pt: 5, pb: 3 }}>
        <div>
          <img src='/assets/white-menu-icon.png' alt='' />
        </div>
        <HeaderText>PROGRAM TRACKS</HeaderText>
        <UserAvatar />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='center' alignItems='center' sx={{ p: 2 }}>
        <img src='/assets/logo.png' alt='' />
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        {fetching ? (
          <Loader />
        ) : (
          <GridStyled spacing={2} container direction='row' alignItems='stretch' flexWrap='nowrap'>
            {programTracks?.length &&
              programTracks?.map((item: any, index: number) => {
                return (
                  <Grid key={index?.toString()} item>
                    <CardContainer>
                      <CardContent>
                        <BGImg src='/assets/commercial-driver-vector.png' alt='' />
                        <PackageLable>{item?.name}</PackageLable>
                        <div style={{ textAlign: 'left' }}>
                          <SubTitleContainer>
                            {item?.subTitlesList?.length > 0 &&
                              item?.subTitlesList?.map((val: string) => {
                                return <SubTitleText key={val}>{val}</SubTitleText>;
                              })}
                          </SubTitleContainer>
                        </div>
                      </CardContent>
                    </CardContainer>
                  </Grid>
                );
              })}
          </GridStyled>
        )}
      </Box>
    </>
  );
}

export default Packages;
