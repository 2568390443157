import { Box } from '@mui/material';
import React, { useState } from 'react';
import { DashboardSmallCard } from '../DashboardSmallCard/DashboardSmallCard';
import { MoreTimeOutlined } from '@mui/icons-material';
import AddWorkshop from '../Modals/AddWorkshop/AddWorkshop';
import { WriteModeEnum } from '../../utils/writeModeEnum';

const AddWorkshopButton = () => {
  const [showWorkshopModal, setShowWorkshopModal] = useState<boolean>(false);
  return (
    <>
      <Box style={{ cursor: 'pointer' }} onClick={() => setShowWorkshopModal(true)}>
        <DashboardSmallCard label='Create Workshop' desc='Schedule an Offline Workshop' avatar={<MoreTimeOutlined />} />
      </Box>
      <AddWorkshop
        open={showWorkshopModal}
        onClose={() => setShowWorkshopModal(false)}
        mode={WriteModeEnum.CREATE}
        data={null}
        onAddWorkshop={() => {}}
      />
    </>
  );
};

export default AddWorkshopButton;
