import React, { useState, useEffect } from 'react';
import { Formik, Field, FieldArray, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Typography,
  MenuItem,
  Divider,
  InputLabel,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from '../../../utils/colors';
import { getCareerPathList } from '../../../services/careerPath.service';
import { addNewCurriculum, fetchCurriculums } from '../../../services/curriculum.service';
import { useAppSelector } from '../../../store/store';
import { UserModel } from '../../../store/user/userModel';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import { editCurriculumById } from '../../../services/curriculum.service';
import { getTrainerList } from '../../../services/user.service';
import { toast } from 'react-toastify';
import { TimeDropdown } from '../../TimeDropdown/TimeDropdown';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ICurriculumModel } from '../../../store/curriculum/curriculumModel';
import { WriteModeEnum } from '../../../utils/writeModeEnum';
import { CurriculumDeliver, CurriculumModes } from '../../../utils/curriculumEnum';
import { WriteMode } from '../../../utils/common';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddCircle } from '@mui/icons-material';

interface AddNewCurriculumProps {
  open: boolean;
  mode: WriteMode;
  data?: any;
  onClose: () => void;
  onAddCurriculum?: () => void;
}

const AddNewCurriculum = (props: AddNewCurriculumProps) => {
  const { open, mode, data, onClose, onAddCurriculum } = props;
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [careerPath, setCareerPath] = useState<any[]>([]);
  const [trainers, setTrainers] = useState<any[]>([]);
  const [selectedCareerPath, setSelectedCareerPath] = useState<any>();
  const [selectedTrainer, setSelectedTrainer] = useState<any>();
  const [curriculumList, setCurriculumList] = useState<any>([]);

  const emptyMilestone = {
    name: '',
    type: '',
    days: '',
    deliver: '',
    mode: '',
    startTime: '',
    endTime: '',
  };

  const initialValues: ICurriculumModel = {
    careerPath: '',
    trainer: '',
    name: '',
    description: '',
    milestones: [emptyMilestone],
  };

  const validationSchema = Yup.object({
    careerPath: Yup.string().required('CareerPath is required'),
    trainer: Yup.string().required('Trainer is required'),
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    milestones: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Milestone name is required'),
          type: Yup.string().required('Milestone type is required'),
          days: Yup.number().required('Day is required').positive('Day must be a positive number'),
          startTime: Yup.object().required('Start time is required'),
          endTime: Yup.object().required('End time is required'),
          mode: Yup.string().optional(),
          deliver: Yup.string().optional(),
        })
      )
      .min(1, 'At least one milestone is required'),
  });

  const convertTimeStringToObject = (timeString: string) => {
    const [time, meridiem] = timeString.split(' ');
    return {
      time: time,
      meridiem: meridiem,
    };
  };

  const getCurriculums = async () => {
    const list = await fetchCurriculums();
    setCurriculumList(list);
  };

  React.useEffect(() => {
    getCurriculums();
    // eslint-disable-next-line
  }, []);

  //get initial values on edit
  const getInitialValuesOnEdit = () => {
    const milestones = data?.milestones;
    const updatedMilestones = milestones?.map((milestone: any) => {
      const updatedMilestone = { ...milestone };
      if (milestone.startTime && milestone.endTime) {
        updatedMilestone.startTime = convertTimeStringToObject(milestone.startTime.toString());
        updatedMilestone.endTime = convertTimeStringToObject(milestone.endTime.toString());
      }
      return updatedMilestone;
    });

    return {
      careerPath: data?.careerPathId,
      trainer: data?.trainerId,
      name: data?.name,
      description: data?.description,
      milestones: updatedMilestones,
    };
  };

  //handle submit functionality
  const handleCreate = async (values: ICurriculumModel) => {
    if (user.id && selectedCareerPath) {
      const milestones = values?.milestones;
      let previousMilestone: any = {};
      let previousDays = -Infinity;

      for (const milestone of milestones) {
        const currentDays = Number(milestone.days);
        if (currentDays < previousDays) {
          toast.error(`Milestone ${previousMilestone?.name} should be after milestone ${milestone.name}!`);
          throw new Error('Invalid milestones order');
        } else {
          previousDays = currentDays;
          previousMilestone = milestone;
        }
      }
      for (const milestone of milestones) {
        if (milestone.startTime && milestone.endTime) {
          milestone.startTime = `${milestone.startTime.time} ${milestone.startTime.meridiem}`;
          milestone.endTime = `${milestone.endTime.time} ${milestone.endTime.meridiem}`;
        }
      }
      const formValues = {
        careerPathId: selectedCareerPath?.id,
        trainerId: selectedTrainer?.id,
        createdBy: user?.id,
        description: values?.description,
        name: values?.name,
        milestones: milestones,
      };
      try {
        await addNewCurriculum(formValues, user?.role);
        onAddCurriculum?.();
      } catch (error) {
        console.error(error);
      }
      onClose();
    }
  };

  //handle edit functionality
  const handleEdit = async (values: ICurriculumModel) => {
    if (user?.id) {
      const milestones = values.milestones;
      for (const milestone of milestones) {
        if (milestone.startTime && milestone.endTime) {
          milestone.startTime = `${milestone.startTime.time} ${milestone.startTime.meridiem}`;
          milestone.endTime = `${milestone.endTime.time} ${milestone.endTime.meridiem}`;
        }
      }
      const params = {
        careerPathId: values.careerPath,
        name: values.name,
        description: values.description,
        milestones: milestones,
      };
      const curriculumId = data?.id || '';

      try {
        await editCurriculumById(curriculumId, params);
        onAddCurriculum?.();
      } catch (err: any) {
        console.error(err?.response?.data?.message || err?.message);
      }
      onClose();
    }
  };

  useEffect(() => {
    (async () => {
      const data = await getCareerPathList();
      setCareerPath(data);
      const trainerList = await getTrainerList();
      setTrainers(trainerList);
      const cpath = careerPath.find((item: any) => item.id == data?.careerPathId);
      setSelectedCareerPath(cpath);
      const trainer = trainers.find((item: any) => item.id == data?.trainerId);
      setSelectedTrainer(trainer);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  //get career path name by id
  const getCareerPathNameById = (id: string) => {
    const cpath: any = careerPath.find((item: any) => item.id === id);
    return cpath?.name;
  };

  //get trainer name by id
  const getTrainerNameById = (id: string) => {
    const trainer: any = trainers.find((item: any) => item.id === id);
    return trainer ? trainer.firstName + ' ' + trainer.lastName : null;
  };

  const validateCurriculumName = (values: ICurriculumModel) => {
    const errors: any = {};
    const newName = values.name;

    if (
      curriculumList.some((item: any) => item.name.toLowerCase() === newName.toLowerCase()) &&
      mode === WriteModeEnum.CREATE
    ) {
      errors.name = 'Name already exists';
    } else if (
      mode === WriteModeEnum.EDIT &&
      newName !== data?.name &&
      curriculumList.some((item: any) => item.name.toLowerCase() === newName.toLowerCase())
    ) {
      errors.name = 'Name already exists';
    }
    return errors;
  };

  const handleSubmit = async (values: ICurriculumModel, { setSubmitting }: FormikHelpers<ICurriculumModel>) => {
    if (mode === WriteModeEnum.CREATE) {
      await handleCreate(values);
    } else {
      await handleEdit(values);
    }
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: '14px' } }}>
      <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Typography variant='h6'>
            {mode === WriteModeEnum.CREATE ? 'Create New Curriculum' : 'Edit Curriculum'}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={mode === WriteModeEnum.CREATE ? initialValues : getInitialValuesOnEdit()}
          validationSchema={validationSchema}
          validate={validateCurriculumName}
          onSubmit={handleSubmit}>
          {({ isValid, values, handleSubmit, setFieldValue, getFieldProps, isSubmitting, errors, touched }) => (
            <Box py={2}>
              <Grid container spacing={2} pb={2}>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    size='small'
                    value={getCareerPathNameById(selectedCareerPath?.id)}
                    disableClearable
                    options={careerPath?.map((option: any) => option.name)}
                    renderInput={(params) => <TextField {...params} label='Select Career Path' variant='outlined' />}
                    onChange={(event: Object, selectedValue: string) => {
                      const selectedPath: any = careerPath.find((item: any) => item.name === selectedValue);
                      setSelectedCareerPath(selectedPath);
                      setFieldValue('careerPath', selectedPath.id);
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Autocomplete
                    size='small'
                    value={getTrainerNameById(selectedTrainer?.id) || undefined}
                    disableClearable
                    disabled={mode === WriteModeEnum.CREATE ? false : true}
                    options={trainers?.map((option: any) => option.firstName + ' ' + option.lastName)}
                    renderInput={(params) => <TextField {...params} label='Select Trainer' variant='outlined' />}
                    onChange={(event: Object, selectedValue: string) => {
                      const selectedTrainer: any = trainers.find(
                        (item: any) => item.firstName + ' ' + item.lastName === selectedValue
                      );
                      setSelectedTrainer(selectedTrainer);
                      setFieldValue('trainer', selectedTrainer.id);
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Field
                    fullWidth
                    name='name'
                    as={TextField}
                    label='Name'
                    variant='outlined'
                    size='small'
                    error={touched.name && errors.name}
                    helperText={touched.name && errors.name ? errors.name : ''}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Field
                    name='description'
                    as={TextField}
                    label='Description'
                    variant='outlined'
                    size='small'
                    fullWidth
                    multiline
                    rows={4}
                    error={touched.description && errors.description}
                    helperText={touched.description && errors.description ? errors.description : ''}
                  />
                </Grid>

                {/* Milestones form divider */}
                <Grid item md={12} xs={12}>
                  <Divider>Fill Milestones Information</Divider>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FieldArray
                    name='milestones'
                    render={({ push, remove }) => (
                      <>
                        {values.milestones.map((milestone, index) => (
                          <Grid container key={String(milestone)} spacing={1}>
                            <Grid item lg={12} md={12} xs={12}>
                              <Field
                                name={`milestones.${index}.name`}
                                as={TextField}
                                label='Milestone Name'
                                variant='outlined'
                                size='small'
                                style={{ width: '100%' }}
                              />
                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>
                              <Autocomplete
                                fullWidth
                                size='small'
                                defaultValue={mode === WriteModeEnum.CREATE ? data?.milestones[index]?.type : ''}
                                disableClearable
                                options={['Class', 'Test', 'File Upload']}
                                renderInput={(params) => (
                                  <TextField {...params} label='Milestone Type' variant='outlined' />
                                )}
                                onChange={(event: Object, selectedValue: string) => {
                                  setFieldValue(`milestones.${index}.type`, selectedValue);
                                }}
                              />
                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>
                              <Field
                                fullWidth
                                name={`milestones.${index}.days`}
                                as={TextField}
                                label='Day'
                                variant='outlined'
                                size='small'
                              />
                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>
                              <TextField
                                fullWidth
                                select
                                size='small'
                                id='demo-controlled-open-select'
                                label='Mode'
                                {...getFieldProps(`milestones.${index}.mode`)}>
                                {CurriculumModes.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.value}
                                    onClick={(e: any) => {
                                      setFieldValue(`milestones.${index}.mode`, e.target.dataset.value);
                                    }}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>
                              <TextField
                                fullWidth
                                select
                                size='small'
                                id='demo-controlled-open-select'
                                label='Deliver'
                                {...getFieldProps(`milestones.${index}.deliver`)}>
                                {CurriculumDeliver.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.value}
                                    onClick={(e: any) => {
                                      setFieldValue(`milestones.${index}.deliver`, e.target.dataset.value);
                                    }}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>
                              <InputLabel sx={{ marginBottom: '5px' }}>Start Time</InputLabel>
                              <Grid container>
                                <TimeDropdown name={`milestones.${index}.startTime`} />
                              </Grid>
                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>
                              <InputLabel sx={{ marginBottom: '5px' }}>End Time</InputLabel>
                              <Grid container>
                                <TimeDropdown name={`milestones.${index}.endTime`} />
                              </Grid>
                            </Grid>

                            {/* Remove milestone button */}
                            <Grid container justifyContent={'right'} py={2}>
                              <Grid item>
                                <Tooltip title='Remove Milestone' arrow>
                                  <IconButton color='error' onClick={() => remove(index)}>
                                    <DeleteIcon
                                      style={{
                                        color: COLORS.palette.red,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}

                        <Grid container justifyContent={'left'} py={2}>
                          <Grid item>
                            <Tooltip title='Add Milestone' arrow>
                              <IconButton color='primary' onClick={() => push(emptyMilestone)}>
                                <AddCircle
                                  style={{
                                    color: COLORS.theme.primaryColor,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </>
                    )}></FieldArray>
                </Grid>

                {/* Submit button */}
                <Grid container justifyContent={'center'}>
                  <Grid item>
                    <LoadingButton
                      label={mode === WriteModeEnum.CREATE ? 'Submit' : 'Update'}
                      onClick={() => handleSubmit()}
                      loading={isSubmitting}
                      disabled={!isValid}
                      size='medium'
                      type='submit'
                      variant='contained'
                      styles={{ textTransform: 'none' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewCurriculum;
