import React, { FunctionComponent, useEffect } from 'react';
import { MenuItem, Grid, Modal, Box, styled, IconButton, Typography } from '@mui/material';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, type FormikHelpers } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { addNewUser, editUser } from '../../../services/user.service';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import { UserRolesEnum } from '../../../utils/rolesEnum';
import { getRoleLabel } from '../../../utils/user';
import { adminTypes } from '../../../utils/adminTypes';
import { caseManagerTypeOptions } from '../../../utils/caseManagerTypes';

interface CreateUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  organization?: string;
  location?: string;
  adminType?: string;
  caseManagerType?: string;
  id?: string;
}

interface AddUserModalProps {
  onClose: () => void;
  open: boolean;
  data: CreateUserFormData | null;
  onAddUser: () => void;
}

const schema = yup.object().shape({
  email: yup.string().email().required('Email address is required'),
  firstName: yup.string().when('role', {
    is: (val: string) => val !== UserRolesEnum.EMPLOYER,
    then(schema) {
      return schema.required('First Name is required');
    },
  }),
  lastName: yup.string().when('role', {
    is: (val: string) => val !== UserRolesEnum.EMPLOYER,
    then(schema) {
      return schema.required('Last Name is required');
    },
  }),
  role: yup.string().required('Role is required'),
  organization: yup.string().when('role', {
    is: (val: string) => val === UserRolesEnum.TRAINER,
    then(schema) {
      return schema.required('Organization is required');
    },
  }),
  location: yup.string().when('role', {
    is: (val: string) => val === UserRolesEnum.TRAINER,
    then(schema) {
      return schema.required('Location is required');
    },
  }),
  adminType: yup.string().when('role', {
    is: (val: string) => val === UserRolesEnum.ADMIN,
    then(schema) {
      return schema.required('Admin Type is required');
    },
  }),
  caseManagerType: yup.string().when('role', {
    is: (val: string) => val === UserRolesEnum.CASE_MANAGER,
    then(schema) {
      return schema.required('Case Manager Type is required');
    },
  }),
});

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const AddUserModal: FunctionComponent<AddUserModalProps> = (props) => {
  const [selectedRole, setSelectedRole] = React.useState<string | null>('');

  //saff roles for dropdown
  const roles = [
    { id: 1, value: UserRolesEnum.ADMIN, title: getRoleLabel(UserRolesEnum.ADMIN) },
    { id: 2, value: UserRolesEnum.SUPER_ADMIN, title: getRoleLabel(UserRolesEnum.SUPER_ADMIN) },
    { id: 3, value: UserRolesEnum.NAVIGATOR, title: getRoleLabel(UserRolesEnum.NAVIGATOR) },
    { id: 4, value: UserRolesEnum.CASE_MANAGER, title: getRoleLabel(UserRolesEnum.CASE_MANAGER) },
    { id: 5, value: UserRolesEnum.TRAINER, title: getRoleLabel(UserRolesEnum.TRAINER) },
    { id: 6, value: UserRolesEnum.EMPLOYER, title: getRoleLabel(UserRolesEnum.EMPLOYER) },
    { id: 7, value: UserRolesEnum.EMPLOYMENT_LIAISON, title: getRoleLabel(UserRolesEnum.EMPLOYMENT_LIAISON) },
  ];

  //initial values for formik
  const initialValues: CreateUserFormData = {
    firstName: props.data?.firstName || '',
    lastName: props.data?.lastName || '',
    email: props.data?.email || '',
    role: props.data?.role || '',
    organization: props.data?.organization || '',
    location: props.data?.location || '',
    adminType: props.data?.adminType || '',
    caseManagerType: props.data?.caseManagerType || '',
  };

  //handle submit form
  const handleSubmit = async (values: CreateUserFormData, { setSubmitting }: FormikHelpers<CreateUserFormData>) => {
    const params: any = {
      email: values.email,
      role: values.role,
    };

    if (values.role !== UserRolesEnum.EMPLOYER) {
      params.firstName = values.firstName;
      params.lastName = values.lastName;
    }

    if (values.role === UserRolesEnum.TRAINER || values.role === UserRolesEnum.EMPLOYER) {
      params.location = values.location;
      params.organization = values.organization;
    }
    if (values.role === UserRolesEnum.ADMIN) {
      params.adminType = values.adminType;
    }
    if (values.role === UserRolesEnum.CASE_MANAGER) {
      params.caseManagerType = values.caseManagerType;
    }

    try {
      let result: any;
      if (props.data) {
        result = await editUser({
          ...params,
          id: props.data.id,
        });
      } else {
        result = await addNewUser({ ...params, loggedIn: false });
      }

      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (err: any) {
      toast.error(err?.message);
    }
    setSubmitting(false);
    props.onClose();
    props.onAddUser();
  };

  useEffect(() => {
    setSelectedRole(props.data?.role || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const onClose = () => {
    props.onClose();
    setSelectedRole(null);
  };
  return (
    <ModalWrapper
      open={props.open}
      onClose={() => {
        onClose();
      }}>
      <Formik initialValues={props.data ? props.data : initialValues} onSubmit={handleSubmit} validationSchema={schema}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          getFieldProps,
          isSubmitting,
        }) => (
          <Box sx={styles.centeredStyle}>
            <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
              <Typography variant='h5' fontWeight={'bold'}>
                {props.data ? 'Edit' : 'Add'} User
              </Typography>
              <IconButton onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            {/* Staff Role Dropdown */}
            <Grid container spacing={2} pb={2}>
              <Grid item lg={12} xs={12}>
                <TextField
                  fullWidth
                  select
                  size='small'
                  id='demo-controlled-open-select'
                  sx={{ height: '45px' }}
                  label='Role'
                  {...getFieldProps('role')}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}>
                  {roles.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.value}
                      onClick={(e: any) => {
                        setSelectedRole(e.target.dataset.value);
                        values.adminType = '';
                        values.location = '';
                        values.organization = '';
                      }}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {/* First and Last Name */}
            {selectedRole !== UserRolesEnum.EMPLOYER ? (
              <Grid container spacing={2} pb={2}>
                <Grid item lg={6} xs={12}>
                  <TextField
                    fullWidth
                    size='small'
                    autoComplete='firstName'
                    name='firstName'
                    type='text'
                    label='First Name'
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    fullWidth
                    size='small'
                    autoComplete='lastName'
                    type='text'
                    label='Last Name'
                    name='lastName'
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>
              </Grid>
            ) : null}

            {/* Email address */}
            <Grid container spacing={2} pb={2}>
              <Grid item lg={12} xs={12}>
                <TextField
                  fullWidth
                  size='small'
                  type='email'
                  name='email'
                  label='Email address'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
            </Grid>

            {selectedRole === UserRolesEnum.TRAINER || selectedRole === UserRolesEnum.EMPLOYER ? (
              <Grid container spacing={2} pb={2}>
                <Grid item lg={6} xs={12}>
                  <TextField
                    fullWidth
                    size='small'
                    autoComplete='organization'
                    name='organization'
                    type='text'
                    label='Organization'
                    value={values.organization}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.organization && Boolean(errors.organization)}
                    helperText={touched.organization && errors.organization}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    fullWidth
                    size='small'
                    autoComplete='location'
                    type='text'
                    label='Location'
                    name='location'
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.location && Boolean(errors.location)}
                    helperText={touched.location && errors.location}
                  />
                </Grid>
              </Grid>
            ) : selectedRole === UserRolesEnum.ADMIN ? (
              <Grid item lg={6} xs={12} pb={2}>
                <TextField
                  fullWidth
                  size='small'
                  name='adminType'
                  value={values.adminType}
                  label='Admin Type'
                  select
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.adminType && Boolean(errors.adminType)}>
                  {adminTypes.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}

            {selectedRole === UserRolesEnum.CASE_MANAGER && (
              <Grid item lg={6} xs={12} pb={2}>
                <TextField
                  fullWidth
                  size='small'
                  name='caseManagerType'
                  value={values.caseManagerType}
                  label='Case Manager Type'
                  select
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.adminType && Boolean(errors.adminType)}>
                  {caseManagerTypeOptions.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            {/* Submit form button */}
            <Grid container justifyContent={'center'}>
              <Grid item>
                <LoadingButton
                  onClick={() => handleSubmit()}
                  label={props.data ? 'Update' : 'Add'}
                  loading={isSubmitting}
                  disabled={!isValid}
                  size='medium'
                  type='submit'
                  variant='contained'
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default AddUserModal;

const styles = {
  centeredStyle: {
    width: '500px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    borderRadius: '14px',
    '@media (max-width: 480px)': {
      width: '90%',
      height: '90%',
      padding: '20px',
      margin: '10px',
    },
  },
};
