import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import GenericDialog from '../../../components/Dialog/genericDialog/genericDialog';
import CustomTable from '../../../components/CustomTable';
import AddNewCurriculum from '../../../components/Modals/AddNewCurriculum/AddNewCurriculum';
import {
  deleteCurriculum,
  getCurriculumList,
  pseudoAddCurriculum,
  searchCurriculum,
} from '../../../services/curriculum.service';
import { convertTimeStampToDate } from '../../../utils/dateTime';
import { shouldShowButton } from '../dashboard/dashboardAccessControl';
import { UserModel } from '../../../store/user/userModel';
import { useAppSelector } from '../../../store/store';
import { COLORS } from '../../../utils/colors';
import { ICurriculumMetaInfo } from '../../../store/curriculum/curriculumModel';

const CurriculumList = (props: any) => {
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [curriculumList, setCurriculumList] = React.useState<Array<any>>([]);
  const [curriculumModal, setCurriculumModal] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteSessionId, setDeleteSessionId] = useState(String);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [editMode, setEditMode] = useState({
    isEditMode: false,
    editData: {
      name: '',
      description: '',
      createdAt: '',
      id: '',
      careerPathId: '',
      trainerId: '',
    },
  });

  const PAGE_SIZE = 10;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [curriculumMetaInfo, setICurriculumMetaInfo] = React.useState<ICurriculumMetaInfo>();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [searchPaginationModel, setSearchPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [isSearchMode, setIsSearchMode] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      await deleteCurriculum(deleteSessionId);
      getAllCurriculumList();
    } catch (err: any) {
      console.error(err?.response?.data?.message || err?.message);
    }
  };

  const getAllCurriculumList = async () => {
    setLoading(true);
    setIsSearchMode(false);
    setSearchQuery('');
    const result = await getCurriculumList(paginationModel.page + 1, sortModel);

    if (result?.curriculums.length) {
      setICurriculumMetaInfo(result);
      const sessionDateTimeList = result?.curriculums.map((item: any) => {
        return {
          ...item,
          createdAt: convertTimeStampToDate(item.createdAt).toDate(),
        };
      });

      setCurriculumList([...sessionDateTimeList]);
    }
    setLoading(false);
  };

  useEffect(
    () => {
      getAllCurriculumList();
    },
    // eslint-disable-next-line
    [editMode.isEditMode, editMode.editData, props, shouldFetch, paginationModel.page, sortModel]
  );

  const handleAddCurriculum = () => {
    setShouldFetch(true);
    getAllCurriculumList();
  };

  const handleSearch = async () => {
    if (searchQuery) {
      setLoading(true);
      setIsSearchMode(true);
      const result = await searchCurriculum(searchQuery.toLowerCase().trim(), searchPaginationModel.page + 1);

      if (result?.curriculums.length) {
        setICurriculumMetaInfo(result);
        const sessionDateTimeList = result?.curriculums.map((item: any) => {
          return {
            ...item,
            createdAt: convertTimeStampToDate(item.createdAt).toDate(),
          };
        });

        setCurriculumList([...sessionDateTimeList]);
      } else {
        setICurriculumMetaInfo(result);
        setCurriculumList([]);
      }
      setLoading(false);
    }
  };

  const dummyCallAddCurriculum = async () => {
    await pseudoAddCurriculum();
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [searchPaginationModel.page]);

  useEffect(() => {
    if (!searchQuery) {
      getAllCurriculumList();
    }
    // eslint-disable-next-line
  }, [searchQuery]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      sortable: true,
      width: 250,
    },
    {
      field: 'description',
      headerName: 'Description',
      type: 'string',
      sortable: true,
      width: 250,
    },
    {
      field: 'createdAt',
      headerName: 'Date & Time',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) => dayjs(params.row.createdAt).format('MM/DD/YYYY hh:mm A'),
    },
    {
      headerName: 'Actions',
      sortable: false,
      width: 200,
      renderCell: (params: any) => (
        <Box display={'flex'} gap={2}>
          <IconButton
            onClick={() => {
              setEditMode({ ...editMode, isEditMode: true, editData: params.row });
              setCurriculumModal(true);
            }}
            disabled={!shouldShowButton('createCareerPath', user?.role)}>
            <EditIcon
              style={{
                color: !shouldShowButton('createCareerPath', user?.role) ? 'grey' : COLORS.theme.linkColor,
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeleteSessionId(params.row.id);
              setDeleteAlert(true);
            }}
            disabled={!shouldShowButton('createCareerPath', user?.role)}>
            <DeleteIcon
              style={{
                color: !shouldShowButton('createCareerPath', user?.role) ? 'grey' : '#d32f2f',
              }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleSortModelChange = (newSortModel: any) => {
    setSortModel(newSortModel);
  };

  return (
    <Box>
      <CustomTable
        columns={columns}
        hideSearch={false}
        rows={curriculumList.length ? curriculumList : []}
        filterList={[]}
        loading={loading}
        onFilterListChange={() => {}}
        onPaginationModelChange={isSearchMode ? setSearchPaginationModel : setPaginationModel}
        paginationModel={isSearchMode ? searchPaginationModel : paginationModel}
        primaryButtonLable='Create Curriculum'
        primaryButtonAction={() => {
          setEditMode({ ...editMode, isEditMode: false });
          setCurriculumModal(true);
          dummyCallAddCurriculum();
        }}
        disabled={!shouldShowButton('createNewCurriculum', user?.role)}
        rowCount={curriculumMetaInfo?.totalRecords || 0}
        title='Curriculums'
        onChangeSearchQuery={(query: string) => {
          setSearchQuery(query);
        }}
        handleSearch={() => handleSearch()}
        searchQuery={searchQuery}
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel}
      />

      <AddNewCurriculum
        open={curriculumModal}
        data={editMode.isEditMode ? editMode.editData : null}
        onClose={() => setCurriculumModal(false)}
        mode={editMode.isEditMode ? 'edit' : 'create'}
        onAddCurriculum={handleAddCurriculum}
      />
      <GenericDialog
        onClose={() => setDeleteAlert(false)}
        dialogTitle='Are you sure?'
        agreeBtnText='Delete'
        disagreeBtnText='Cancel'
        agreeFunc={() => handleDelete()}
        open={deleteAlert}
      />
    </Box>
  );
};

export default CurriculumList;
