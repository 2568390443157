import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import TextField from '../../ReusableComponents/Textfield';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { categories } from '../../utils/categoryOptions';

interface IAddNoteProps {
  open: boolean;
  loading: boolean;
  onClose: Function;
  onSubmit: Function;
  initialData: any;
}

const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
});

const AddNote = (props: IAddNoteProps) => {
  const initialValues = {
    category: props.initialData.category || '',
    title: props.initialData.title || '',
    description: props.initialData.description || '',
    noteId: props.initialData.noteId || null,
  };
  return (
    <Dialog
      open={true}
      onClose={() => props.onClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title' sx={{ textAlign: 'center' }}>
        Add New Note
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values) => props.onSubmit(values)}
        validateOnMount={true}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Autocomplete
                      fullWidth
                      disableClearable
                      size='small'
                      value={{ id: 1, label: values.category }}
                      options={categories.map((item: any) => ({
                        label: item.value,
                        id: item.id,
                      }))}
                      getOptionLabel={(option: any) => option.label}
                      onChange={(event: any, object: any) => {
                        setFieldValue('category', object.label);
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} placeholder='select category' label='Category' />
                      )}
                      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      label='Title'
                      placeholder='Title'
                      fullWidth
                      name='title'
                      type='text'
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.title && Boolean(errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      label='Description'
                      placeholder='Add description here...'
                      fullWidth
                      name='description'
                      type='text'
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </>
            </DialogContent>
            <DialogActions>
              <Button color='error' variant='contained' onClick={() => props.onClose()}>
                Cancel
              </Button>
              <LoadingButton
                label={props?.initialData?.noteId ? 'Update' : 'Add'}
                loading={props.loading}
                disabled={props.loading}
                size='medium'
                type='submit'
                variant='contained'
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddNote;
