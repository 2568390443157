export const TOOLTIPS = {
  activeInactiveToggle:
    // eslint-disable-next-line max-len
    'Client marked as “inactive” have either not been able to be contacted after at least 3 attempts across 3 different days or are temporarily not interested in PINCC, but still open to receiving updates regarding the program. Clients in “inactive” status do not have to be contacted, but can be reached out to as instructed by PINCC leadership. Once the client expresses interest again, they are marked as “active” by the staff member who reestablishes contact. Inactive clients can continue to be moved through other statuses and/or be sent to employment.',
  suspendButton:
    // eslint-disable-next-line max-len
    'Clients in this status have been permanently removed from PINCC due to inappropriate behavior, refusal to comply with intake, or a request to not be contacted. Rejoining PINCC requires the approval from the PINCC Executive Director or the CWE PINCC Deputy Director.',
  intakeToggleButton: 'Toggle Client Intake Complete Status',
  updateNavigatorButton: 'Update Navigator',
};
