import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../utils/colors';
import logo from '../../../assets/images/logo.png';
import truck from '../../../assets/images/truck.png';
import { useNavigate } from 'react-router-dom';
import { getCareerPathList } from '../../../services/careerPath.service';

export const ProspectiveClientCareerPaths = () => {
  const navigate = useNavigate();
  const [list, setList] = React.useState<any>([]);
  React.useEffect(() => {
    (async () => {
      const array = await getCareerPathList();
      setList(array);
    })();
  }, []);
  return (
    <>
      <Box sx={styles.centeredStyle}>
        <Typography variant='h6' color='white' textAlign={'center'}>
          Career Paths
        </Typography>
        <Box sx={styles.logoBox}>
          <img src={logo} alt='logo' style={styles.imageStyle} />
        </Box>
        <Grid container justifyContent={'center'}>
          <Box sx={styles.cardsBox}>
            <Grid container sx={styles.scrollGrid}>
              {list.map((item: any) => {
                return (
                  <Grid item key={item.curriculumId} p={1}>
                    <Card sx={styles.card} elevation={0}>
                      <CardContent>
                        <Typography variant='h6' color='white' gutterBottom>
                          {item.name}
                        </Typography>

                        <Box sx={{ mt: 1 }}>
                          <Typography variant='body2' color={COLORS.pc_pts.heading} fontWeight={500}>
                            Description:
                          </Typography>
                          <Typography variant='body2' color='white'>
                            {item.description}
                          </Typography>
                        </Box>

                        <Box sx={{ mt: 1 }}>
                          <Typography variant='body2' color={COLORS.pc_pts.heading} fontWeight={500}>
                            Salary Range:
                          </Typography>
                          <Typography variant='body2' color='white'>
                            {item.salaries}
                          </Typography>
                        </Box>

                        <Box sx={styles.btnBox}>
                          <Button
                            size='small'
                            variant='text'
                            sx={styles.button}
                            onClick={() => {
                              navigate('../career-path-info', {
                                state: {
                                  careerPathInfo: {
                                    name: item.name,
                                    description: item.description,
                                    salaries: item.salaries,
                                    milestones: item.info,
                                    id: item.id,
                                  },
                                },
                              });
                            }}>
                            <Typography variant='button' color={COLORS.palette.white}>
                              More Details
                            </Typography>
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default ProspectiveClientCareerPaths;

const styles = {
  centeredStyle: {
    background: COLORS.theme.primaryColor,
    height: '100vh',
    minHeight: '100vh',
  },
  btnBox: {
    justifyContent: 'center',
    display: 'flex',
  },
  logoBox: {
    display: 'flex',
    justifyContent: 'center',
    py: 5,
  },
  imageStyle: {
    height: '150px',
    width: '150px',
  },
  scrollGrid: {
    display: 'flex',
    direction: 'row',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(255,255,255,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(255,255,255,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,.1)',
      borderRadius: '8px',
    },
  },
  card: {
    width: { lg: 400, xs: 260 },
    height: { lg: 400, xs: 400 },
    border: `4px solid ${COLORS.pc_pts.cardBorder}`,
    borderRadius: '12px',
    backgroundColor: COLORS.pc_pts.cardBg,
    backgroundImage: `url(${truck})`,
    backgroundPosition: 'right',
    backgroundSize: 'cover',
  },
  cardsBox: {
    width: {
      lg: '100%',
      xs: '100%',
    },
    px: 1,
  },
  button: { mt: 4, background: COLORS.pc_pts.cardBtn },
};
