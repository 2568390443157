import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import { COLORS } from '../../utils/colors';
import { useEffect, useState } from 'react';
import { addRealTimeInActivityLogs, getClientActivityLogsById } from '../../services/client.service';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import AddDateTime from '../Modals/AddDateTime/AddDateTime';
import { WriteModeEnum } from '../../utils/writeModeEnum';
import { shouldShowButton } from '../../pages/admin/dashboard/dashboardAccessControl';

export const ClientActivityLogs = (props: any) => {
  const [page, setPage] = useState(0);
  const [activityLogs, setActivityLogs] = useState<any>([]);
  const [dateTimeModal, setDateTimeModal] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState<string>('');

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    (async () => {
      const unsubscribe = await getClientActivityLogsById(props.clientId, (logData: any) => {
        setActivityLogs(logData);
      });
      return () => {
        unsubscribe;
      };
    })();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const logId = rowData?.logId ? rowData.logId : rowData.context;
      await addRealTimeInActivityLogs(logId, props.clientId, dateTime);
      handleClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //catch err
    }
  };

  const handleClose = () => {
    setDateTimeModal(!dateTimeModal);
    setDateTime('');
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.columnTitle}>Event</TableCell>
                  <TableCell sx={styles.columnTitle}>Portal Time</TableCell>
                  <TableCell sx={styles.columnTitle}>Real Time </TableCell>
                  <TableCell sx={styles.columnTitle}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activityLogs.slice(page * 20, page * 20 + 20).map((row: any, index: any) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{row.context}</TableCell>
                    <TableCell component='th' scope='row'>
                      {dayjs(row.timestamp).tz('America/New_York').format('MM/DD/YYYY, hh:mm A')}
                    </TableCell>
                    <TableCell>
                      {' '}
                      {row.realTime ? dayjs(row.realTime).tz('America/New_York').format('MM/DD/YYYY, hh:mm A') : '--'}
                    </TableCell>
                    <TableCell>
                      <Box display={'flex'} gap={1}>
                        <Tooltip title='Create' arrow>
                          <IconButton
                            disabled={row.realTime || !shouldShowButton('createEditActivityLogs', props.user?.role)}
                            color='primary'
                            onClick={(e) => {
                              e.stopPropagation();
                              setDateTimeModal(!dateTimeModal);
                              setRowData(row);
                            }}>
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Edit' arrow>
                          <IconButton
                            color='primary'
                            disabled={!shouldShowButton('createEditActivityLogs', props.user?.role)}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDateTimeModal(!dateTimeModal);
                              setIsEditMode(true);
                              setRowData(row);
                            }}>
                            <EditIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component='div'
            rowsPerPageOptions={[]}
            count={activityLogs.length}
            rowsPerPage={20}
            page={page}
            onPageChange={handleChangePage}
          />
        </Grid>
      </Grid>
      <AddDateTime
        open={dateTimeModal}
        onClose={handleClose}
        mode={isEditMode ? WriteModeEnum.EDIT : WriteModeEnum.CREATE}
        handleSubmit={handleSubmit}
        setDateTime={setDateTime}
        dateTime={dateTime}
        loading={loading}
        data={rowData}
      />
    </>
  );
};

const styles = {
  columnTitle: {
    color: COLORS.palette.black,
    fontWeight: 'bold',
  },
};
