export const WorkshopTypes = [
  { id: 1, value: 'Virtual', label: 'Virtual' },
  { id: 2, value: 'In-Person', label: 'In Person' },
  { id: 2, value: 'Self-Paced', label: 'Self Paced' },
];

export const WorkshopTypesEnum = {
  IN_PERSON: 'In-Person',
  VIRTUAL: 'Virtual',
  SELF_PACED: 'Self-Paced',
};

export const AttachmentOptions = [
  { id: 1, value: 'file', title: 'Files (pdf, doc, other)' },
  { id: 2, value: 'link', title: 'Links (video, website, other)' },
];

export const AttachmentOptionsEnum = {
  FILE: 'file',
  LINK: 'link',
};
