import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { Loader } from '../../../components/Loader/Loader';

interface ClientListProps {
  reportId: number;
  countLabel: string;
  clients: any[];
  selectedReportId: number | null;
  onClientClick: Function;
  loading: boolean;
}

const ClientList: React.FC<ClientListProps> = ({
  reportId,
  countLabel,
  clients,
  selectedReportId,
  onClientClick,
  loading,
}) => {
  if (loading) {
    return <Loader loaderBoxStyle={{ height: '50vh' }} />;
  }
  return selectedReportId === reportId && clients.length > 0 ? (
    <>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} pt={2}>
        <Typography>{countLabel}:&nbsp;</Typography>
        <Typography fontWeight={'bold'}>{clients.length}</Typography>
      </Box>
      <Grid container spacing={3} mt={2}>
        {clients.map((client) => (
          <Grid item xs={12} sm={6} md={4} key={client.id}>
            <Paper
              elevation={3}
              sx={{
                padding: '2px',
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
              onClick={() => onClientClick(client)}>
              <Typography variant='subtitle1' sx={{ color: 'blue' }} className='client-name'>
                {client.name}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  ) : null;
};

export default ClientList;
