import React from 'react';
import { Box, Card, CardActionArea, CardHeader, Typography } from '@mui/material';
import { COLORS } from '../../utils/colors';
import { ChevronRight, Whatshot } from '@mui/icons-material';

export type SmallCardsProps = {
  desc: string;
  label: string;
  avatar?: React.ReactNode;
  action?: React.ReactNode;
  disableRipple?: boolean;
};

export const DashboardSmallCard = (props: SmallCardsProps) => {
  const { label, desc, avatar, action, disableRipple } = props;

  return (
    <Card variant='elevation' sx={styles.card}>
      <CardActionArea disableRipple={disableRipple || false}>
        <CardHeader
          avatar={avatar || <Whatshot />}
          action={
            action || (
              <Box mt={2} px={2}>
                <ChevronRight />
              </Box>
            )
          }
          title={
            <Typography variant='body1' fontWeight={'600'}>
              {label}
            </Typography>
          }
          subheader={<Typography variant='body1'>{desc}</Typography>}
        />
      </CardActionArea>
    </Card>
  );
};

const styles = {
  card: {
    background: COLORS.palette.white,
    border: `1px solid ${COLORS.palette.borderColor}`,
    borderRadius: '10px',
  },
};
