import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Divider, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { IWorkshopInfo, emptyWorkshopInfo } from '../../store/employmentModule/workshopModal';
import { fetchClientIdsByWorkshopId, getWorkshopById } from '../../services/workshop.service';
import WorkshopBasicInfo from '../../components/WorkshopBasicInfo/WorkshopBasicInfo';
import WorkshopAction from '../../components/WorkshopAction/WorkshopAction';
import WorkshopAttachmentView from '../../components/WorkshopAttachmentView/WorkshopAttachmentView';
import { WorkshopTypesEnum } from '../../utils/workshopUtils';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { ClientsExpectingToAttendWorkshop } from '../../components/ClientsExpectingToAttendWorkshop';
import AttendanceModal from '../../components/Modals/InfoSessionAttendanceModal/InfoSessionAttendanceModal';
import SendGroupMessageToClients from '../../components/SendGroupMessageToClients/SendGroupMessageToClients';
import { toast } from 'react-toastify';

const WorkshopInfo = () => {
  const { workshopId } = useLocation().state;
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [workshop, setWorkshop] = React.useState<IWorkshopInfo>(emptyWorkshopInfo);
  const [showAttendanceModal, setShowAttendanceModal] = useState<boolean>(false);
  const [clientIds, setClientIds] = useState<string[]>([]);
  const allowedWorkshopPermit =
    user.role === UserRolesEnum.ADMIN ||
    user.role === UserRolesEnum.SUPER_ADMIN ||
    user.role === UserRolesEnum.EMPLOYMENT_LIAISON;

  const getWorkshopInfo = async () => {
    try {
      const unsubscribe = await getWorkshopById(workshopId, (data: any) => {
        setWorkshop(data);
      });
      return () => {
        unsubscribe();
      };
    } catch (err: any) {
      //error handling
    }
  };

  const loadClients = async () => {
    try {
      const unsubscribe = await fetchClientIdsByWorkshopId(workshopId, (clientIds: any) => {
        setClientIds(clientIds);
      });
      return () => {
        unsubscribe();
      };
    } catch (err: any) {
      toast.error('Unable to load clients');
    }
  };

  useEffect(() => {
    getWorkshopInfo();
    loadClients();
    // eslint-disable-next-line
  }, [workshopId]);

  return (
    <Box>
      <Grid container mt={4}>
        <Grid item lg={10} xs={12}>
          <WorkshopBasicInfo workshop={workshop} />
        </Grid>
        <Grid item lg={2} xs={12} display={'flex'} justifyContent={'flex-end'} alignItems={'flex-start'}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setShowAttendanceModal(true);
            }}>
            Attendance
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        display={allowedWorkshopPermit && workshop.type !== WorkshopTypesEnum.IN_PERSON ? 'flex' : 'none'}>
        <Grid item lg={12} xs={12}>
          <Divider>
            <Chip label='Resources' sx={{ fontWeight: 'bold' }} />
          </Divider>
        </Grid>
        <Grid item lg={6} xs={12}>
          <WorkshopAction workshop={workshop} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <WorkshopAttachmentView workshop={workshop} />
        </Grid>
      </Grid>
      <Box py={4}>
        <Divider>
          <Chip label='Clients and Messaging' sx={{ fontWeight: 'bold' }} />
        </Divider>
      </Box>
      <Grid container sx={{ mt: 2 }} spacing={2}>
        <Grid item lg={6} xs={12}>
          <Grid container>
            <ClientsExpectingToAttendWorkshop workshopId={workshopId} />
          </Grid>
        </Grid>
        <Grid item lg={6} xs={12}>
          <SendGroupMessageToClients clientIds={clientIds} />
        </Grid>
      </Grid>

      <AttendanceModal
        open={showAttendanceModal}
        onClose={() => setShowAttendanceModal(false)}
        infoSessionId=''
        workshopId={workshopId}
        isAttendanceMarked={workshop.isAttendanceMarked}
      />
    </Box>
  );
};

export default WorkshopInfo;
