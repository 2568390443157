import { Box } from '@mui/material';
import logo from '../../assets/images/logo.png';
import { COLORS } from '../../utils/colors';
import './loading.css';

const Loading = () => {
  return (
    <Box
      sx={{
        background: COLORS.theme.primaryColor,
        height: '100vh',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box sx={styles.logoBox}>
        <img
          className='circle'
          src={logo}
          alt='logo'
          style={{
            height: '150px',
            width: '150px',
            animation: 'pulse-animation 2s infinite' /** Apply the spinning animation */,
          }}
        />
      </Box>
    </Box>
  );
};

export default Loading;

const styles = {
  logoBox: {
    display: 'flex',
    justifyContent: 'center',
    py: 5,
  },
};
