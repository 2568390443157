import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from '../../../utils/colors';
import { IfilterOptions, getFilters } from '../../../utils/filterOptions';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface IFilterModal {
  open: boolean;
  onClose: () => void;
  handleFilter: Function;
  selectedOptions: { [key: string]: string[] };
  setSelectedOptions: Function;
  handleCheckboxChange: Function;
  isClientList: boolean;
}

const FilterModal = (props: IFilterModal) => {
  const { open, onClose, handleFilter, handleCheckboxChange, selectedOptions, setSelectedOptions, isClientList } =
    props;
  const [filters, setFilters] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const result = await getFilters(isClientList);
      setFilters(result);
    })();
  }, [isClientList]);

  const handleClose = () => {
    onClose();
    setSelectedOptions({});
  };

  const handleReset = () => {
    setSelectedOptions({});
  };

  const handleSubmit = () => {
    onClose();
    handleFilter();
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '14px' } }}>
      <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Typography variant='h6'>{'Filters'}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {filters.map((filter) => (
          <Accordion key={filter.id} sx={{ mb: 1, mt: 1 }}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls={`panel-${filter.id}-content`}
              id={`panel-${filter.id}-header`}>
              <Typography>{filter.label}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                maxHeight: 200,
                overflowY: 'auto',
                paddingRight: 2,
              }}>
              <List dense>
                {Array.isArray(filter.options) &&
                  filter.options.map((option: IfilterOptions) => {
                    const labelId = `checkbox-list-secondary-label-${option.id}`;
                    const isChecked = (selectedOptions[filter.id] || []).includes(option.id);

                    return (
                      <ListItem
                        key={option.id}
                        secondaryAction={
                          <Checkbox
                            size='small'
                            edge='end'
                            onChange={() => handleCheckboxChange(filter.id, option.id)}
                            checked={isChecked}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        }
                        disablePadding>
                        <ListItemButton>
                          <ListItemText id={labelId} primary={option.label} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions sx={{ padding: '16px', ml: 3, mr: 3, mt: 1, justifyContent: 'space-evenly' }}>
        <Button
          fullWidth
          variant='outlined'
          onClick={handleReset}
          sx={{ borderRadius: 10, color: COLORS.palette.lightOrange, borderColor: COLORS.palette.lightOrange }}>
          Reset
        </Button>
        <Button
          fullWidth
          variant='contained'
          onClick={handleSubmit}
          sx={{ borderRadius: 10, backgroundColor: COLORS.palette.blue }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
