import React, { useState, useRef } from 'react';
import { Box, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { IResource, IWorkshopInfo } from '../../store/employmentModule/workshopModal';
import { AttachmentOptions, AttachmentOptionsEnum } from '../../utils/workshopUtils';
import { COLORS } from '../../utils/colors';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { editWorkshop } from '../../services/workshop.service';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';
import { v4 as uuidv4 } from 'uuid';
import { CloudUploadOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { uploadFiles } from '../../services/client.service';
import dayjs from 'dayjs';

interface WorkshopActionProps {
  workshop: IWorkshopInfo;
}

const WorkshopAction = (props: WorkshopActionProps) => {
  const { workshop } = props;
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<any>({ name: null });
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAttachmentType, setSelectedAttachmentType] = useState<string>('');
  const [attachmentInput, setAttachmentInput] = useState<any>(null);

  const onSubmit = async () => {
    setLoading(true);
    try {
      let fileLink: any;
      if (selectedAttachmentType === AttachmentOptionsEnum.FILE) {
        fileLink = await uploadFiles([file]);
      }
      const attachment: IResource = {
        type: selectedAttachmentType,
        content: attachmentInput || fileLink[0],
        createdBy: user.id || '',
        id: uuidv4(),
      };
      const payload: IWorkshopInfo = {
        ...workshop,
        updatedAt: dayjs().toISOString(),
        attachments: [...workshop.attachments, attachment],
      };
      await editWorkshop(payload);
      setSelectedAttachmentType('');
      setAttachmentInput(null);
      setFile({ name: null });
    } catch (err) {
      // catch error
    }
    setLoading(false);
  };

  const handleFileChange = (event: any) => {
    const { files } = event.target;
    if (files[0]?.size > 5242880) {
      setFile({ name: null });
      toast.error('File Size is too large');
    } else {
      setFile(files[0]);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const renderFileUpload = () => {
    return (
      <Paper elevation={0} sx={{ width: '100%' }}>
        <input type='file' name='file' ref={fileInputRef} onChange={handleFileChange} hidden />
        <Box width='100%' p={4} sx={{ backgroundColor: '#f6f6f6', borderRadius: '10px' }}>
          <Box mt={2} border='2px dashed' borderColor={COLORS.theme.primaryColor} onClick={handleClick} height='250px'>
            <Box display='flex' flexDirection='column' height='100%' justifyContent='center' alignItems='center'>
              <CloudUploadOutlined fontSize='large' color='primary' />
              <Typography variant='body1' textTransform={'none'} fontWeight={'bold'} color={COLORS.theme.primaryColor}>
                {file?.name ? file?.name : 'No File Selected'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  };

  const renderLinkUpload = () => {
    return (
      <Box mt={2}>
        <TextField
          fullWidth
          label='Enter Link'
          size='small'
          value={attachmentInput}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const val: string = event.target.value as string;
            setAttachmentInput(val);
          }}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'} p={2} my={2}>
      <Typography variant='h6'>Add Attachment</Typography>
      <Typography variant='body2' mb={4}>
        You can upload files, links, video resources to the workshop.
      </Typography>

      <TextField
        fullWidth
        select
        label='Select Upload Type'
        size='small'
        value={selectedAttachmentType}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          const val: string = event.target.value as string;
          setSelectedAttachmentType(val);
        }}>
        {AttachmentOptions.map((item: any) => (
          <MenuItem key={item.id} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </TextField>

      {selectedAttachmentType === AttachmentOptionsEnum.LINK && renderLinkUpload()}
      {selectedAttachmentType === AttachmentOptionsEnum.FILE && renderFileUpload()}

      <Box display='flex' justifyContent='center' mt={2}>
        <LoadingButton
          onClick={() => {
            onSubmit();
          }}
          label='Submit'
          styles={{
            textTransform: 'none',
          }}
          loading={loading}
          size='medium'
          type='submit'
          variant='contained'
          disabled={attachmentInput === null && file.name === null}
        />
      </Box>
    </Box>
  );
};

export default WorkshopAction;
