import {
  Box,
  Grid,
  ListItemText,
  Typography,
  List,
  ListItem,
  Button,
  ListItemButton,
  Divider,
  Chip,
  IconButton,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { programTrackInfoById } from '../../../services/programTrack.service';
import { convertTimeStampToDate } from '../../../utils/dateTime';
import { getClientsByProgramTrackId, updateClientMapStatus } from '../../../services/programTrackClientMap.service';
import { getUserInfoById } from '../../../services/user.service';
import AttendanceModal from '../../../components/Modals/AttendanceModal/AttendanceModal';
import { COLORS } from '../../../utils/colors';
import { useLocation } from 'react-router';
import { ClientMapStatus } from '../../../utils/clientMapStatus';
import { UserModel } from '../../../store/user/userModel';
import { useAppSelector } from '../../../store/store';
import { programTrackTypes } from '../../../utils/programTrackEnums';
import { UserRolesEnum } from '../../../utils/rolesEnum';
import { Loader } from '../../../components/Loader/Loader';
import { CheckCircleOutlineOutlined, ExpandMoreOutlined, InfoOutlined } from '@mui/icons-material';
import MilestonesUsersModal from '../../../components/Modals/MilestonesUsersModal/MilestonesUsersModal';
// eslint-disable-next-line
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import ClientEnrollmentReview from '../../../components/ClientEnrollmentReview/ClientEnrollmentReview';
import { getCareerPathInfoById } from '../../../services/careerPath.service';
import DropoutReasonModal from '../../../components/Modals/DropoutReasonModal/DropoutReasonModal';
import { findMaxMilestoneFromClient } from '../../../utils/milestones';
import GenericDialog from '../../../components/Dialog/genericDialog/genericDialog';
// eslint-disable-next-line
import ClientAttendanceSheetModal from '../../../components/Modals/ClientAttendanceSheetModal/ClientAttendanceSheetModal';
import BulkAttendanceModal from '../../../components/Modals/BulkAttendanceModal/BulkAttendanceModal';
import SendGroupMessageToClients from '../../../components/SendGroupMessageToClients/SendGroupMessageToClients';
import { ClientStatus } from '../../../utils/clientStatus';

export const ProgramTrackInfo = () => {
  const { programTrackId } = useLocation().state;
  const user: UserModel = useAppSelector((state: any) => state.user.user) || {};
  const [loading, setLoading] = useState<boolean>(false);
  const [clientLoading, setClientLoading] = useState<boolean>(false);
  const [programTrackInfo, setProgramTrackInfo] = useState<any>(null);
  const [clientList, setClientList] = useState<any[]>([]);
  const [trainer, setTrainer] = useState<any>(null);
  const [showAttendanceModal, setShowAttendanceModal] = useState<boolean>(false);
  const [showBulkAttendanceModal, setShowBulkAttendanceModal] = useState<boolean>(false);
  const [showDropoutReasonModal, setShowDropoutReasonModal] = useState<boolean>(false);
  const [showMilestoneModal, setShowMilestoneModal] = useState<boolean>(false);
  const [selectedClientList, setSelectedClientList] = useState<any[]>([]);
  const [milestoneStep, setMilestoneStep] = useState<string>('');
  const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState('');
  const [confirmComplete, setConfirmComplete] = useState<boolean>(false);
  const [showAttendanceSheet, setShowAttendanceSheet] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [reviewLoader, setReviewLoader] = useState(false);

  const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 10,
      '&:not(:last-child)': {
        // borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
    })
  );
  const [careerPath, setCareerPath] = useState<any>(null);

  //load client list
  const loadClients = async () => {
    setClientLoading(true);
    const clients = await getClientsByProgramTrackId(programTrackId);
    setClientList(clients);
    setClientLoading(false);
  };

  //initial useEffect function
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await loadInitialData();
      await loadClients();
      setLoading(false);
    };
    // eslint-disable-next-line
    fetchData();
    // eslint-disable-next-line
  }, []);

  //load initial data
  const loadInitialData = async () => {
    const programTrackDetail: any = await programTrackInfoById(programTrackId);
    setProgramTrackInfo(programTrackDetail);
    const cpathInfo: any = await getCareerPathInfoById(programTrackDetail?.careerPathId);
    setCareerPath(cpathInfo || null);
    const trainerInfo: any = await getUserInfoById(programTrackDetail?.trainerId);
    setTrainer(trainerInfo || null);
  };

  //initial loader render function
  const showLoader = () => {
    return <Loader />;
  };
  //activate initial loader
  if (loading) {
    return showLoader();
  }

  if (reviewLoader) {
    return (
      <Grid container spacing={0} alignItems='center' justifyContent='center' style={{ minHeight: '100vh' }}>
        <Grid item>{showLoader()}</Grid>
      </Grid>
    );
  }

  const loadClientsOnReview = async () => {
    const clients = await getClientsByProgramTrackId(programTrackId);
    setClientList(clients);
  };

  const toggleDropoutReasonModal = () => {
    setShowDropoutReasonModal(!showDropoutReasonModal);
  };

  const fetchClientIds = (clientList: any) => {
    const clientIds = clientList
      ?.filter((item: any) => item.status !== ClientStatus.DROP_OUT)
      ?.map((item: any) => item.clientId);
    return clientIds;
  };
  return (
    <>
      <Box sx={{ width: '100%', py: 5 }}>
        <Grid container>
          <Grid item lg={12} xs={12} sx={styles.topbarContainer}>
            {/* Page title */}
            <Box sx={styles.titleAndDesc}>
              <Typography variant='h6'>{programTrackInfo?.name}</Typography>
              <Typography variant='body1' sx={styles.description}>
                {programTrackInfo?.description}
              </Typography>
            </Box>

            {/* Attendance button */}
            {(user.role === UserRolesEnum.SUPER_ADMIN ||
              user.role === UserRolesEnum.TRAINER ||
              user.role === UserRolesEnum.ADMIN) &&
            programTrackInfo?.milestones?.length > 0 ? (
              <Button
                disabled={
                  clientList?.filter(
                    (cl) =>
                      cl.currentMilestoneIndex === findMaxMilestoneFromClient(clientList) &&
                      cl.status !== ClientMapStatus.COMPLETE &&
                      cl.status !== ClientMapStatus.PRE_COMPLETE
                  )?.length === 0
                    ? true
                    : false
                }
                variant='contained'
                onClick={() => {
                  setShowBulkAttendanceModal(!showBulkAttendanceModal);
                }}>
                <Typography variant='body2' textTransform={'none'} noWrap>
                  Attendance
                </Typography>
              </Button>
            ) : null}
          </Grid>
        </Grid>

        {/* Program Track Info */}
        <Grid container mt={4}>
          <Grid item lg={6} xs={12}>
            <List>
              <ListItem disablePadding>
                <ListItemText
                  primary='Track ID:'
                  secondary='CDL123'
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>

              <ListItem disablePadding>
                <ListItemText
                  primary='Career Path:'
                  secondary={careerPath?.name || '--'}
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>

              <ListItem disablePadding>
                <ListItemText
                  primary='Curriculum:'
                  secondary={programTrackInfo?.curriculumName || '--'}
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>

              <ListItem disablePadding>
                <ListItemText
                  primary='Training Vendor:'
                  secondary={trainer ? trainer.firstName + ' ' + trainer.lastName : '--'}
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>

              <ListItem disablePadding>
                <ListItemText
                  primary='Start Date:'
                  secondary={
                    programTrackInfo?.type === programTrackTypes.COHORT.value
                      ? convertTimeStampToDate(programTrackInfo.startTime).format('MM/DD/YYYY')
                      : '--'
                  }
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>

              <ListItem disablePadding>
                <ListItemText
                  primary='End Date:'
                  secondary={
                    programTrackInfo?.type === programTrackTypes.COHORT.value
                      ? convertTimeStampToDate(programTrackInfo.endTime).format('MM/DD/YYYY')
                      : '--'
                  }
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item lg={6} xs={12} sx={{ justifyContent: { lg: 'right' }, display: 'flex' }}>
            <List>
              <ListItem disablePadding>
                <ListItemText
                  primary='Capacity:'
                  secondary={programTrackInfo?.capacity || 0}
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>

              <ListItem disablePadding>
                <ListItemText
                  primary='Clients:'
                  secondary={
                    clientList
                      ? clientList.filter(
                          (cl) =>
                            cl.status === ClientMapStatus.ACTIVE ||
                            cl.status === ClientMapStatus.PRE_COMPLETE ||
                            cl.status === ClientMapStatus.COMPLETE
                        )?.length
                      : '--'
                  }
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>

              <ListItem disablePadding>
                <ListItemText
                  primary='Drop out:'
                  secondary={
                    clientList ? clientList.filter((cl) => cl.status === ClientMapStatus.DROP_OUT)?.length : '--'
                  }
                  primaryTypographyProps={styles.listItemPrimaryText}
                  sx={styles.listItemContainer}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>

        {/* Enrollment review client list */}
        <Accordion style={{ width: '50%' }}>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography fontWeight={'bold'}>Clients: Under Enrollment Review</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ClientEnrollmentReview
              programTrackId={programTrackId}
              fetchClientsOnReview={() => loadClientsOnReview()}
              toggleReviewLoader={(val: boolean) => setReviewLoader(val)}
            />
          </AccordionDetails>
        </Accordion>

        <Box sx={{ mt: 4 }}>
          <Divider>
            <Chip label='Messaging' sx={{ fontWeight: 'bold' }} />
          </Divider>
        </Box>
        <Grid container>
          <Grid item lg={6} xs={12} mt={4}>
            <SendGroupMessageToClients clientIds={fetchClientIds(clientList)} />
          </Grid>
        </Grid>

        {/* Divider */}
        <Box sx={{ mt: 4 }}>
          <Divider>
            <Chip label='Milestones' sx={{ fontWeight: 'bold' }} />
          </Divider>
        </Box>

        {/* Milestones List */}
        <Grid container spacing={1} columns={18} mt={4} gap={1}>
          {clientLoading ? (
            <Grid item lg={12} xs={12}>
              {showLoader()}
            </Grid>
          ) : programTrackInfo?.milestones?.length > 0 ? (
            programTrackInfo.milestones.map((milestone: any, milestoneIndex: any) => (
              <>
                <Grid item lg={4} xs={12} key={milestoneIndex}>
                  <Box sx={{ background: COLORS.ptinfo.cardBg, borderRadius: '10px', fontSize: '1rem' }}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'}>
                      <Box width={'85%'}>
                        <Typography m={2} variant='h6'>
                          {milestone.name}
                        </Typography>
                      </Box>
                      <IconButton
                        color='primary'
                        sx={{
                          fontSize: '1.5rem',
                        }}
                        onClick={() => {
                          setSelectedMilestoneIndex(milestoneIndex);
                          setShowMilestoneModal(!showMilestoneModal);
                        }}>
                        <InfoOutlined />
                      </IconButton>
                    </Box>

                    <Divider />
                    <List>
                      {clientList
                        ?.filter(
                          (cl) =>
                            parseInt(cl.currentMilestoneIndex) === milestoneIndex &&
                            cl.status !== ClientMapStatus.COMPLETE &&
                            cl.status !== ClientMapStatus.PRE_COMPLETE &&
                            cl.status !== ClientMapStatus.DROP_OUT
                        )
                        .map((client: any, clientIndex: any) => (
                          <ListItem key={clientIndex} disablePadding>
                            <ListItemButton
                              onClick={() => {
                                setSelectedClientList([client]);
                                setMilestoneStep(milestoneIndex);
                                setShowAttendanceModal(!showAttendanceModal);
                              }}>
                              <ListItemText
                                primary={client.firstName + ' ' + client.lastName}
                                primaryTypographyProps={{
                                  color: client.status === ClientMapStatus.DROP_OUT ? 'error' : 'textPrimary',
                                }}
                              />
                              <Chip
                                size='small'
                                sx={{
                                  display: client.status === ClientMapStatus.DROP_OUT ? 'block' : 'none',
                                  background: COLORS.client.dropoutBg,
                                  color: COLORS.client.dropoutColor,
                                }}
                                label={
                                  <Typography
                                    variant='button'
                                    sx={{
                                      fontSize: '10px',
                                      textTransform: 'uppercase',
                                    }}>
                                    {client.status.split('_').join(' ')}
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      {clientList &&
                        clientList.filter(
                          (cl) =>
                            parseInt(cl.currentMilestoneIndex) === milestoneIndex &&
                            cl.status !== ClientMapStatus.COMPLETE &&
                            cl.status !== ClientMapStatus.PRE_COMPLETE &&
                            cl.status !== ClientMapStatus.DROP_OUT
                        )?.length === 0 && (
                          <ListItem>
                            <ListItemText primary='No client yet' primaryTypographyProps={{ textAlign: 'center' }} />
                          </ListItem>
                        )}
                    </List>
                  </Box>
                </Grid>
              </>
            ))
          ) : (
            <Grid item lg={12} xs={12}>
              <Typography variant='h6' textAlign={'center'}>
                No milestone available yet.
              </Typography>
            </Grid>
          )}
        </Grid>

        {/* Divider */}
        <Box mt={4}>
          <Divider />
        </Box>
      </Box>

      <Grid container spacing={1}>
        {/* Pre Completed Clients */}
        <Grid item lg={6}>
          <Box sx={{ background: COLORS.ptinfo.cardBg, borderRadius: '10px' }}>
            <Box display={'flex'} py={1} px={2} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant='h6'>Pre-Completed</Typography>
              <Tooltip arrow title='Compilation of clients who are in the pre-complete stage of this program track.'>
                <InfoOutlined color='primary' />
              </Tooltip>
            </Box>
            <Divider />
            <List sx={styles.list}>
              {clientList && clientList.filter((item) => item.status === ClientMapStatus.PRE_COMPLETE)?.length === 0 ? (
                <ListItem>
                  <ListItemText primary='No client yet' primaryTypographyProps={{ textAlign: 'center' }} />
                </ListItem>
              ) : (
                clientList
                  ?.filter((item) => item.status === ClientMapStatus.PRE_COMPLETE)
                  ?.map((item, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItem>
                        <ListItemText primary={item.firstName + ' ' + item.lastName} />
                        <IconButton
                          color='primary'
                          onClick={() => {
                            setSelectedClient(item);
                            setShowAttendanceSheet(!showAttendanceSheet);
                          }}>
                          <InfoOutlined />
                        </IconButton>
                        <IconButton
                          color='success'
                          onClick={() => {
                            setSelectedClient(item);
                            setConfirmComplete(true);
                          }}>
                          <CheckCircleOutlineOutlined />
                        </IconButton>
                      </ListItem>
                    </ListItem>
                  ))
              )}
            </List>
          </Box>
        </Grid>

        {/* Completed clients */}
        <Grid item lg={6}>
          <Box sx={{ background: COLORS.ptinfo.cardBg, borderRadius: '10px' }}>
            <Box display={'flex'} py={1} px={2} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant='h6'>Completed</Typography>
              <Tooltip arrow title='Compilation of clients who have successfully concluded this program track.'>
                <InfoOutlined color='primary' />
              </Tooltip>
            </Box>
            <Divider />
            <List sx={styles.list}>
              {clientList && clientList.filter((item) => item.status === ClientMapStatus.COMPLETE)?.length === 0 ? (
                <ListItem>
                  <ListItemText primary='No client yet' primaryTypographyProps={{ textAlign: 'center' }} />
                </ListItem>
              ) : (
                clientList
                  ?.filter((item) => item.status === ClientMapStatus.COMPLETE)
                  ?.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={item.firstName + ' ' + item.lastName}
                        secondary={convertTimeStampToDate(
                          item.completedMilestones[item.completedMilestones?.length - 1].completionDate
                        ).format('MM/DD/YYYY')}
                      />
                    </ListItem>
                  ))
              )}
            </List>
          </Box>
        </Grid>
      </Grid>

      {/* Attendance Modal Handling */}
      <AttendanceModal
        userRole={user.role}
        programTrackId={programTrackId}
        milestoneStep={String(parseInt(milestoneStep) + 1)}
        clientList={selectedClientList}
        open={showAttendanceModal}
        onClose={() => setShowAttendanceModal(!showAttendanceModal)}
        onSubmit={async (client: any) => {
          loadClients();
          setSelectedClientList(client);
          setShowAttendanceModal(!showAttendanceModal);
        }}
        toggleDropoutReasonModal={toggleDropoutReasonModal}
      />

      {/* Bulk Attendance Modal */}
      <BulkAttendanceModal
        open={showBulkAttendanceModal}
        programTrackInfo={programTrackInfo}
        refresh={() => loadClientsOnReview()}
        onClose={() => setShowBulkAttendanceModal(false)}
      />

      {/* DropoutReasonModal */}
      {showDropoutReasonModal ? (
        <DropoutReasonModal
          open={showDropoutReasonModal}
          onClose={() => setShowDropoutReasonModal(!showDropoutReasonModal)}
          programTrackId={programTrackId}
          milestoneStep={String(parseInt(milestoneStep) + 1)}
          clientList={[selectedClientList[0]?.clientId]}
          onSubmit={() => {
            loadClients();
          }}
        />
      ) : null}

      {/* Milestones Users modal */}
      <MilestonesUsersModal
        milestoneIndex={String(parseInt(selectedMilestoneIndex))}
        milestoneName={programTrackInfo?.milestones[selectedMilestoneIndex]?.name}
        clientList={clientList || []}
        open={showMilestoneModal}
        onClose={() => setShowMilestoneModal(!showMilestoneModal)}
      />

      {/* Client Complete Action Confirmation Modal */}
      <GenericDialog
        onClose={() => setConfirmComplete(false)}
        dialogTitle='Are you sure?'
        agreeBtnText='Complete'
        disagreeBtnText='Cancel'
        agreeFunc={async () => {
          setLoading(true);
          await updateClientMapStatus(selectedClient.clientId, ClientMapStatus.COMPLETE);
          await loadClients();
          setLoading(false);
        }}
        open={confirmComplete}
      />

      {/* Attendance Sheet */}
      <ClientAttendanceSheetModal
        clientInfo={selectedClient}
        open={showAttendanceSheet}
        onClose={() => setShowAttendanceSheet(!showAttendanceSheet)}
      />
    </>
  );
};

const styles = {
  topbarContainer: {
    display: { lg: 'flex' },
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 1,
  },
  list: {
    overflow: 'auto',
    maxHeight: 300,
    '& ul': { padding: 0 },
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: '8px',
    },
  },
  titleAndDesc: {
    width: '80%',
    mb: 1,
  },
  description: {
    textAlign: 'justify',
    textJustify: 'inter-word',
    position: 'relative',
  },
};
