import React from 'react';
import { NavLink } from 'react-router-dom';
import { DashboardSmallCard } from '../DashboardSmallCard/DashboardSmallCard';
import { PersonAddAltOutlined } from '@mui/icons-material';

const CreateClientButton = () => {
  return (
    <div>
      <NavLink to={'/admin/createClient'} style={{ textDecoration: 'none' }}>
        <DashboardSmallCard label='Create Client' desc='Add New Client' avatar={<PersonAddAltOutlined />} />
      </NavLink>
    </div>
  );
};

export default CreateClientButton;
