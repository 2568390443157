import styled from '@emotion/styled';
import { Autocomplete, Box, Grid, Modal, Typography, TextField, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import { DropoutConst, suspensionReasons } from '../../../utils/dropoutAndSuspensionReasons';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import { suspendClient } from '../../../services/client.service';

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const localStyle = {
  centeredStyle: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    borderRadius: '14px',
    width: '600px',
    '@media (max-width: 480px)': {
      padding: '20px',
      margin: '10px',
    },
  },
  titleGrid: {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  fieldArray: {
    position: 'relative',
    top: '20px',
    left: '17px',
    width: '515px',
  },
  loadingButtonGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
};

interface ISuspendReasonModal {
  open: boolean;
  onClose: Function;
  onSubmit: Function;
  clientId: string;
}

const SuspendReasonModal = (props: ISuspendReasonModal) => {
  const { open, onClose, clientId, onSubmit } = props;
  const [selectedReason, setSelectedReason] = useState<any>();
  const [otherReason, setOtherReason] = useState('');
  const [loading, setLoading] = useState(false);

  const handleReasonChange = (event: any, newValue: any) => {
    setSelectedReason(newValue);
  };

  const handleClose = () => {
    onClose();
    setSelectedReason(null);
    setOtherReason('');
  };

  const handleSubmit = async () => {
    setLoading(true);
    let suspensionReason = '';

    if (otherReason) {
      suspensionReason = otherReason;
    } else {
      suspensionReason = selectedReason.value;
    }
    await suspendClient(clientId, suspensionReason, selectedReason.id);
    handleClose();
    onSubmit();
    setLoading(false);
  };

  const checkDisable = () => {
    if (selectedReason) {
      if (selectedReason.id === DropoutConst.OTHER_OPTION_ID) {
        if (otherReason) {
          return false;
        } else {
          return true;
        }
      } else if (selectedReason.id !== DropoutConst.OTHER_OPTION_ID) {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <ModalWrapper open={open} onClose={() => handleClose()}>
      <Box sx={localStyle.centeredStyle}>
        <Grid container sx={localStyle.titleGrid}>
          <Grid item>
            <Typography variant='h6'>Suspension Reason</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleClose()}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={localStyle.fieldArray}>
          <Grid item xs={12}>
            <Autocomplete
              value={selectedReason}
              onChange={handleReasonChange}
              options={suspensionReasons}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => <TextField {...params} label='Select Reason' />}
            />
          </Grid>
          {selectedReason && selectedReason.id === DropoutConst.OTHER_OPTION_ID ? (
            <Grid item xs={12}>
              <TextField
                label='Other Reason'
                fullWidth
                multiline
                rows={3}
                variant='outlined'
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12} lg={12} sx={localStyle.loadingButtonGrid}>
          <LoadingButton
            onClick={() => handleSubmit()}
            label={'Submit'}
            styles={{ width: '250px' }}
            loading={loading}
            disabled={loading || checkDisable()}
            size='large'
            type='submit'
            variant='contained'
          />
        </Grid>
      </Box>
    </ModalWrapper>
  );
};

export default SuspendReasonModal;
