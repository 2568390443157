import {
  CancelOutlined,
  CancelPresentationOutlined,
  CheckBoxOutlined,
  CheckCircleOutlineOutlined,
} from '@mui/icons-material';
import { ISplitButtonOption } from './common';

export const AttendanceStatus = {
  PRESENT: 'present',
  ABSENT: 'absent',
  DROPOUT: 'dropout',
  COMPLETE: 'complete',
};

export const AttendanceButtons: ISplitButtonOption[] = [
  {
    id: 1,
    value: 'dropout',
    label: 'Dropout',
    color: 'error',
    variant: 'contained',
    icon: <CancelOutlined />,
  },
  {
    id: 2,
    value: 'complete',
    label: 'Complete',
    color: 'success',
    variant: 'contained',
    icon: <CheckCircleOutlineOutlined />,
  },
  {
    id: 3,
    value: 'present',
    label: 'Present',
    color: 'primary',
    variant: 'contained',
    icon: <CheckBoxOutlined />,
  },
  {
    id: 4,
    value: 'absent',
    label: 'Absent',
    color: 'warning',
    variant: 'contained',
    icon: <CancelPresentationOutlined />,
  },
];
