export const preHireRequirementsOptions = [
  { id: '1', value: 'Resume Submission' },
  { id: '2', value: 'Application Form Submission' },
  { id: '3', value: 'Cover Letter' },
  { id: '4', value: 'References' },
  { id: '5', value: 'Background Check (Paid for: Yes/No)' },
  { id: '6', value: 'Drug Screening' },
  { id: '7', value: 'Medical Examination' },
  { id: '8', value: 'Legal Eligibility to Work (e.g., work visa or permit)' },
  { id: '9', value: 'Other: [write-in]' },
  { id: '10', value: 'None' },
];

export const functionButtons = [
  { id: '1', title: 'Reschedule' },
  { id: '2', title: 'No Job Offer' },
  { id: '3', title: 'Client Rejected Offer' },
  { id: '4', title: 'Schedule 2nd Interview' },
  { id: '5', title: 'Offer Accepted' },
];

export const jobOfferStatusEnums = {
  NO_JOB_OFFER: 'no-job-offer',
  CLIENT_REJECTED_OFFER: 'client-rejected-offer',
  OFFER_ACCEPTED: 'offer-accepted',
};

export const TBD = 'to be determined';
