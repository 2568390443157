import React, { useState } from 'react';
import { useAppSelector } from '../../../store/store';
import { UserModel } from '../../../store/user/userModel';
import { getClientInfoById } from '../../../services/client.service';
import { Box, Typography } from '@mui/material';
import ClientDocuments from '../../../components/ClientDocuments/ClientDocuments';
import { Loader } from '../../../components/Loader/Loader';

export default function ClientDocumentsPage() {
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [clientInfo, setClientInfo] = useState(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  React.useEffect(() => {
    if (user?.id) {
      (async () => {
        setLoading(true);
        const client: any = await getClientInfoById(user.id);
        setClientInfo(client);
        setLoading(false);
      })();
    }
    // eslint-disable-next-line
  }, [user]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box p={5}>
      <Typography variant='h4' sx={{ m: 2 }}>
        Client Documents
      </Typography>
      {clientInfo ? <ClientDocuments clientInfo={clientInfo} isClientView={true} /> : null}
    </Box>
  );
}
