import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

interface ICustomDateRangePickerProps {
  onStartDateChange: Function;
  onEndDateChange: Function;
  selectedReportId: number;
}

const CustomDateRangePicker = (props: ICustomDateRangePickerProps) => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const handleStartDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue);
    props.onStartDateChange(newValue);
  };

  const handleEndDateChange = (newValue: Dayjs | null) => {
    setEndDate(newValue);
    props.onEndDateChange(newValue);
  };

  useEffect(() => {
    if (props.selectedReportId) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [props.selectedReportId]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' gap={2} height={40}>
        <DatePicker
          label='Start Date'
          value={startDate}
          onChange={handleStartDateChange}
          slotProps={{
            textField: {
              InputLabelProps: { shrink: true },
              sx: {
                '& .MuiInputBase-root': { height: '40px' },
              },
            },
          }}
        />
        <DatePicker
          label='End Date'
          value={endDate}
          onChange={handleEndDateChange}
          slotProps={{
            textField: {
              InputLabelProps: { shrink: true },
              sx: {
                '& .MuiInputBase-root': { height: '40px' },
              },
            },
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default CustomDateRangePicker;
