import { Timestamp, collection, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { db, functions } from '../config/firebaseApp';
import { toast } from 'react-toastify';
import { shouldShowButton } from '../pages/admin/dashboard/dashboardAccessControl';
import { httpsCallable } from 'firebase/functions';
import { convertTimeStampToDate } from '../utils/dateTime';
import { ISortModel } from '../utils/common';

export interface ICareerPath {
  id?: string;
  createdBy?: string;
  description: string;
  name: string;
  code: string;
  salaries: string;
  createdAt?: Timestamp;
  info?: {}[];
}

export interface ICareerPathMetaInfo {
  totalRecords: number;
  currentPage: number;
  careerPaths: Array<Object>;
  totalPages: number;
}

export const getCareerPathList = async () => {
  const careerPaths: any = [];
  try {
    const querySnapshot = await getDocs(collection(db, 'careerPath'));
    querySnapshot.forEach((doc) => {
      careerPaths.push({ id: doc.id, ...doc.data() });
    });
  } catch (err: any) {
    console.log(err.message);
  }
  return careerPaths;
};

export const getCareerPathInfoById = async (careerPathId: string) => {
  try {
    if (!careerPathId) {
      throw new Error();
    }

    const careerPathDocRef = doc(db, 'careerPath', careerPathId);
    const careerPathDoc = await getDoc(careerPathDocRef);

    if (careerPathDoc.exists()) {
      return { id: careerPathDoc.id, ...careerPathDoc.data() } as unknown as Promise<ICareerPath>;
    }
    return null;
  } catch (err: any) {
    toast.error('Error Retrieving Career Path!');
  }
};

export const addNewCareerPath = async (careerPathData: ICareerPath, userRole?: string) => {
  try {
    if (!careerPathData) {
      throw new Error();
    }
    const hasPermission = shouldShowButton('createCareerPath', userRole);
    if (!hasPermission) {
      throw new Error('You do not have permission to create a Career Path');
    }

    const response: any = await httpsCallable(functions, 'createNewCareerPath')(careerPathData);

    if (response.data.success) {
      toast.success('New Career Path created successfully!');
    }
  } catch (err: any) {
    toast.error('Error Creating Career Path!');
  }
};

export const editCareerPathById = async (careerPathId: string, payload: any) => {
  try {
    if (!careerPathId) {
      throw new Error();
    }

    const careerPathDocRef = doc(db, 'careerPath', careerPathId);
    const careerPathDoc = await getDoc(careerPathDocRef);

    if (careerPathDoc.exists()) {
      const data = { ...payload, id: careerPathId };
      const response: any = await httpsCallable(functions, 'editCareerPath')(data);
      if (response.data.success) {
        toast.success('careerPath updated successfully!');
      }
    }
  } catch (error: any) {
    toast.error('Error Updating CareerPath!');
  }
};

export const getCareerPathsByQuery = async (page: number, sortModel: ISortModel[]) => {
  try {
    const querySnapshot = await getDocs(collection(db, 'careerPath'));
    const pageSize = 10;
    const offset = (page - 1) * pageSize;

    if (!querySnapshot.empty) {
      const careerPaths: Array<ICareerPath> = [];
      querySnapshot.forEach((doc) => {
        careerPaths.push({ id: doc.id, ...doc.data() } as ICareerPath);
      });
      if (sortModel.length > 0) {
        const field = sortModel[0].field;
        const sort = sortModel[0].sort;

        if (field === 'name') {
          careerPaths.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.name.localeCompare(b.name);
            } else if (sort === 'desc') {
              return b.name.localeCompare(a.name);
            }
          });
        } else if (field === 'code') {
          careerPaths.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.code.localeCompare(b.code);
            } else if (sort === 'desc') {
              return b.code.localeCompare(a.code);
            }
          });
        } else if (field === 'createdAt') {
          careerPaths.sort((a, b) => {
            const dateA = convertTimeStampToDate(a.createdAt).toDate();
            const dateB = convertTimeStampToDate(b.createdAt).toDate();
            return dateB.getTime() - dateA.getTime();
          });
        }
      } else {
        careerPaths.sort((a, b) => {
          const dateA = convertTimeStampToDate(a.createdAt).toDate();
          const dateB = convertTimeStampToDate(b.createdAt).toDate();
          return dateB.getTime() - dateA.getTime();
        });
      }
      const totalPages = Math.ceil(careerPaths.length / pageSize);
      const limitedCareerPaths = careerPaths.slice(offset, offset + pageSize);
      const result: ICareerPathMetaInfo = {
        totalRecords: careerPaths.length,
        currentPage: page,
        careerPaths: limitedCareerPaths,
        totalPages: totalPages,
      };
      return result;
    } else {
      throw new Error();
    }
  } catch (err: any) {
    toast.error('Error Retrieving Career Path!');
  }
};

export const getMultipleCareerPathInfoByIds = async (careerPathIds: string[]) => {
  try {
    if (careerPathIds.length === 0) {
      return [];
    }

    const careerPathDocRef = collection(db, 'careerPath');
    const querySnapshot = await getDocs(query(careerPathDocRef));
    const documents = querySnapshot.docs.filter((doc) => careerPathIds.includes(doc.id));

    const careerPathList: any = [];

    documents.forEach((doc) => {
      careerPathList.push({ id: doc.id, ...doc.data() });
    });

    return careerPathList;
  } catch (error: any) {
    console.error('Error fetching career paths:', error);
    toast.error('Error Retrieving Career Paths!');
    return [];
  }
};

export const searchCareerPath = async (searchQuery: string, page: number) => {
  try {
    if (!searchQuery) {
      throw new Error();
    }

    const response: any = await httpsCallable(functions, 'searchCareerPathByName')({ query: searchQuery });

    const careerPathData = response.data.data;
    const careerPathIds: any = [];
    let careerPathList = [];

    if (careerPathData.length > 0) {
      for (const data of careerPathData) {
        careerPathIds.push(data.id);
      }

      careerPathList = await getMultipleCareerPathInfoByIds(careerPathIds);
      const pageSize = 10;
      const offset = (page - 1) * pageSize;
      const totalPages = Math.ceil(careerPathList.length / pageSize);
      const limitedCareerPaths = careerPathList.slice(offset, offset + pageSize);
      const result: ICareerPathMetaInfo = {
        totalRecords: careerPathList.length,
        currentPage: page,
        careerPaths: limitedCareerPaths,
        totalPages: totalPages,
      };
      return result;
    } else {
      return {
        totalRecords: 0,
        currentPage: page,
        careerPaths: [],
        totalPages: 0,
      };
    }
  } catch (error) {
    toast.error('Error Retrieving Career Paths!');
  }
};
