import React from 'react';
import { Grid } from '@mui/material';
import { isIOS } from '../../utils/platform';
import { COLORS } from '../../utils/colors';

function TwilioConfirmation() {
  return (
    <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center' sx={styles.root}>
      <Grid item xs={12} sx={{ my: 2 }}>
        <img alt='Logo' src='/assets/logo.png' style={isIOS() ? { width: 50 } : { width: 100 }} />
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <img alt='Twilio Confirmation' src='/assets/twilio-confirmation.png' style={{ height: '50%' }} />
      </Grid>
    </Grid>
  );
}

export default TwilioConfirmation;

const styles = {
  root: {
    minHeight: '100vh',
    backgroundColor: COLORS.theme.primaryColor,
  },
};
