import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { getMultipleClientByIds } from '../../services/client.service';
import { Loader } from '../Loader/Loader';

interface ClientListToSendGroupMsgProps {
  clientIds: string[];
  selectedClients: string[];
  handleClientSelect: Function;
  setSelectedClients: Function;
}

const ClientListToSendGroupMsg = (props: ClientListToSendGroupMsgProps) => {
  const { clientIds, handleClientSelect, selectedClients, setSelectedClients } = props;

  const [clients, setClients] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const clients = await getMultipleClientByIds(clientIds);
      setClients(clients);
      setLoading(false);
    })();
  }, [clientIds]);

  useEffect(() => {
    if (isSelectAll) {
      setSelectedClients(clientIds);
    } else {
      setSelectedClients([]);
    }
  }, [isSelectAll, clientIds, setSelectedClients]);

  const scrollableListStyle = {
    height: '250px',
    overflowY: 'auto',
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      {clients.length > 0 ? (
        <Grid container>
          <Box sx={{ mb: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='h6'>Select Clients for Group Message</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant='body1'>Select All</Typography>
              <Checkbox
                aria-label='select all'
                size='small'
                checked={isSelectAll}
                onChange={() => {
                  setIsSelectAll(!isSelectAll);
                }}
              />
            </Box>
          </Box>
          <Grid item xs={12}>
            <Box sx={scrollableListStyle}>
              <List>
                {clients.map((client: any) => (
                  <ListItem
                    key={client.id}
                    disablePadding
                    onClick={() => {
                      handleClientSelect(client.id);
                    }}>
                    <ListItemText
                      primary={
                        <>
                          {client.firstName + ' ' + client.lastName}
                          {client.isAgreeToTextMessaging === false && (
                            <span style={{ color: 'red', fontSize: '25px', position: 'relative', top: '5px' }}>**</span>
                          )}
                        </>
                      }
                    />
                    <Checkbox
                      checked={selectedClients.includes(client.id)}
                      onChange={() => {
                        handleClientSelect(client.id);
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Typography variant='body2' textAlign={'center'} py={2}>
            No clients available!.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ClientListToSendGroupMsg;
