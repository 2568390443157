import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemButton, ListItemText, Box } from '@mui/material';
import styled from '@emotion/styled';
import { COLORS } from './../../utils/colors';
import { ICommonClient } from '../../services/client.service';
import { Loader } from '../Loader/Loader';

const CardStyled = styled(Card)((props: any) => ({
  background: COLORS.palette.white,
  border: `1px solid ${COLORS.palette.borderColor}`,
  borderRadius: '8px',
  height: props?.height || '376px',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    borderRadius: '8px',
  },
}));

const StickyHeader = styled(Box)({
  position: 'sticky',
  top: 0,
  zIndex: 1,
  background: COLORS.palette.white,
  borderBottom: `1px solid ${COLORS.palette.borderColor}`,
  borderRadius: '8px 8px 0 0',
});

export interface ICardItem {
  id: string;
  title: string;
  desc: string;
}

export type CardsProps = {
  isLoading?: boolean;
  list: Array<ICommonClient>;
  desc: string;
  label: string;
  height?: string;
  onItemClick: Function;
};

export const DashboardCard = (props: CardsProps) => {
  const { isLoading, list, label, desc, height, onItemClick } = props;

  return (
    <CardStyled height={height}>
      <StickyHeader>
        <Box p={2}>
          <Typography variant='h5'>{label}</Typography>
          <Typography variant='caption' color='grey'>
            {desc}
          </Typography>
        </Box>
      </StickyHeader>
      <CardContent sx={{ p: 0, m: 0, paddingTop: 1 }}>
        {isLoading ? (
          <Loader loaderBoxStyle={{ height: '0vh' }} />
        ) : list?.length > 0 ? (
          <List disablePadding>
            {list.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => onItemClick(item)}>
                  <ListItemText
                    primary={item?.firstName + ' ' + item?.lastName}
                    primaryTypographyProps={{ fontWeight: '500', color: COLORS.palette.blue }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant='body2' textAlign={'center'} sx={{ m: 0, pt: 5 }} gutterBottom>
            No result
          </Typography>
        )}
      </CardContent>
    </CardStyled>
  );
};
