import React, { useEffect, useState } from 'react';
import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import JobBasicInfo from '../../components/JobBasicInfo/JobBasicInfo';
import { IJobInfo, emptyJobInfo } from '../../store/employmentModule/employmentModal';
import { getJobById } from '../../services/employmentModule.service';
import { ClientsInvitedToJobOpportunity } from '../../components/ClientsInvitedToJobOpportunity';

const JobInfo = () => {
  const { jobId } = useLocation().state;
  const [jobInfo, setJobInfo] = useState<IJobInfo>(emptyJobInfo);

  const getJobInfo = async () => {
    try {
      if (jobId) {
        const unsubscribe = await getJobById(jobId, (data: any) => {
          setJobInfo(data);
        });
        return () => {
          unsubscribe();
        };
      }
    } catch (err: any) {
      // error handling
    }
  };

  useEffect(() => {
    getJobInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Box sx={{ ml: 4, mt: 4 }}>
        <Typography variant='h6'>Job Details</Typography>
        <Grid container mt={2}>
          <Grid item lg={12} xs={12}>
            <JobBasicInfo jobInfo={jobInfo} />
          </Grid>
        </Grid>
      </Box>
      <Box pt={4}>
        <Divider>
          <Chip label='Clients' sx={{ fontWeight: 'bold' }} />
        </Divider>
      </Box>
      <Grid container spacing={2} m={2}>
        <Grid item lg={8} xs={12} sx={{ pt: 5 }}>
          <Grid container spacing={2}>
            <ClientsInvitedToJobOpportunity isHired={false} jobId={jobId} />
            <ClientsInvitedToJobOpportunity isHired={true} jobId={jobId} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobInfo;
