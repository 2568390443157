import { Timestamp, collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db, functions } from '../config/firebaseApp';
import { toast } from 'react-toastify';
import { shouldShowButton } from '../pages/admin/dashboard/dashboardAccessControl';
import { httpsCallable } from 'firebase/functions';
import { ICurriculumMetaInfo, ICurriculumMilestoneModel } from '../store/curriculum/curriculumModel';
import { convertTimeStampToDate } from '../utils/dateTime';
import { ISortModel } from '../utils/common';

export interface ICurriculumData {
  id?: string;
  careerPathId: string;
  trainerId: string;
  createdBy: string;
  description: string;
  name: string;
  createdAt?: Timestamp;
  milestones: ICurriculumMilestoneModel[];
}

export const addNewCurriculum = async (curriculumData: ICurriculumData, userRole?: string) => {
  try {
    if (!curriculumData) {
      throw new Error();
    }
    const hasPermission = shouldShowButton('createNewCurriculum', userRole);
    if (!hasPermission) {
      throw new Error('You do not have permission to create a Curriculum');
    }

    const response: any = await httpsCallable(functions, 'createNewCurriculum')(curriculumData);
    if (response.data.success) {
      toast.success('New Curriculum Created Successfully!');
    }
  } catch (err: any) {
    toast.error('Error Creating Curriculum!');
  }
};

export const pseudoAddCurriculum = async () => {
  try {
    const curriculumData = null;
    await httpsCallable(functions, 'createNewCurriculum')(curriculumData);
  } catch (err: any) {
    // err
  }
};

export const getCurriculumsByCareerPathId = async (careerPathId: string) => {
  try {
    if (!careerPathId) {
      throw new Error();
    }

    const collectionRef = collection(db, 'curriculum');
    const q = query(collectionRef, where('careerPathId', '==', careerPathId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const list: any = [];
      querySnapshot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      return list;
    } else {
      return [];
    }
  } catch (err: any) {
    toast.error('Error Retrieving Curriculums!');
  }
};

export const getCurriculumInfoByCurriculumId = async (curriculumId: string) => {
  try {
    if (!curriculumId) {
      throw new Error();
    }

    const curriculumDocRef = doc(db, 'curriculum', curriculumId);
    const curriculumDoc = await getDoc(curriculumDocRef);

    if (curriculumDoc.exists()) {
      return { id: curriculumDoc.id, ...curriculumDoc.data() };
    } else {
      return {};
    }
  } catch (error: any) {
    toast.error('Error Retrieving Curriculum Data!');
  }
};

export const getCurriculumList = async (page: number, sortModel: ISortModel[]) => {
  const pageSize = 10;
  const offset = (page - 1) * pageSize;
  try {
    const querySnapshot = await getDocs(collection(db, 'curriculum'));
    if (!querySnapshot.empty) {
      const curriculums: any = [];
      querySnapshot.forEach((doc) => {
        curriculums.push({ id: doc.id, ...doc.data() });
      });
      if (sortModel.length > 0) {
        const field = sortModel[0].field;
        const sort = sortModel[0].sort;

        if (field === 'name') {
          curriculums.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.name.localeCompare(b.name);
            } else if (sort === 'desc') {
              return b.name.localeCompare(a.name);
            }
          });
        } else if (field === 'description') {
          curriculums.sort((a: any, b: any) => {
            if (sort === 'asc') {
              return a.description.localeCompare(b.description);
            } else if (sort === 'desc') {
              return b.description.localeCompare(a.description);
            }
          });
        } else if (field === 'createdAt') {
          curriculums.sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB.getTime() - dateA.getTime();
          });
        }
      } else {
        curriculums.sort((a: any, b: any) => {
          const dateA = convertTimeStampToDate(a.createdAt).toDate();
          const dateB = convertTimeStampToDate(b.createdAt).toDate();
          return dateB.getTime() - dateA.getTime();
        });
      }
      const totalPages = Math.ceil(curriculums.length / pageSize);
      const limitedCurriculums = curriculums.slice(offset, offset + pageSize);
      const result: ICurriculumMetaInfo = {
        totalRecords: curriculums.length,
        currentPage: page,
        curriculums: limitedCurriculums,
        totalPages: totalPages,
      };
      return result;
    } else {
      return {
        totalRecords: 0,
        currentPage: page,
        curriculums: [],
        totalPages: 0,
      };
    }
  } catch (err: any) {
    toast.error('Error Retrieving Curriculum Data!');
  }
};

export const deleteCurriculum = async (curriculumId: string) => {
  try {
    const response: any = await httpsCallable(functions, 'deleteCurriculum')({ id: curriculumId });
    if (response.data.success) {
      toast.success('Curriculum Deleted Successfully!');
    }
  } catch (error: any) {
    toast.error('Error Deleting Curriculum!');
  }
};

export const editCurriculumById = async (curriculumId: string, payload: any) => {
  try {
    if (!curriculumId) {
      throw new Error();
    }

    const curriculumDocRef = doc(db, 'curriculum', curriculumId);
    const curriculumDoc = await getDoc(curriculumDocRef);

    if (curriculumDoc.exists()) {
      const data = { ...payload, id: curriculumId };

      const response: any = await httpsCallable(functions, 'editCurriculum')(data);

      if (response.data.success) {
        toast.success('Curriculum Updated Successfully!');
      } else {
        toast.error('Error Updating Curriculum!');
      }
    }
  } catch (error: any) {
    toast.error('Error Updating Curriculum!');
  }
};

export const isTrainerAssignedToCurriculum = async (trainerId: string) => {
  try {
    if (!trainerId) {
      throw new Error();
    }

    const collectionRef = collection(db, 'curriculum');
    const q = query(collectionRef, where('trainerId', '==', trainerId));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty ? true : false;
  } catch (err: any) {
    toast.error('Error Retrieving Data!');
  }
};

export const getMultipleCurriculumByIds = async (curriculumIds: string[]) => {
  try {
    if (!curriculumIds.length) {
      throw new Error();
    }

    const curriculumRef = collection(db, 'curriculum');
    const querySnapshot = await getDocs(query(curriculumRef));
    const documents = querySnapshot.docs.filter((doc) => curriculumIds.includes(doc.id));
    const curriculumList: any = [];

    documents.forEach((doc) => {
      curriculumList.push({ id: doc.id, ...doc.data() });
    });

    return curriculumList;
  } catch (err: any) {
    toast.error('Error Retrieving Curriculums!');
    return [];
  }
};

export const searchCurriculum = async (searchQuery: string, page: number) => {
  try {
    if (!searchQuery) {
      throw new Error();
    }

    const response: any = await httpsCallable(functions, 'searchCurriculumByName')({ query: searchQuery });

    const curriculumData = response.data.data;
    const curriculumIds: any = [];
    let curriculumList = [];

    if (curriculumData.length > 0) {
      for (const data of curriculumData) {
        curriculumIds.push(data.id);
      }

      curriculumList = await getMultipleCurriculumByIds(curriculumIds);
      const pageSize = 10;
      const offset = (page - 1) * pageSize;
      const totalPages = Math.ceil(curriculumList.length / pageSize);
      const limitedCareerPaths = curriculumList.slice(offset, offset + pageSize);
      const result: ICurriculumMetaInfo = {
        totalRecords: curriculumList.length,
        currentPage: page,
        curriculums: limitedCareerPaths,
        totalPages: totalPages,
      };
      return result;
    } else {
      return {
        totalRecords: 0,
        currentPage: page,
        curriculums: [],
        totalPages: 0,
      };
    }
  } catch (error) {
    toast.error('Error Searching Curriculum!');
  }
};

export const fetchCurriculums = async () => {
  try {
    const curriculumCollectionRef = collection(db, 'curriculum');
    const snapshots = await getDocs(curriculumCollectionRef);
    const curriculumList: any[] = snapshots.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });

    return curriculumList;
  } catch (error) {
    toast.error('Error Fetching Curriculum');
    return [];
  }
};
