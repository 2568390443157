import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTable from '../CustomTable';
import { CreateColumnHeader } from '../CreateColumnHeader/CreateColumnHeader';
import { COLORS } from '../../utils/colors';
import { CreateColumnCell } from '../CreateColumnCell/CreateColumnCell';
import {
  ITrainerMapMetaInfo,
  getAllAssociateTrainers,
  searchAssociateTrainer,
} from '../../services/programTrackTrainerMap.service';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';
import { getMultipleProgramTracksById } from '../../services/programTrack.service';
import AddAssociateEmail from '../Modals/AddAssociateEmail';

const getTrainerMapList = async (trainerMaps: any) => {
  const programTrackIds = [];
  for (const trainerMap of trainerMaps) {
    programTrackIds.push(trainerMap.programTrackId);
  }
  const programTrackInfo = await getMultipleProgramTracksById(programTrackIds);
  const trainerMapRow: any = [];
  for (const trainerMap of trainerMaps) {
    trainerMap.programTrackInfo = programTrackInfo.find((data: any) => data.id === trainerMap.programTrackId) || {};
    trainerMapRow.push({
      associateEmail: trainerMap.associateEmail,
      programTrackName: trainerMap.programTrackInfo.name,
    });
  }
  return trainerMapRow;
};

const AssociateTrainersList = () => {
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [trainerMapMetaInfo, setTrainerMapMetaInfo] = useState<ITrainerMapMetaInfo>();
  const [trainerMapList, setTrainerMapList] = useState<Array<any>>([]);
  const [addAssociateEmailModal, setAddAssociateEmailModal] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  const PAGE_SIZE = 10;
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [searchPaginationModel, setSearchPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const columns = [
    {
      field: 'associateEmail',
      headerName: 'Associate Email',
      type: 'string',
      sortable: false,
      width: 300,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        const color = COLORS.palette.black;
        return CreateColumnCell(params.row.associateEmail, undefined, color);
      },
    },
    {
      field: 'programTrackName',
      headerName: 'Program Track',
      type: 'string',
      sortable: true,
      width: 300,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        const color = COLORS.palette.black;
        return CreateColumnCell(params.row.programTrackName, undefined, color);
      },
    },
  ];

  const getAllAssociateTrainersList = async () => {
    setLoading(true);
    setIsSearchMode(false);
    setSearchQuery('');
    const result = await getAllAssociateTrainers(user?.id || '', paginationModel.page + 1);
    if (result?.programTrackTrainerMaps.length) {
      setTrainerMapMetaInfo(result);
      const trainerMapRow: any = await getTrainerMapList(result?.programTrackTrainerMaps || []);
      setTrainerMapList(trainerMapRow);
    }
    setLoading(false);
    setShouldFetch(false);
  };

  const handleSearch = async () => {
    if (searchQuery) {
      setLoading(true);
      setIsSearchMode(true);
      const result = await searchAssociateTrainer(
        searchQuery.toLowerCase().trim(),
        searchPaginationModel.page + 1,
        user?.id || ''
      );

      if (result?.programTrackTrainerMaps.length) {
        setTrainerMapMetaInfo(result);
        const trainerMapRow: any = await getTrainerMapList(result?.programTrackTrainerMaps || []);
        setTrainerMapList(trainerMapRow);
      } else {
        setTrainerMapMetaInfo(result);
        setTrainerMapList([]);
      }
      setLoading(false);
    }
  };

  useEffect(
    () => {
      getAllAssociateTrainersList();
    },
    // eslint-disable-next-line
    [paginationModel.page, shouldFetch]
  );
  const handleAddAssociateTrainer = () => {
    setShouldFetch(true);
    getAllAssociateTrainersList;
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [searchPaginationModel.page]);

  useEffect(() => {
    if (!searchQuery) {
      getAllAssociateTrainersList();
    }
    // eslint-disable-next-line
  }, [searchQuery]);

  function getRowId(row: any) {
    return row.associateEmail + row.programTrackName;
  }
  return (
    <>
      <Box sx={{ width: '100%', height: 500 }}>
        <CustomTable
          rows={trainerMapList?.length ? trainerMapList : []}
          columns={columns}
          hideSearch={false}
          filterList={[]}
          loading={loading}
          getRowId={getRowId}
          onFilterListChange={() => {}}
          onPaginationModelChange={isSearchMode ? setSearchPaginationModel : setPaginationModel}
          paginationModel={isSearchMode ? searchPaginationModel : paginationModel}
          primaryButtonLable='Add Associate Email'
          primaryButtonAction={() => {
            setAddAssociateEmailModal(true);
          }}
          rowCount={trainerMapMetaInfo?.totalRecords || 0}
          title='Associate Trainers'
          onChangeSearchQuery={(query: string) => {
            setSearchQuery(query);
          }}
          handleSearch={() => handleSearch()}
          searchQuery={searchQuery}
        />
      </Box>
      <AddAssociateEmail
        open={addAssociateEmailModal}
        onClose={() => setAddAssociateEmailModal(false)}
        onAddAssociateTrainer={handleAddAssociateTrainer}
      />
    </>
  );
};

export default AssociateTrainersList;
