import { Card, CardContent, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../utils/colors';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { UserModel } from '../../store/user/userModel';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { IProgramTrack, getActiveAndFutureProgramTracks } from '../../services/programTrack.service';
import { convertTimeStampToDate } from '../../utils/dateTime';
import { programTrackTypes } from '../../utils/programTrackEnums';
import { Loader } from '../Loader/Loader';

const styles = {
  cardMain: {
    background: COLORS.palette.lightBlue,
    color: COLORS.palette.white,
    border: `1px solid ${COLORS.palette.borderColor}`,
    borderRadius: '8px',
    boxShadow: 'none',
  },
  list: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    textDecoration: 'underline',
    color: '#1B59F8',
  },
  listDesc: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    color: COLORS.palette.lightGray,
  },
  trainingText: {
    textDecoration: 'underline',
    padding: '20px 0 10px 20px',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  nextArrow: {
    position: 'absolute' as 'absolute',
    color: COLORS.palette.white,
    height: '10px',
    width: '10px',
  },
  linkText: {
    position: 'relative' as 'relative',
    color: COLORS.palette.white,
    opacity: '0.9',
    textDecoration: 'none',
    paddingRight: '16px',
  },
  textStyle: {
    fontSize: '14px',
    mb: 1,
    opacity: '0.7',
  },
};

const StyledCard = styled(Card)({
  background: COLORS.theme.primaryColor,
  color: COLORS.palette.white,
  border: `1px solid ${COLORS.palette.borderColor}`,
  borderRadius: '8px',
  boxShadow: 'none',
  '@media (max-width: 480px)': {
    width: '260px',
  },
});

interface IDashboardProgramCard {
  user: UserModel;
}

export const DashboardProgramCard = (props: IDashboardProgramCard) => {
  const navigate = useNavigate();
  const { user } = props;
  const [programTrackList, setProgramTrackList] = useState<Array<IProgramTrack>>([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (
        user?.role === UserRolesEnum.SUPER_ADMIN ||
        user?.role === UserRolesEnum.ADMIN ||
        user?.role === UserRolesEnum.CASE_MANAGER ||
        user?.role === UserRolesEnum.NAVIGATOR ||
        user?.role === UserRolesEnum.EMPLOYMENT_LIAISON
      ) {
        const activeAndFutureProgramTracksList = await getActiveAndFutureProgramTracks();
        setProgramTrackList(activeAndFutureProgramTracksList);
      } else {
        const activeAndFutureProgramTracksList = await getActiveAndFutureProgramTracks();
        const filteredList = activeAndFutureProgramTracksList.filter((item: any) => item.trainerId === user?.id);
        setProgramTrackList(filteredList);
      }
      setIsLoading(false);
    })();
  }, [user]);

  return (
    <>
      {isLoading ? (
        <Loader loaderBoxStyle={{ height: '0vh' }} />
      ) : programTrackList.length > 0 ? (
        programTrackList.map((programTrack: any) => (
          <StyledCard key={programTrack.id} sx={styles.cardMain}>
            <CardContent>
              <Typography variant='h5' sx={{ m: 0 }} gutterBottom>
                {programTrack.name}
              </Typography>
              {programTrack.type === programTrackTypes.COHORT.value ? (
                <Typography sx={styles.textStyle} color={COLORS.palette.white}>
                  {'Started ' +
                    convertTimeStampToDate(programTrack.startTime).format('MMMM DD, YYYY') +
                    ' | Ends ' +
                    convertTimeStampToDate(programTrack.endTime).format('MMMM DD, YYYY')}
                </Typography>
              ) : (
                <Typography sx={styles.textStyle} color={COLORS.palette.white}>
                  Rolling Program Track
                </Typography>
              )}
              <Typography sx={{ ...styles.textStyle, mb: 2 }} color={COLORS.palette.white}>
                No. of Enrollments: {programTrack?.enrolled || 0}
              </Typography>
              <div style={styles.flexBox}>
                <Link
                  component='button'
                  onClick={() => {
                    navigate('../program-track-info', { state: { programTrackId: programTrack.id } });
                  }}
                  style={styles.linkText}>
                  {'Explore Link'}
                  <span style={styles.nextArrow}>
                    <KeyboardArrowRightIcon />
                  </span>{' '}
                </Link>
              </div>
            </CardContent>
          </StyledCard>
        ))
      ) : null}
    </>
  );
};
