import React, { useEffect, useState } from 'react';
import { Box, Chip, Divider, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  fetchClientsToSendTextMsgReminder,
  getInfoSessionById,
  sendTextMessageReminder,
} from '../../services/infoSession.service';
import { InfoSessionEditModel, infoSessionEmpty } from '../../store/infoSession/infoSessionModel';
import dayjs from 'dayjs';
import { infoSessionTypes } from '../../utils/infoSessionTypeEnums';
import AttendanceModal from '../../components/Modals/InfoSessionAttendanceModal/InfoSessionAttendanceModal';
import AddIcon from '@mui/icons-material/Add';
import AddPCToAttendanceRosterModal from '../../components/Modals/AddPCToAttendanceRosterModal';
import { ClientsExpectingToAttendInfoSession } from '../../components/ClientsExpectingToAttendInfoSession';
import { LoadingButton } from '../../components/LoadingButton/LoadingButton';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import SendGroupMessageToClients from '../../components/SendGroupMessageToClients/SendGroupMessageToClients';

interface InfoSessionDetail {
  primary: string;
  secondary: React.ReactNode;
}

const InfoSessionInfo = () => {
  const { infoSessionId } = useLocation().state;
  const [infoSessionData, setInfoSessionData] = useState<InfoSessionEditModel>(infoSessionEmpty);
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [showAttendanceModal, setShowAttendanceModal] = useState<boolean>(false);
  const [showAddPCModal, setShowAddPCModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getInfoSessionData = async () => {
    try {
      if (infoSessionId) {
        const unsubscribe = await getInfoSessionById(infoSessionId, (data: any) => {
          setInfoSessionData(data);
        });
        if (unsubscribe) {
          return () => {
            unsubscribe();
          };
        }
      }
    } catch (err: any) {
      // error handling
    }
  };

  const fetchClientsForTextMsgReminder = async () => {
    try {
      if (infoSessionId) {
        const unsubscribe = await fetchClientsToSendTextMsgReminder(infoSessionId, (data: any) => {
          setClientIds(data);
        });
        if (unsubscribe) {
          return () => {
            unsubscribe();
          };
        }
      }
    } catch (err: any) {
      // error handling
    }
  };

  useEffect(() => {
    getInfoSessionData();
    fetchClientsForTextMsgReminder();
    // eslint-disable-next-line
  }, []);

  const handleOnSend = async () => {
    try {
      setLoading(true);
      await sendTextMessageReminder(clientIds, infoSessionId);
      setLoading(false);
    } catch (err) {
      //catch err
    }
  };

  const renderListItem = (primary: string, secondary: React.ReactNode) => (
    <ListItem disablePadding key={primary}>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={styles.listItemPrimaryText}
        sx={styles.listItemContainer}
      />
    </ListItem>
  );

  const infoSessionDetail: InfoSessionDetail[] = [
    {
      primary: 'Date & Time: ',
      secondary: dayjs(infoSessionData.dateTime).tz('America/New_York').format('MMMM DD, YYYY, hh:mm A'),
    },
    { primary: 'Type of session: ', secondary: infoSessionData.sessionType },
    { primary: 'Training Site: ', secondary: infoSessionData.hra },
    { primary: 'Program: ', secondary: infoSessionData.title },
    { primary: 'Description: ', secondary: infoSessionData.description },
    { primary: 'No. of Clients: ', secondary: infoSessionData.numClients || 0 },
  ];
  if (infoSessionData.sessionType === infoSessionTypes.IN_PERSON) {
    infoSessionDetail.push({ primary: 'Location: ', secondary: infoSessionData.location });
  }
  if (infoSessionData.customUrlName) {
    infoSessionDetail.push({
      primary: 'Custom Url: ',
      secondary: (
        <a
          href={
            process.env.REACT_APP_PROJECT_ID !== 'nyatlas-test'
              ? `https://pincc.nyc/infosessions/${infoSessionData.customUrlName}`
              : `https://pincctest.com/infosessions/${infoSessionData.customUrlName}`
          }
          target='_blank'
          rel='noopener noreferrer'>
          {process.env.REACT_APP_PROJECT_ID !== 'nyatlas-test'
            ? `https://pincc.nyc/infosessions/${infoSessionData.customUrlName}`
            : `https://pincctest.com/infosessions/${infoSessionData.customUrlName}`}
        </a>
      ),
    });
  }

  return (
    <>
      <Box>
        <Grid container>
          <Grid item lg={6} xs={12} mt={3}>
            <Typography variant='h6'>Info-Session Details</Typography>
            <List>{infoSessionDetail.map((item) => renderListItem(item.primary, item.secondary))}</List>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            mt={3}
            display={'flex'}
            gap={1}
            alignItems={'flex-start'}
            justifyContent={'flex-end'}>
            <LoadingButton
              variant='contained'
              color='primary'
              onClick={() => {
                setShowAttendanceModal(true);
              }}
              typographyComp={
                <Typography variant='body2' textTransform={'none'} noWrap>
                  Attendance
                </Typography>
              }
            />

            <LoadingButton
              size='medium'
              variant='contained'
              color='primary'
              disabled={infoSessionData?.isAttendanceMarked}
              startIcon={<AddIcon />}
              onClick={() => {
                setShowAddPCModal(true);
              }}
              typographyComp={
                <Typography variant='body2' textTransform={'none'} noWrap>
                  Prospective Client
                </Typography>
              }
            />
            <LoadingButton
              size='medium'
              color={'primary'}
              variant={'contained'}
              loading={loading}
              disabled={false}
              onClick={() => {
                handleOnSend();
              }}
              startIcon={<SendRoundedIcon />}
              typographyComp={
                <Typography variant='body2' textTransform={'none'} noWrap>
                  Send Reminder Message
                </Typography>
              }
            />
          </Grid>
          <Grid item lg={12} xs={12} py={4}>
            <Divider>
              <Chip label='Clients and Messaging' sx={{ fontWeight: 'bold' }} />
            </Divider>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <Grid container>
                <ClientsExpectingToAttendInfoSession infoSessionId={infoSessionId} />
              </Grid>
            </Grid>
            <Grid item lg={6} xs={12}>
              <SendGroupMessageToClients clientIds={clientIds} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <AttendanceModal
        open={showAttendanceModal}
        onClose={() => setShowAttendanceModal(false)}
        infoSessionId={infoSessionId}
        isAttendanceMarked={infoSessionData?.isAttendanceMarked}
        workshopId=''
      />
      <AddPCToAttendanceRosterModal
        open={showAddPCModal}
        onClose={() => setShowAddPCModal(false)}
        infoSessionId={infoSessionId}
      />
    </>
  );
};

export default InfoSessionInfo;

const styles = {
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 1,
    alignSelf: 'flex-start',
  },
};
