import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Avatar,
  TableRow,
  TableCell,
  Typography,
  Box,
  TableSortLabel,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import { TableHead } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import avatar from '../../../assets/images/avtar.jpg';
import AddUserModal from '../../../components/Modals/AddUser/AddUser';
import { COLORS } from '../../../utils/colors';
import { useAppSelector } from '../../../store/store';
import {
  IStaffMetaInfo,
  deleteStaff,
  getStaffsByQuery,
  resetStaffPassword,
  searchStaff,
} from '../../../services/user.service';
import { isTrainerAssignedToCurriculum } from '../../../services/curriculum.service';
import { getRoleLabel } from '../../../utils/user';
import { UserModel } from '../../../store/user/userModel';
import CustomTable from '../../../components/CustomTable';
import { StaffFilterList } from '../client/utils';
import { Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import GenericDialog from '../../../components/Dialog/genericDialog/genericDialog';
import { UserRolesEnum } from '../../../utils/rolesEnum';
import { toast } from 'react-toastify';
import { isEmpLiaisonAssignedToClient, isNavigatorAssignedToClient } from '../../../services/client.service';
import { isEmployerAttachedToJob } from '../../../services/employmentModule.service';

const headCells = [
  {
    id: 'fullname',
    disablePadding: true,
    label: 'Full Name',
    alignLeft: 'true',
  },
  {
    id: 'email',
    disablePadding: false,
    label: 'Email Address',
  },
  {
    id: 'role',
    disablePadding: false,
    label: 'Role',
  },
];

function EnhancedTableHead(props: any) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const UserManagement = () => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [role, setRole] = React.useState('all');
  const [deleteStaffId, setDeleteStaffId] = useState(String);
  const [deleteStaffRole, setDeleteStaffRole] = useState(String);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [staffMetaInfo, setStaffMetaInfo] = React.useState<IStaffMetaInfo>();

  const [searchPaginationModel, setSearchPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const user = useAppSelector((state: any) => state.user.user);
  const [staffList, setStaffList] = useState<any>([]);
  const [editMode, setEditMode] = useState({
    isEditMode: null,
    editData: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      id: '',
      organization: '',
      location: '',
      adminType: '',
    },
  } as {
    isEditMode: boolean | null;
    editData: UserModel | null;
  });
  const [resetPasswordStaffName, setResetPasswordStaffName] = React.useState(String);
  const [resetPasswordStaffEmail, setResetPasswordStaffEmail] = React.useState(String);
  const [resetPasswordAlert, setResetPasswordAlert] = React.useState(false);

  const modifyStaffList = (list: any[]) => {
    return list.map((item: any) => {
      return {
        ...item,
        avatarUrl: avatar,
        organization: item.organization || '',
        location: item.location || '',
      };
    });
  };

  const handleCloseModal = () => {
    setEditMode({ isEditMode: null, editData: null });
  };

  const getColor = (role: string) => {
    if (role === 'Case Manager') return COLORS.palette.lightYellow;
    if (role === 'Trainer') return COLORS.palette.lightGreen;
    if (role === 'Navigator') return COLORS.palette.lightSky;
    return COLORS.palette.transparent;
  };

  const columns = [
    {
      field: 'fullname',
      headerName: 'Full Name',
      type: 'string',
      sortable: false,
      width: 250,
      renderCell: (params: any) => {
        return (
          <Stack direction='row' alignItems='center' spacing={2}>
            <Avatar alt={params.row.firstName + ' ' + params.row.lastName} src={params.row.avatarUrl} />
            <Typography variant='subtitle2' noWrap>
              {params.row.role === UserRolesEnum.TRAINER || params.row.role === UserRolesEnum.EMPLOYER
                ? params.row.organization
                : params.row.firstName + ' ' + params.row.lastName}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'string',
      sortable: true,
      width: 300,
    },
    {
      field: 'role',
      headerName: 'Role',
      type: 'string',
      sortable: true,
      width: 300,
      renderCell: (params: any) => (
        <Box sx={{ display: 'flex' }}>
          <Typography
            style={{
              color: '#000',
              padding: '5px',
              fontWeight: '500',
              background: getColor(getRoleLabel(params.row?.role) as string),
            }}>
            {params.row.role === UserRolesEnum.ADMIN
              ? `${getRoleLabel(params.row.role)} ${params.row.adminType ? `(${params.row.adminType})` : ''}`
              : getRoleLabel(params.row.role)}
          </Typography>
        </Box>
      ),
    },
    {
      headerName: 'Actions',
      sortable: false,
      width: 300,
      headerAlign: 'center',
      renderCell: (params: any) => (
        <Box display={'flex'} gap={1} sx={{ position: 'relative', left: 40 }}>
          <IconButton
            sx={{ color: COLORS.theme.linkColor }}
            onClick={() => {
              setEditMode({ isEditMode: true, editData: params.row });
            }}>
            <Edit />
          </IconButton>
          {user?.role === UserRolesEnum.SUPER_ADMIN &&
          (params.row?.role === UserRolesEnum.CASE_MANAGER ||
            params.row?.role === UserRolesEnum.TRAINER ||
            params.row?.role === UserRolesEnum.NAVIGATOR ||
            params.row?.role === UserRolesEnum.EMPLOYER) ? (
            <IconButton
              onClick={() => {
                setDeleteStaffId(params.row.id);
                setDeleteStaffRole(params.row.role);
                setDeleteAlert(true);
              }}>
              <DeleteIcon
                style={{
                  color: '#d32f2f',
                }}
              />
            </IconButton>
          ) : (
            <IconButton>
              <Tooltip title='Not Allowed' placement='top'>
                <DeleteIcon
                  style={{
                    color: '#808080',
                  }}
                />
              </Tooltip>
            </IconButton>
          )}
          {user?.role === UserRolesEnum.SUPER_ADMIN && params.row.loggedIn === false ? (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setResetPasswordStaffName(params.row.firstName);
                setResetPasswordStaffEmail(params.row.email);
                setResetPasswordAlert(true);
              }}>
              <Button size='small' type='submit' variant='outlined' color='info'>
                Reset Password
              </Button>
            </IconButton>
          ) : null}
        </Box>
      ),
    },
  ];

  const getStaffList = async () => {
    setLoading(true);
    setSearchQuery('');
    setIsSearchMode(false);
    const result = await getStaffsByQuery(role, paginationModel.page + 1);
    setStaffMetaInfo(result);
    if (result?.staffs.length) {
      const modifiedList = modifyStaffList(result.staffs);
      setStaffList(modifiedList);
    } else {
      setStaffList([]);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    try {
      if (deleteStaffRole === UserRolesEnum.CASE_MANAGER) {
        setLoading(true);
        await deleteStaff(deleteStaffId);

        setLoading(false);
        toast.success('Case Manager Successfully Deleted!');
      } else if (deleteStaffRole === UserRolesEnum.TRAINER) {
        const data = await isTrainerAssignedToCurriculum(deleteStaffId);
        if (!data) {
          setLoading(true);
          await deleteStaff(deleteStaffId);
          setLoading(false);
          toast.success('Trainer Successfully Deleted!');
        } else {
          toast.error('Trainer assigned to Curriculum!');
        }
      } else if (deleteStaffRole === UserRolesEnum.NAVIGATOR) {
        const data = await isNavigatorAssignedToClient(deleteStaffId);
        if (!data) {
          setLoading(true);
          await deleteStaff(deleteStaffId);
          setLoading(false);
          toast.success('Navigator Successfully Deleted!');
        } else {
          toast.error('Navigator assigned to Client!');
        }
      } else if (deleteStaffRole === UserRolesEnum.EMPLOYMENT_LIAISON) {
        const data = await isEmpLiaisonAssignedToClient(deleteStaffId);
        if (!data) {
          setLoading(true);
          await deleteStaff(deleteStaffId);
          setLoading(false);
          toast.success('Employment Liaison Successfully Deleted!');
        } else {
          toast.error('Employment Liaison assigned to Client!');
        }
      } else if (deleteStaffRole === UserRolesEnum.EMPLOYER) {
        const data = await isEmployerAttachedToJob(deleteStaffId);
        if (!data) {
          setLoading(true);
          await deleteStaff(deleteStaffId);
          setLoading(false);
          toast.success('Employer Successfully Deleted!');
        } else {
          toast.error('Employer attached to Job Opportunity!');
        }
      }
      getStaffList();
    } catch (err: any) {
      console.error(err?.response?.data?.message || err?.message);
    }
  };

  useEffect(() => {
    if (!isSearchMode) {
      getStaffList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, role]);

  const handleSearch = async () => {
    if (searchQuery) {
      setLoading(true);
      setIsSearchMode(true);
      const result = await searchStaff(searchQuery.toLocaleLowerCase(), role, searchPaginationModel.page + 1);
      setStaffMetaInfo(result);
      if (result?.staffs.length) {
        const modifiedList = modifyStaffList(result.staffs);
        setStaffList(modifiedList);
      } else {
        setStaffList([]);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSearchMode) {
      handleSearch();
    } else {
      getStaffList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPaginationModel.page, role]);

  useEffect(() => {
    if (!searchQuery) {
      getStaffList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleAddUser = () => {
    getStaffList();
  };

  const handleButtonClick = async () => {
    try {
      const data = {
        firstName: resetPasswordStaffName,
        email: resetPasswordStaffEmail,
      };
      setLoading(true);
      await resetStaffPassword(data);
      setLoading(false);
      toast.success('Reset password mail sent successfully!');
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <Box>
      <AddUserModal
        open={editMode.isEditMode !== null}
        data={editMode.isEditMode ? editMode.editData : null}
        onClose={handleCloseModal}
        onAddUser={handleAddUser}
      />
      <CustomTable
        columns={columns}
        rows={staffList || []}
        filterList={StaffFilterList}
        disbaleMultiSelect={true}
        loading={loading}
        onFilterListChange={(list: any) => {
          setRole(list[0] || 'all');
        }}
        onRowClick={() => {}}
        onPaginationModelChange={isSearchMode ? setSearchPaginationModel : setPaginationModel}
        paginationModel={isSearchMode ? searchPaginationModel : paginationModel}
        primaryButtonLable='Add User'
        primaryButtonAction={() => {
          setEditMode({ ...editMode, isEditMode: false });
        }}
        rowCount={staffMetaInfo?.totalRecords || 0}
        title='Staff Management'
        onChangeSearchQuery={(query: string) => setSearchQuery(query)}
        handleSearch={() => handleSearch()}
        searchQuery={searchQuery}
      />
      <GenericDialog
        onClose={() => setDeleteAlert(false)}
        dialogTitle='Are you sure?'
        agreeBtnText='Delete'
        disagreeBtnText='Cancel'
        agreeFunc={() => handleDelete()}
        open={deleteAlert}
      />
      <GenericDialog
        onClose={() => setResetPasswordAlert(false)}
        dialogTitle='Are you sure?'
        agreeBtnText='Reset Password'
        disagreeBtnText='Cancel'
        agreeFunc={() => handleButtonClick()}
        open={resetPasswordAlert}
      />
    </Box>
  );
};

export default UserManagement;
