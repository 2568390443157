import React, { useEffect, useState } from 'react';
import {
  AccountCircleOutlined,
  FileOpenOutlined,
  FilePresentOutlined,
  ForumOutlined,
  NotesOutlined,
  TimelineOutlined,
  BadgeOutlined,
  InsightsOutlined,
} from '@mui/icons-material';
import { Box, Button, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { COLORS } from '../../../../utils/colors';
import { ClientInfoForm } from '../../../../components/ClientInfoForm/ClientInfoForm';
import { useLocation } from 'react-router-dom';
import { getLiveClientInfoById } from '../../../../services/client.service';
//eslint-disable-next-line
import { ClientProgramTrackEnrollment } from '../../../../components/ClientProgramTrackEnrollment/ClientProgramTrackEnrollment';
import { NavigatorSelection } from '../../../../components/Modals/NavigatorSelection/NavigatorSelection';
import { getCareerPathInfoById, getCareerPathList } from '../../../../services/careerPath.service';
import { getNavigatorList } from '../../../../services/user.service';
import { ClientProgramTrack } from '../../../../components/ClientProgramTrack/ClientProgramTrack';
import { programTrackInfoById } from '../../../../services/programTrack.service';
import { getClientMapDataByClientId } from '../../../../services/programTrackClientMap.service';
// eslint-disable-next-line
// import { ClientMessaging } from '../../../../components/ClientMessaging/ClientMessaging';
import { ClientActivityLogs } from '../../../../components/ClientActivityLogs/ClientActivityLogs';
import { ClientNotes } from '../../../../components/ClientNotes/ClientNotes';
import ClientDocuments from '../../../../components/ClientDocuments/ClientDocuments';
import { getUserInfoById } from '../../../../services/user.service';
import { ClientStatus } from '../../../../utils/clientStatus';
import { ClientActionBar } from '../../../../components/ClientActionBar/ClientActionBar';
import ClientLetter from '../../../../components/ClientLetter/ClientLetter';
import { Loader } from '../../../../components/Loader/Loader';
import ClientEmployment from '../../../../components/ClientEmployment/ClientEmployment';
import SmsChatWithClients from '../../../../components/SmsChatWithClients/SmsChatWithClients';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import BackFillProgramTrackData from '../../../../components/Modals/BackFillProgramTrackData/BackFillProgramTrackData';
import BackFillEmploymentData from '../../../../components/Modals/BackFillEmploymentData/BackFillEmploymentData';
import { shouldShowButton } from '../../dashboard/dashboardAccessControl';
import { UserModel } from '../../../../store/user/userModel';
import { useAppSelector } from '../../../../store/store';

interface IClientInfoProps {
  clientId?: string;
  programTrackId?: string | null;
}

const ClientInfo = (props: IClientInfoProps) => {
  const smallScreen = useMediaQuery('(max-width: 768px)');
  const user: UserModel = useAppSelector((state: any) => state.user.user);

  // eslint-disable-next-line
  let { clientId, programTrackId, activeChannelDetails, activeTabIndex } = useLocation().state;

  if (props.clientId || props.programTrackId) {
    clientId = props.clientId;
    programTrackId = props.programTrackId;
  }

  const [localProgramTrackId, setLocalProgramTrackId] = useState<any>(programTrackId);
  const [clientInfo, setClientInfo] = useState<any>();
  const [programTrackInfo, setProgramTrackInfo] = useState<any>();
  const [careerPathInfo, setCareerPathInfo] = useState<any>();
  const [clientMapData, setClientMapData] = useState<any>();
  const [showNavigatorModal, setShowNavigatorModal] = useState(false);
  const [careerPath, setCareerPath] = useState([]);
  const [clientNavigator, setClientNavigator] = useState<any>();
  const [navigatorList, setNavigatorList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEnrolledSubmitted, setIsEnrolledSubmitted] = useState(false);
  const [tabIndex, setTabIndex] = useState<number>(activeTabIndex || 0);
  const [isClientUpdated, setIsClientUpdated] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openPTBackFillModal, setOpenPTBackFillModal] = useState<boolean>(false);
  const [openEmpBackFillModal, setOpenEmpBackFillModal] = useState<boolean>(false);

  const durationInSeconds = programTrackInfo?.endTime - programTrackInfo?.startTime;
  const durationInDays = Math.floor(durationInSeconds / (24 * 60 * 60));

  useEffect(() => {
    (async () => {
      const data = await getCareerPathList();
      setCareerPath(data);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      const list = await getNavigatorList();
      setNavigatorList(list);
    })();
  }, []);

  const getNavigatorOfClient = async (navId: string) => {
    const navigator: any = await getUserInfoById(navId);
    setClientNavigator(navigator);
  };

  React.useEffect(() => {
    if (props.clientId) {
      setClientInfo(null);
      setTabIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clientId]);

  React.useEffect(() => {
    (async () => {
      let client: any = null;
      const unsubscribe = await getLiveClientInfoById(clientId, async (data: any) => {
        client = data;
        setClientInfo(client);

        const programTrack: any = await programTrackInfoById(props.programTrackId || localProgramTrackId);
        const clientMapDataInfo: any = await getClientMapDataByClientId(clientId);

        setProgramTrackInfo(programTrack);
        if (client?.assignedTo) {
          await getNavigatorOfClient(client?.assignedTo);
        }

        if (programTrack?.careerPathId) {
          const CareerPathData: any = await getCareerPathInfoById(programTrack?.careerPathId);
          setCareerPathInfo(CareerPathData);
          setClientMapData(clientMapDataInfo.find((i: any) => i.programTrackId === localProgramTrackId));
        }
      });

      return () => {
        unsubscribe();
      };
    })();
    // eslint-disable-next-line
  }, [clientId, isSubmitted, isEnrolledSubmitted, isClientUpdated, tabIndex, refresh, props.programTrackId]);

  //initial loader while page loading
  if (!clientInfo) {
    return <Loader />;
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const renderBackFillButton = (isPTtab: boolean) => {
    return (
      <Box sx={{ mt: 1, mb: 1, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={!shouldShowButton(isPTtab ? 'backFillProgramTrackData' : 'backFillEmploymentData', user?.role)}
          onClick={() => {
            if (isPTtab) {
              setOpenPTBackFillModal(!openPTBackFillModal);
            } else {
              setOpenEmpBackFillModal(!openEmpBackFillModal);
            }
          }}>
          <CloudSyncIcon color='primary' />
          <Typography ml={1} textTransform={'capitalize'}>
            {isPTtab ? 'Backfill Program track Data' : 'Backfill Employment Data'}
          </Typography>
        </Button>
      </Box>
    );
  };

  const renderTabPanel = () => {
    switch (tabIndex) {
      case 0:
        return <ClientInfoForm client={clientInfo} onSubmit={() => setIsClientUpdated(!isClientUpdated)} />;
      case 1:
        if (
          clientInfo?.status === ClientStatus.PENDING_ENROLLMENT ||
          clientInfo?.status === ClientStatus.COMPLETED ||
          clientInfo?.status === ClientStatus.DROP_OUT ||
          clientInfo?.status === ClientStatus.ENROLLMENT_REVIEW
        ) {
          return (
            <>
              {renderBackFillButton(true)}
              <ClientProgramTrackEnrollment
                careerPath={careerPath}
                clientInfo={clientInfo}
                onSubmit={(selectedProgramTrackId: string) => {
                  setLocalProgramTrackId(selectedProgramTrackId);
                  setIsEnrolledSubmitted(!isEnrolledSubmitted);
                }}
                onCancel={() => {
                  setIsEnrolledSubmitted(!isEnrolledSubmitted);
                }}
              />
            </>
          );
        } else if (clientInfo?.status === ClientStatus.ENROLLED) {
          const rollingLastDay = programTrackInfo?.milestones[programTrackInfo.milestones.length - 1]?.days;
          return (
            <>
              {renderBackFillButton(true)}
              <ClientProgramTrack
                milestones={programTrackInfo ? programTrackInfo.milestones : []}
                completedMilestones={clientMapData ? clientMapData.completedMilestones : []}
                durationInDays={durationInDays ? durationInDays : rollingLastDay}
                careerPathInfo={careerPathInfo}
                clientPTCMStatus={clientMapData ? clientMapData.status : ''}
                programTrackName={programTrackInfo?.name}
                programTrackType={programTrackInfo?.type}
                programTrackId={localProgramTrackId}
                client={clientInfo}
                refreshData={() => setRefresh(!refresh)}
              />
            </>
          );
        }
        return (
          <>
            {renderBackFillButton(true)}
            <Typography>Not available yet.</Typography>;
          </>
        );
      case 2:
        return (
          <>
            <Box my={5}>
              <Typography mb={1} variant='h6'>
                Text Messaging
              </Typography>
              <SmsChatWithClients hideSidebar={true} clientInfo={clientInfo} />
            </Box>
            {/* <Box my={5}>
              <Typography mb={1} variant='h6'>
                In-App Messaging
              </Typography>
              <ClientMessaging
                activeChannelDetails={activeChannelDetails}
                clientId={clientInfo?.id}
                hideSidebar={true}
              />
            </Box> */}
          </>
        );
      case 3:
        return <ClientDocuments clientInfo={clientInfo} />;
      case 4:
        return <ClientLetter clientInfo={clientInfo} />;
      case 5:
        return (
          <>
            {renderBackFillButton(false)}
            <ClientEmployment clientInfo={clientInfo} />;
          </>
        );
      case 6:
        return <ClientActivityLogs clientId={clientInfo?.id} user={user} />;
      case 7:
        return <ClientNotes clientId={clientInfo?.id} clientStatus={clientInfo?.status} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', pt: 5 }}>
        {/* Client Action Bar */}
        <Box sx={{ mb: 2 }}>
          <ClientActionBar
            clientInfo={clientInfo}
            clientNavigator={clientNavigator}
            setClientNavigator={setClientNavigator}
            setShowNavigatorModal={setShowNavigatorModal}
          />
        </Box>

        {/* Tabs */}
        <Tabs
          sx={styles.tabs}
          TabIndicatorProps={{
            sx: {
              top: 0,
            },
          }}
          value={tabIndex}
          onChange={handleChange}
          variant={smallScreen ? 'scrollable' : 'fullWidth'}>
          <Tab icon={<AccountCircleOutlined />} iconPosition='start' label='Client Info' sx={styles.tab} />
          <Tab icon={<TimelineOutlined />} iconPosition='start' label='Program Track' sx={styles.tab} />
          <Tab icon={<ForumOutlined />} iconPosition='start' label='Messaging' sx={styles.tab} />
          <Tab icon={<FileOpenOutlined />} iconPosition='start' label='Documents' sx={styles.tab} />
          <Tab icon={<FilePresentOutlined />} iconPosition='start' label='School Letter' sx={styles.tab} />
          <Tab icon={<BadgeOutlined />} iconPosition='start' label='Employment' sx={styles.tab} />
          <Tab icon={<InsightsOutlined />} iconPosition='start' label='Activity Log' sx={styles.tab} />
          <Tab icon={<NotesOutlined />} iconPosition='start' label='Notes' sx={styles.tab} />
        </Tabs>

        {/* Render content here */}
        <Box>{renderTabPanel()}</Box>
      </Box>

      {/* Navigator Modal */}
      <NavigatorSelection
        clientId={clientInfo?.id}
        navigators={navigatorList}
        open={showNavigatorModal}
        onClose={setShowNavigatorModal}
        onSubmit={(navId: string) => {
          getNavigatorOfClient(navId);
          setIsSubmitted(!isSubmitted);
        }}
      />
      <BackFillProgramTrackData
        open={openPTBackFillModal}
        onClose={() => {
          setOpenPTBackFillModal(!openPTBackFillModal);
        }}
        clientInfo={clientInfo}
        refreshData={() => setRefresh(!refresh)}
      />
      <BackFillEmploymentData
        open={openEmpBackFillModal}
        onClose={() => {
          setOpenEmpBackFillModal(!openEmpBackFillModal);
        }}
        clientInfo={clientInfo}
        refreshData={() => setRefresh(!refresh)}
      />
    </>
  );
};

export default ClientInfo;

const styles = {
  accountAndLetterBox: {
    justifyContent: { lg: 'space-between' },
    display: { xs: 'block', lg: 'flex' },
    alignItems: 'center',
  },
  accountStatusBtn: {
    width: 'max-content',
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid #D6D6D6',
    borderRadius: '8px',
  },
  msgComposerBox: {
    display: 'flex',
    width: '100%',
    borderRadius: '100px',
    background: COLORS.client.searchBarBg,
    mt: 1,
  },
  msgBox: {
    padding: '10px',
    background: COLORS.palette.white,
    border: '1px solid #D6D6D6',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  msgsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '150px',
    overflow: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: '8px',
    },
  },
  msgSentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'flex-end',
  },
  msgSent: {
    padding: '10px',
    background: '#cdcdcd',
    color: COLORS.palette.black,
    borderRadius: '8px',
    width: '50%',
    display: 'flex',
    justifyContent: 'right',
  },

  //tabs styling
  tabs: {
    mb: 2,
    color: '#000',
    '& .Mui-selected': {
      backgroundColor: '#fff',
      border: '1px solid #e0e0e0',
      borderTop: 'none',
    },
  },
  tab: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    backgroundColor: '#f5f7f8',
    minHeight: '40px',
    height: '48px',
    marginLeft: '1px',
    marginRight: '1px',
  },
};
