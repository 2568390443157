import { signInWithEmailAndPassword } from 'firebase/auth';
import { AppDispatch } from '../store/store';
import { auth } from '../config/firebaseApp';
import { getRole, saveAuditLogOnLoginFail } from '../services/firebase.service';
import { getUserInfoByUid } from '../services/client.service';
import { UserRolesEnum } from './rolesEnum';
import { updateUser } from '../store/user/userAction';

export const loginUser = async (email: string, password: string, dispatch: AppDispatch) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    if (userCredential.user && userCredential.user.uid) {
      const role = await getRole(auth);
      const userInfo: any = await getUserInfoByUid(
        userCredential.user.uid,
        !(role === UserRolesEnum.PROSPECTIVE_CLIENT || role === UserRolesEnum.CLIENT)
      );
      if (userInfo) {
        dispatch(updateUser(userInfo));
      }
    }
  } catch (e: any) {
    if (e.message === 'Firebase: Error (auth/wrong-password).') {
      saveAuditLogOnLoginFail(email);
      throw new Error('Invalid credentials');
    } else {
      saveAuditLogOnLoginFail(email);
      throw e;
    }
  }
};
