import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { ListItemButton, ListItemIcon } from '@mui/material';
import { COLORS } from '../../utils/colors';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
const drawerWidth = '15%';

interface SidebarProps {
  sidebarMenuItems: any[];
}

const Sidebar = (props: SidebarProps) => {
  const { sidebarMenuItems } = props;
  const isMobile = document.body.clientWidth > 464 ? false : true;
  const imgWidth = isMobile ? '48px' : '100px';

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant={'permanent'} sx={styles.drawer}>
        {/* Branding */}
        <Box width={'100%'} display={'flex'} justifyContent={'center'} p={isMobile ? 0 : 5} my={isMobile ? 5 : 0}>
          <img src={logo} alt={'logo'} style={{ width: imgWidth }} />
        </Box>

        {/* Sidenavs */}
        <List>
          {sidebarMenuItems.map((item, index) => (
            <ListItemButton key={item.name}>
              <ListItem component={Link} to={item.path} key={index} disableGutters disablePadding>
                <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
                {!isMobile ? <ListItemText primary={item.name} sx={{ color: 'white' }} /> : null}
              </ListItem>
            </ListItemButton>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;

const styles = {
  drawer: {
    width: drawerWidth,
    ml: { sm: `${drawerWidth}px` },
    '& .MuiDrawer-paper': {
      background: COLORS.theme.primaryColor,
      width: drawerWidth,
      boxSizing: 'border-box',
    },
  },
};
