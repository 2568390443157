import { Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import CustomTable from '../../../components/CustomTable';
import AddCareerPath from '../../../components/Modals/AddCareerPath/AddCareerPath';
import {
  ICareerPath,
  ICareerPathMetaInfo,
  getCareerPathsByQuery,
  searchCareerPath,
} from '../../../services/careerPath.service';
import { convertTimeStampToDate } from '../../../utils/dateTime';
import { shouldShowButton } from '../dashboard/dashboardAccessControl';
import { UserModel } from '../../../store/user/userModel';
import { useAppSelector } from '../../../store/store';
import { COLORS } from '../../../utils/colors';

const CareerPathList = () => {
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [careerPathModal, setCareerPathModal] = useState(false);
  const [careerPaths, setCareerPaths] = useState<Array<ICareerPath>>([]);
  const [loading, setLoading] = React.useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [editMode, setEditMode] = useState({
    isEditMode: false,
    editData: {} as any,
  });
  const PAGE_SIZE = 10;
  const [careerPathMetaInfo, setcareerPathMetaInfo] = React.useState<ICareerPathMetaInfo>();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [searchPaginationModel, setSearchPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [isSearchMode, setIsSearchMode] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const fetchCareerPaths = async () => {
    setLoading(true);
    setIsSearchMode(false);
    setSearchQuery('');
    const result = await getCareerPathsByQuery(paginationModel.page + 1, sortModel);
    setcareerPathMetaInfo(result);
    if (result?.careerPaths) {
      setCareerPaths(result.careerPaths as Array<ICareerPath>);
    }
    setLoading(false);
  };

  useEffect(
    () => {
      if (!isSearchMode) {
        fetchCareerPaths();
      }
    },
    // eslint-disable-next-line
    [paginationModel.page, editMode.isEditMode, editMode.editData, shouldFetch, sortModel]
  );

  const handleSearch = async () => {
    if (searchQuery) {
      setIsSearchMode(true);
      setLoading(true);
      const result = await searchCareerPath(searchQuery.toLowerCase().trim(), searchPaginationModel.page + 1);
      setcareerPathMetaInfo(result);
      if (result?.careerPaths) {
        setCareerPaths(result.careerPaths as Array<ICareerPath>);
      } else {
        setCareerPaths([]);
      }
      setLoading(false);
    }
  };

  useEffect(
    () => {
      handleSearch();
    },
    // eslint-disable-next-line
    [searchPaginationModel.page]
  );

  useEffect(
    () => {
      if (!searchQuery) {
        fetchCareerPaths();
      }
    },
    // eslint-disable-next-line
    [searchQuery]
  );

  const handleAddCareerPath = () => {
    setShouldFetch(true);
    fetchCareerPaths();
  };

  const getEditData = (rowData: any) => {
    const regex = /\$?([\d,]+)-\$?([\d,]+)/;
    const salaries = rowData.salaries.match(regex);
    return {
      code: rowData.code,
      name: rowData.name,
      description: rowData.description,
      minSalary: salaries[1],
      maxSalary: salaries[2],
      info: rowData.info,
      id: rowData.id,
    };
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      sortable: true,
      width: 250,
    },
    {
      field: 'code',
      headerName: 'Code',
      type: 'string',
      sortable: true,
      width: 250,
    },
    {
      field: 'salaries',
      headerName: 'Salary',
      type: 'string',
      sortable: true,
      width: 250,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) => convertTimeStampToDate(params.row.createdAt).format('MM/DD/YYYY'),
    },
    {
      headerName: 'Actions',
      sortable: false,
      width: 250,
      renderCell: (params: any) => (
        <Box display={'flex'} gap={2}>
          <IconButton
            onClick={() => {
              setEditMode({ ...editMode, isEditMode: true, editData: getEditData(params.row) });
              setCareerPathModal(true);
            }}
            disabled={!shouldShowButton('createCareerPath', user?.role)}>
            <EditIcon
              style={{
                color: !shouldShowButton('createCareerPath', user?.role) ? 'grey' : COLORS.theme.linkColor,
              }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleSortModelChange = (newSortModel: any) => {
    setSortModel(newSortModel);
  };

  return (
    <Box sx={{ width: '100%', height: 500 }}>
      <CustomTable
        primaryButtonLable='Add Career Path'
        primaryButtonAction={() => {
          setEditMode({ ...editMode, isEditMode: false });
          setCareerPathModal(true);
        }}
        disabled={!shouldShowButton('createCareerPath', user?.role)}
        onFilterListChange={() => {}}
        rows={careerPaths}
        columns={columns}
        loading={loading}
        rowCount={careerPathMetaInfo?.totalRecords || 0}
        paginationModel={isSearchMode ? searchPaginationModel : paginationModel}
        onPaginationModelChange={isSearchMode ? setSearchPaginationModel : setPaginationModel}
        filterList={[]}
        title='Career Paths'
        onChangeSearchQuery={(query: string) => {
          setSearchQuery(query);
        }}
        handleSearch={() => handleSearch()}
        searchQuery={searchQuery}
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel}
      />
      <AddCareerPath
        open={careerPathModal}
        onClose={() => setCareerPathModal(false)}
        data={editMode.isEditMode ? editMode.editData : null}
        mode={editMode.isEditMode ? 'EDIT' : 'CREATE'}
        onAddCareerPath={handleAddCareerPath}
      />
    </Box>
  );
};

export default CareerPathList;
