import { Box, Typography } from '@mui/material';
import React from 'react';

const Resource = () => {
  return (
    <Box mt={2} pt={5}>
      <Typography variant='h5' mb={2}>
        Resource Page
      </Typography>
    </Box>
  );
};

export default Resource;
