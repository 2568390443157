import { ListAltOutlined, OtherHousesOutlined, AccountCircleOutlined } from '@mui/icons-material';

export const ClientSidebarMenuItems = [
  {
    path: '/client/home',
    name: 'Home',
    icon: <OtherHousesOutlined />,
  },
  {
    path: '/client/account-info',
    name: 'Account Info',
    icon: <AccountCircleOutlined />,
  },
  {
    path: '/client/documents',
    name: 'Documents',
    icon: <ListAltOutlined />,
  },
];
