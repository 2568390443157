import { Timestamp } from 'firebase/firestore';

export const emptyWorkshopInfo: IWorkshopInfo = {
  id: '',
  title: '',
  description: '',
  location: '',
  dateTime: [],
  type: '',
  meetingLink: '',
  repeatWeekly: false,
  repeatWeeks: 0,
  attachments: [],
  createdAt: '',
  updatedAt: '',
  isAttendanceMarked: false,
  numClients: 0,
};

export interface IWorkshopInfo {
  id: string;
  title: string;
  description: string;
  location: string;
  dateTime: IDateTime[];
  type: string;
  meetingLink: string;
  repeatWeekly: boolean;
  repeatWeeks: number;
  attachments: IResource[];
  createdAt: string;
  updatedAt: string;
  isAttendanceMarked: boolean;
  numClients: number;
}

export interface IWorkshopMetaInfo {
  totalRecords: number;
  currentPage: number;
  workshops: IWorkshopInfo[];
  totalPages: number;
}

export interface IResource {
  createdBy: string;
  content: string;
  id: string;
  type: string;
}

export interface IDateTime {
  timing: string;
}

export interface IClientJRTComplete {
  id: string;
  workshopId: string;
  markedAt: string;
}

export interface IClientWorkshopAttendance {
  attendance: string;
  markedAt: Timestamp;
  markedBy: Timestamp;
  workshopId: string;
}
