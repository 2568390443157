import {
  Autocomplete,
  Box,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchPTAnalytics, getPTAnalyticsById } from '../../../services/programTrackAnalytics.service';
import { getProgramTracks } from '../../../services/programTrack.service';
import {
  getPCBoardedInLastXDays,
  getPCConvertedInLastXDays,
} from '../../../services/prospectiveClientAnalytics.service';
import { fetchCurriculums, getCurriculumInfoByCurriculumId } from '../../../services/curriculum.service';
import {
  getMultipleCurriculumAnalyticsByIds,
  getcurriculumAnalyticsById,
} from '../../../services/curriculumAnalytics.service';

const Analytics = () => {
  const [analyticsPT, setAnalyticsPT] = useState<any>([]);
  const [programTracks, setProgramTracks] = useState<any>([]);
  const [curriculum, setCurriculum] = useState<any>([]);
  const [selectedCurriculum, setSelectedCurriculum] = useState({ id: '', label: '' });
  const [analyticCurriculum, setAnalyticCurriculum] = useState<any>();
  const [selectedProgramTrack, setSelectedProgramTrack] = useState<object>({ id: '', label: '' });
  const [maxTimeTaken, setMaxTimeTaken] = useState(-1);
  const [pastDaysOnboard, setPastDaysOnboard] = useState<string>('');
  const [pastDaysConvert, setPastDaysConvert] = useState<string>('');
  const [pcBoarded, setPCBoarded] = useState<number>(-1);
  const [pcConverted, setPCConverted] = useState<number>(-1);
  const [versionCA, setVersionCA] = useState<number>(-1);

  const getPTAnalyticsData = async () => {
    const analyticsList = await fetchPTAnalytics();
    const maxValue = getMaxTimeTaken(analyticsList);
    setMaxTimeTaken(maxValue);
    analyticsList.sort((a: any, b: any) => {
      if (b.enrolled === 0 && a.enrolled === 0) {
        return 0;
      } else if (b.enrolled === 0) {
        return -1;
      } else if (a.enrolled === 0) {
        return 1;
      }

      const percentageA = Math.floor((a.completed / a.enrolled) * 100);
      const percentageB = Math.floor((b.completed / b.enrolled) * 100);

      return percentageB - percentageA;
    });
    setAnalyticsPT(analyticsList);
  };

  const getAllProgramTrack = async () => {
    const ptList = await getProgramTracks();
    setProgramTracks(ptList);
  };

  const getAllCurriculum = async () => {
    const curriculumList = await fetchCurriculums();
    setCurriculum(curriculumList);
  };

  const getMaxTimeTaken = (list: any[]) => {
    let max = 0;
    list.forEach((obj) => {
      if (obj.avgTimeTaken > max) {
        max = obj.avgTimeTaken;
      }
    });
    return max;
  };

  const handleProgramTrackChange = async (programTrackId: string) => {
    const ptAnalyticsDoc = await getPTAnalyticsById(programTrackId);
    setAnalyticsPT([ptAnalyticsDoc]);
  };

  const handleChangePCOnBoard = async (event: any) => {
    const val = event.target.value;
    setPastDaysOnboard(val);
    if (val > 0) {
      await getPCBoardedInLastXDays(parseInt(val)).then((val) => {
        setPCBoarded(Number(val));
      });
    } else {
      setPCBoarded(-1);
    }
  };

  const handleChangePCConvert = async (event: any) => {
    const val = event.target.value;
    setPastDaysConvert(val);

    if (val > 0) {
      await getPCConvertedInLastXDays(parseInt(val)).then((val) => {
        setPCConverted(Number(val));
      });
    } else {
      setPCConverted(-1);
    }
  };

  const handleCurriculumChange = async (curriculumId: string) => {
    const curriculumDoc: any = await getCurriculumInfoByCurriculumId(curriculumId);
    const idArray = [];

    if (curriculumDoc.version) {
      setVersionCA(curriculumDoc.version);
      for (let i = 1; i <= curriculumDoc.version; i++) {
        if (i !== 1) {
          const id = curriculumId + '_' + i;
          idArray.push(id);
        } else {
          idArray.push(curriculumId);
        }
      }
      const curriculumAnalyticsArray = await getMultipleCurriculumAnalyticsByIds(idArray);
      setAnalyticCurriculum(curriculumAnalyticsArray.reverse());
    } else {
      const curriculumAnalyticsDoc = await getcurriculumAnalyticsById(curriculumId);
      setAnalyticCurriculum([curriculumAnalyticsDoc]);
    }
  };

  const getMaxTimeTakenByMilestones = (id: string) => {
    let maxTTByMilestone = 0;
    const targetDoc = analyticCurriculum.filter((doc: any) => doc.id === id);

    Object.keys(targetDoc[0]).map((key) => {
      if (key !== 'enrolled' && key !== 'id') {
        const { timeTaken } = targetDoc[0][key];
        if (timeTaken > maxTTByMilestone) {
          maxTTByMilestone = timeTaken;
        }
      }
    });
    return maxTTByMilestone;
  };

  useEffect(() => {
    getPTAnalyticsData();
    getAllProgramTrack();
    getAllCurriculum();
    // eslint-disable-next-line
  }, []);

  const styles = {
    header: {
      mb: 1,
      position: 'sticky',
      top: 0,
      backgroundColor: '#ffffff',
      zIndex: 1,
      height: '10%',
    },
    list: {
      border: 1,
      height: 400,
      overflowY: 'auto',
      pl: 2,
      pr: 2,
      pb: 2,
    },
  };
  return (
    <Box mt={2} pt={5}>
      <Typography variant='h5'>Analytics</Typography>
      <Typography variant='caption' textTransform={'none'} mb={2}>
        Client Analytics
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{ marginTop: 5 }}>
          <Box>
            <h3 style={{ textAlign: 'center' }}>Prospective Clients Onboarded </h3>
          </Box>
          <TextField
            label='Past Days'
            variant='outlined'
            placeholder='Enter Number of Days'
            value={pastDaysOnboard}
            onChange={handleChangePCOnBoard}
            fullWidth
            type='number'
            sx={{ marginTop: 3 }}
            InputProps={{ inputProps: { min: 1 } }}
          />
          {pcBoarded >= 0 ? (
            <Box>
              <h3>Prospective Clients Onboarded Count: {pcBoarded}</h3>
            </Box>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} sx={{ marginTop: 5 }}>
          <Box>
            <h3 style={{ textAlign: 'center' }}>Prospective Clients Converted </h3>
          </Box>
          <TextField
            label='Past Days'
            variant='outlined'
            placeholder='Enter Number of Days'
            value={pastDaysConvert}
            onChange={handleChangePCConvert}
            fullWidth
            type='number'
            InputProps={{ inputProps: { min: 1 } }}
            sx={{ marginTop: 3 }}
          />
          {pcConverted >= 0 ? (
            <Box>
              <h3>Prospective Clients Converted Count: {pcConverted}</h3>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      <h3 style={{ marginTop: 35 }}>Program Tracks Analytics</h3>
      <Autocomplete
        sx={{ width: 300, marginTop: 2 }}
        value={selectedProgramTrack}
        options={programTracks?.map((option: any) => ({
          id: option.id,
          label: `${option.name} (${option.type})`,
        }))}
        getOptionLabel={(option: any) => option.label}
        onChange={(event, value: any) => {
          if (value) {
            setSelectedProgramTrack(value);
            handleProgramTrackChange(value.id);
          } else {
            setSelectedProgramTrack({ id: '', label: '' });
            getPTAnalyticsData();
          }
        }}
        renderInput={(params) => <TextField {...params} label='Select Program Track' />}
      />
      <Grid container sx={{ marginTop: 5 }}>
        <Grid item xs={12} sm={4} sx={styles.list}>
          <Box sx={styles.header}>
            <h3 style={{ textAlign: 'center' }}>COMPLETION RATE</h3>
          </Box>
          {analyticsPT.length > 0
            ? analyticsPT.map((item: any, index: number) => {
                return item.enrolled > 0 ? (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: 2,
                    }}>
                    <h5>{item.name}</h5>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <LinearProgress
                        variant='determinate'
                        value={Math.floor((item.completed / item.enrolled) * 100)}
                        sx={{ height: 15, minWidth: 250 }}
                      />
                      <h3 style={{ paddingLeft: 4 }}>{Math.floor((item.completed / item.enrolled) * 100) + '%'}</h3>
                    </Box>
                  </Box>
                ) : null;
              })
            : null}
        </Grid>
        <Grid item xs={12} sm={4} sx={styles.list}>
          <Box sx={styles.header}>
            <h3 style={{ textAlign: 'center' }}>DROPOUT RATE</h3>
          </Box>
          {analyticsPT.length > 0
            ? analyticsPT.map((item: any, index: number) => {
                return item.enrolled > 0 ? (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: 2,
                    }}>
                    <h5>{item.name}</h5>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <LinearProgress
                        variant='determinate'
                        value={Math.floor((item.dropouts / item.enrolled) * 100)}
                        sx={{ height: 15, minWidth: 250 }}
                      />
                      <h3 style={{ paddingLeft: 4 }}>{Math.floor((item.dropouts / item.enrolled) * 100) + '%'}</h3>
                    </Box>
                  </Box>
                ) : null;
              })
            : null}
        </Grid>
        <Grid item xs={12} sm={4} sx={styles.list}>
          <Box sx={styles.header}>
            <h3 style={{ textAlign: 'center' }}>TIME TAKEN</h3>
          </Box>
          {analyticsPT.length > 0
            ? analyticsPT.map((item: any, index: number) => {
                return maxTimeTaken > 0 && item.avgTimeTaken > 0 ? (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: 2,
                    }}>
                    <h5>{item.name}</h5>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <LinearProgress
                        variant='determinate'
                        value={Math.floor((item.avgTimeTaken / maxTimeTaken) * 100)}
                        sx={{ height: 15, minWidth: 250 }}
                      />
                      <h4 style={{ paddingLeft: 4, textAlign: 'center', fontSize: 15 }}>
                        {item.avgTimeTaken + ' days'}
                      </h4>
                    </Box>
                  </Box>
                ) : null;
              })
            : null}
        </Grid>
      </Grid>
      <h3 style={{ marginTop: 25 }}>Curriculum Analytics</h3>
      <Autocomplete
        sx={{ width: 300, marginTop: 2 }}
        value={selectedCurriculum}
        options={curriculum?.map((option: any) => ({
          id: option.id,
          label: option.name,
        }))}
        getOptionLabel={(option: any) => option.label}
        onChange={(event, value: any) => {
          if (value) {
            setSelectedCurriculum(value);
            handleCurriculumChange(value.id);
          } else {
            setSelectedCurriculum({ id: '', label: '' });
            setAnalyticCurriculum(null);
            setVersionCA(-1);
          }
        }}
        renderInput={(params) => <TextField {...params} label='Select Curriculum' />}
      />
      <div style={{ overflowX: 'auto' }}>
        <Table sx={{ marginTop: 5 }}>
          <TableHead>
            <TableRow>
              <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Milestone
              </TableCell>
              <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Completion Rate
              </TableCell>
              <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Time Taken
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {analyticCurriculum
              ? analyticCurriculum.map((item: any, index: number) => {
                  return (
                    <>
                      {versionCA !== -1 ? (
                        <TableRow>
                          <TableCell colSpan={3} sx={{ fontWeight: 'bold', fontSize: 16 }}>
                            {'Version ' + (versionCA - index)}
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {Object.keys(item).map((key) => {
                        if (key !== 'enrolled' && key !== 'id') {
                          const { count, milestoneName, timeTaken } = item[key];
                          return (
                            <TableRow key={key}>
                              <TableCell align='center'>{milestoneName}</TableCell>
                              <TableCell align='center'>
                                {item.enrolled > 0 ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}>
                                    <LinearProgress
                                      variant='determinate'
                                      value={Math.floor((count / item.enrolled) * 100)}
                                      sx={{ height: 15, width: 250 }}
                                    />
                                    <h4 style={{ paddingLeft: 4 }}>
                                      {Math.floor((count / item.enrolled) * 100) + '%'}
                                    </h4>
                                  </Box>
                                ) : (
                                  count
                                )}
                              </TableCell>
                              <TableCell align='center'>
                                {getMaxTimeTakenByMilestones(item.id) > 0 && timeTaken > 0 ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}>
                                    <LinearProgress
                                      variant='determinate'
                                      value={Math.floor((timeTaken / getMaxTimeTakenByMilestones(item.id)) * 100)}
                                      sx={{ height: 15, width: 250 }}
                                    />
                                    <h4 style={{ paddingLeft: 4 }}>{timeTaken + ' days'}</h4>
                                  </Box>
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                        return null;
                      })}
                    </>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </div>
    </Box>
  );
};

export default Analytics;
