import { Box, Checkbox, CircularProgress, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { useEffect, useState } from 'react';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import { useAppSelector } from '../../store/store';
import { UserModel } from '../../store/user/userModel';
import { toast } from 'react-toastify';
import {
  fetchEligibleClientsForJobOpportunity,
  scheduleClientJobInterview,
} from '../../services/employmentModule.service';

interface JobInterviewScheduleSheetProps {
  jobId: string;
  onSubmit: Function;
}

const JobInterviewScheduleSheet = (props: JobInterviewScheduleSheetProps) => {
  const { jobId, onSubmit } = props;
  const user: UserModel = useAppSelector((state: any) => state.user.user) || {};
  const [clientList, setClientList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedClients, setSelectedClients] = useState<any>([]);
  const [clientLoader, setClientLoader] = useState<boolean>(false);

  const handleClientSelect = (clientId: string) => {
    if (selectedClients.includes(clientId)) {
      setSelectedClients(selectedClients.filter((id: string) => id !== clientId));
    } else {
      setSelectedClients([...selectedClients, clientId]);
    }
  };

  const fetchEligibleClients = async () => {
    try {
      setClientLoader(true);
      const loadingTimeout = setTimeout(() => {
        setClientLoader(true);
      }, 200);

      const unsubscribe = await fetchEligibleClientsForJobOpportunity(jobId, (clientData: any) => {
        clearTimeout(loadingTimeout);
        setClientList(clientData);
        setClientLoader(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error);
    }
  };

  const handleInterviewSchedule = async () => {
    setLoading(true);
    let response: any;
    response = await scheduleClientJobInterview(selectedClients, jobId, user?.id || '');
    if (response?.success) {
      toast.success(response.message);
      onSubmit();
    } else {
      toast.error('Something went wrong');
    }
    setLoading(false);
    setClientList([]);
  };

  useEffect(() => {
    fetchEligibleClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box py={3}>
      {clientLoader ? (
        <Grid container direction='column' alignItems='center' justifyContent='center'>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Box display={clientList?.length > 0 ? 'block' : 'none'}>
            <Grid container>
              <Grid item xs={12}>
                <List>
                  {clientList.map((client: any) => (
                    <ListItem
                      key={client.id}
                      disablePadding
                      onClick={() => {
                        handleClientSelect(client.id);
                      }}>
                      <ListItemText primary={client.firstName + ' ' + client.lastName} />
                      <Checkbox
                        checked={selectedClients.includes(client.id)}
                        onChange={() => {
                          handleClientSelect(client.id);
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
            <Grid container direction='column' alignItems='center' justifyContent='center' pt={2}>
              <Grid item xs={12}>
                <LoadingButton
                  size='small'
                  color={'primary'}
                  variant={'contained'}
                  loading={loading}
                  disabled={selectedClients.length > 0 ? false : true}
                  onClick={handleInterviewSchedule}
                  startIcon={<ScheduleOutlinedIcon />}
                  typographyComp={
                    <Typography variant='body2' textTransform={'none'} noWrap>
                      Schedule Interview
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box display={clientList?.length > 0 ? 'none' : 'block'}>
            <Typography variant='body2' textAlign={'center'} py={2}>
              No Clients Eligible Yet.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default JobInterviewScheduleSheet;
