import React from 'react';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Field, Formik, type FormikHelpers } from 'formik';
import { Box, IconButton, Modal, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { COLORS } from '../../utils/colors';
import { convertToClient } from '../../services/client.service';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
  fourDigitSSN: yup
    .string()
    .matches(/^[0-9]{4}$/, 'Must be a 4-digit number')
    .required('4 Digits SSN Number is required'),
  dob: yup.date().max(new Date(), 'invalid date').required(),
});

interface IMembershipId {
  fourDigitSSN: string;
  dob: Date | null;
}

interface IProps {
  open: boolean;
  onClose: Function;
  onSubmit: Function;
  id?: string;
  dob: string;
}

export const ConvertProspectiveClient = (props: IProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const initialValues: IMembershipId = {
    fourDigitSSN: '',
    dob: dayjs(props.dob).toDate(),
  };

  const submit = async (values: IMembershipId, { setSubmitting }: FormikHelpers<IMembershipId>) => {
    let isFormSubmitted = false;
    if (props.id && values.dob) {
      try {
        setLoading(true);
        const result = await convertToClient({
          dob: values.dob,
          fourDigitSSN: values.fourDigitSSN,
          id: props.id,
        });

        setLoading(false);
        if (result.success) {
          isFormSubmitted = true;
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
        navigate('/admin/client');
      } catch (err: any) {
        setLoading(false);
        toast.error(err.message);
      }
    }
    setSubmitting(false);
    props.onSubmit(isFormSubmitted);
    props.onClose();
  };

  return (
    <Modal open={props.open} onClose={() => props.onClose()}>
      <Box sx={styles.modalBox}>
        <Box sx={styles.modalHeaderBox}>
          <Typography variant='h6' sx={{ fontWeight: 'bold' }} id='modal-title'>
            Convert Prospective Client
          </Typography>
          <IconButton onClick={() => props.onClose()}>
            <Close />
          </IconButton>
        </Box>
        <Box mt={2}>
          <Formik initialValues={initialValues} onSubmit={submit} validationSchema={schema}>
            {({ values, handleSubmit, handleChange, handleBlur, touched, errors, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  type='text'
                  name='fourDigitSSN'
                  label='Social Security Number'
                  value={values.fourDigitSSN}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.fourDigitSSN && Boolean(errors.fourDigitSSN)}
                  helperText={touched.fourDigitSSN && errors.fourDigitSSN}
                />
                <Field
                  name='dob'
                  component={DatePicker}
                  label='Date Of Birth'
                  inputFormat='MM/DD/YYYY'
                  value={dayjs(values.dob)}
                  onChange={(dob: string | number | Date | dayjs.Dayjs | null | undefined) => {
                    const formattedDate = dayjs(dob).format('MM/DD/YYYY');
                    setFieldValue('dob', formattedDate);
                  }}
                  error={touched.dob && Boolean(errors.dob)}
                  sx={{ width: '100%', marginTop: '20px' }}
                />
                {touched.dob && Boolean(errors.dob) ? <p style={{ color: COLORS.result.error }}>{errors.dob}</p> : null}
                <LoadingButton
                  type='submit'
                  variant='contained'
                  // @ts-ignore
                  color='darkModePrimary'
                  loading={loading}
                  size='large'
                  label='Convert'
                  styles={{ width: '100%', marginTop: '10px' }}
                />
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'max-content',
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    p: 4,
    overflow: 'auto',
  },
  modalHeaderBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
