import * as React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { ISplitButtonOption } from '../../utils/common';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { ListItemIcon, Typography } from '@mui/material';

export interface SplitButtonProps {
  defaultValue?: string;
  options: ISplitButtonOption[];
  onMenuItemClick: Function;
  loading: boolean;
}

const SplitButton = (props: SplitButtonProps) => {
  const { defaultValue, options, onMenuItemClick, loading } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedItemId, setSelectedItemId] = React.useState(
    options.find((item) => item.value === defaultValue)?.id || 0
  );
  const [selectedItem, setSelectedItem] = React.useState<ISplitButtonOption | null>(null);

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, itemId: number) => {
    setSelectedItemId(itemId);
    onMenuItemClick(itemId);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    setSelectedItem(options.find((item) => item.id === selectedItemId) || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemId]);

  return (
    <React.Fragment>
      <ButtonGroup size='small' variant='contained' ref={anchorRef} aria-label='split button'>
        <LoadingButton
          color={selectedItem?.color || 'primary'}
          variant={selectedItem?.variant || 'contained'}
          loading={loading}
          startIcon={selectedItem?.icon || null}
          endIcon={<ArrowDropDownIcon />}
          disabled={options.length === 0 || selectedItem?.disabled || false}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select'
          aria-haspopup='menu'
          onClick={handleToggle}
          typographyComp={
            <Typography variant='body2' textTransform={'none'} noWrap>
              {selectedItem?.label || 'Select'}
            </Typography>
          }
        />
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {options?.map((option) => (
                    <MenuItem
                      key={option?.id}
                      selected={option?.id === selectedItemId}
                      disabled={option?.disabled}
                      onClick={(event) => handleMenuItemClick(event, option?.id)}>
                      {option?.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                      <Typography variant='body2' textTransform={'none'} noWrap>
                        {option.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default SplitButton;
