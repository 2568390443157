import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { IResource, IWorkshopInfo } from '../../store/employmentModule/workshopModal';
import { AttachmentOptionsEnum } from '../../utils/workshopUtils';
import { COLORS } from '../../utils/colors';
import { DeleteOutline, VisibilityOutlined } from '@mui/icons-material';
import { getFileNameFromUrl } from '../../utils/fileHandler';
import { CreateColumnCell } from '../CreateColumnCell/CreateColumnCell';
import { editWorkshop } from '../../services/workshop.service';
import dayjs from 'dayjs';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';
import { UserRolesEnum } from '../../utils/rolesEnum';

interface WorkshopAttachmentViewProps {
  workshop: IWorkshopInfo;
}

const WorkshopAttachmentView = (props: WorkshopAttachmentViewProps) => {
  const { workshop } = props;
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const allowedWorkshopPermit =
    user.role === UserRolesEnum.ADMIN ||
    user.role === UserRolesEnum.SUPER_ADMIN ||
    user.role === UserRolesEnum.EMPLOYMENT_LIAISON;

  const onDelete = async (id: string) => {
    try {
      const updatedAttachments = workshop.attachments.filter((attachment) => attachment.id !== id);
      const payload: IWorkshopInfo = {
        ...workshop,
        updatedAt: dayjs().toISOString(),
        attachments: updatedAttachments,
      };
      await editWorkshop(payload);
    } catch (err) {
      // catch error
    }
  };

  const renderAttachments = (item: IResource) => {
    return (
      <Box display={'flex'} alignItems={'center'}>
        <Grid container>
          <Grid item xs={2} lg={2} display={'flex'} justifyContent={'left'} alignContent={'center'}>
            <Box>
              {CreateColumnCell(
                item.type,
                COLORS.palette.white,
                undefined,
                2,
                item.type === AttachmentOptionsEnum.FILE ? COLORS.palette.lightPurple : COLORS.palette.lightBlue,
                item.type === AttachmentOptionsEnum.FILE ? COLORS.palette.purple : COLORS.palette.blue
              )}
            </Box>
          </Grid>
          <Grid item xs={6} lg={6}>
            {/* Document title */}
            <Typography variant='body1'>
              {item.type === AttachmentOptionsEnum.FILE ? getFileNameFromUrl(item.content) : item.content}
            </Typography>
          </Grid>
          <Grid item xs={4} lg={4}>
            <Box display='flex' justifyContent='flex-end' alignItems='center' gap='16px'>
              {/* View document */}
              <Tooltip title='View' arrow>
                <IconButton
                  type='submit'
                  color='success'
                  onClick={() => {
                    window.open(item.content);
                  }}>
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>

              {/* Delete document */}
              <Box display={allowedWorkshopPermit ? 'block' : 'none'}>
                <Tooltip title='Delete' arrow>
                  <IconButton
                    type='submit'
                    color='error'
                    onClick={() => {
                      onDelete(item.id);
                    }}>
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'} p={2} my={2}>
      <Box display={allowedWorkshopPermit ? 'block' : 'none'}>
        <Typography variant='h6'>Manage Attachments</Typography>
        <Typography variant='body2' mb={4}>
          You can manage uploaded files, links, video resources to the workshop.
        </Typography>

        <Typography
          variant='body1'
          mb={4}
          textAlign={'center'}
          display={workshop?.attachments?.length > 0 ? 'none' : 'block'}>
          No attachments found.
        </Typography>
      </Box>

      {workshop?.attachments?.map((item: IResource) => {
        return renderAttachments(item);
      })}
    </Box>
  );
};

export default WorkshopAttachmentView;
