import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import {
  Box,
  Grid,
  Typography,
  Modal,
  IconButton,
  InputBase,
  Paper,
  List,
  ListItemText,
  Avatar,
  ListItemButton,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { COLORS } from '../../../utils/colors';
import { useAppSelector } from '../../../store/store';
import { fetchClientList, fetchClientsForTextMessaging, searchClientsByQuery } from '../../../services/client.service';
import { UserModel } from '../../../store/user/userModel';
import { getStaffInfoByClientId, getStaffList } from '../../../services/user.service';
import { Loader } from '../../Loader/Loader';
import { debounce } from 'lodash';
import { EMAIL_REGEX, MOBILE_REGEX } from '../../../utils/regex';

interface IAddUserListProps {
  open: boolean;
  onClose: () => void;
  isClientList: boolean;
  isSMSmessaging?: boolean;
  data: any;
  clientId?: string;
}

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const localStyle = {
  centeredStyle: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    borderRadius: '14px',
    width: '600px',
    height: '600px',
    '@media (max-width: 480px)': {
      padding: '20px',
      margin: '10px',
    },
  },
  titleGrid: {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    px: 2,
    pt: 2,
  },
  userList: {
    marginTop: '20px',
    maxHeight: '400px',
    overflowY: 'auto',
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    background: COLORS.client.searchBarBg,
    mx: 2,
  },
  inputBase: {
    ml: 1,
    flex: 1,
  },
  iconButton: {
    p: 2,
  },
  avatar: {
    marginRight: '10px',
  },
  loader: {
    position: 'relative',
    bottom: '120px',
  },
};

export const AddUserListOnMessagingPage = (props: IAddUserListProps) => {
  const { open, onClose, isClientList, clientId, isSMSmessaging } = props;
  const [userList, setUserList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const handleRowClick = (user: any) => {
    props.data(user);
    props.data(user);
  };

  useEffect(
    () => {
      if (clientId) {
        (async () => {
          setLoading(true);
          const staffInfo = await getStaffInfoByClientId(clientId);
          setUserList(staffInfo);
          setLoading(false);
        })();
      } else if (isSMSmessaging) {
        (async () => {
          setLoading(true);
          const clientInfo = await fetchClientsForTextMessaging(user);
          setUserList(clientInfo);
          setLoading(false);
        })();
      } else {
        (async () => {
          setLoading(true);
          const userData = isClientList ? await fetchClientList(user) : await getStaffList();
          setUserList(userData);
          setLoading(false);
        })();
      }
    },
    // eslint-disable-next-line
    [open, user, isClientList]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearchChange = React.useCallback(
    debounce((value: string, sarchQueryType: string) => {
      setLoading(true);
      if (isClientList) {
        (async () => {
          const clientData =
            value.length > 0
              ? await searchClientsByQuery(value.toLocaleLowerCase(), sarchQueryType, user)
              : await fetchClientList(user);
          setUserList(clientData);
          setLoading(false);
        })();
      } else {
        (async () => {
          const staffList = await getStaffList();
          const filteredStaffList = staffList.filter((item: any) =>
            `${item.firstName} ${item.lastName}`.toLowerCase().includes(value.toLowerCase())
          );
          setUserList(filteredStaffList);
          setLoading(false);
        })();
      }
    }, 1000),
    [isClientList, user]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let sarchQueryType = '';
    if (EMAIL_REGEX.test(value)) {
      sarchQueryType = 'email';
    } else if (MOBILE_REGEX.test(value)) {
      sarchQueryType = 'mobile';
    } else {
      sarchQueryType = 'name';
    }
    setSearchValue(value);
    debouncedHandleSearchChange(value, sarchQueryType);
  };

  const handleClose = () => {
    setSearchValue('');
    onClose();
  };

  return (
    <ModalWrapper open={open} onClose={handleClose}>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={localStyle.centeredStyle}>
          <Grid container sx={localStyle.titleGrid}>
            <Typography variant='h5'>{isClientList ? 'Client List' : 'Staff List'}</Typography>
            <IconButton onClick={handleClose}>
              <GridCloseIcon />
            </IconButton>
          </Grid>

          <Paper elevation={0} component='form' sx={localStyle.searchBar}>
            <InputBase
              sx={localStyle.inputBase}
              placeholder='Type to search'
              value={searchValue}
              onChange={handleSearchChange}
            />
            <IconButton type='button' sx={{ p: '10px', color: COLORS.theme.primaryColor }}>
              <Search />
            </IconButton>
          </Paper>

          <List sx={localStyle.userList}>
            {userList?.map((user: any) => (
              <ListItemButton key={user.id} onClick={() => handleRowClick(user)}>
                <Box pr={2}>
                  <Avatar src='/broken-image.jpg' sx={{ width: 28, height: 28 }} />
                </Box>
                <ListItemText primary={user?.firstName + ' ' + user?.lastName} />
              </ListItemButton>
            ))}
          </List>
        </Box>
      )}
    </ModalWrapper>
  );
};
