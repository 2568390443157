import { Box } from '@mui/material';
import React, { useState } from 'react';
import { DashboardSmallCard } from '../DashboardSmallCard/DashboardSmallCard';
import AddProgramTrack from '../Modals/AddProgramTrack/AddProgramTrack';
import { TimelineOutlined } from '@mui/icons-material';
import { pseudoAddProgramTrack } from '../../services/programTrack.service';

const CreateProgramTrackWidget = () => {
  const [programTrackModal, setProgramTrackModal] = useState(false);

  const dummyCallAddProgramTrack = async () => {
    await pseudoAddProgramTrack();
  };

  return (
    <>
      <Box
        style={{ cursor: 'pointer' }}
        onClick={() => {
          dummyCallAddProgramTrack();
          setProgramTrackModal(true);
        }}>
        <DashboardSmallCard label='Create Program Track' desc='Add new Program Track' avatar={<TimelineOutlined />} />
      </Box>
      <AddProgramTrack open={programTrackModal} mode={'CREATE'} onClose={() => setProgramTrackModal(false)} />
    </>
  );
};

export default CreateProgramTrackWidget;
