//modes dropdown list
export const CurriculumModes = [
  { id: 1, value: 'Asynchronous' },
  { id: 2, value: 'Synchronous' },
];

//deliver dropdown list
export const CurriculumDeliver = [
  { id: 1, value: 'Virtual' },
  { id: 2, value: 'Classroom' },
  { id: 3, value: 'Laboratory' },
  { id: 4, value: 'Behind the Wheel' },
];
