import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DashboardCard } from '../DashboardCard/DashboardCard';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchClientsHiredInJob, fetchClientsInvitedToJob } from '../../services/employmentModule.service';

interface IClientsInvitedToJobOpportunityProps {
  isHired: boolean;
  jobId: string;
}
export const ClientsInvitedToJobOpportunity = (props: IClientsInvitedToJobOpportunityProps) => {
  const { jobId, isHired } = props;
  const navigate = useNavigate();
  const [clientList, setClientList] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const loadClients = async () => {
    try {
      if (isHired) {
        setShowLoader(true);
        const unsubscribe = await fetchClientsHiredInJob(jobId, (clientList: any) => {
          setClientList(clientList);
          setShowLoader(false);
        });
        return () => {
          unsubscribe();
        };
      } else {
        setShowLoader(true);
        const unsubscribe = await fetchClientsInvitedToJob(jobId, (clientList: any) => {
          setClientList(clientList);
          setShowLoader(false);
        });
        return () => {
          unsubscribe();
        };
      }
    } catch (err: any) {
      toast.error('Unable to load client list');
    }
  };

  useEffect(() => {
    loadClients();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid item lg={6} xs={12}>
      <DashboardCard
        isLoading={showLoader}
        label={isHired ? 'Clients Hired' : 'Clients Invited'}
        desc={
          isHired
            ? 'List of Clients Hired In This Job Opportunity'
            : 'List of Clients who invited to This Job Opportunity'
        }
        list={clientList}
        // eslint-disable-next-line
        onItemClick={(item: any) => {
          navigate('../clientInfo', { state: { clientId: item.id } });
        }}
      />
    </Grid>
  );
};
