export interface IJobInfo {
  id: string;
  title: string;
  description: string;
  employer: string;
  type: string;
  salary: string;
  availableTimings: IJobTimings[];
  notes: string;
  createdAt: string;
  updatedAt: string;
  numApplicants: number;
  preHireRequirements?: IPreHireReq[];
}

export interface IJobMetaInfo {
  totalRecords: number;
  currentPage: number;
  jobOpportunities: IJobInfo[];
  totalPages: number;
}

export interface IJobReportMetaInfo {
  totalRecords: number;
  currentPage: number;
  jobOpportunities: object[];
  totalPages: number;
}

export interface IJobTimings {
  timing: string;
}

export interface IEmploymentIntakeInfo {
  id: string;
  urls: string[];
  checkResume: boolean;
  checkEmailAccess: boolean;
  checkCertifications: boolean;
  checkPassport: boolean;
  checkEmploymentGap: boolean;
  gapDescription?: string;
}

export const emptyJobInfo = {
  id: '',
  title: '',
  description: '',
  employer: '',
  type: '',
  salary: '',
  availableTimings: [],
  notes: '',
  createdAt: '',
  updatedAt: '',
  numApplicants: 0,
};

export interface IConfirmClientEmployment {
  id: string;
  jobId: string;
  startAt: string;
}

export interface ITiming {
  timing: string;
}

export interface IPreHireReq {
  id: string;
  value: string;
}
