import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddCircleRounded } from '@mui/icons-material';
import { COLORS } from '../../../utils/colors';
import { FieldArray, Formik, type FormikHelpers } from 'formik';
import { IWorkshopInfo } from '../../../store/employmentModule/workshopModal';
import { WriteModeEnum } from '../../../utils/writeModeEnum';
import * as yup from 'yup';
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import { addNewWorkshop, editWorkshop } from '../../../services/workshop.service';
import { isNumber } from '../../../utils/common';
import { WorkshopTypes, WorkshopTypesEnum } from '../../../utils/workshopUtils';

interface IAddWorkshopProps {
  open: boolean;
  onClose: () => void;
  onAddWorkshop: () => void;
  mode: string;
  data: IWorkshopInfo | null | undefined;
}

const AddWorkshop = (props: IAddWorkshopProps) => {
  const { open, onClose, mode, data, onAddWorkshop } = props;
  const validationSchema = yup.object().shape({
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
    dateTime: yup
      .array()
      .of(
        yup.object().shape({
          timing: yup.string().required('Timing is required'),
        })
      )
      .min(1, 'At least one timing is required'),
    type: yup.string().required('Workshop Type is required'),
    meetingLink: yup.string().when('type', (typeValue) => {
      if (typeValue[0] === WorkshopTypesEnum.VIRTUAL) {
        return yup.string().required('Meeting Link is Required in Online Workshops');
      } else {
        return yup.string().notRequired();
      }
    }),
    location: yup.string().when('type', (typeValue) => {
      if (typeValue[0] === WorkshopTypesEnum.IN_PERSON) {
        return yup.string().required('Location is Required in Offline Workshops');
      } else {
        return yup.string().notRequired();
      }
    }),
    repeatWeekly: yup.boolean().optional(),
    repeatWeeks: yup.number().when('repeatWeekly', (repeatWeeklyValue) => {
      if (repeatWeeklyValue[0]) {
        return yup
          .number()
          .required('Repeat Weeks is required when Repeat Weekly is selected')
          .test({
            name: 'mustBeNumber',
            message: 'Repeat Weeks must be a valid number',
            test: isNumber,
          })
          .max(24, 'Repeat Weeks must be less than or equal to 24');
      } else {
        return yup.number().nullable();
      }
    }),
  });

  const initialValues: IWorkshopInfo = {
    id: data?.id || '',
    title: data?.title || '',
    description: data?.description || '',
    type: data?.type || '',
    location: data?.location || '',
    dateTime: data?.dateTime || [{ timing: dayjs().toISOString() }],
    repeatWeekly: data?.repeatWeekly || false,
    meetingLink: data?.meetingLink || '',
    repeatWeeks: data?.repeatWeeks || 0,
    attachments: data?.attachments || [],
    createdAt: data?.createdAt || dayjs().toISOString(),
    updatedAt: dayjs().toISOString(),
    isAttendanceMarked: data?.isAttendanceMarked || false,
    numClients: data?.numClients || 0,
  };

  const handleSubmit = async (values: IWorkshopInfo, { setSubmitting }: FormikHelpers<IWorkshopInfo>) => {
    try {
      const payload: any = { ...values };
      if (mode === WriteModeEnum.CREATE) {
        delete payload.id;
        await addNewWorkshop(payload);
      } else {
        await editWorkshop(payload);
      }
      onAddWorkshop();
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: '14px' } }}>
      <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Typography variant='h6'>{mode === WriteModeEnum.CREATE ? 'Create Workshop' : 'Update Workshop'}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          isInitialValid={false}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
            setFieldValue,
          }) => (
            <>
              {console.log(values)}
              <Box py={2}>
                <Grid container spacing={2} pb={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      name='title'
                      label='Title'
                      size='small'
                      type='text'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title ? errors.title : ''}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      select
                      label='Workshop Type'
                      size='small'
                      name='type'
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.type && Boolean(errors.type)}
                      helperText={touched.type && errors.type}>
                      {WorkshopTypes.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {values.type === WorkshopTypesEnum.VIRTUAL ? (
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name='meetingLink'
                        label='Meeting Link'
                        size='small'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.meetingLink}
                        error={Boolean(touched.meetingLink && errors.meetingLink)}
                        helperText={touched.meetingLink && errors.meetingLink ? errors.meetingLink : ''}
                      />
                    </Grid>
                  ) : null}
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      name='description'
                      label='Description'
                      size='small'
                      type='text'
                      multiline
                      rows={3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description ? errors.description : ''}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FieldArray
                      name='dateTime'
                      render={({ push, remove }) => (
                        <Grid container spacing={2} alignItems={'center'}>
                          {values.dateTime.map((timings, index) => {
                            let timing = '';
                            return (
                              <React.Fragment key={timings.timing.toString()}>
                                <Grid item md={10} xs={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    localeText={{ okButtonLabel: 'Select' }}>
                                    <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                      <DateTimePicker
                                        disablePast
                                        label={'Timing'}
                                        slotProps={{ textField: { size: 'small', error: false, fullWidth: true } }}
                                        name={`dateTime.${index}.timing`}
                                        defaultValue={dayjs(values?.dateTime[index]?.timing) || null}
                                        viewRenderers={{
                                          hours: renderTimeViewClock,
                                          minutes: renderTimeViewClock,
                                          seconds: renderTimeViewClock,
                                        }}
                                        onChange={(date: dayjs.Dayjs | null) => {
                                          timing = date?.toISOString() || '';
                                        }}
                                        onViewChange={(view) => {
                                          if (view === 'meridiem') {
                                            const updatedTimings = [...values.dateTime];
                                            updatedTimings[index].timing = timing || '';
                                            setFieldValue('dateTime', updatedTimings);
                                          }
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Grid>

                                <Grid item md={1} xs={6}>
                                  <IconButton
                                    color='error'
                                    disabled={values.dateTime.length === 1}
                                    onClick={() => {
                                      remove(index);
                                    }}>
                                    <DeleteIcon
                                      style={{
                                        color:
                                          values.dateTime.length === 1 ? COLORS.palette.lightRed : COLORS.palette.red,
                                      }}
                                    />
                                  </IconButton>
                                </Grid>
                              </React.Fragment>
                            );
                          })}

                          <Grid item md={1} xs={6}>
                            <IconButton
                              color='primary'
                              disabled={values.type === WorkshopTypesEnum.VIRTUAL}
                              onClick={() =>
                                push({
                                  timing: '',
                                })
                              }>
                              <AddCircleRounded
                                style={{
                                  color: COLORS.theme.primaryColor,
                                }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}></FieldArray>
                  </Grid>
                  {values.type === WorkshopTypesEnum.IN_PERSON ? (
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name='location'
                        label='Location'
                        size='small'
                        type='text'
                        multiline
                        rows={2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.location}
                        error={Boolean(touched.location && errors.location)}
                        helperText={touched.location && errors.location ? errors.location : ''}
                      />
                    </Grid>
                  ) : null}
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={<Checkbox name='repeatWeekly' checked={values.repeatWeekly} onChange={handleChange} />}
                      label='Repeat Info-Session Weekly'
                      disabled={mode === WriteModeEnum.EDIT}
                    />
                  </Grid>
                  {values.repeatWeekly ? (
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name='repeatWeeks'
                        inputMode='numeric'
                        label='Repeat for (weeks)'
                        size='small'
                        type='text'
                        onChange={(event) => {
                          const value = event.target.value;
                          const parsedValue = Number.parseInt(value, 10);
                          setFieldValue('repeatWeeks', isNaN(parsedValue) ? null : parsedValue);
                        }}
                        disabled={mode === WriteModeEnum.EDIT}
                        onBlur={handleBlur}
                        value={values.repeatWeeks}
                        error={Boolean(touched.repeatWeeks && errors.repeatWeeks)}
                        helperText={touched.repeatWeeks && errors.repeatWeeks ? errors.repeatWeeks : ''}
                      />
                    </Grid>
                  ) : null}
                </Grid>

                <Grid container justifyContent={'center'}>
                  <Grid item>
                    <LoadingButton
                      onClick={() => handleSubmit()}
                      label={mode === WriteModeEnum.CREATE ? 'Submit' : 'Update'}
                      loading={isSubmitting}
                      disabled={!isValid}
                      size='medium'
                      type='submit'
                      variant='contained'
                      styles={{ textTransform: 'none' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddWorkshop;
