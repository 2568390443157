import React from 'react';

export interface ISplitButtonOption {
  id: number;
  value: string;
  label: string;
  color?: Color;
  variant?: ButtonVariant;
  icon?: React.ReactNode;
  disabled?: boolean;
}

export interface ISortModel {
  field: string;
  sort: string;
}

export type Color = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

export type ButtonSize = 'small' | 'medium' | 'large';

export type ButtonVariant = 'text' | 'outlined' | 'contained';

export type ButtonType = 'button' | 'submit' | 'reset';

export type WriteMode = 'create' | 'edit';

export const isNumber = (value: any) => {
  return Number.isFinite(value) && !Number.isNaN(value);
};

export const authorizedOrganizations = [
  'Department of Children and Families',
  'Division of Workers Compensation',
  'Division of Unemployment Compensation',
  'School(s) (e.g., primary, secondary, university, college, vocational institutions)',
  'Hospital(s), Clinic(s), Physician(s)',
  'Law Enforcement Agencies',
  'Past/Prospective Employer(s)',
  'Other: [Please Specify]',
];

export const termsConditions = [
  `PINCC and/or its HRA service providers will request and/or release 
  information solely to determine appropriate training activities and supportive services, 
  or to assist me in obtaining unsubsidized employment after completing training/job search activities.`,
  `Information collected will be shared only upon request from authorized 
  entities and then solely on a strict need-to-know basis.`,
  `In compliance with state and federal laws, information may be 
  disclosed to state and federal auditors, monitors, and agents.`,
  ` PINCC or the HRA service provider will document each instance of a request/release in my PINCC file,
  detailing the date, other involved entity(ies), and type of information requested/released.`,
  'I may schedule an appointment with my PINCC point-of-contact to review these instances.',
  'All information will remain confidential within all authorized agencies.',
];
