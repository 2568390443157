import { Box, Grid, Typography, Modal, IconButton, List, ListItem, ListItemText, Divider } from '@mui/material';
import styled from '@emotion/styled';
import { ClientMapStatus } from '../../../utils/clientMapStatus';
import MilestonesCompletedUsersTable from '../../MilestonesCompletedUsersTable/MilestonesCompletedUsersTable';
import { AttendanceStatus } from '../../../utils/attendance';
import { Close } from '@mui/icons-material';

interface MilestonesUsersModalProps {
  open: boolean;
  onClose: Function;
  clientList: any[];
  milestoneIndex: string;
  milestoneName: string;
}

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const MilestonesUsersModal = (props: MilestonesUsersModalProps) => {
  const { open, onClose, clientList, milestoneIndex, milestoneName } = props;

  return (
    <ModalWrapper open={open} onClose={() => onClose()}>
      <Box sx={styles.centeredStyle}>
        {/* Modal Header */}
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6'>Milestone Information: {milestoneName}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        {/* Present list */}

        <Box mt={4}>
          <Divider textAlign='left'>
            <Typography fontSize={14} fontWeight={'bold'}>
              Present
            </Typography>
          </Divider>

          {clientList?.filter(
            (cl) =>
              cl.completedMilestones[parseInt(milestoneIndex)]?.attendance === AttendanceStatus.PRESENT &&
              cl.status !== ClientMapStatus.DROP_OUT
          ).length === 0 ? (
            <ListItem>
              <ListItemText primary='No client yet' primaryTypographyProps={{ textAlign: 'center' }} />
            </ListItem>
          ) : (
            <Box mt={2}>
              <MilestonesCompletedUsersTable
                clientList={clientList?.filter(
                  (cl) =>
                    cl.completedMilestones[parseInt(milestoneIndex)]?.attendance === AttendanceStatus.PRESENT &&
                    cl.status !== ClientMapStatus.DROP_OUT
                )}
                milestoneIndex={milestoneIndex}
              />
            </Box>
          )}
        </Box>

        {/* Absent list */}

        <Box mt={4}>
          <Divider textAlign={'left'}>
            <Typography fontSize={14} fontWeight={'bold'}>
              Absent
            </Typography>
          </Divider>
          <List>
            {clientList?.filter(
              (cl) =>
                cl.completedMilestones[parseInt(milestoneIndex)]?.attendance === AttendanceStatus.ABSENT &&
                cl.status !== ClientMapStatus.DROP_OUT
            ).length === 0 ? (
              <ListItem>
                <ListItemText primary='No client yet' primaryTypographyProps={{ textAlign: 'center' }} />
              </ListItem>
            ) : (
              <Box mt={2}>
                <MilestonesCompletedUsersTable
                  clientList={clientList?.filter(
                    (cl) =>
                      cl.completedMilestones[parseInt(milestoneIndex)]?.attendance === AttendanceStatus.ABSENT &&
                      cl.status !== ClientMapStatus.DROP_OUT
                  )}
                  milestoneIndex={milestoneIndex}
                />
              </Box>
            )}
          </List>
        </Box>

        {/* Dropout List */}
        <Box mt={4}>
          <Divider textAlign={'left'}>
            <Typography fontSize={14} fontWeight={'bold'}>
              Dropout
            </Typography>
          </Divider>
          <List>
            {clientList
              ?.filter(
                (cl) =>
                  parseInt(cl.currentMilestoneIndex) === parseInt(milestoneIndex) &&
                  cl.status === ClientMapStatus.DROP_OUT &&
                  cl.completedMilestones.length !== cl.milestones.length
              )
              .map((client: any, clientIndex: any) => (
                <ListItem key={clientIndex} disablePadding>
                  <ListItemText primary={client.firstName + ' ' + client.lastName} />
                </ListItem>
              ))}

            {clientList?.filter(
              (cl) =>
                parseInt(cl.currentMilestoneIndex) === parseInt(milestoneIndex) &&
                cl.status === ClientMapStatus.DROP_OUT
            ).length === 0 && (
              <ListItem>
                <ListItemText primary='No client yet' primaryTypographyProps={{ textAlign: 'center' }} />
              </ListItem>
            )}
          </List>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default MilestonesUsersModal;

const styles = {
  centeredStyle: {
    width: '900px',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    borderRadius: '14px',
    '@media (max-width: 480px)': {
      padding: '20px',
      margin: '10px',
    },
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 1,
  },
  listItemSecondaryText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
