import React, { useState } from 'react';
import { Box, Checkbox, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';

interface AttendanceSheetProps {
  loading: boolean;
  clients: any[];
  onSubmit: Function;
}

const AttendanceSheet = (props: AttendanceSheetProps) => {
  const { loading, clients, onSubmit } = props;
  const [selectedClients, setSelectedClients] = useState<any>([]);

  const handleClientSelect = (clientId: string) => {
    if (selectedClients.includes(clientId)) {
      setSelectedClients(selectedClients.filter((id: string) => id !== clientId));
    } else {
      setSelectedClients([...selectedClients, clientId]);
    }
  };

  return (
    <Box>
      <Box display={clients?.length > 0 ? 'block' : 'none'}>
        <Grid container>
          <Grid item xs={12}>
            <List>
              {clients?.map((client: any) => (
                <ListItem
                  key={client.id}
                  disablePadding
                  onClick={() => {
                    handleClientSelect(client.id);
                  }}>
                  <ListItemText primary={client.firstName + ' ' + client.lastName} />
                  <Checkbox
                    checked={selectedClients.includes(client.id)}
                    onChange={() => {
                      handleClientSelect(client.id);
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <Grid container direction='column' alignItems='center' justifyContent='center' pt={2}>
          <Grid item xs={12}>
            <LoadingButton
              size='medium'
              color={'primary'}
              variant={'contained'}
              loading={loading}
              disabled={selectedClients.length > 0 ? false : true}
              onClick={() => {
                onSubmit(selectedClients);
              }}
              startIcon={<CheckCircleOutlineOutlined />}
              typographyComp={
                <Typography variant='body2' textTransform={'none'} noWrap>
                  Mark Attendance
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box display={clients?.length > 0 ? 'none' : 'block'}>
        <Typography variant='body2' textAlign={'center'} py={2}>
          No clients available or attendance already marked.
        </Typography>
      </Box>
    </Box>
  );
};

export default AttendanceSheet;
