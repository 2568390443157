import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { convertTimeStampToDate } from '../../utils/dateTime';
import { COLORS } from '../../utils/colors';

interface MilestonesCompletedUsersTableProps {
  clientList: any[];
  milestoneIndex: string;
}

const MilestonesCompletedUsersTable = (props: MilestonesCompletedUsersTableProps) => {
  const { clientList, milestoneIndex } = props;

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none', border: `1px solid ${COLORS.palette.borderColor}` }}>
      <Table sx={{ minWidth: 700 }} size='small'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>#</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Client Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Attendance Marked By</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Attendance Marked Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientList.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>No client yet</TableCell>
            </TableRow>
          ) : (
            clientList.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.firstName + ' ' + row.lastName}</TableCell>
                <TableCell>
                  {row.completedMilestones[milestoneIndex]?.markedBy
                    ? row.completedMilestones[milestoneIndex].markedBy.firstName +
                      ' ' +
                      row.completedMilestones[milestoneIndex].markedBy.lastName
                    : '--'}
                </TableCell>
                <TableCell>
                  {row.completedMilestones[milestoneIndex]?.completionDate
                    ? convertTimeStampToDate(row.completedMilestones[milestoneIndex].completionDate).format(
                        'MM/DD/YYYY'
                      )
                    : '--'}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MilestonesCompletedUsersTable;
