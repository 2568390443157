import { onSnapshot, collection } from 'firebase/firestore';
import { db, functions } from '../config/firebaseApp';
import { httpsCallable } from 'firebase/functions';

export const saveUserMessage = async (senderId: string, channelId: string, message: string, receiverId?: string) => {
  let message_payload: any = {
    senderId: senderId,
    receiverId: receiverId,
    channelId: channelId,
    text: message,
  };
  await httpsCallable(functions, 'saveUserMessage')(message_payload);
};

export const updateMessage = async (senderId: string, channelId: string) => {
  let payload: any = {
    senderId: senderId,
    channelId: channelId,
  };
  await httpsCallable(functions, 'markMessagesRead')(payload);
};

export const getChatMessagesByChannelId = (channelId: string, callback: Function) => {
  try {
    if (!channelId) {
      throw new Error();
    }

    const collectionRef = collection(db, 'userMessages', channelId, 'messages');
    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const messageList: any[] = [];

      querySnapshot.forEach((doc) => {
        const messageData = doc.data();
        const updatedData = { id: doc.id, ...messageData };
        messageList.push(updatedData);
      });
      messageList.sort((a, b) => a.timestamp - b.timestamp);
      callback(messageList);
    });

    return unsubscribe;
  } catch (err: any) {
    console.log('Error Retrieving Chat Messages!');
    return () => {};
  }
};
